.scheduled-event {
    .fluid-form>div.box-round {
        display: block;
    }
    .fluid-form>div:not(.button-row)>div>input[type=text] {
        width: auto;
        width: ~"calc(100% - 20px)";
    }

    .fluid-form>div:not(.button-row)>div>textarea {
        width: auto;
        width: ~"calc(100% - 18px)";
    }
    .fluid-form>div:not(.button-row)>div>select {
        width: auto;
    }
    .fluid-form {
        h2,
        h3,
        h4 {
            margin-left: 10px;
            margin-right: 10px;
        }
        div.fluid-one-line {
            //used for radios and checkboxes
            display: flex;
            width: 95%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            >div:not(.fluid-one-line) {
                width:100%;
                flex-wrap: wrap;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 5px;
                input[type="radio"],
                input[type="checkbox"] {
                    margin: .3em 5px 0 0;
                }
                textarea {
                    width: 100%;
                }
                label {
                    padding: 0 0 0 5px;
                }

            }
        }
        div.fluid-checkbox-line {
            //used for radios and checkboxes
            display: flex;
            width: 95%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            padding-left: 4px;
            padding-top: 0px;
            >div:not(.fluid-checkbox-line) {
                width:100%;
                flex-wrap: wrap;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 5px;
                padding-left: 4px;
                padding-top: 0px;
                input[type="radio"],
                input[type="checkbox"] {
                    margin: .3em 5px 0 0;
                }
                textarea {
                    width: 100%;
                }
                label {
                    padding: 0 0 0 5px;
                }
            }
        }
        &>div {
            //fluid rows
            display: flex;
            align-items: flex-start;
            &>div {
                //fluid row blocks
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                flex-basis: 25%;
                position: relative;
                label {}
                em:nth-child(3) {
                    position: absolute;
                    top: 16px;
                    right: -10px;
                    a {
                        font-size: medium;
                        margin-right: -5px;
                    }
                    font-style: normal;
                }
                b + em:nth-child(2) {
                    position: relative;
                    font-size: 150%;
                    right: -3px;
                    a {
                        font-size: medium;
                        margin-right: -5px;
                    }
                    font-style: normal;
                }
                b + em:nth-child(3) {
                    position: relative;
                    top: -3px;
                    right: 3px;
                    a {
                        font-size: medium;
                        margin-right: -5px;
                    }
                    font-style: normal;
                }
                &>div {
                    em {
                        position: relative;
                        top: -3px;
                        right: 3px;
                        a {
                            font-size: medium;
                            margin-right: -5px;
                        }
                        font-style: normal;
                    }
                }
            }
            &>div:not(.fluid-one-line):nth-child(2) {
                select+em {
                    font-style: normal;
                }
            }
        }
        input {
            line-height: 1.5em;
            font-size: 1.3em;
        }
        input+em,
        select+em,
        textarea+em,
        .ui-datepicker-append+em,
        .ui-datepicker-trigger+em {
            font-size: 150%;
            margin-left: 5px;
            vertical-align: top;
        }
        @media screen and (-webkit-min-device-pixel-ratio:0) {
            /*safari and chrome*/
            select {
                height: 32px;
                line-height: 32px;
            }
        }
        .error-message {
            margin: 0;
        }
    }
    .fluid-form>div:not(.button-row):nth-of-type(odd):not(.button-row) {
        background-color: #fff;
    }
    .step-content {
        padding: 0 ~"calc(50% - 360px)";
        max-height: calc(~"97vh - 100px");
    }
    fluid-form>div:not(.button-row)>div {
        margin-left: 11px;
        margin-right: 5px;
        margin-top: -5px;
        margin-bottom: 5px;
    }
}
.scheduling-time-event {
    width: 5em;
    padding-left: 20px !important;
}
.scheduling-frequency-width {
   margin-left: 80px;
}
.scheduling-frequency-daterequired {
    b + em:nth-child(3) {
        font-size: 150%;
        position: relative;
        right: 6px;
    }
}

[dir=rtl] .scheduling-frequency-daterequired {
    b + em:nth-child(3) {
        inset-inline-start: 6px;
    }
}
.scheduling-record-row{
  margin-bottom: 15px;
}
.scheduling-div-background-color{
    div:not(.button-row):nth-of-type(odd):not(.feedback-error):not(.feedback-confirm) {
        background-color: #fff !important;
    }
}
.scheduling-start-date{
    margin-top: 15px;
}
.scheduling-h3 {
    height: 25px;
    span {
        font-weight: bold;
        float: left;
    }
}
.scheduling-span-link {
    float: right;
    margin-right: 12px;
}
.scheduling-feedback-message {
	div>p {
		margin-left: 0px !important;
	}
}