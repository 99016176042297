.groupDefaultInfo span {
    font-weight: bold;
    font-size: 14px;
    font-family: Arial;
}

.sticky-block{
    position: sticky;
    top: -2px;
    background-color: #ffffff;
    z-index: 100;
}

#pagePermissionForm .button-row.action-bar {
    bottom: -10px;
    height: auto; 
}

.page-perms-multiselect {
    font-family: @pds-font-family;
    font-size: 14px;
    line-height: 24px;
    .page-perms-multiselect-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background: none;
        background-color: #FFFFFF;
        border: 1px solid #838E91;
        padding: 3px 7px 3px 10px;
        font-weight: 600;
        color: #333333;
        margin: 0;
        height: 32px;
        width: 100%;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-align: start;
            text-overflow: ellipsis;
        }
        &:hover {
            background-color: #F1F4F4;
            text-decoration: underline;
            cursor: pointer;
        }
        &.dropdown-open {
            border: 1px solid #0075DB;
            background-color: #C2E2FF;
        }
    }
    .popup-box {
        top: 10px;
        .page-perms-multiselect-menu {
            border: 1px solid #838E91;
            border-radius: 4px;
            box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.2);
            padding-top: 5px;
            ul {
                margin: 0 5px;
                li {
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    label {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        padding: 0 10px;
                        border-radius: 4px;
                        &:hover {
                            background-color: #F1F4F4;
                            cursor: pointer;
                            span { 
                                text-decoration: underline;
                            }
                        }
                        &.is-selected {
                            background-color: #C2E2FF;
                            &:hover {
                                cursor: pointer;
                                span { 
                                    text-decoration: underline;
                                }
                            }
                        }
                        input[type="checkbox"] {
                            transform: scale(1.5);
                            margin: 0;
                        }
                        span {
                            padding-top: 5px;
                            padding-bottom: 6px;
                            margin-left: 13px;
                        }
                    }
                }
            }
        }
    }
}

.pss-accordion {
    font-family: @pds-font-family;
    
    .pss-main-section-title {
        font-size: 18px; 
        margin-bottom: 0;
        margin-right: 0;
    }
    .pss-accordion-filter-sticky{
        position: sticky;
        top: 81px;
        background-color: #ffffff;
        z-index: 100;
    }
    .pss-accordion-filter-section {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        .pss-accordion-filter {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            flex-shrink: 0;
            min-width: 0;
            &#accordion-context-filter {
                flex-basis: 148px;
            }
            &#accordion-pages-filter {
                flex-basis: 163px;
            }
            &#accordion-access-level-filter {
                flex-basis: 290px;
            }
        }
        .pss-accordion-search {
            input {
                width: 430px;
                height: 24px;
                border: 1px solid #838E91;
                border-radius: 4px;
            }
        }
    }

    div.pss-accordion-dialog-scrollable-list {
        height:268px;
        overflow-y:auto;
        margin-left: 1.5em;
        margin-right: 1.5em;

        .pss-accordion-item-options .pss-accordion-item-option label{
            font-size: 14px;
        }
    }

    .pss-accordion-dialog-subgroup-header {
        .pss-accordion-subgroup-header; 
        display: block; 
        border-bottom: 0px;
      
        .pss-accordion-all-group-filter-section {
            .pss-accordion-filter-section;
             margin-bottom: 0;
            .pss-accordion-search {
                font-size: 14px;
                font-weight: 400;
                padding: 0 50px 0 0px;
                select {
                    width: 200px; 
                }
                input, input:focus {
                    width: 230px;
                    height: 24px;
                    border: 1px solid #838E91;
                    border-radius: 4px;
                    outline:#838E91;
                }
                select:focus {
                    outline:#838E91;
                }
            }
        }

        .pss-accordian-child-perms-message {
            display: flex;
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: bold;
        }
    }

   
    .pss-accordion-controls {
        display: flex;
        justify-content: flex-end;
        & > :first-child {
            border-right: 1px solid @common-navy;
        }
        a {
            color: @common-navy;
            font-size: 16px;
            line-height: 24px;
            padding-left: 10px;
            padding-right: 10px;
        }
        a:hover {
            color: @link-color-hover;
            text-decoration: underline;
        }
    }

    .pss-accordion-section{
        padding-bottom: 50px;
    }

    .pss-accordion-group {
        padding-left: 15px;
        color:#333;
    }

    .pss-accordion-group-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #cccccc;
        display: flex;
        align-items: center;
        padding-left: 1.5em;
        background-repeat: no-repeat;
        background-position: 0.5em 1em;
        [dir=rtl] & {
            background-position: right 0.5em top 1em;
        }
        .pss-accordion-group-title {
            margin: 0 5px;
            flex: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333;
        }

        &.expanded {
            background-image: url("../img/arrow-down-blk.svg");
        }
        &.collapsed {
            background-image: url("../img/arrow-right-blk.svg");
            [dir=rtl] & {
                background-image: url("../img/arrow-left-blk.svg");
            }
        }
        .pss-accordion-select {
            border: 1px solid #838E91;
            border-radius: 4px;
            padding: 5px 25px 5px 5px;
            font-size: 14px ; 
            font-weight: 600; 
            font-family: 'Open Sans';
            appearance: none;

            &.arrow{
                background: url("../img/arrow-down-blk.svg");
                background-position: right .7em top 50%, 0 0;
                [dir=rtl] & {
                    background-position: .7em 50%, 0 0;
                }
                background-size: .65em auto, 100%;
                background-repeat: no-repeat;
            }
        }
    }
    .pss-accordion-subgroup-header{
         margin-top: 0px /* !important */;
        .pss-accordion-group-header;
            font-size: 16px;
    }

    .pss-accordion-item-list {
        .pss-accordion-item-row {
            padding: 6px 40px 6px 10px;
            display: flex;
            &.item-row-odd {
                background-color: #F4F4F4;

            }
            &:not(.ng-hide) {
                margin-top:22px;
            }
            &:not(.ng-hide) ~ .pss-accordion-item-row {
                margin-top: 0px;
            }
            .pss-accordion-item-name {
                flex: 1 0 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
            }

            .pss-accordion-item-options {
                display: flex;
                flex-shrink: 0;
                flex-basis: 450px;
                justify-content: space-between;

                .pss-accordion-item-option {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 10px;
                    align-items: flex-start;

                    label {
                        font-weight: normal;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        text-decoration: none;
                        padding-left: 3px;
                    }
                    
                    input[type="radio"] {
                        transform: scale(1.6);
                        &:checked + label {
                            font-weight: bold;
                        }
                        & + label::after {
                            content: attr(data-text);
                            height: 0;
                            visibility: hidden;
                            overflow: hidden;
                            user-select: none;
                            pointer-events: none;
                            font-weight: bold;
                            
                            @media speech {
                                display: none;
                            } 
                        }
                    }
                }
            }

            .pss-accordion-item-allgroups-options {
                display: flex;
                flex-shrink: 0;
                flex-basis: 450px;
                justify-content: right;
                [dir=rtl] & {
                    justify-content: left;
                }
                .pss-accordion-item-allgroups-option {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 10px;
                    align-items: flex-start;
                    pds-icon {
                        width: 18px;
                        height: 18px;
                        vertical-align: middle;
                        [dir=rtl] & {
                          transform: scaleX(-1);
                        }
                    }
                    pds-icon:hover {
                        fill: #0075db;
                    }
                }
            }
        }

        .pss-accordion-dialog-list-header {
            .pss-accordion-item-row; 
            margin-top: 0;
            background-color:#00427C; 
            color:white; 
            margin-left: 1.5em;
            margin-right: 1.5em;
            border-radius: 4px;
        }
    }
}


.pagePermissionBody {
    .pds-scope {
        .ui-dialog-titlebar-close {
            display: none;
        }
        
        .ui-dialog-title {
            float:none;
            display: block;
            text-align: center;
        }  
    }      

    .button-row.action-bar.pds-scope button, 
    .pds-scope  div.ui-dialog-buttonset button{
            font-family: Open Sans, sans-serif; 
            font-weight: 600;
            font-size: 16px;
    }

    .button-row.action-bar.pds-scope button:hover:not([disabled]), //buttons on the page bottom
    .button-row.action-bar.pds-scope button:focus:not([disabled]), //buttons on the page bottom
    .pds-scope div.ui-dialog-buttonset button:hover:not([disabled]), //buttons on the dialog
    .pds-scope div.ui-dialog-buttonset button:focus:not([disabled]) {
        background-color: #F1F4F4 ;
        color:#0075db ;
        text-decoration: underline;
    }

    .button-row.action-bar.pds-scope button.pds-primary:hover:not([disabled]), 
    .button-row.action-bar.pds-scope button.pds-primary:focus:not([disabled]),
    .pds-scope div.ui-dialog-buttonset button.pds-primary:hover:not([disabled]), 
    .pds-scope div.ui-dialog-buttonset button.pds-primary:focus:not([disabled]) {
        background-color: #00427C;
        border-color: #00427C; 
        color:white;
        text-decoration: underline;
    }


    .ui-dialog.pds-scope,
    .ui-dialog.pds-scope .ui-dialog-buttonpane {
        padding: 0 0 0 0;
    }  
}