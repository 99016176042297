html:not([dir=rtl]) body.admin-view #pds-header {
    background: linear-gradient(to right, #0075DB, #00427C);
}
html[dir=rtl] body.admin-view  #pds-header {
    background: linear-gradient(to left, #0075DB, #00427C);
}

#seatingChart-header{
width:75px;
}
