.health-dashboard-div-height {
  max-height:280px;
}
.health-concerns-textareas {
  max-width: 515px;
  width: 515px;
  height: 100px;
}
.health-contactlog-textareas {
  max-width: 515px;
  width: 515px;
  height: 70px;
}
.health-medication-textareas {
  max-width: 515px;
  width: 515px;
  height: 100px;
}
.health-medication-migrate-time-textarea {
  padding: 3px;
  width: 315px;
}
.health-medication-doses-textareas {
  max-width: 397px;
  width: 397px;
  max-height: 150px;
  height: 100px;
}
.health-medication-inventory-textareas {
  max-width: 397px;
  width: 397px;
  max-height: 150px;
  height: 65px;
}
.health-medication-inventory-display-textareas {
  margin-left:7px;
  margin-top:10px;
  max-width: 95%;
  width: 100%;
  max-height: 150px;
  height: 65px;
}
.health-medication-unit-div {
  max-width: 45%;
}
.health-medication-unit-input {
  max-width: 85%;
}
.health-officevisits-textareas {
  max-width: 505px;
  width: 505px;
  height: 65px;
}
.health-other-textareas {
  max-width: 600px;
  width: 600px;
  height: 65px;
}
.health-pewaiver-textareas {
  max-width: 515px;
  width: 515px;
  height: 100px;
}
.health-physical-textareas {
  max-width: 515px;
  width: 515px;
  height: 65px;
}
.health-screenwaiver-textareas {
  max-width: 515px;
  width: 500px;
  height: 65px;
}
.health-monitoring-notes-textareas {
  margin-left:7px;
  margin-top:10px;
  max-width: 95%;
  width: 100%;
  max-height: 150px;
  height: 125px;
}
.health-viewlogscreen-text {
  font-weight: normal;
  font-size: 10px;
  color: #333333;
  float: right;
}
select.health-codeset {
  max-width: 100%;
  width: 94%
}
.fluid-form .autocomplete-combobox-container>span {
  margin: 0 0 0 0!important;
}
#medicationSearch_value {
  width: calc(~"221.5% + 10px");
}
#medicationSearch_dropdown {
  width: calc(~"221.5% + 34px")!important;
}
input.medication-category {
  max-width: 100%;
  width: 94%;
}

.healthDrawerTextArea {
  padding: 3px;
  width: 200px;
}

.healthDrawerInventoryCount {
  display: inline;
  color: #222;
  font-weight: normal;
}

.healthInsuranceSelectDropDownWrapper{
  display: contents;
  max-width: 600px;
}

.healthInsuranceSelectDropDown {
  max-width: 590px;
}
.health-popup-student-name {
  padding: 0px 2px 2px 5px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.health-drawer-student-name {
  margin: 0 12px 12px;
  padding: 2px 2px 2px 5px;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.healthDrawer-2-col {
  width: 48%;
  background-color:white !important;
}
div.healthDrawer-2-col input.input-with-suffix {
  min-width: 100px;
  width: 7em !important;
}
div.healthDrawer-2-col input,
div.healthDrawer-2-col select {
  min-width: 210px;
  width: 210px;
}
div.healthDrawer-2-col input.timeEntry {
  min-width: 195px;
  width: 195px;
}
div.healthDrawer-2-col input.psDateWidget {
  min-width: 165px;
  width: 165px;
}
form.officeVisitDrawer h2{
  margin:10px 10px 0px 10px;
  width:91.5%;
}
div.healthDrawer-3-col{
  width: 31%;
}
div.healthDrawer-3-col input{
  max-width: 115px;
}
form.officeVisitDrawer div.fluid-form{
  margin-top:0;
}
form.officeVisitDrawer div.middle-div{
  background-color:white !important;
}
form.screeningDrawer div.middle-div{
  background-color:white !important;
}
form.screeningDrawer span.checkbox-span{
    padding-left:106px;
}
form.screeningDrawer div.fluid-form{
    border:none;
}
form.screeningDrawer #system-review-header{
   background-color:#b2D0E9;
}
div.screeningDrawer-3-colA {
  width:25%;
}
div.screeningDrawer-3-colB {
  width:22%;
}
div.screeningDrawer-3-colC {
  width:47%;
}
form.screeningDrawer input.checkbox-input{
    margin-left:20px;
}
form.screeningDrawer h2{
    margin: 5px 0px 3px 1px;
    padding-left: 14px;
    padding-top: 6px;
}
.health-screeningDrawer-textareas {
    width:233px;
    height:34px;
}
.dailylog-attendance-header{
  margin-bottom: 10px;
}
div.immunizationDrawer h2{
  margin:10px 10px 0px 10px;
  width:91.5%;
}
div.immunizationDrawer div.fluid-form{
 border:none;
}
div.immunizationDrawer div.middle-div{
 background-color:white !important;
}
.health-immunization-textareas {
  max-width: 505px;
  width: 505px;
  height: 65px;
}

.immunization-popup::before {
	height: 16px;
	width: 16px;
	background:
		url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8IS0tIENyZWF0ZWQgd2l0aCBJbmtzY2FwZSAoaHR0cDovL3d3dy5pbmtzY2FwZS5vcmcvKSAtLT4NCg0KPHN2Zw0KICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIg0KICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyINCiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyINCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICB2ZXJzaW9uPSIxLjEiDQogICB3aWR0aD0iMTYiDQogICBoZWlnaHQ9IjE2Ig0KICAgaWQ9InN2ZzExMTUxIj4NCiAgPGRlZnMNCiAgICAgaWQ9ImRlZnMxMTE1MyIgLz4NCiAgPG1ldGFkYXRhDQogICAgIGlkPSJtZXRhZGF0YTExMTU2Ij4NCiAgICA8cmRmOlJERj4NCiAgICAgIDxjYzpXb3JrDQogICAgICAgICByZGY6YWJvdXQ9IiI+DQogICAgICAgIDxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0Pg0KICAgICAgICA8ZGM6dHlwZQ0KICAgICAgICAgICByZGY6cmVzb3VyY2U9Imh0dHA6Ly9wdXJsLm9yZy9kYy9kY21pdHlwZS9TdGlsbEltYWdlIiAvPg0KICAgICAgICA8ZGM6dGl0bGU+PC9kYzp0aXRsZT4NCiAgICAgIDwvY2M6V29yaz4NCiAgICA8L3JkZjpSREY+DQogIDwvbWV0YWRhdGE+DQogIDxnDQogICAgIGlkPSJsYXllcjEiPg0KICAgIDxwYXRoDQogICAgICAgZD0iTSA4LjUxNDM1NjUsMC4wNTI0NzUxMSAwLjA0NTA0OTUxLDEwLjk5OTUwNSAxNS45OTI1NzQsMTEuMDQ0NTU0IHoiDQogICAgICAgaWQ9InBhdGgxMTE2MSINCiAgICAgICBzdHlsZT0iZmlsbDojNjY2NjY2O2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPg0KICA8L2c+DQo8L3N2Zz4=") no-repeat scroll center top rgba(0, 0, 0, 0);
	background-size: 100%;
	content: "";
	position: absolute;
	top: -15px;
	left: 10px;
}

.immunization-popup {
	position: absolute;
	border: 1px solid #666;
	border-top-width: 4px;
	box-shadow: 0 5px 10px #666;
	background: #FFF;
	z-index: 100;
}

.immunization-popup ul {
	padding: 2px;
	margin: 0;
}

.immunization-popup ul li {
	list-style-type: none;
}

.immunization-popup select {
	width: 19em;
}

#healthAlertDiv p {
  margin: 0;
}
//This tag is used from reporting folder
[dir=rtl] #CA_Immunization p{
    display: inline-flex;
}

[dir=rtl] #exemptionVaccineCell{
    inset-inline-start:50px
}

[dir=rtl] #img-vaccerror{
    padding-inline-end:1px;
}

.immunization-tooltip-table,
.immunization-tooltip-table td {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    text-align: left;
}

.immunization-tooltip-table td:nth-child(1) {
    min-width: 150px;
}

.immunization-tooltip-table td:nth-child(2) {
    min-width: 400px;
}

.padding-0{
  padding: 0 !important;
}

.addOptVaccinebtn{
  float: right;
  margin-bottom: 10px;
}

/** Added to maintain the Drawer height  */
.immunization-height {
  height: 98% !important;
}

form.visionScreeningDrawer h2 {
  margin:10px 10px 0px 10px;
  width:91.5%;
}

form.visionScreeningDrawer div.middle-div {
  background-color:white !important;
}

form.visionScreeningDrawer span.checkbox-span{
    padding-left:106px;
}
.marginTop-0 {
    margin-top:0 !important;
}
form.visionScreeningDrawer select:disabled{
    opacity: 1.2;
}
button.health-medication-time-remove-button {
  border: none;
  background-color: transparent;
  font: inherit;
  color: #0075ff;
  cursor: pointer;
  outline: none;
}

/* Style when the button is disabled */
button.health-medication-time-remove-button[disabled] {
  cursor: not-allowed;
  color: gray;
}

.health-medication-dosage-container {
    display: flex;
    flex-direction: column;
}

.dosage-time-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 13px;
}

.inline-element {
    display: inline-block;
    vertical-align: middle;
}

.time-label {
    flex: 1; /* Distribute available space evenly */
    margin-right: 10px;
}

.input-container {
    flex: 2; /* Distribute available space evenly */
    margin-right: 10px;
}
.disabled {
  opacity: 0.5; /* Lower opacity to indicate disabled state */
  pointer-events: none; /* Disable pointer events on the element */
  cursor: not-allowed; /* Change cursor to indicate not allowed */
}

.grid-row-scheduled-time {
    display: flex; /* Use flexbox to arrange columns horizontally */
    align-items: center; /* Vertically center the items in the row */
    gap: 10px; /* Add space between columns */
}

.health-codeset-scheduled-time {
    width: 100px;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
}

.custom-column {
    flex: 0 0 calc(50% - 20px);
    margin: 10px;
}

.health-medication-remove-button {
    background-color: #0066A5;
    background-position: left bottom;
    background-repeat: repeat-x;
    border: 0 none;
    border-radius: 4px;
    color: #FFFFFF;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    padding: .2em .4em;
    margin: 0px;
    margin-inline-start: 10px;
    font-size: 100%;
    line-height: 1.2em;
}

.dosageData {
    display: block;
}