#ar-buttonDiv {
  float: left;
  width: 30%;
  padding: 5px;
  box-sizing: border-box;
}
#ar-buttonDiv button {
  width: 100%;
}
#ar-editCatalog-button {
  margin-top: 10px;
}
#ar-infoDiv {
  float: left;
  width: 70%;
  padding: 5px;
  box-sizing: border-box;
}