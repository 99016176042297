.pds-container {
        .class-block .pds-menu-dropdown, .pds-search-container .pds-search-container-inner > .pds-navigation-search ul, .pds-input-checkbox > ul,
.pds-input-radio > ul, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user ul, .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme ul, .pds-nav-primary ul, .pds-nav-secondary ul, .pds-app-actions, .pds-menu-dropdown, .pds-user-menu-theme ul {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  margin-bottom: 0;
  list-style: none; }

.pds-app-header-bar header a, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a,
.pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name, .pds-nav-primary ul a, .pds-nav-secondary ul a, .pds-nav-primary .pds-nav-close a, .pds-nav-primary .pds-nav-category a, .pds-nav-secondary .pds-nav-close a, .pds-nav-secondary .pds-nav-category a, .class-block > div .class-block-link, .class-block .class-block-options, .pds-app-actions > li > a, .pds-tabs li > a, .pds-scoped-tabs .pds-tabs li > a, .pds-global-breadcrumbs .pds-breadcrumbs li > a, .pds-scoped-breadcrumbs .pds-breadcrumbs li > a, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link {
  color: #333333; }
  .pds-app-header-bar header a:hover, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a:hover,
  .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name:hover, .pds-nav-primary ul a:hover, .pds-nav-secondary ul a:hover, .pds-nav-primary .pds-nav-close a:hover, .pds-nav-primary .pds-nav-category a:hover, .pds-nav-secondary .pds-nav-close a:hover, .pds-nav-secondary .pds-nav-category a:hover, .class-block > div .class-block-link:hover, .class-block .class-block-options:hover, .pds-app-actions > li > a:hover, .pds-tabs li > a:hover, .pds-global-breadcrumbs .pds-breadcrumbs li > a:hover, .pds-scoped-breadcrumbs .pds-breadcrumbs li > a:hover, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile:hover, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link:hover, .pds-app-header-bar header a:focus, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a:focus,
  .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name:focus, .pds-nav-primary ul a:focus, .pds-nav-secondary ul a:focus, .pds-nav-primary .pds-nav-close a:focus, .pds-nav-primary .pds-nav-category a:focus, .pds-nav-secondary .pds-nav-close a:focus, .pds-nav-secondary .pds-nav-category a:focus, .class-block > div .class-block-link:focus, .class-block .class-block-options:focus, .pds-app-actions > li > a:focus, .pds-tabs li > a:focus, .pds-global-breadcrumbs .pds-breadcrumbs li > a:focus, .pds-scoped-breadcrumbs .pds-breadcrumbs li > a:focus, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile:focus, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link:focus {
    text-decoration: none; }

.pds-central .pds-fields .pds-input-text .pds-valid-indicator,
.pds-central .pds-fields .pds-input-select .pds-valid-indicator,
.pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator,
.pds-central .pds-fields .pds-input-textarea .pds-valid-indicator,
.pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator, .pds-central-divider, button, pds-icon, .pds-central, pds-grid-widget .pds-filter-controls .pds-selected-items, .pds-selection-status-item, .pds-help-menu-content, .pds-gw-multi-select-filter-content .pds-selected-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.class-block .pds-menu-dropdown, .pds-search-container .pds-search-container-inner > .pds-navigation-search ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .class-block .pds-menu-dropdown .section-header, .pds-search-container .pds-search-container-inner > .pds-navigation-search ul .section-header {
    font-size: 10px;
    color: #333333;
    background: #F1F4F4;
    padding: 0 0.5rem; }
  .class-block .pds-menu-dropdown li, .pds-search-container .pds-search-container-inner > .pds-navigation-search ul li {
    border-bottom: 1px solid #C4C9CA; }
    .class-block .pds-menu-dropdown li:last-child, .pds-search-container .pds-search-container-inner > .pds-navigation-search ul li:last-child {
      border: none; }

html, body, .pds-numbered-slider-content, .pds-app-nav-content,
.class-block,
.pds-section-select-content,
.pds-date-widget-content,
.pds-calendar-selectors,
.pds-calendar-days,
.pds-number-widget-content,
.pds-dot-background,
.pds-progress-bar,
.pds-radio-button-widget-content,
.pds-reporting-term-select-container,
.pds-search-container,
.pds-select-widget-content,
.pds-text-widget-content,
.pds-textarea-widget-content,
.pds-checkbox-widget-content,
.pds-app-switcher-content, .pds-gw-date-filter-content button.pds-date-widget-dialog-trigger-button,
.pds-gw-date-filter-content select.pds-gw-date-filter-operator-select,
.pds-gw-date-filter-content input, .pds-grid-widget-filter-content, .pds-grid-widget-pagination-control-content, .pds-gw-multi-field-filter-content input, .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:checked ~ .pds-label-text:first-of-type,
.pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-gw-number-filter-content select,
.pds-gw-number-filter-content input, .pds-gw-pill-select-filter-content button, .pds-gw-select-filter-content select, .pds-gw-text-filter-content input {
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #333333; }

.pds-app-switcher-contents button.pds-app-switcher-trigger {
  border: none;
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: block;
  height: auto;
  text-align: left;
  background: none; }
  .pds-app-switcher-contents button.pds-app-switcher-trigger:hover:not([disabled]) {
    background-color: transparent; }

button.pds-button-inverted, pds-item-controls .pds-item-control-contents button, pds-item-controls .pds-item-control-contents button[type=button] {
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
  height: 32px;
  width: 56px; }
  button.pds-button-inverted pds-icon, pds-item-controls .pds-item-control-contents button pds-icon, pds-item-controls .pds-item-control-contents button[type=button] pds-icon {
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    fill: #FFFFFF; }
  button.pds-button-inverted:hover, pds-item-controls .pds-item-control-contents button:hover {
    cursor: pointer;
    background-color: #F1F4F4;
    border-color: #F1F4F4;
    color: #000000; }
    button.pds-button-inverted:hover pds-icon, pds-item-controls .pds-item-control-contents button:hover pds-icon {
      fill: #000000; }
  @media (max-width: 768px) {
    button.pds-button-inverted, pds-item-controls .pds-item-control-contents button, pds-item-controls .pds-item-control-contents button[type=button] {
      height: 44px; } }

form,
.pds-fields,
.pds-input-text,
.pds-input-date,
.pds-input-checkbox,
.pds-input-checkbox-single,
.pds-checkbox-only,
.pds-input-radio,
.pds-input-textarea,
.pds-input-select,
.pds-switch,
.pds-input-rating-star,
.pds-input-upload, button, a.pds-primary-btn,
input.pds-primary-btn, a.pds-primary-inverse-btn,
input.pds-primary-inverse-btn, a.pds-secondary-btn,
input.pds-secondary-btn, a.pds-secondary-inverse-btn,
input.pds-secondary-inverse-btn, a.pds-tertiary-btn,
input.pds-tertiary-btn, .pds-message-success, .pds-message-info, .pds-message-error, .pds-message-warning, .pds-message-bland, html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  form *, .pds-fields *, .pds-input-text *, .pds-input-date *, .pds-input-checkbox *, .pds-input-checkbox-single *, .pds-checkbox-only *, .pds-input-radio *, .pds-input-textarea *, .pds-input-select *, .pds-switch *, .pds-input-rating-star *, .pds-input-upload *, button *, a.pds-primary-btn *, input.pds-primary-btn *, a.pds-primary-inverse-btn *, input.pds-primary-inverse-btn *, a.pds-secondary-btn *, input.pds-secondary-btn *, a.pds-secondary-inverse-btn *, input.pds-secondary-inverse-btn *, a.pds-tertiary-btn *, input.pds-tertiary-btn *, .pds-message-success *, .pds-message-info *, .pds-message-error *, .pds-message-warning *, .pds-message-bland *, html *,
  form *::before,
  .pds-fields *::before,
  .pds-input-text *::before,
  .pds-input-date *::before,
  .pds-input-checkbox *::before,
  .pds-input-checkbox-single *::before,
  .pds-checkbox-only *::before,
  .pds-input-radio *::before,
  .pds-input-textarea *::before,
  .pds-input-select *::before,
  .pds-switch *::before,
  .pds-input-rating-star *::before,
  .pds-input-upload *::before,
  button *::before,
  a.pds-primary-btn *::before,
  input.pds-primary-btn *::before,
  a.pds-primary-inverse-btn *::before,
  input.pds-primary-inverse-btn *::before,
  a.pds-secondary-btn *::before,
  input.pds-secondary-btn *::before,
  a.pds-secondary-inverse-btn *::before,
  input.pds-secondary-inverse-btn *::before,
  a.pds-tertiary-btn *::before,
  input.pds-tertiary-btn *::before,
  .pds-message-success *::before,
  .pds-message-info *::before,
  .pds-message-error *::before,
  .pds-message-warning *::before,
  .pds-message-bland *::before,
  html *::before,
  form *::after,
  .pds-fields *::after,
  .pds-input-text *::after,
  .pds-input-date *::after,
  .pds-input-checkbox *::after,
  .pds-input-checkbox-single *::after,
  .pds-checkbox-only *::after,
  .pds-input-radio *::after,
  .pds-input-textarea *::after,
  .pds-input-select *::after,
  .pds-switch *::after,
  .pds-input-rating-star *::after,
  .pds-input-upload *::after,
  button *::after,
  a.pds-primary-btn *::after,
  input.pds-primary-btn *::after,
  a.pds-primary-inverse-btn *::after,
  input.pds-primary-inverse-btn *::after,
  a.pds-secondary-btn *::after,
  input.pds-secondary-btn *::after,
  a.pds-secondary-inverse-btn *::after,
  input.pds-secondary-inverse-btn *::after,
  a.pds-tertiary-btn *::after,
  input.pds-tertiary-btn *::after,
  .pds-message-success *::after,
  .pds-message-info *::after,
  .pds-message-error *::after,
  .pds-message-warning *::after,
  .pds-message-bland *::after,
  html *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit; }

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0; }

[data-tippy-root] {
  max-width: calc(~"100vw - 10px"); }

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  -webkit-transition-property: visibility,opacity,-webkit-transform;
  transition-property: visibility,opacity,-webkit-transform;
  transition-property: transform,visibility,opacity;
  transition-property: transform,visibility,opacity,-webkit-transform; }

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0; }

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  -webkit-transform-origin: center top;
      -ms-transform-origin: center top;
          transform-origin: center top; }

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0; }

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  -webkit-transform-origin: center bottom;
      -ms-transform-origin: center bottom;
          transform-origin: center bottom; }

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0; }

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  -webkit-transform-origin: center left;
      -ms-transform-origin: center left;
          transform-origin: center left; }

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0; }

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  -webkit-transform-origin: center right;
      -ms-transform-origin: center right;
          transform-origin: center right; }

.tippy-box[data-inertia][data-state=visible] {
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
          transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11); }

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333; }

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid; }

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1; }

/**
  This file is NOT the place to go make styles for the polymer components. This is just something to give
  the root elements a display value so they will take up space in the DOM.

  Occasionally there will be an exception such as pds-icon needs to set a default height and width on the element.
 */
pds-activity-feed-notification,
pds-announce-text,
pds-app-nav,
pds-assignment-template-notification,
pds-chat-quick-message,
pds-checkbox-widget,
pds-circular-progress,
pds-class-block,
pds-class-picker,
pds-communication-notification,
pds-date-picker-dialog,
pds-date-widget,
pds-dialog,
pds-dynamic-select,
pds-global-create-menu,
pds-help-menu,
pds-user-menu,
pds-icon,
pds-loader,
pds-notification-badge,
pds-notification-indicator,
pds-assignment-return-notification,
pds-number-widget,
pds-numbered-slider,
pds-overlay-dropdown,
pds-progress-bar-widget,
pds-radio-button-widget,
pds-reporting-term-selector,
pds-rich-text-editor,
pds-search,
pds-select-widget,
pds-text-widget,
pds-textarea-widget,
pds-time-widget,
pds-file-picker-widget,
pds-parsed-html-content,
pds-banner,
pds-grid-widget-sort-fields,
pds-calendar,
pds-calendar-legend,
pds-calendar-event-form,
pds-grid-widget-pagination-control,
pds-detail-select-widget {
  display: block; }

pds-user-avatar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  pds-user-avatar pds-img {
    display: block; }

pds-rich-text-display,
pds-app-switcher,
pds-pop-over {
  display: inline-block; }

pds-format-time,
pds-format-number,
pds-format-date {
  display: inline; }

pds-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  fill: #677274;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  pds-icon svg {
    height: 26px;
    width: 26px;
    pointer-events: none; }

pds-app-nav {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 110px;
          flex: 0 0 110px; }
  @media (max-width: 768px) {
    pds-app-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-preferred-size: 50px;
          flex-basis: 50px;
      min-width: 50px; } }

pds-app-switcher {
  height: 100%; }

.pds-icon-sprite {
  display: none !important; }

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.pds-fields, .pds-readonly-data, .pds-readonly-data-long {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

form, input {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding: 0; }

.pds-input-date .pds-help-text, .pds-input-select .pds-help-text, .pds-input-text .pds-help-text, .pds-input-upload .pds-help-text, .pds-rich-text-editor-content .pds-help-text, .pds-input-textarea .pds-help-text, .pds-input-checkbox .pds-help-text, .pds-input-radio .pds-help-text, .pds-multi-select-widget-content .pds-help-text {
  padding-top: 7.5px;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  padding-bottom: 0;
  -webkit-padding-start: 7.5px;
          padding-inline-start: 7.5px;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 10px;
  color: #4D5557;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (min-width: 769px) {
    .pds-input-date .pds-help-text, .pds-input-select .pds-help-text, .pds-input-text .pds-help-text, .pds-input-upload .pds-help-text, .pds-rich-text-editor-content .pds-help-text, .pds-input-textarea .pds-help-text, .pds-input-checkbox .pds-help-text, .pds-input-radio .pds-help-text, .pds-multi-select-widget-content .pds-help-text {
      font-size: 12px; } }

fieldset:focus,
label:focus {
  outline: none; }


.pds-central .pds-input-date,
.pds-central .pds-input-select,
.pds-central .pds-input-text,
.pds-central .pds-input-upload, .pds-central .pds-rich-text-editor-content,
.pds-central .pds-input-textarea,
.pds-central .pds-input-checkbox,
.pds-central .pds-input-radio {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0; }


.pds-input-date,
.pds-input-select,
.pds-input-text,
.pds-input-upload, .pds-rich-text-editor-content,
.pds-input-textarea,
.pds-input-checkbox,
.pds-input-radio {
  margin: 0 15px 20px;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  .pds-input-date:focus,
  .pds-input-select:focus,
  .pds-input-text:focus,
  .pds-input-upload:focus, .pds-rich-text-editor-content:focus, .pds-input-textarea:focus, .pds-input-checkbox:focus,
  .pds-input-radio:focus {
    outline: none; }
  .pds-input-date label, .pds-input-select label, .pds-input-text label, .pds-input-upload label, .pds-rich-text-editor-content label, .pds-input-textarea label, .pds-input-checkbox label, .pds-input-radio label {
    line-height: 1.5;
    margin-bottom: 0.5rem;
    display: block; }
    .pds-input-date label:focus, .pds-input-select label:focus, .pds-input-text label:focus, .pds-input-upload label:focus, .pds-rich-text-editor-content label:focus, .pds-input-textarea label:focus, .pds-input-checkbox label:focus, .pds-input-radio label:focus {
      outline: none; }
    .pds-input-date label .pds-field-help, .pds-input-select label .pds-field-help, .pds-input-text label .pds-field-help, .pds-input-upload label .pds-field-help, .pds-rich-text-editor-content label .pds-field-help, .pds-input-textarea label .pds-field-help, .pds-input-checkbox label .pds-field-help, .pds-input-radio label .pds-field-help {
      -webkit-margin-start: 8px;
              margin-inline-start: 8px; }
  .pds-input-date .pds-help-text, .pds-input-select .pds-help-text, .pds-input-text .pds-help-text, .pds-input-upload .pds-help-text, .pds-rich-text-editor-content .pds-help-text, .pds-input-textarea .pds-help-text, .pds-input-checkbox .pds-help-text, .pds-input-radio .pds-help-text {
    padding: 0;
    margin: 0;
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    legend ~ .pds-input-date .pds-help-text, legend ~ .pds-input-select .pds-help-text, legend ~ .pds-input-text .pds-help-text, legend ~ .pds-input-upload .pds-help-text, legend ~ .pds-rich-text-editor-content .pds-help-text, legend ~ .pds-input-textarea .pds-help-text, legend ~ .pds-input-checkbox .pds-help-text, legend ~ .pds-input-radio .pds-help-text {
      -webkit-margin-start: 0;
              margin-inline-start: 0; }
    .pds-input-date .pds-help-text:focus, .pds-input-select .pds-help-text:focus, .pds-input-text .pds-help-text:focus, .pds-input-upload .pds-help-text:focus, .pds-rich-text-editor-content .pds-help-text:focus, .pds-input-textarea .pds-help-text:focus, .pds-input-checkbox .pds-help-text:focus, .pds-input-radio .pds-help-text:focus {
      outline: none; }
  .pds-input-date label ~ .pds-help-text, .pds-input-select label ~ .pds-help-text, .pds-input-text label ~ .pds-help-text, .pds-input-upload label ~ .pds-help-text, .pds-rich-text-editor-content label ~ .pds-help-text, .pds-input-textarea label ~ .pds-help-text, .pds-input-checkbox label ~ .pds-help-text, .pds-input-radio label ~ .pds-help-text {
    margin-top: -10px; }
  .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
  .pds-input-date select,
  .pds-input-select select,
  .pds-input-text select,
  .pds-input-upload select,
  .pds-rich-text-editor-content select,
  .pds-input-textarea select,
  .pds-input-checkbox select,
  .pds-input-radio select {
    height: 32px; }
    @media (max-width: 768px) {
      .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
      .pds-input-date select,
      .pds-input-select select,
      .pds-input-text select,
      .pds-input-upload select,
      .pds-rich-text-editor-content select,
      .pds-input-textarea select,
      .pds-input-checkbox select,
      .pds-input-radio select {
        height: 44px; } }
  .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
  .pds-input-date select,
  .pds-input-select select,
  .pds-input-text select,
  .pds-input-upload select,
  .pds-rich-text-editor-content select,
  .pds-input-textarea select,
  .pds-input-checkbox select,
  .pds-input-radio select,
  .pds-input-date textarea,
  .pds-input-select textarea,
  .pds-input-text textarea,
  .pds-input-upload textarea,
  .pds-rich-text-editor-content textarea,
  .pds-input-textarea textarea,
  .pds-input-checkbox textarea,
  .pds-input-radio textarea {
    color: #333333;
    border: 1px solid #838E91;
    margin-bottom: 0; }
  .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
  .pds-input-date textarea,
  .pds-input-select textarea,
  .pds-input-text textarea,
  .pds-input-upload textarea,
  .pds-rich-text-editor-content textarea,
  .pds-input-textarea textarea,
  .pds-input-checkbox textarea,
  .pds-input-radio textarea,
  .pds-input-date .pds-readonly-data,
  .pds-input-select .pds-readonly-data,
  .pds-input-text .pds-readonly-data,
  .pds-input-upload .pds-readonly-data,
  .pds-rich-text-editor-content .pds-readonly-data,
  .pds-input-textarea .pds-readonly-data,
  .pds-input-checkbox .pds-readonly-data,
  .pds-input-radio .pds-readonly-data,
  .pds-input-date .pds-readonly-data-long,
  .pds-input-select .pds-readonly-data-long,
  .pds-input-text .pds-readonly-data-long,
  .pds-input-upload .pds-readonly-data-long,
  .pds-rich-text-editor-content .pds-readonly-data-long,
  .pds-input-textarea .pds-readonly-data-long,
  .pds-input-checkbox .pds-readonly-data-long,
  .pds-input-radio .pds-readonly-data-long {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px; }
    .pds-input-date input::-webkit-input-placeholder, .pds-input-select input::-webkit-input-placeholder, .pds-input-text input::-webkit-input-placeholder, .pds-input-upload input::-webkit-input-placeholder, .pds-rich-text-editor-content input::-webkit-input-placeholder, .pds-input-textarea input::-webkit-input-placeholder, .pds-input-checkbox input::-webkit-input-placeholder, .pds-input-radio input::-webkit-input-placeholder, .pds-input-date textarea::-webkit-input-placeholder, .pds-input-select textarea::-webkit-input-placeholder, .pds-input-text textarea::-webkit-input-placeholder, .pds-input-upload textarea::-webkit-input-placeholder, .pds-rich-text-editor-content textarea::-webkit-input-placeholder, .pds-input-textarea textarea::-webkit-input-placeholder, .pds-input-checkbox textarea::-webkit-input-placeholder, .pds-input-radio textarea::-webkit-input-placeholder, .pds-input-date .pds-readonly-data::-webkit-input-placeholder, .pds-input-select .pds-readonly-data::-webkit-input-placeholder, .pds-input-text .pds-readonly-data::-webkit-input-placeholder, .pds-input-upload .pds-readonly-data::-webkit-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data::-webkit-input-placeholder, .pds-input-textarea .pds-readonly-data::-webkit-input-placeholder, .pds-input-checkbox .pds-readonly-data::-webkit-input-placeholder, .pds-input-radio .pds-readonly-data::-webkit-input-placeholder, .pds-input-date .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-select .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-text .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-upload .pds-readonly-data-long::-webkit-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-textarea .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-checkbox .pds-readonly-data-long::-webkit-input-placeholder, .pds-input-radio .pds-readonly-data-long::-webkit-input-placeholder {
      font-weight: 400; }
    .pds-input-date input::-moz-placeholder, .pds-input-select input::-moz-placeholder, .pds-input-text input::-moz-placeholder, .pds-input-upload input::-moz-placeholder, .pds-rich-text-editor-content input::-moz-placeholder, .pds-input-textarea input::-moz-placeholder, .pds-input-checkbox input::-moz-placeholder, .pds-input-radio input::-moz-placeholder, .pds-input-date textarea::-moz-placeholder, .pds-input-select textarea::-moz-placeholder, .pds-input-text textarea::-moz-placeholder, .pds-input-upload textarea::-moz-placeholder, .pds-rich-text-editor-content textarea::-moz-placeholder, .pds-input-textarea textarea::-moz-placeholder, .pds-input-checkbox textarea::-moz-placeholder, .pds-input-radio textarea::-moz-placeholder, .pds-input-date .pds-readonly-data::-moz-placeholder, .pds-input-select .pds-readonly-data::-moz-placeholder, .pds-input-text .pds-readonly-data::-moz-placeholder, .pds-input-upload .pds-readonly-data::-moz-placeholder, .pds-rich-text-editor-content .pds-readonly-data::-moz-placeholder, .pds-input-textarea .pds-readonly-data::-moz-placeholder, .pds-input-checkbox .pds-readonly-data::-moz-placeholder, .pds-input-radio .pds-readonly-data::-moz-placeholder, .pds-input-date .pds-readonly-data-long::-moz-placeholder, .pds-input-select .pds-readonly-data-long::-moz-placeholder, .pds-input-text .pds-readonly-data-long::-moz-placeholder, .pds-input-upload .pds-readonly-data-long::-moz-placeholder, .pds-rich-text-editor-content .pds-readonly-data-long::-moz-placeholder, .pds-input-textarea .pds-readonly-data-long::-moz-placeholder, .pds-input-checkbox .pds-readonly-data-long::-moz-placeholder, .pds-input-radio .pds-readonly-data-long::-moz-placeholder {
      font-weight: 400; }
    .pds-input-date input:-ms-input-placeholder, .pds-input-select input:-ms-input-placeholder, .pds-input-text input:-ms-input-placeholder, .pds-input-upload input:-ms-input-placeholder, .pds-rich-text-editor-content input:-ms-input-placeholder, .pds-input-textarea input:-ms-input-placeholder, .pds-input-checkbox input:-ms-input-placeholder, .pds-input-radio input:-ms-input-placeholder, .pds-input-date textarea:-ms-input-placeholder, .pds-input-select textarea:-ms-input-placeholder, .pds-input-text textarea:-ms-input-placeholder, .pds-input-upload textarea:-ms-input-placeholder, .pds-rich-text-editor-content textarea:-ms-input-placeholder, .pds-input-textarea textarea:-ms-input-placeholder, .pds-input-checkbox textarea:-ms-input-placeholder, .pds-input-radio textarea:-ms-input-placeholder, .pds-input-date .pds-readonly-data:-ms-input-placeholder, .pds-input-select .pds-readonly-data:-ms-input-placeholder, .pds-input-text .pds-readonly-data:-ms-input-placeholder, .pds-input-upload .pds-readonly-data:-ms-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data:-ms-input-placeholder, .pds-input-textarea .pds-readonly-data:-ms-input-placeholder, .pds-input-checkbox .pds-readonly-data:-ms-input-placeholder, .pds-input-radio .pds-readonly-data:-ms-input-placeholder, .pds-input-date .pds-readonly-data-long:-ms-input-placeholder, .pds-input-select .pds-readonly-data-long:-ms-input-placeholder, .pds-input-text .pds-readonly-data-long:-ms-input-placeholder, .pds-input-upload .pds-readonly-data-long:-ms-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data-long:-ms-input-placeholder, .pds-input-textarea .pds-readonly-data-long:-ms-input-placeholder, .pds-input-checkbox .pds-readonly-data-long:-ms-input-placeholder, .pds-input-radio .pds-readonly-data-long:-ms-input-placeholder {
      font-weight: 400; }
    .pds-input-date input::-ms-input-placeholder, .pds-input-select input::-ms-input-placeholder, .pds-input-text input::-ms-input-placeholder, .pds-input-upload input::-ms-input-placeholder, .pds-rich-text-editor-content input::-ms-input-placeholder, .pds-input-textarea input::-ms-input-placeholder, .pds-input-checkbox input::-ms-input-placeholder, .pds-input-radio input::-ms-input-placeholder, .pds-input-date textarea::-ms-input-placeholder, .pds-input-select textarea::-ms-input-placeholder, .pds-input-text textarea::-ms-input-placeholder, .pds-input-upload textarea::-ms-input-placeholder, .pds-rich-text-editor-content textarea::-ms-input-placeholder, .pds-input-textarea textarea::-ms-input-placeholder, .pds-input-checkbox textarea::-ms-input-placeholder, .pds-input-radio textarea::-ms-input-placeholder, .pds-input-date .pds-readonly-data::-ms-input-placeholder, .pds-input-select .pds-readonly-data::-ms-input-placeholder, .pds-input-text .pds-readonly-data::-ms-input-placeholder, .pds-input-upload .pds-readonly-data::-ms-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data::-ms-input-placeholder, .pds-input-textarea .pds-readonly-data::-ms-input-placeholder, .pds-input-checkbox .pds-readonly-data::-ms-input-placeholder, .pds-input-radio .pds-readonly-data::-ms-input-placeholder, .pds-input-date .pds-readonly-data-long::-ms-input-placeholder, .pds-input-select .pds-readonly-data-long::-ms-input-placeholder, .pds-input-text .pds-readonly-data-long::-ms-input-placeholder, .pds-input-upload .pds-readonly-data-long::-ms-input-placeholder, .pds-rich-text-editor-content .pds-readonly-data-long::-ms-input-placeholder, .pds-input-textarea .pds-readonly-data-long::-ms-input-placeholder, .pds-input-checkbox .pds-readonly-data-long::-ms-input-placeholder, .pds-input-radio .pds-readonly-data-long::-ms-input-placeholder {
      font-weight: 400; }
    .pds-input-date input::placeholder, .pds-input-select input::placeholder, .pds-input-text input::placeholder, .pds-input-upload input::placeholder, .pds-rich-text-editor-content input::placeholder, .pds-input-textarea input::placeholder, .pds-input-checkbox input::placeholder, .pds-input-radio input::placeholder,
    .pds-input-date textarea::placeholder,
    .pds-input-select textarea::placeholder,
    .pds-input-text textarea::placeholder,
    .pds-input-upload textarea::placeholder,
    .pds-rich-text-editor-content textarea::placeholder,
    .pds-input-textarea textarea::placeholder,
    .pds-input-checkbox textarea::placeholder,
    .pds-input-radio textarea::placeholder,
    .pds-input-date .pds-readonly-data::placeholder,
    .pds-input-select .pds-readonly-data::placeholder,
    .pds-input-text .pds-readonly-data::placeholder,
    .pds-input-upload .pds-readonly-data::placeholder,
    .pds-rich-text-editor-content .pds-readonly-data::placeholder,
    .pds-input-textarea .pds-readonly-data::placeholder,
    .pds-input-checkbox .pds-readonly-data::placeholder,
    .pds-input-radio .pds-readonly-data::placeholder,
    .pds-input-date .pds-readonly-data-long::placeholder,
    .pds-input-select .pds-readonly-data-long::placeholder,
    .pds-input-text .pds-readonly-data-long::placeholder,
    .pds-input-upload .pds-readonly-data-long::placeholder,
    .pds-rich-text-editor-content .pds-readonly-data-long::placeholder,
    .pds-input-textarea .pds-readonly-data-long::placeholder,
    .pds-input-checkbox .pds-readonly-data-long::placeholder,
    .pds-input-radio .pds-readonly-data-long::placeholder {
      font-weight: 400; }
    @media (max-width: 768px) {
      .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
      .pds-input-date textarea,
      .pds-input-select textarea,
      .pds-input-text textarea,
      .pds-input-upload textarea,
      .pds-rich-text-editor-content textarea,
      .pds-input-textarea textarea,
      .pds-input-checkbox textarea,
      .pds-input-radio textarea,
      .pds-input-date .pds-readonly-data,
      .pds-input-select .pds-readonly-data,
      .pds-input-text .pds-readonly-data,
      .pds-input-upload .pds-readonly-data,
      .pds-rich-text-editor-content .pds-readonly-data,
      .pds-input-textarea .pds-readonly-data,
      .pds-input-checkbox .pds-readonly-data,
      .pds-input-radio .pds-readonly-data,
      .pds-input-date .pds-readonly-data-long,
      .pds-input-select .pds-readonly-data-long,
      .pds-input-text .pds-readonly-data-long,
      .pds-input-upload .pds-readonly-data-long,
      .pds-rich-text-editor-content .pds-readonly-data-long,
      .pds-input-textarea .pds-readonly-data-long,
      .pds-input-checkbox .pds-readonly-data-long,
      .pds-input-radio .pds-readonly-data-long {
        font-size: 16px;
        line-height: 24px; } }
  @media (min-width: 769px) {
    .pds-input-date .pds-input, .pds-input-select .pds-input, .pds-input-text .pds-input, .pds-input-upload .pds-input, .pds-rich-text-editor-content .pds-input, .pds-input-textarea .pds-input, .pds-input-checkbox .pds-input, .pds-input-radio .pds-input,
    .pds-input-date .pds-input-area,
    .pds-input-select .pds-input-area,
    .pds-input-text .pds-input-area,
    .pds-input-upload .pds-input-area,
    .pds-rich-text-editor-content .pds-input-area,
    .pds-input-textarea .pds-input-area,
    .pds-input-checkbox .pds-input-area,
    .pds-input-radio .pds-input-area,
    .pds-input-date .pds-readonly-data,
    .pds-input-select .pds-readonly-data,
    .pds-input-text .pds-readonly-data,
    .pds-input-upload .pds-readonly-data,
    .pds-rich-text-editor-content .pds-readonly-data,
    .pds-input-textarea .pds-readonly-data,
    .pds-input-checkbox .pds-readonly-data,
    .pds-input-radio .pds-readonly-data,
    .pds-input-date .pds-readonly-data-long,
    .pds-input-select .pds-readonly-data-long,
    .pds-input-text .pds-readonly-data-long,
    .pds-input-upload .pds-readonly-data-long,
    .pds-rich-text-editor-content .pds-readonly-data-long,
    .pds-input-textarea .pds-readonly-data-long,
    .pds-input-checkbox .pds-readonly-data-long,
    .pds-input-radio .pds-readonly-data-long, .pds-input-date input, .pds-input-select input, .pds-input-text input, .pds-input-upload input, .pds-rich-text-editor-content input, .pds-input-textarea input, .pds-input-checkbox input, .pds-input-radio input,
    .pds-input-date select,
    .pds-input-select select,
    .pds-input-text select,
    .pds-input-upload select,
    .pds-rich-text-editor-content select,
    .pds-input-textarea select,
    .pds-input-checkbox select,
    .pds-input-radio select,
    .pds-input-date textarea,
    .pds-input-select textarea,
    .pds-input-text textarea,
    .pds-input-upload textarea,
    .pds-rich-text-editor-content textarea,
    .pds-input-textarea textarea,
    .pds-input-checkbox textarea,
    .pds-input-radio textarea {
      width: 50%;
      max-width: 470px; } }
  .pds-input-date .pds-input:disabled, .pds-input-select .pds-input:disabled, .pds-input-text .pds-input:disabled, .pds-input-upload .pds-input:disabled, .pds-rich-text-editor-content .pds-input:disabled, .pds-input-textarea .pds-input:disabled, .pds-input-checkbox .pds-input:disabled, .pds-input-radio .pds-input:disabled,
  .pds-input-date .pds-input-area:disabled,
  .pds-input-select .pds-input-area:disabled,
  .pds-input-text .pds-input-area:disabled,
  .pds-input-upload .pds-input-area:disabled,
  .pds-rich-text-editor-content .pds-input-area:disabled,
  .pds-input-textarea .pds-input-area:disabled,
  .pds-input-checkbox .pds-input-area:disabled,
  .pds-input-radio .pds-input-area:disabled,
  .pds-input-date .pds-readonly-data:disabled,
  .pds-input-select .pds-readonly-data:disabled,
  .pds-input-text .pds-readonly-data:disabled,
  .pds-input-upload .pds-readonly-data:disabled,
  .pds-rich-text-editor-content .pds-readonly-data:disabled,
  .pds-input-textarea .pds-readonly-data:disabled,
  .pds-input-checkbox .pds-readonly-data:disabled,
  .pds-input-radio .pds-readonly-data:disabled,
  .pds-input-date .pds-readonly-data-long:disabled,
  .pds-input-select .pds-readonly-data-long:disabled,
  .pds-input-text .pds-readonly-data-long:disabled,
  .pds-input-upload .pds-readonly-data-long:disabled,
  .pds-rich-text-editor-content .pds-readonly-data-long:disabled,
  .pds-input-textarea .pds-readonly-data-long:disabled,
  .pds-input-checkbox .pds-readonly-data-long:disabled,
  .pds-input-radio .pds-readonly-data-long:disabled,
  .pds-input-date input:disabled,
  .pds-input-select input:disabled,
  .pds-input-text input:disabled,
  .pds-input-upload input:disabled,
  .pds-rich-text-editor-content input:disabled,
  .pds-input-textarea input:disabled,
  .pds-input-checkbox input:disabled,
  .pds-input-radio input:disabled,
  .pds-input-date select:disabled,
  .pds-input-select select:disabled,
  .pds-input-text select:disabled,
  .pds-input-upload select:disabled,
  .pds-rich-text-editor-content select:disabled,
  .pds-input-textarea select:disabled,
  .pds-input-checkbox select:disabled,
  .pds-input-radio select:disabled,
  .pds-input-date textarea:disabled,
  .pds-input-select textarea:disabled,
  .pds-input-text textarea:disabled,
  .pds-input-upload textarea:disabled,
  .pds-rich-text-editor-content textarea:disabled,
  .pds-input-textarea textarea:disabled,
  .pds-input-checkbox textarea:disabled,
  .pds-input-radio textarea:disabled {
    opacity: 0.45;
    background-color: transparent; }
  
  .pds-input-date .pds-readonly-data,
  .pds-input-select .pds-readonly-data,
  .pds-input-text .pds-readonly-data,
  .pds-input-upload .pds-readonly-data,
  .pds-rich-text-editor-content .pds-readonly-data,
  .pds-input-textarea .pds-readonly-data,
  .pds-input-checkbox .pds-readonly-data,
  .pds-input-radio .pds-readonly-data,
  .pds-input-date .pds-readonly-data-long,
  .pds-input-select .pds-readonly-data-long,
  .pds-input-text .pds-readonly-data-long,
  .pds-input-upload .pds-readonly-data-long,
  .pds-rich-text-editor-content .pds-readonly-data-long,
  .pds-input-textarea .pds-readonly-data-long,
  .pds-input-checkbox .pds-readonly-data-long,
  .pds-input-radio .pds-readonly-data-long {
    min-height: 32px; }
    @media (max-width: 768px) {
      
      .pds-input-date .pds-readonly-data,
      .pds-input-select .pds-readonly-data,
      .pds-input-text .pds-readonly-data,
      .pds-input-upload .pds-readonly-data,
      .pds-rich-text-editor-content .pds-readonly-data,
      .pds-input-textarea .pds-readonly-data,
      .pds-input-checkbox .pds-readonly-data,
      .pds-input-radio .pds-readonly-data,
      .pds-input-date .pds-readonly-data-long,
      .pds-input-select .pds-readonly-data-long,
      .pds-input-text .pds-readonly-data-long,
      .pds-input-upload .pds-readonly-data-long,
      .pds-rich-text-editor-content .pds-readonly-data-long,
      .pds-input-textarea .pds-readonly-data-long,
      .pds-input-checkbox .pds-readonly-data-long,
      .pds-input-radio .pds-readonly-data-long {
        min-height: 44px; } }
  .pds-is-readonly.pds-input-date input, .pds-is-readonly.pds-input-select input, .pds-is-readonly.pds-input-text input, .pds-is-readonly.pds-input-upload input, .pds-is-readonly.pds-rich-text-editor-content input, .pds-is-readonly.pds-input-textarea input, .pds-is-readonly.pds-input-checkbox input, .pds-is-readonly.pds-input-radio input,
  .pds-is-readonly.pds-input-date select,
  .pds-is-readonly.pds-input-select select,
  .pds-is-readonly.pds-input-text select,
  .pds-is-readonly.pds-input-upload select,
  .pds-is-readonly.pds-rich-text-editor-content select,
  .pds-is-readonly.pds-input-textarea select,
  .pds-is-readonly.pds-input-checkbox select,
  .pds-is-readonly.pds-input-radio select,
  .pds-is-readonly.pds-input-date textarea,
  .pds-is-readonly.pds-input-select textarea,
  .pds-is-readonly.pds-input-text textarea,
  .pds-is-readonly.pds-input-upload textarea,
  .pds-is-readonly.pds-rich-text-editor-content textarea,
  .pds-is-readonly.pds-input-textarea textarea,
  .pds-is-readonly.pds-input-checkbox textarea,
  .pds-is-readonly.pds-input-radio textarea {
    display: none; }
  .pds-is-readonly.pds-input-date input[type="checkbox"], .pds-is-readonly.pds-input-select input[type="checkbox"], .pds-is-readonly.pds-input-text input[type="checkbox"], .pds-is-readonly.pds-input-upload input[type="checkbox"], .pds-is-readonly.pds-rich-text-editor-content input[type="checkbox"], .pds-is-readonly.pds-input-textarea input[type="checkbox"], .pds-is-readonly.pds-input-checkbox input[type="checkbox"], .pds-is-readonly.pds-input-radio input[type="checkbox"] {
    display: inline-block; }
  .pds-is-readonly.pds-input-date .pds-readonly-data, .pds-is-readonly.pds-input-select .pds-readonly-data, .pds-is-readonly.pds-input-text .pds-readonly-data, .pds-is-readonly.pds-input-upload .pds-readonly-data, .pds-is-readonly.pds-rich-text-editor-content .pds-readonly-data, .pds-is-readonly.pds-input-textarea .pds-readonly-data, .pds-is-readonly.pds-input-checkbox .pds-readonly-data, .pds-is-readonly.pds-input-radio .pds-readonly-data, .pds-is-readonly.pds-input-date .pds-readonly-data-long, .pds-is-readonly.pds-input-select .pds-readonly-data-long, .pds-is-readonly.pds-input-text .pds-readonly-data-long, .pds-is-readonly.pds-input-upload .pds-readonly-data-long, .pds-is-readonly.pds-rich-text-editor-content .pds-readonly-data-long, .pds-is-readonly.pds-input-textarea .pds-readonly-data-long, .pds-is-readonly.pds-input-checkbox .pds-readonly-data-long, .pds-is-readonly.pds-input-radio .pds-readonly-data-long {
    display: block; }
  @media (min-width: 769px) {
    
    .pds-input-date textarea,
    .pds-input-select textarea,
    .pds-input-text textarea,
    .pds-input-upload textarea,
    .pds-rich-text-editor-content textarea,
    .pds-input-textarea textarea,
    .pds-input-checkbox textarea,
    .pds-input-radio textarea {
      width: 50%;
      max-width: 100%; } }

.pds-input-area {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif; }
  .pds-input-area .pds-input,
  .pds-input-area .pds-readonly-data,
  .pds-input-area .pds-readonly-data-long,
  .pds-input-area input,
  .pds-input-area select,
  .pds-input-area textarea {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }

.pds-read-only-data-with-edit > :first-child {
  width: calc(~"100% - 54px"); }

.pds-validation {
  color: #CB1010;
  font-size: 14px;
  padding-top: 5px;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  padding-bottom: 0;
  -webkit-padding-end: 7.5px;
          padding-inline-end: 7.5px;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif; }

.pds-validation.pds-is-hidden {
  display: none;
  height: 0;
  color: transparent;
  position: absolute;
  clip: rect(0px, 0px, 0px, 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
          clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px); }

.pds-readonly-data, .pds-readonly-data-long {
  display: none;
  font-size: 18px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  padding: 4px 10px 5px;
  margin: 0;
  border: 1px solid transparent;
  background: #F1F4F4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 768px) {
    .pds-readonly-data, .pds-readonly-data-long {
      padding-top: 8px;
      padding-bottom: 8px; } }

.pds-readonly-data-long {
  height: 124px;
  position: relative;
  overflow: hidden;
  padding: 9px;
  padding-bottom: 42px;
  border: none; }
  .pds-readonly-data-long .pds-readonly-controls {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    width: 100%;
    text-align: center;
    height: 30px;
    background: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-readonly-data-long .pds-readonly-controls:before {
      content: ' ';
      position: absolute;
      height: 9px;
      background: #F1F4F4;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: -9px; }
    .pds-readonly-data-long .pds-readonly-controls .pds-readonly-less,
    .pds-readonly-data-long .pds-readonly-controls .pds-readonly-more {
      padding: 3.75px 0; }
  .pds-readonly-data-long .pds-readonly-more {
    display: block; }
  .pds-readonly-data-long .pds-readonly-less {
    display: none; }
  .pds-readonly-data-long.pds-is-expanded {
    height: auto; }
    .pds-readonly-data-long.pds-is-expanded .pds-readonly-controls:before {
      content: none; }
    .pds-readonly-data-long.pds-is-expanded .pds-readonly-more {
      display: none; }
    .pds-readonly-data-long.pds-is-expanded .pds-readonly-less {
      display: block; }

input, textarea, select {
  padding: 4px 10px 5px;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #838E91; }
  @media (max-width: 768px) {
    input, textarea, select {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100%; } }

input {
  font-family: Open Sans, sans-serif;
  overflow: visible; }

textarea {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  height: auto;
  border-radius: 4px;
  overflow: auto;
  min-height: 105px;
  resize: auto; }

select::-ms-expand {
  display: none; }

select {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: none;
  background-color: #FFFFFF;
  background-image: url("../../../../scripts/pds/img/select.svg");
  background-size: 32px 32px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  font-family: Open Sans, sans-serif;
  line-height: 21px;
  height: 32px;
  padding: 4px 10px 5px;
  -webkit-padding-end: 32px;
          padding-inline-end: 32px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  margin: 0; }
  [dir='rtl'] select {
    background-position: 0% 100%;
    background-image: url("../../../../scripts/pds/img/rtl-select.svg"); }
    [dir='rtl'] select.cke_dialog_ui_input_select {
      background: transparent; }
  select:focus {
    -webkit-box-shadow: 0 0 5px #C4C9CA;
            box-shadow: 0 0 5px #C4C9CA; }
  @media (max-width: 768px) {
    select {
      height: 44px;
      -webkit-padding-end: 44px;
              padding-inline-end: 44px;
      font-size: 16px;
      line-height: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-size: 44px 44px; } }

label {
  color: #333333;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  font-size: 16px;
  font-weight: 400; }

fieldset {
  border: none;
  padding: 0; }

.pds-input-textarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative; }
  .pds-input-textarea .pds-help-text,
  .pds-input-textarea .pds-textarea-characters,
  .pds-input-textarea label {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .pds-input-textarea .pds-textarea-characters {
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin: 5px 0 0;
    padding: 0 10px;
    border-radius: 4px;
    background: #F1F4F4;
    color: #4D5557;
    font-size: 12px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.pds-input-checkbox,
.pds-input-radio {
  font-family: Open Sans, sans-serif; }
  .pds-input-checkbox legend,
  .pds-input-radio legend {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }
  .pds-input-checkbox li,
  .pds-input-radio li {
    min-height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-input-checkbox li .pds-label-text,
    .pds-input-radio li .pds-label-text {
      padding: 8px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    @media (max-width: 768px) {
      .pds-input-checkbox li,
      .pds-input-radio li {
        min-height: 44px; } }
  .pds-input-checkbox label,
  .pds-input-radio label {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    width: 100%;
    margin-bottom: 0; }
  @media (min-width: 769px) {
    .pds-fields .pds-input-checkbox .pds-fields label, .pds-fields
    .pds-input-radio .pds-fields label {
      width: 100%;
      max-width: 100%; } }
  .pds-input-checkbox label ~ .pds-help-text,
  .pds-input-radio label ~ .pds-help-text {
    margin-top: -10px;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    margin-bottom: 0;
    -webkit-margin-start: 35px;
            margin-inline-start: 35px; }
  .pds-input-checkbox.pds-select-list .pds-label-text,
  .pds-input-radio.pds-select-list .pds-label-text {
    border-bottom: 1px solid #C4C9CA;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .pds-input-checkbox.pds-select-list ul li:last-child .pds-label-text,
  .pds-input-radio.pds-select-list ul li:last-child .pds-label-text {
    border-bottom: none; }

.pds-input-radio.pds-is-readonly label {
  display: none; }

.pds-field-help {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 0 3px;
  padding: 0;
  background: url("../../../../scripts/pds/img/help-s.svg") no-repeat center center;
  background-size: 20px;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.pds-alternate-color-scheme .pds-field-help {
  background: url("../../../../scripts/pds/img/help-s-white.svg") no-repeat center center;
  background-size: 20px; }

.pds-central {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-central label {
    color: #FFFFFF; }
  .pds-central input,
  .pds-central select,
  .pds-central textarea {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0; }
  .pds-central .pds-fields input {
    border-radius: 4px; }
  .pds-central .pds-validation {
    color: #F79797; }
  .pds-central .pds-fields .pds-input-text input,
  .pds-central .pds-fields .pds-input-text select,
  .pds-central .pds-fields .pds-input-select input,
  .pds-central .pds-fields .pds-input-select select,
  .pds-central .pds-fields .pds-input-radio-button input,
  .pds-central .pds-fields .pds-input-radio-button select,
  .pds-central .pds-fields .pds-input-textarea input,
  .pds-central .pds-fields .pds-input-textarea select,
  .pds-central .pds-fields .pds-input-checkbox-area input,
  .pds-central .pds-fields .pds-input-checkbox-area select {
    height: 44px;
    background-size: 44px 44px; }
  .pds-central .pds-fields .pds-input-text .pds-button-action-attached,
  .pds-central .pds-fields .pds-input-select .pds-button-action-attached,
  .pds-central .pds-fields .pds-input-radio-button .pds-button-action-attached,
  .pds-central .pds-fields .pds-input-textarea .pds-button-action-attached,
  .pds-central .pds-fields .pds-input-checkbox-area .pds-button-action-attached {
    height: 44px;
    width: 44px;
    padding: 0;
    -webkit-padding-start: 5px;
            padding-inline-start: 5px;
    -webkit-padding-end: 3px;
            padding-inline-end: 3px;
    -webkit-margin-start: -3px;
            margin-inline-start: -3px; }
  .pds-central .pds-fields .pds-input-text .pds-field-link,
  .pds-central .pds-fields .pds-input-select .pds-field-link,
  .pds-central .pds-fields .pds-input-radio-button .pds-field-link,
  .pds-central .pds-fields .pds-input-textarea .pds-field-link,
  .pds-central .pds-fields .pds-input-checkbox-area .pds-field-link {
    color: #C4C9CA;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
  .pds-central .pds-fields .pds-input-text .pds-valid-indicator,
  .pds-central .pds-fields .pds-input-select .pds-valid-indicator,
  .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator,
  .pds-central .pds-fields .pds-input-textarea .pds-valid-indicator,
  .pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator {
    position: absolute;
    padding: 10px;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 46px;
    height: 46px;
    inset-inline-start: -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pds-central .pds-fields .pds-input-text .pds-valid-indicator.pds-is-valid [name="checkmark-circle"],
    .pds-central .pds-fields .pds-input-select .pds-valid-indicator.pds-is-valid [name="checkmark-circle"],
    .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator.pds-is-valid [name="checkmark-circle"],
    .pds-central .pds-fields .pds-input-textarea .pds-valid-indicator.pds-is-valid [name="checkmark-circle"],
    .pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator.pds-is-valid [name="checkmark-circle"] {
      fill: #52BA2B; }
    .pds-central .pds-fields .pds-input-text .pds-valid-indicator.pds-is-invalid [name="close-s"],
    .pds-central .pds-fields .pds-input-select .pds-valid-indicator.pds-is-invalid [name="close-s"],
    .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator.pds-is-invalid [name="close-s"],
    .pds-central .pds-fields .pds-input-textarea .pds-valid-indicator.pds-is-invalid [name="close-s"],
    .pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator.pds-is-invalid [name="close-s"] {
      fill: #F79797; }
    .pds-central .pds-fields .pds-input-text .pds-valid-indicator.pds-is-loading,
    .pds-central .pds-fields .pds-input-select .pds-valid-indicator.pds-is-loading,
    .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator.pds-is-loading,
    .pds-central .pds-fields .pds-input-textarea .pds-valid-indicator.pds-is-loading,
    .pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator.pds-is-loading {
      width: 57px; }
      .pds-central .pds-fields .pds-input-text .pds-valid-indicator.pds-is-loading pds-loader,
      .pds-central .pds-fields .pds-input-select .pds-valid-indicator.pds-is-loading pds-loader,
      .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator.pds-is-loading pds-loader,
      .pds-central .pds-fields .pds-input-textarea .pds-valid-indicator.pds-is-loading pds-loader,
      .pds-central .pds-fields .pds-input-checkbox-area .pds-valid-indicator.pds-is-loading pds-loader {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; }
  .pds-central .pds-input-checkbox legend,
  .pds-central .pds-input-radio legend {
    color: #FFFFFF;
    -webkit-margin-start: -10px;
            margin-inline-start: -10px; }
  .pds-central .pds-fields .pds-input-checkbox .pds-valid-indicator,
  .pds-central .pds-fields .pds-input-radio-button .pds-valid-indicator {
    top: -12px; }
  .pds-central .pds-fields .pds-input-text select.pds-date-picker-dialog {
    height: 32px;
    background-size: 32px 32px; }
    @media (max-width: 768px) {
      .pds-central .pds-fields .pds-input-text select.pds-date-picker-dialog {
        height: 42px;
        background-size: 42px 42px; } }
  .pds-central .pds-primary {
    width: 100%;
    border: none;
    height: 50px; }
  .pds-central .pds-field-badge {
    border: none;
    color: #FFFFFF; }
  .pds-central .pds-show-password {
    position: absolute;
    inset-inline-end: 0;
    color: #0075DB;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-width: 1px;
    border-inline-start-width: 0;
    border-style: solid;
    border-color: #C4C9CA;
    height: 44px;
    font-size: 13px;
    font-weight: bold;
    padding: 0 10px;
    margin: 0;
    min-width: unset;
    max-width: 60px;
    border-radius: 0;
    border-start-end-radius: 4px;
    border-end-end-radius: 4px; }
  .pds-central .pds-radio-button-clear:hover .pds-icon {
    fill: #677274; }

.pds-central-divider {
  margin: 15px 0;
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-central-divider:after, .pds-central-divider:before {
    content: '';
    height: 1px;
    background: #FFFFFF;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px; }
  .pds-central-divider:before {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px; }
  .pds-central-divider:after {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }
  .pds-central-divider a {
    color: #C4C9CA;
    display: inline-block;
    padding: 0 10px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }

@media (min-width: 769px) {
  .pds-fields .pds-input,
  .pds-fields .pds-input-area,
  .pds-fields .pds-readonly-data,
  .pds-fields .pds-readonly-data-long,
  .pds-fields input,
  .pds-fields select,
  .pds-fields textarea, .pds-central .pds-input,
  .pds-central .pds-input-area,
  .pds-central .pds-readonly-data,
  .pds-central .pds-readonly-data-long,
  .pds-central input,
  .pds-central select,
  .pds-central textarea, .pds-central .pds-fields .pds-input,
  .pds-central .pds-fields .pds-input-area,
  .pds-central .pds-fields .pds-readonly-data,
  .pds-central .pds-fields .pds-readonly-data-long,
  .pds-central .pds-fields input,
  .pds-central .pds-fields select,
  .pds-central .pds-fields textarea {
    width: 100% !important;
    max-width: 100% !important; } }

p.pds-badge {
  margin: 0 15px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

button.pds-button-square, button.pds-button-round, button.pds-button-action-attached, button.pds-button-action {
  -webkit-padding-start: 5px;
          padding-inline-start: 5px;
  -webkit-padding-end: 5px;
          padding-inline-end: 5px;
  width: 32px;
  min-width: 32px;
  height: 32px; }
  button.pds-button-square pds-icon, button.pds-button-round pds-icon, button.pds-button-action-attached pds-icon, button.pds-button-action pds-icon {
    -webkit-margin-start: 0;
            margin-inline-start: 0; }
  @media (max-width: 768px) {
    button.pds-button-square, button.pds-button-round, button.pds-button-action-attached, button.pds-button-action {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      width: 44px;
      min-width: 44px;
      height: 44px; } }

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: visible;
  text-transform: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 4px;
  background-color: #FFFFFF;
  height: 32px;
  border: 1px solid #0075DB;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 3px 10px;
  color: #0075DB;
  cursor: pointer;
  min-width: 100px;
  margin: 0; }
  [data-whatinput='mouse'] button {
    outline: 0; }
  @media (max-width: 768px) {
    button {
      height: 44px;
      font-size: 18px;
      line-height: 27px;
      padding-top: 6px;
      padding-bottom: 7px; } }
  button pds-icon {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
    fill: #0075DB; }
    button pds-icon svg {
      height: 20px;
      width: 20px; }
    @media (max-width: 768px) {
      button pds-icon {
        margin-top: 4px;
        margin-bottom: 3px; } }
  button.pds-button-square {
    border: 1px solid #0075DB;
    background-color: #FFFFFF; }
    button.pds-button-square pds-icon {
      fill: #0075DB; }
    button.pds-button-square:hover:not([disabled]), button.pds-button-square:focus:not([disabled]) {
      background-color: #F1F4F4; }
    button.pds-button-square.pds-is-active:hover:not([disabled]), button.pds-button-square.pds-is-active:focus:not([disabled]) {
      background-color: #00427C; }
      button.pds-button-square.pds-is-active:hover:not([disabled]) pds-icon, button.pds-button-square.pds-is-active:focus:not([disabled]) pds-icon {
        fill: #FFFFFF; }
    button.pds-button-square:active:not([disabled]) {
      background-color: #0075DB;
      color: #FFFFFF; }
      button.pds-button-square:active:not([disabled]) pds-icon {
        fill: #FFFFFF; }
    button.pds-button-square.pds-radio-button-clear {
      border-radius: 50%;
      background: transparent;
      border-color: transparent; }
      button.pds-button-square.pds-radio-button-clear pds-icon {
        fill: #677274; }
      button.pds-button-square.pds-radio-button-clear:hover:not([disabled]), button.pds-button-square.pds-radio-button-clear:focus:not([disabled]) {
        background-color: #F1F4F4;
        border-color: #677274; }
      button.pds-button-square.pds-radio-button-clear:active:not([disabled]) {
        background: transparent; }
        button.pds-button-square.pds-radio-button-clear:active:not([disabled]) pds-icon {
          fill: #677274; }
  button.pds-button-round {
    border-radius: 50%;
    border: 1px solid #0075DB;
    background-color: #FFFFFF; }
    button.pds-button-round pds-icon {
      fill: #0075DB; }
    button.pds-button-round:hover:not([disabled]), button.pds-button-round:focus:not([disabled]) {
      background-color: #F1F4F4; }
    button.pds-button-round.pds-is-active:hover:not([disabled]), button.pds-button-round.pds-is-active:focus:not([disabled]) {
      background-color: #00427C; }
      button.pds-button-round.pds-is-active:hover:not([disabled]) pds-icon, button.pds-button-round.pds-is-active:focus:not([disabled]) pds-icon {
        fill: #FFFFFF; }
    button.pds-button-round.pds-button-large {
      height: 44px;
      width: 44px; }
      @media (max-width: 768px) {
        button.pds-button-round.pds-button-large {
          height: 48px;
          width: 48px; } }
  button.pds-button-blend {
    background-color: transparent;
    border-color: transparent;
    color: #677274; }
    button.pds-button-blend pds-icon {
      fill: #677274;
      color: #677274; }
    button.pds-button-blend:hover:not([disabled]), button.pds-button-blend:focus:not([disabled]) {
      background-color: #F1F4F4;
      border-color: transparent; }
    button.pds-button-blend[disabled] {
      color: #677274;
      opacity: 0.3;
      border-color: transparent; }
    button.pds-button-blend.pds-is-active[disabled] {
      color: #FFFFFF; }
    button.pds-button-blend.pds-button-square:hover:not([disabled]), button.pds-button-blend.pds-button-square:focus:not([disabled]), button.pds-button-blend.pds-button-round:hover:not([disabled]), button.pds-button-blend.pds-button-round:focus:not([disabled]) {
      border: 1px solid #677274; }
    button.pds-button-blend.pds-button-square.pds-is-active pds-icon, button.pds-button-blend.pds-button-round.pds-is-active pds-icon {
      fill: #FFFFFF; }
    button.pds-button-blend.pds-button-square[disabled], button.pds-button-blend.pds-button-round[disabled] {
      background-color: transparent; }
  button.style-strip {
    border: none;
    background: transparent;
    cursor: default; }
    button.style-strip:hover:not([disabled]), button.style-strip:focus:not([disabled]) {
      background-color: transparent; }
  button[disabled] {
    color: #0075DB;
    background-color: #FFFFFF;
    opacity: 0.3; }
    button[disabled] pds-icon {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      fill: #0075DB; }
  button.pds-primary {
    color: #FFFFFF;
    background-color: #0075DB;
    color: #FFFFFF; }
    button.pds-primary[disabled] {
      opacity: 0.3; }
    button.pds-primary .pds-icon {
      fill: #FFFFFF; }
    button.pds-primary[disabled] {
      opacity: 0.3; }
    button.pds-primary:hover:not([disabled]), button.pds-primary:focus:not([disabled]) {
      background-color: #00427C;
      border-color: #00427C; }
  button.pds-tertiary {
    color: #DA2F6B;
    border: 1px solid #DA2F6B; }
    button.pds-tertiary.pds-is-active {
      color: #FFFFFF;
      background-color: #DA2F6B;
      border-color: #DA2F6B; }
      button.pds-tertiary.pds-is-active.pds-is-active:hover:not([disabled]), button.pds-tertiary.pds-is-active.pds-is-active:focus:not([disabled]) {
        background-color: #8B1941;
        border-color: #8B1941; }
      button.pds-tertiary.pds-is-active:hover:not([disabled]), button.pds-tertiary.pds-is-active:focus:not([disabled]) {
        border-color: transparent; }
  button.pds-is-active {
    color: #FFFFFF;
    background-color: #0075DB;
    border-color: transparent; }
    button.pds-is-active:hover:not([disabled]), button.pds-is-active:hover:not([disabled]) {
      background-color: #00427C; }
    button.pds-is-active[disabled] {
      opacity: 0.3; }
  button:hover:not([disabled]), button:focus:not([disabled]) {
    cursor: pointer;
    background-color: #F1F4F4;
    text-decoration: underline;
    outline: none; }
  button.pds-class-picker-close {
    border-color: transparent;
    background: transparent;
    width: auto; }
    button.pds-class-picker-close pds-icon {
      fill: white; }
    button.pds-class-picker-close:hover:not([disabled]), button.pds-class-picker-close:focus:not([disabled]) {
      background-color: transparent; }
  button.pds-button-inverted {
    border: none; }
  button.pds-button-multiselect {
    padding: 3px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 55px;
            padding-inline-end: 55px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #838E91;
    color: #333333;
    background-color: #FFFFFF; }
    button.pds-button-multiselect:hover:not([disabled]) {
      background-color: #F1F4F4; }
    button.pds-button-multiselect:focus:not([disabled]) {
      border: 1px solid #0075DB; }
    button.pds-button-multiselect .pds-popover-button-contents {
      color: #333333; }
      button.pds-button-multiselect .pds-popover-button-contents pds-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: absolute;
        inset-inline-end: 6px;
        fill: #677274; }
        button.pds-button-multiselect .pds-popover-button-contents pds-icon:hover:not([disabled]), button.pds-button-multiselect .pds-popover-button-contents pds-icon:focus:not([disabled]) {
          fill: #677274; }
    button.pds-button-multiselect.pds-is-active {
      border: 1px solid #0075DB;
      background-color: #C2E2FF; }
      button.pds-button-multiselect.pds-is-active pds-icon {
        fill: #677274; }
      button.pds-button-multiselect.pds-is-active:hover:not([disabled]), button.pds-button-multiselect.pds-is-active:focus:not([disabled]) {
        background-color: #C2E2FF; }
        button.pds-button-multiselect.pds-is-active:hover:not([disabled]) pds-icon, button.pds-button-multiselect.pds-is-active:focus:not([disabled]) pds-icon {
          fill: #677274; }
  button.pds-is-active {
    background-color: #0075DB; }
    button.pds-is-active pds-icon {
      fill: #FFFFFF; }
    button.pds-is-active:hover:not([disabled]), button.pds-is-active:focus:not([disabled]) {
      background-color: #00427C; }
      button.pds-is-active:hover:not([disabled]) pds-icon, button.pds-is-active:focus:not([disabled]) pds-icon {
        fill: #677274; }
  button.pds-button-inverse {
    color: #FFFFFF;
    background-color: transparent; }
    button.pds-button-inverse:hover:not([disabled]), button.pds-button-inverse:focus:not([disabled]) {
      background-color: rgba(0, 0, 0, 0.2); }
    button.pds-button-inverse[disabled] {
      color: #FFFFFF;
      opacity: 0.3; }
    button.pds-button-inverse.pds-primary {
      color: #0075DB;
      background-color: #FFFFFF;
      border: 1px solid #FFFFFF; }
      button.pds-button-inverse.pds-primary:hover:not([disabled]), button.pds-button-inverse.pds-primary:focus:not([disabled]) {
        background-color: #F1F4F4;
        border-color: #FFFFFF; }
      button.pds-button-inverse.pds-primary[disabled] {
        color: rgba(0, 102, 165, 0.3);
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.3);
        opacity: unset; }
  button.pds-button-pill {
    border-radius: 16px; }
    @media (max-width: 768px) {
      button.pds-button-pill {
        border-radius: 22px; } }
  button.pds-radio-button-clear {
    border-radius: 50%;
    background: transparent;
    border-color: transparent; }
    button.pds-radio-button-clear:hover, button.pds-radio-button-clear:focus {
      background-color: #F1F4F4;
      border-color: #677274; }
    button.pds-radio-button-clear pds-icon {
      position: relative;
      inset-inline-start: -1px;
      width: 22px;
      height: 22px; }
  button.pds-button-multi-action {
    position: relative;
    background-color: #FFFFFF;
    color: #0075DB;
    border: 1px solid #0075DB;
    -webkit-padding-end: 40px;
            padding-inline-end: 40px; }
    button.pds-button-multi-action:hover:not([disabled]), button.pds-button-multi-action:focus:not([disabled]) {
      background-color: #F1F4F4; }
    button.pds-button-multi-action::before {
      content: '';
      position: absolute;
      top: 0;
      inset-inline-end: 30px;
      height: 100%;
      width: 1px;
      background-color: #0075DB; }
    button.pds-button-multi-action::after {
      content: '';
      position: absolute;
      top: 5px;
      inset-inline-end: 5px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5px 0;
      border-color: #0075DB transparent transparent transparent;
      margin: 7px 5px; }
      @media (max-width: 768px) {
        button.pds-button-multi-action::after {
          top: 12px; } }

/* ---===== LEGACY BUTTON STYLES (OBSOLETE) ======--- */
a.pds-primary-btn,
input.pds-primary-btn, a.pds-primary-inverse-btn,
input.pds-primary-inverse-btn, a.pds-secondary-btn,
input.pds-secondary-btn, a.pds-secondary-inverse-btn,
input.pds-secondary-inverse-btn, a.pds-tertiary-btn,
input.pds-tertiary-btn {
  font-family: Open Sans, sans-serif;
  height: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  display: inline-block;
  min-width: 100px;
  padding: 3px 10px;
  text-align: center;
  cursor: pointer; }
  a.pds-primary-btn[disabled],
  input.pds-primary-btn[disabled], a.pds-primary-inverse-btn[disabled],
  input.pds-primary-inverse-btn[disabled], a.pds-secondary-btn[disabled],
  input.pds-secondary-btn[disabled], a.pds-secondary-inverse-btn[disabled],
  input.pds-secondary-inverse-btn[disabled], a.pds-tertiary-btn[disabled],
  input.pds-tertiary-btn[disabled] {
    opacity: 0.3;
    pointer-events: none;
    cursor: default; }
    a.pds-primary-btn[disabled]:hover,
    input.pds-primary-btn[disabled]:hover, a.pds-primary-inverse-btn[disabled]:hover,
    input.pds-primary-inverse-btn[disabled]:hover, a.pds-secondary-btn[disabled]:hover,
    input.pds-secondary-btn[disabled]:hover, a.pds-secondary-inverse-btn[disabled]:hover,
    input.pds-secondary-inverse-btn[disabled]:hover, a.pds-tertiary-btn[disabled]:hover,
    input.pds-tertiary-btn[disabled]:hover {
      outline: none; }
    a.pds-primary-btn[disabled]:hover,
    input.pds-primary-btn[disabled]:hover, a.pds-primary-inverse-btn[disabled]:hover,
    input.pds-primary-inverse-btn[disabled]:hover, a.pds-secondary-btn[disabled]:hover,
    input.pds-secondary-btn[disabled]:hover, a.pds-secondary-inverse-btn[disabled]:hover,
    input.pds-secondary-inverse-btn[disabled]:hover, a.pds-tertiary-btn[disabled]:hover,
    input.pds-tertiary-btn[disabled]:hover, a.pds-primary-btn[disabled]:focus,
    input.pds-primary-btn[disabled]:focus, a.pds-primary-inverse-btn[disabled]:focus,
    input.pds-primary-inverse-btn[disabled]:focus, a.pds-secondary-btn[disabled]:focus,
    input.pds-secondary-btn[disabled]:focus, a.pds-secondary-inverse-btn[disabled]:focus,
    input.pds-secondary-inverse-btn[disabled]:focus, a.pds-tertiary-btn[disabled]:focus,
    input.pds-tertiary-btn[disabled]:focus {
      opacity: 0.3;
      text-decoration: none;
      pointer-events: none; }
    a.pds-primary-btn[disabled]:focus,
    input.pds-primary-btn[disabled]:focus, a.pds-primary-inverse-btn[disabled]:focus,
    input.pds-primary-inverse-btn[disabled]:focus, a.pds-secondary-btn[disabled]:focus,
    input.pds-secondary-btn[disabled]:focus, a.pds-secondary-inverse-btn[disabled]:focus,
    input.pds-secondary-inverse-btn[disabled]:focus, a.pds-tertiary-btn[disabled]:focus,
    input.pds-tertiary-btn[disabled]:focus {
      outline: 1px solid #8FE5FF; }
  @media (max-width: 768px) {
    a.pds-primary-btn,
    input.pds-primary-btn, a.pds-primary-inverse-btn,
    input.pds-primary-inverse-btn, a.pds-secondary-btn,
    input.pds-secondary-btn, a.pds-secondary-inverse-btn,
    input.pds-secondary-inverse-btn, a.pds-tertiary-btn,
    input.pds-tertiary-btn {
      width: auto;
      height: 44px;
      font-size: 18px;
      line-height: 27px;
      padding: 6px 10px 7px; } }

a.pds-primary-btn,
input.pds-primary-btn {
  background-color: #0075DB;
  border: 1px solid #0075DB;
  color: #FFFFFF; }
  a.pds-primary-btn:hover, a.pds-primary-btn:focus,
  input.pds-primary-btn:hover,
  input.pds-primary-btn:focus {
    background-color: #00427C;
    text-decoration: underline;
    border-color: #00427C; }
  a.pds-primary-btn[disabled]:hover,
  input.pds-primary-btn[disabled]:hover {
    background-color: #0075DB; }
  a.pds-primary-btn[disabled]:focus,
  input.pds-primary-btn[disabled]:focus {
    border: 1px solid #8FCBFF; }

a.pds-primary-inverse-btn,
input.pds-primary-inverse-btn {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #0075DB; }
  a.pds-primary-inverse-btn:hover, a.pds-primary-inverse-btn:focus,
  input.pds-primary-inverse-btn:hover,
  input.pds-primary-inverse-btn:focus {
    background-color: #F1F4F4;
    text-decoration: underline;
    border: 1px solid #FFFFFF; }
  a.pds-primary-inverse-btn[disabled],
  input.pds-primary-inverse-btn[disabled] {
    background-color: rgba(255, 255, 225, 0.3);
    color: rgba(0, 102, 165, 0.3);
    border-color: rgba(255, 255, 225, 0.3);
    opacity: unset; }

a.pds-secondary-btn,
input.pds-secondary-btn {
  background-color: #FFFFFF;
  border: 1px solid #0075DB;
  color: #0075DB; }
  a.pds-secondary-btn:hover, a.pds-secondary-btn:focus,
  input.pds-secondary-btn:hover,
  input.pds-secondary-btn:focus {
    background-color: #F1F4F4;
    text-decoration: underline;
    border: 1px solid #0075DB; }
  a.pds-secondary-btn[disabled]:hover, a.pds-secondary-btn[disabled]:focus,
  input.pds-secondary-btn[disabled]:hover,
  input.pds-secondary-btn[disabled]:focus {
    background-color: #FFFFFF;
    border: 1px solid #0075DB; }

a.pds-secondary-inverse-btn,
input.pds-secondary-inverse-btn {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF; }
  a.pds-secondary-inverse-btn:hover, a.pds-secondary-inverse-btn:focus,
  input.pds-secondary-inverse-btn:hover,
  input.pds-secondary-inverse-btn:focus {
    background-color: rgba(0, 0, 0, 0.2);
    text-decoration: underline;
    border: 1px solid #FFFFFF; }
  a.pds-secondary-inverse-btn[disabled]:hover, a.pds-secondary-inverse-btn[disabled]:focus,
  input.pds-secondary-inverse-btn[disabled]:hover,
  input.pds-secondary-inverse-btn[disabled]:focus {
    background-color: transparent;
    border: 1px solid #FFFFFF; }

a.pds-tertiary-btn,
input.pds-tertiary-btn {
  background-color: #FFFFFF;
  border: 1px solid #DA2F6B;
  color: #DA2F6B; }
  a.pds-tertiary-btn.pds-tertiary-btn-is-active,
  input.pds-tertiary-btn.pds-tertiary-btn-is-active {
    background-color: #DA2F6B;
    color: #FFFFFF; }
    a.pds-tertiary-btn.pds-tertiary-btn-is-active:hover, a.pds-tertiary-btn.pds-tertiary-btn-is-active:focus,
    input.pds-tertiary-btn.pds-tertiary-btn-is-active:hover,
    input.pds-tertiary-btn.pds-tertiary-btn-is-active:focus {
      background-color: #8B1941;
      border-color: #8B1941; }
  a.pds-tertiary-btn:hover, a.pds-tertiary-btn:focus,
  input.pds-tertiary-btn:hover,
  input.pds-tertiary-btn:focus {
    background-color: #F1F4F4;
    text-decoration: underline; }
  a.pds-tertiary-btn[disabled]:hover, a.pds-tertiary-btn[disabled]:focus,
  input.pds-tertiary-btn[disabled]:hover,
  input.pds-tertiary-btn[disabled]:focus {
    background-color: #FFFFFF;
    border: 1px solid #DA2F6B; }

@media (max-width: 768px) {
  a.pds-primary-btn, a.pds-primary-inverse-btn, a.pds-secondary-btn, a.pds-secondary-inverse-btn, a.pds-tertiary-btn {
    line-height: 28px; } }

button.pds-button-action-attached {
  margin-bottom: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  background-color: #FFFFFF;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-style: solid;
  border-color: #838E91;
  border-width: 1px;
  border-inline-start-width: 0; }
  button.pds-button-action-attached pds-icon {
    fill: #677274; }
  button.pds-button-action-attached:hover {
    background-color: #F1F4F4; }

button.pds-button-action {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px; }

.pds-button-bar, .pds-page-controls .pds-view-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-button-bar button, .pds-page-controls .pds-view-select button {
    margin: 0;
    border-radius: 0;
    border-inline-end-width: 0; }
    .pds-button-bar button:hover:not([disabled]), .pds-page-controls .pds-view-select button:hover:not([disabled]), .pds-button-bar button:focus:not([disabled]), .pds-page-controls .pds-view-select button:focus:not([disabled]) {
      border-inline-end-width: 0; }
    .pds-button-bar button:last-of-type:hover:not([disabled]), .pds-page-controls .pds-view-select button:last-of-type:hover:not([disabled]), .pds-button-bar button:last-of-type:focus:not([disabled]), .pds-page-controls .pds-view-select button:last-of-type:focus:not([disabled]) {
      border-inline-end-width: 1px; }
    .pds-button-bar button:last-of-type, .pds-page-controls .pds-view-select button:last-of-type {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
      border-inline-end-width: 1px; }
    .pds-button-bar button:first-of-type, .pds-page-controls .pds-view-select button:first-of-type {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px; }

.pds-button-group, .pds-button-group-primary {
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .pds-button-group > *, .pds-button-group-primary > * {
    margin: 0 5px 10px; }

.pds-button-group-primary button[disabled] {
  color: #DFE2E2; }

.pds-button-group-primary button.pds-button-inverted {
  color: #FFFFFF; }
  .pds-button-group-primary button.pds-button-inverted pds-icon {
    fill: #FFFFFF; }
  .pds-button-group-primary button.pds-button-inverted:hover {
    color: #000000; }
    .pds-button-group-primary button.pds-button-inverted:hover pds-icon {
      fill: #000000; }

.pds-button-group-primary button.pds-primary {
  color: #FFFFFF; }
  .pds-button-group-primary button.pds-primary.pds-button-inverted {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #0075DB; }
    .pds-button-group-primary button.pds-primary.pds-button-inverted pds-icon {
      -webkit-margin-start: 0;
              margin-inline-start: 0;
      fill: #0075DB; }
    .pds-button-group-primary button.pds-primary.pds-button-inverted:hover {
      color: #FFFFFF;
      background-color: #0075DB; }
      .pds-button-group-primary button.pds-primary.pds-button-inverted:hover pds-icon {
        fill: #FFFFFF; }

.pds-icon-sprite {
  display: none; }

pds-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  fill: #677274;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  pds-icon svg {
    height: 26px;
    width: 26px;
    pointer-events: none; }
  pds-icon img {
    height: 26px;
    width: 26px; }

.pds-button-square pds-icon .pds-icon-gradient {
  width: 20px;
  height: 20px; }

.pds-icon-dollar-sign {
  fill: #52BA2B; }

.pds-icon-cake {
  fill: #DA2F6B; }

.pds-icon-scale {
  fill: #00B6EF; }

.pds-icon-medical {
  fill: #EB1313; }

.pds-icon-alert-other-s {
  fill: #F5691D; }

.pds-icon-alert-parent-s {
  fill: #9C56B9; }

.pds-icon-source {
  display: none; }

.pds-rocket-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 40px;
  width: 32px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/rocket.svg"); }
  button .pds-rocket-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-bars-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 40px;
  width: 32px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/bars.svg"); }
  button .pds-bars-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-app-switcher-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/application-launcher-icon.svg"); }
  button .pds-app-switcher-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-pt-icon-xxl {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/app1.svg"); }
  button .pds-pt-icon-xxl {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-ptp-icon-xxl {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/app2.svg"); }
  button .pds-ptp-icon-xxl {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-learning-icon-xxl {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/app3.svg"); }
  button .pds-learning-icon-xxl {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-assessment-icon-xxl {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/app4.svg"); }
  button .pds-assessment-icon-xxl {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-sped-icon-xxl {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/app5.svg"); }
  button .pds-sped-icon-xxl {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-plus {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/add-s.svg"); }
  button .pds-icon-plus {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-pointer-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/pointer.svg"); }
  button .pds-pointer-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-blank-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-color: transparent;
  border: 2px solid #FFFFFF; }
  button .pds-blank-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-blank-icon-sm {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0;
  background-color: transparent;
  border: 2px solid #FFFFFF; }
  button .pds-blank-icon-sm {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-pointer-right-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/pointer-right-white.svg"); }
  button .pds-icon-pointer-right-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-triangle-down {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/pointer-down.svg"); }
  button .pds-icon-triangle-down {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-triangle-up {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/pointer-up.svg"); }
  button .pds-icon-triangle-up {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-calendar-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/calendar-s-white.svg"); }
  button .pds-icon-calendar-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-close-sm {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/close-circle-grey.svg"); }
  button .pds-icon-close-sm {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }
  button:hover .pds-icon-close-sm, button:focus .pds-icon-close-sm {
    background-image: url("../../../../scripts/pds/img/close-circle-grey-dark.svg"); }

.pds-analysis-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-size: 150%;
  background-image: url("../../../../scripts/pds/img/analysis.svg"); }
  button .pds-analysis-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-create-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 0;
  background-size: 125%;
  background-image: url("../../../../scripts/pds/img/create.svg"); }
  button .pds-create-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-grading-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-size: 150%;
  background-image: url("../../../../scripts/pds/img/grading.svg"); }
  button .pds-grading-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-library-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-size: 150%;
  background-image: url("../../../../scripts/pds/img/library.svg"); }
  button .pds-library-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-planning-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-size: 140%;
  background-image: url("../../../../scripts/pds/img/planning.svg"); }
  button .pds-planning-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-search-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 0;
  background-size: 125%;
  background-image: url("../../../../scripts/pds/img/search.svg"); }
  button .pds-search-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-search-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 0;
  background-size: 125%;
  background-image: url("../../../../scripts/pds/img/search-grey.svg"); }
  button .pds-search-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-settings-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/settings-s.svg"); }
  button .pds-settings-icon {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-above-icon-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/above-icon-white.svg"); }
  button .pds-above-icon-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-above-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/above-icon-grey.svg"); }
  button .pds-above-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-below-icon-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/below-icon-white.svg"); }
  button .pds-below-icon-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-below-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/below-icon-grey.svg"); }
  button .pds-below-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-assignments-asc-icon-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/assignments-asc-icon-white.svg"); }
  button .pds-assignments-asc-icon-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-assignments-asc-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/assignments-asc-icon-grey.svg"); }
  button .pds-assignments-asc-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-assignments-dsc-icon-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/assignments-dsc-icon-white.svg"); }
  button .pds-assignments-dsc-icon-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-assignments-dsc-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/assignments-dsc-icon-grey.svg"); }
  button .pds-assignments-dsc-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-list-icon-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/list-icon-white.svg"); }
  button .pds-list-icon-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-list-icon-grey {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/list-icon-grey.svg"); }
  button .pds-list-icon-grey {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-arrow-blue {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/arrow-blue.svg"); }
  button .pds-arrow-blue {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-arrow-white {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/arrow-white.svg"); }
  button .pds-arrow-white {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-success-check {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/success-check.svg"); }
  button .pds-success-check {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-x-close {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/x-close.svg"); }
  button .pds-x-close {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-x-error {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 0;
  background-image: url("../../../../scripts/pds/img/x-error.svg"); }
  button .pds-x-error {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-icon-smallest {
  width: 10px;
  height: 10px; }
  .pds-icon-smallest svg {
    width: 10px;
    height: 10px; }

.pds-icon-smaller {
  width: 13px;
  height: 13px; }
  .pds-icon-smaller svg {
    width: 13px;
    height: 13px; }

.pds-icon-small {
  width: 16px;
  height: 16px; }
  .pds-icon-small svg {
    width: 16px;
    height: 16px; }

.pds-icon-medium {
  width: 20px;
  height: 20px; }
  .pds-icon-medium svg {
    width: 20px;
    height: 20px; }

.pds-icon-large {
  width: 26px;
  height: 26px; }
  .pds-icon-large svg {
    width: 26px;
    height: 26px; }

svg.pds-icon-list {
  padding: 0; }

pds-icon[name="MS-immersive-reader"] {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
  pds-icon[name="MS-immersive-reader"] svg {
    width: 20px;
    height: 20px; }

.pds-icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 15px 15px; }
  .pds-icon-list .pds-icon-labeled {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-margin-end: 40px;
            margin-inline-end: 40px;
    max-width: 250px; }
    .pds-icon-list .pds-icon-labeled pds-icon {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }

.pds-app {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 0px 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: 60px 0px 1fr;
  grid-template-rows: 60px 1fr;
      grid-template-areas: "pds-layout-header pds-layout-header pds-layout-sidebar"
 "pds-layout-nav pds-layout-main pds-layout-sidebar";
  grid-gap: 0px;
  width: 100vw;
  height: 100vh; }
  @media print {
    .pds-app {
      overflow-y: visible !important;
      height: 100% !important; } }
  @media (max-width: 768px) {
    .pds-app {
      -ms-grid-columns: auto 0px 110px 0px 1fr;
      grid-template-columns: auto 110px 1fr;
      -ms-grid-rows: auto 0px 1fr;
      grid-template-rows: auto 1fr;
          grid-template-areas: "pds-layout-nav pds-layout-header pds-layout-header"
 "pds-layout-main pds-layout-main pds-layout-main"; } }
  .pds-app.pds-error-page {
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
        grid-template-areas: "pds-layout-main";
    overflow-y: auto; }
    .pds-app.pds-error-page router-outlet {
      position: absolute; }
    .pds-app.pds-error-page .pds-content, .pds-app.pds-error-page main .pds-content-basic, main .pds-app.pds-error-page .pds-content-basic, .pds-app.pds-error-page main .pss-content, main .pds-app.pds-error-page .pss-content {
      padding-top: 0; }

.pds-app-header-bar,
.pds-wizard-header-bar {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: pds-layout-header; }

.pds-app.pds-detail-screen-pro-mode > .pds-app-header-bar,
.pds-app.pds-detail-screen-pro-mode > .pds-wizard-header-bar {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; }

pds-app-nav,
.pds-app-nav-container {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: pds-layout-nav; }

.pds-app-nav-container pds-app-nav {
  grid-area: unset;
  height: 100%; }

.pds-app-sidebar {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 5;
  grid-area: pds-layout-sidebar;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.pds-app.pds-detail-screen-pro-mode > .pds-app-sidebar {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 3; }

pds-error-page {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: pds-layout-main;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  overflow: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding-top: 60px; }

.pds-app.pds-error-page > pds-error-page {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; }

.pds-app.pds-detail-screen-pro-mode > pds-error-page {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; }
  @media (max-width: 576px) {
    pds-error-page {
      padding-top: 50px; } }

main {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: pds-layout-main;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.pds-app.pds-error-page > main {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; }

.pds-app.pds-detail-screen-pro-mode > main {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; }
  @media print {
    main {
      display: block !important;
      overflow-y: visible !important;
      height: 100% !important; } }

pss-error-page {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top; }

.pds-content-light {
  overflow: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 15px; }
  @media print {
    .pds-content-light {
      display: block !important;
      overflow-y: visible !important;
      height: 100% !important; } }

.pds-central {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow: auto;
  background: #00427C;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-height: 899px) {
    .pds-central {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media (max-width: 768px) {
    .pds-central {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  .pds-central .pds-content, .pds-central main .pds-content-basic, main .pds-central .pds-content-basic, .pds-central main .pss-content, main .pds-central .pss-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 650px;
            flex: 0 1 650px;
    margin: auto; }
  .pds-central .pds-fields {
    max-width: 450px;
    margin: 0 auto; }

.pds-content-light {
  background: #FFFFFF; }

body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media print {
    body {
      overflow-y: visible !important;
      display: block !important;
      height: 100% !important; } }

@-ms-viewport {
  zoom: 1.0;
  width: device-width; }

@-o-viewport {
  zoom: 1.0;
  width: device-width; }

@viewport {
  zoom: 1.0;
  width: device-width; }

main {
  background: #F1F4F4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  width: auto;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  main .pds-content, main .pds-content-basic, main .pss-content {
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 15px 0 0;
    position: relative; }
    @media print {
      main .pds-content, main .pds-content-basic, main .pss-content {
        overflow-y: visible !important;
        display: block !important;
        height: 100% !important; } }
    main .pds-content.pds-content-basic, main .pds-content-basic {
      background: #FFFFFF; }
    main .pds-content.pds-content-flush, main .pds-content-flush.pds-content-basic, main .pds-content-flush.pss-content {
      padding-top: 0; }
    main .pds-content .pds-content-top, main .pds-content-basic .pds-content-top, main .pss-content .pds-content-top {
      margin-top: -15px; }
    main .pds-content .pds-default-spacing, main .pds-content-basic .pds-default-spacing, main .pss-content .pds-default-spacing {
      margin: 0 15px 20px;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
    main .pds-content.pds-content-full, main .pds-content-full.pds-content-basic, main .pds-content-full.pss-content {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      padding: 0;
      overflow: hidden;
      height: 95vh; }
  main .pds-content-basic {
    background: #FFFFFF; }
  main .pss-content {
    padding-top: 0; }
  main .pds-is-scrollable {
    overflow-y: auto;
    overflow-x: hidden; }

.pds-page-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  background: #FFFFFF;
  padding: 0 20px;
  margin: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #C4C9CA;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif; }
  @media (max-width: 768px) {
    .pds-page-head {
      -webkit-padding-start: 15px;
              padding-inline-start: 15px;
      -webkit-padding-end: 0;
              padding-inline-end: 0; } }
  @media (max-width: 768px) {
    .pds-detail-screen-pro-mode .pds-page-head {
      padding: 0;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .pds-detail-screen-pro-mode .pds-page-head pds-detail-select, .pds-detail-screen-pro-mode .pds-page-head pds-detail-select-widget {
        padding: 8px;
        -webkit-padding-start: 0;
                padding-inline-start: 0;
        -webkit-padding-end: 10px;
                padding-inline-end: 10px;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 100%;
                flex: 1 0 100%; }
      .pds-detail-screen-pro-mode .pds-page-head pds-class-picker {
        border-top: 1px solid #838E91;
        padding: 8px;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto; } }
  .pds-page-head .pds-page-head-text {
    width: 100%;
    margin: 0;
    padding: 11px 0 12px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .pds-page-head .pds-page-text-primary, .pds-page-head h1 {
    word-break: break-word;
    padding: 0;
    margin: 0;
    color: #333333;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600; }
    .pds-page-head .pds-page-text-primary:focus, .pds-page-head h1:focus {
      outline: none;
      border: none;
      -webkit-margin-end: auto;
              margin-inline-end: auto;
      border-bottom: 3px solid #C4C9CA;
      color: #000000;
      font-weight: 700; }
  .pds-page-head .pds-page-text-secondary {
    word-break: break-word;
    color: #677274;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .pds-page-head .pds-page-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    padding: 0;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media (max-width: 768px) {
      .pds-page-head .pds-page-actions {
        -webkit-padding-start: 30px;
                padding-inline-start: 30px; } }
    .pds-page-head .pds-page-actions pds-class-picker {
      padding-top: 0;
      padding-bottom: 0; }
      .pds-page-head .pds-page-actions pds-class-picker button.pds-section-select-trigger {
        margin-top: 0; }
  .pds-page-head h1 .pds-app-title:focus {
    outline: none;
    border: none;
    border-bottom: 3px solid #C4C9CA;
    color: #000000;
    font-weight: 700; }
  .pds-page-head pds-detail-select, .pds-page-head pds-detail-select-widget {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    padding: 8px; }
    @media (max-width: 992px) {
      .pds-page-head pds-detail-select, .pds-page-head pds-detail-select-widget {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; } }
    @media (max-width: 768px) {
      .pds-page-head pds-detail-select, .pds-page-head pds-detail-select-widget {
        -webkit-margin-end: 0;
                margin-inline-end: 0; } }
  @media (max-width: 992px) {
    .pds-page-head pds-detail-select {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media (max-width: 768px) {
    .pds-page-head pds-detail-select-widget .pds-detail-select-widget-content {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  .pds-page-head pds-class-picker {
    padding: 8px; }

@media (max-width: 768px) {
  .pds-detail-screen-pro-mode .pds-page-head {
    padding: 0;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .pds-detail-screen-pro-mode .pds-page-head pds-detail-select, .pds-detail-screen-pro-mode .pds-page-head pds-detail-select-widget {
      padding: 8px;
      -webkit-padding-start: 0;
              padding-inline-start: 0;
      -webkit-padding-end: 10px;
              padding-inline-end: 10px;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 100%;
              flex: 1 0 100%; }
    .pds-detail-screen-pro-mode .pds-page-head pds-class-picker {
      border-top: 1px solid #838E91;
      padding: 8px;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto; } }

table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px;
  width: calc(~"100% - 40px");
  padding: 0;
  border: none;
  border-radius: 4px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  table.pds-table-indent {
    margin: 15px;
    width: calc(~"100% - 30px"); }
  table button {
    margin-bottom: 0; }
  table tr:last-child td {
    background-image: none; }
  table tr {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    table tr:not(:first-child) {
      margin-top: 2px;
      margin-bottom: 2px; }
    table tr:first-child {
      margin-bottom: 2px; }
  table td, table th {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: start;
    color: #333333;
    padding: 5px;
    margin: 0;
    border: none; }
    table td:first-child, table th:first-child {
      -webkit-padding-start: 15px;
              padding-inline-start: 15px; }
  table th {
    font-weight: 600;
    font-size: 12px;
    border: none; }
  table thead {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    table thead th {
      background-color: transparent;
      height: 40px; }
      table thead th pds-popover .pds-popover-trigger-content {
        padding: 0; }
  table tbody {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    table tbody td {
      background-color: #FFFFFF;
      height: 40px;
      /* W3C Markup */
      background-image: linear-gradient(to top, #c4c9ca 0, rgba(255, 255, 255, 0) 1px);
      background-position: bottom center;
      background-repeat: repeat-x; }
      table tbody td:first-child {
        background-size: calc(~"100% - 15px");
        background-repeat: no-repeat;
        background-position: right bottom; }
      table tbody td:last-child {
        background-size: calc(~"100% - 15px");
        background-repeat: no-repeat;
        background-position: left bottom;
        -webkit-padding-end: 15px;
                padding-inline-end: 15px; }
        table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu {
          display: block; }
          table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu > pds-popover {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
            table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu > pds-popover div.pds-popover-button button {
              height: calc(~"100% - 1px");
              border: none;
              border-radius: 0;
              border-start-end-radius: 4px;
              border-end-end-radius: 4px; }
              table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu > pds-popover div.pds-popover-button button::before {
                content: "";
                height: 30px;
                -webkit-padding-end: 9px;
                        padding-inline-end: 9px;
                -webkit-border-start: 1px solid #C4C9CA;
                        border-inline-start: 1px solid #C4C9CA;
                border-radius: 0; }
              table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu > pds-popover div.pds-popover-button button::after {
                content: "";
                -webkit-padding-start: 8px;
                        padding-inline-start: 8px; }
            table tbody td:last-child:not(.pds-content-and-controls) pds-popover-menu > pds-popover div.pds-popover-button button.pds-is-active::before {
              -webkit-border-start: 1px solid #0075DB;
                      border-inline-start: 1px solid #0075DB; }
    table tbody th:first-child {
      background-color: #F1F4F4;
      background-size: calc(~"100% - 15px");
      background-repeat: no-repeat;
      background-position: right bottom;
      /* W3C Markup */
      background-image: linear-gradient(to top, #c4c9ca 0, rgba(255, 255, 255, 0) 1px); }
  table > tbody tr:first-child th:first-child, table > tbody tr:first-child td:first-child {
    border-start-start-radius: 4px; }
  table > tbody tr:first-child th:last-child, table > tbody tr:first-child td:last-child {
    border-start-end-radius: 4px; }
  table > tbody tr:last-child th:first-of-type, table > tbody tr:last-child td:first-of-type {
    border-end-start-radius: 4px; }
  table > tbody tr:last-child th:last-of-type, table > tbody tr:last-child td:last-of-type {
    border-end-end-radius: 4px; }

table.pds-data-list th, table.pds-data-list td, table.pds-data-list tr {
  border: none;
  background: none; }

table.pds-data-list > thead tr:first-child th, table.pds-data-list > thead tr:first-child td, table.pds-data-list > thead:first-child tr:first-child th, table.pds-data-list > thead:first-child tr:first-child td, table.pds-data-list > tbody tr:first-child th, table.pds-data-list > tbody tr:first-child td, table.pds-data-list > tbody:first-child tr:first-child th, table.pds-data-list > tbody:first-child tr:first-child td, table.pds-data-list > tfoot tr:first-child th, table.pds-data-list > tfoot tr:first-child td, table.pds-data-list > tfoot:first-child tr:first-child th, table.pds-data-list > tfoot:first-child tr:first-child td {
  border-top: none; }
  table.pds-data-list > thead tr:first-child th:first-child, table.pds-data-list > thead tr:first-child td:first-child, table.pds-data-list > thead:first-child tr:first-child th:first-child, table.pds-data-list > thead:first-child tr:first-child td:first-child, table.pds-data-list > tbody tr:first-child th:first-child, table.pds-data-list > tbody tr:first-child td:first-child, table.pds-data-list > tbody:first-child tr:first-child th:first-child, table.pds-data-list > tbody:first-child tr:first-child td:first-child, table.pds-data-list > tfoot tr:first-child th:first-child, table.pds-data-list > tfoot tr:first-child td:first-child, table.pds-data-list > tfoot:first-child tr:first-child th:first-child, table.pds-data-list > tfoot:first-child tr:first-child td:first-child {
    border-start-start-radius: 0; }
  table.pds-data-list > thead tr:first-child th:last-child, table.pds-data-list > thead tr:first-child td:last-child, table.pds-data-list > thead:first-child tr:first-child th:last-child, table.pds-data-list > thead:first-child tr:first-child td:last-child, table.pds-data-list > tbody tr:first-child th:last-child, table.pds-data-list > tbody tr:first-child td:last-child, table.pds-data-list > tbody:first-child tr:first-child th:last-child, table.pds-data-list > tbody:first-child tr:first-child td:last-child, table.pds-data-list > tfoot tr:first-child th:last-child, table.pds-data-list > tfoot tr:first-child td:last-child, table.pds-data-list > tfoot:first-child tr:first-child th:last-child, table.pds-data-list > tfoot:first-child tr:first-child td:last-child {
    border-start-end-radius: 0; }

table.pds-data-list > thead tr:last-child th:first-child, table.pds-data-list > thead tr:last-child td:first-child, table.pds-data-list > thead:last-child tr:last-child th:first-child, table.pds-data-list > thead:last-child tr:last-child td:first-child, table.pds-data-list > tbody tr:last-child th:first-child, table.pds-data-list > tbody tr:last-child td:first-child, table.pds-data-list > tbody:last-child tr:last-child th:first-child, table.pds-data-list > tbody:last-child tr:last-child td:first-child, table.pds-data-list > tfoot tr:last-child th:first-child, table.pds-data-list > tfoot tr:last-child td:first-child, table.pds-data-list > tfoot:last-child tr:last-child th:first-child, table.pds-data-list > tfoot:last-child tr:last-child td:first-child {
  border-end-start-radius: 0; }

table.pds-data-list > thead tr:last-child th:last-child, table.pds-data-list > thead tr:last-child td:last-child, table.pds-data-list > thead:last-child tr:last-child th:last-child, table.pds-data-list > thead:last-child tr:last-child td:last-child, table.pds-data-list > tbody tr:last-child th:last-child, table.pds-data-list > tbody tr:last-child td:last-child, table.pds-data-list > tbody:last-child tr:last-child th:last-child, table.pds-data-list > tbody:last-child tr:last-child td:last-child, table.pds-data-list > tfoot tr:last-child th:last-child, table.pds-data-list > tfoot tr:last-child td:last-child, table.pds-data-list > tfoot:last-child tr:last-child th:last-child, table.pds-data-list > tfoot:last-child tr:last-child td:last-child {
  border-end-end-radius: 0; }

pss-calendar thead th {
  text-align: center; }

pds-grid-widget table {
  margin-top: 0px; }
  pds-grid-widget table thead tr:first-of-type th, pds-grid-widget table thead tr:first-of-type td {
    padding-top: 0;
    height: 35px; }

td.single-checkbox-col,
th.single-checkbox-col,
td.pds-single-checkbox-col,
th.pds-single-checkbox-col,
td.pds-single-radio-button-col,
th.pds-single-radio-button-col {
  width: 46px; }

table.pds-table-hr th, table.pds-table-hr td {
  border-bottom: 1px solid #C4C9CA; }
  table.pds-table-hr th:first-child, table.pds-table-hr td:first-child {
    -webkit-border-start: none;
            border-inline-start: none; }
  table.pds-table-hr th:last-child, table.pds-table-hr td:last-child {
    -webkit-border-end: none;
            border-inline-end: none; }

table.pds-table-hr > thead:first-child tr:first-child th, table.pds-table-hr > thead:first-child tr:first-child td, table.pds-table-hr > tbody:first-child tr:first-child th, table.pds-table-hr > tbody:first-child tr:first-child td, table.pds-table-hr > tfoot:first-child tr:first-child th, table.pds-table-hr > tfoot:first-child tr:first-child td {
  border-top: none; }

[dir="rtl"] .pds-app-header-bar {
  background: -webkit-gradient(linear, right top, left top, from(#0075DB), to(#00427C));
  background: linear-gradient(to left, #0075DB, #00427C); }

.pds-app-header-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#0075DB), to(#00427C));
  background: linear-gradient(to right, #0075DB, #00427C);
  -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  z-index: 6000;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  max-width: 100%;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif; }
  @media (max-width: 768px) {
    .pds-app-header-bar {
      height: auto;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  .pds-app-header-bar header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    overflow: hidden; }
    @media (max-width: 768px) {
      .pds-app-header-bar header {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 50%;
                flex: 1 1 50%; } }
    @media (max-width: 576px) {
      .pds-app-header-bar header {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 calc(~"100vw - 250px");
                flex: 1 1 calc(~"100vw - 250px"); } }
    .pds-app-header-bar header a {
      -webkit-margin-start: 20px;
              margin-inline-start: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-decoration: none;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      overflow: hidden; }
      @media (max-width: 768px) {
        .pds-app-header-bar header a {
          overflow: hidden; } }
      .pds-app-header-bar header a .pds-logo-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        min-width: 0;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        [dir='rtl'] .pds-app-header-bar header a .pds-logo-text {
          -webkit-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1; }
      .pds-app-header-bar header a .pds-logo-text-primary {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 28px;
                flex: 0 0 28px;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        @media (max-width: 768px) {
          .pds-app-header-bar header a .pds-logo-text-primary {
            font-size: 18px; } }
      .pds-app-header-bar header a .pds-logo-text-secondary {
        font-family: 'Karla', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 16px;
                flex: 0 0 16px; }
      .pds-app-header-bar header a:focus {
        border: none;
        outline: 2px solid #8FE5FF;
        outline-offset: -2px; }
    .pds-app-header-bar header pds-icon {
      /* rtl-lint:ignore */
      margin-left: 4px;
      /* rtl-lint:ignore */
      margin-right: 10px; }
      [dir='rtl'] .pds-app-header-bar header pds-icon {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2; }
    .pds-app-header-bar header svg {
      width: 28px;
      height: 60px; }
  .pds-app-header-bar .pds-app-back {
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 250px;
            flex: 0 0 250px;
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF; }
    .pds-app-header-bar .pds-app-back pds-icon {
      -webkit-margin-end: 15px;
              margin-inline-end: 15px;
      width: 34px;
      height: 34px; }
      [dir='rtl'] .pds-app-header-bar .pds-app-back pds-icon {
        -webkit-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
                transform: scaleX(-1); }
      .pds-app-header-bar .pds-app-back pds-icon svg {
        width: 26px;
        fill: #FFFFFF; }
    .pds-app-header-bar .pds-app-back:hover, .pds-app-header-bar .pds-app-back:focus {
      outline: none;
      border: none;
      cursor: pointer; }
      .pds-app-header-bar .pds-app-back:hover pds-icon, .pds-app-header-bar .pds-app-back:focus pds-icon {
        border-radius: 50%;
        background: #FFFFFF; }
        .pds-app-header-bar .pds-app-back:hover pds-icon svg, .pds-app-header-bar .pds-app-back:focus pds-icon svg {
          z-index: 1;
          fill: #00427C; }
    @media (max-width: 768px) {
      .pds-app-header-bar .pds-app-back .pds-app-back-text {
        display: none; } }
  .pds-app-header-bar .pds-app-header-bar-center {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-app-header-bar .pds-app-header-bar-center pds-class-picker button.pds-class-picker .class-selector-text {
      position: relative;
      top: -1px; }
    @media (max-width: 768px) {
      .pds-app-header-bar .pds-app-header-bar-center {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        background: #FFFFFF;
        -webkit-margin-start: -45px;
                margin-inline-start: -45px;
        min-width: 300px;
        width: 300px; } }
    @media (max-width: 576px) {
      .pds-app-header-bar .pds-app-header-bar-center {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        min-width: 200px;
        width: 200px; }
        .pds-app-header-bar .pds-app-header-bar-center pds-class-picker {
          width: 100%;
          -webkit-padding-end: 5px;
                  padding-inline-end: 5px; } }
    .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text {
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      @media (min-width: 577px) {
        .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text {
          max-width: 300px; } }
      @media (max-width: 576px) {
        .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text {
          max-width: 200px; } }
      .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text h1 {
        font-family: Open Sans, sans-serif;
        font-weight: 300;
        margin: 0 15px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        font-size: 40px;
        line-height: 40px;
        padding: 30px 0; }
        @media (max-width: 768px) {
          .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text h1 {
            font-size: 24px;
            line-height: 24px;
            padding: 21px 0; } }
      .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text .pds-page-text-primary {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        overflow: hidden;
        text-align: center;
        padding: 0; }
      .pds-app-header-bar .pds-app-header-bar-center .pds-app-header-bar-text .pds-page-text-secondary {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        overflow: hidden;
        text-align: center;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
  .pds-app-header-bar .pds-app-actions {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media (max-width: 768px) {
      .pds-app-header-bar .pds-app-actions {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        width: 200px; } }
    .pds-app-header-bar .pds-app-actions .pds-app-action {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 50px;
              flex: 0 1 50px;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer; }
      @media (max-width: 768px) {
        .pds-app-header-bar .pds-app-actions .pds-app-action {
          width: 40px; } }
    .pds-app-header-bar .pds-app-actions div.pds-app-action {
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
    .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button pds-icon {
      fill: #FFFFFF;
      height: 32px;
      width: 32px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      margin: 0; }
      .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button pds-icon svg {
        width: 20px; }
    .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button:hover, .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button:focus,
    .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button button:focus,
    .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button a:focus {
      outline: none;
      border: none;
      cursor: pointer; }
      .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button:hover pds-icon svg, .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button:focus pds-icon svg,
      .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button button:focus pds-icon svg,
      .pds-app-header-bar .pds-app-actions pds-app-switcher .pds-popover-button-contents button a:focus pds-icon svg {
        z-index: 1;
        fill: #00427C; }
    .pds-app-header-bar .pds-app-actions .pds-app-action pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-app-action pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-search pds-icon {
      height: 32px;
      width: 32px;
      fill: #FFFFFF; }
      .pds-app-header-bar .pds-app-actions .pds-app-action pds-icon svg,
      .pds-app-header-bar .pds-app-actions a.pds-app-action pds-icon svg,
      .pds-app-header-bar .pds-app-actions a.pds-search pds-icon svg {
        width: 20px;
        height: 20px; }
    .pds-app-header-bar .pds-app-actions .pds-app-action:hover pds-icon, .pds-app-header-bar .pds-app-actions .pds-app-action:focus pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-app-action:hover pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-app-action:focus pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-search:hover pds-icon,
    .pds-app-header-bar .pds-app-actions a.pds-search:focus pds-icon {
      cursor: pointer;
      border-radius: 50%;
      background-color: #FFFFFF;
      fill: #00427C;
      max-width: 32px; }
    @media (max-width: 768px) {
      .pds-app-header-bar .pds-app-actions a.pds-search {
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; } }
    .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content pds-icon {
      fill: #FFFFFF;
      margin: 0; }
      .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content pds-icon svg {
        width: 20px;
        height: 20px; }
    .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content button {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 32px;
              flex: 0 1 32px;
      height: 100%;
      width: 32px;
      padding: 0; }
      .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content button:hover, .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content button:focus {
        border: none; }
        .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content button:hover pds-icon, .pds-app-header-bar .pds-app-actions pds-help-menu .pds-help-menu-content button:focus pds-icon {
          background-color: #FFFFFF;
          fill: #00427C;
          border-radius: 50%; }
    .pds-app-header-bar .pds-app-actions button.pds-global-create-menu {
      border: 1px solid #00427C; }
      .pds-app-header-bar .pds-app-actions button.pds-global-create-menu:first-child {
        -webkit-border-end: none;
                border-inline-end: none; }
    .pds-app-header-bar .pds-app-actions .pds-global-create-menu-content.pds-global-create-menu .pds-button-bar.pds-global-create-menu > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu, .pds-app-header-bar .pds-app-actions .pds-global-create-menu-content.pds-global-create-menu .pds-page-controls .pds-global-create-menu.pds-view-select > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu, .pds-page-controls .pds-app-header-bar .pds-app-actions .pds-global-create-menu-content.pds-global-create-menu .pds-global-create-menu.pds-view-select > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu {
      height: 20px;
      width: 20px; }
    .pds-app-header-bar .pds-app-actions button.pds-app-action {
      background: none;
      border: none; }
    .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 480px) {
        .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-2 {
          display: none; } }
    .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-3,
    .pds-app-header-bar .pds-app-actions pds-user-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 768px) {
        .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-3,
        .pds-app-header-bar .pds-app-actions pds-user-menu {
          display: none; } }
    .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-4 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 992px) {
        .pds-app-header-bar .pds-app-actions .pds-app-action.pds-app-action-priority-4 {
          display: none; } }
  @media (max-width: 1200px) {
    .pds-app-header-bar pds-detail-select select {
      width: calc(~"100% - 110px"); } }

.pds-detail-screen-pro-mode .pds-app-header-bar {
  background: -webkit-gradient(linear, left top, right top, from(#0075DB), to(#00427C));
  background: linear-gradient(to right, #0075DB, #00427C);
  -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2); }
  @media (max-width: 768px) {
    .pds-detail-screen-pro-mode .pds-app-header-bar {
      height: 60px;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  .pds-detail-screen-pro-mode .pds-app-header-bar header,
  .pds-detail-screen-pro-mode .pds-app-header-bar pds-app-switcher,
  .pds-detail-screen-pro-mode .pds-app-header-bar pds-user-menu,
  .pds-detail-screen-pro-mode .pds-app-header-bar pss-search {
    display: none; }
  .pds-detail-screen-pro-mode .pds-app-header-bar .pds-logo-text pds-app-switcher {
    display: none; }
  .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-back {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 768px) {
      .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-back {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 106px;
                flex: 0 0 106px; } }
    @media (max-width: 576px) {
      .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-back {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50px;
                flex: 0 0 50px; } }
  .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions pds-popover-menu pds-popover {
    height: 32px;
    width: 32px; }
    .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions pds-popover-menu pds-popover button {
      background: none;
      border: none;
      padding: 0; }
      .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions pds-popover-menu pds-popover button pds-icon {
        fill: #FFFFFF;
        margin: 0; }
  @media (max-width: 768px) {
    .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-header-bar-center {
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
      background: none;
      margin: 0;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      min-width: auto; }
    .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 250px;
              flex: 0 0 250px; } }
  @media (max-width: 768px) and (max-width: 768px) {
    .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 106px;
              flex: 0 0 106px; } }
  @media (max-width: 768px) and (max-width: 576px) {
    .pds-detail-screen-pro-mode .pds-app-header-bar .pds-app-actions {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50px;
              flex: 0 0 50px; } }

pds-notification-badge {
  position: absolute;
  top: 5px;
  inset-inline-end: 5px;
  z-index: 1; }

.pds-notification-badge-container {
  position: relative; }

div.pds-notification-badge-container {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

@font-face {
  font-family: "Open Sans";
  src: url("../../../../scripts/pds/fonts/open-sans-all-400-normal.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../../../../scripts/pds/fonts/open-sans-all-300-normal.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../../../../scripts/pds/fonts/open-sans-all-600-normal.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../../../../scripts/pds/fonts/open-sans-all-600-italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../../../../scripts/pds/fonts/open-sans-all-400-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Karla";
  src: url("../../../../scripts/pds/fonts/karla-latin-700.woff2") format("woff2"), url("../../../../scripts/pds/fonts/karla-latin-700.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.pds-text-size-fine {
  font-size: 9px; }

.pds-text-size-default {
  font-size: 14px; }

.pds-text-size-small {
  font-size: 10px; }

.pds-text-size-default {
  font-size: 14px; }

html, body {
  -webkit-text-size-adjust: 100%; }

b,
strong {
  font-weight: 700; }

small {
  font-size: 12px;
  line-height: 18px; }

a {
  background-color: transparent;
  cursor: pointer;
  color: #0075DB;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: underline;
    cursor: pointer;
    color: #002B52; }

h1, h2, h3, h4, h5, h6 {
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  margin: 0 15px;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

h1 {
  font-size: 40px;
  line-height: 40px;
  padding: 30px 0; }
  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
      line-height: 24px;
      padding: 21px 0; } }

h2 {
  font-size: 36px;
  line-height: 36px;
  padding: 28px 0; }
  @media (max-width: 768px) {
    h2 {
      font-size: 22px;
      line-height: 22px;
      padding: 20px 0; } }

h3 {
  font-size: 31px;
  line-height: 31px;
  padding: 25px 0; }
  @media (max-width: 768px) {
    h3 {
      font-size: 21px;
      line-height: 21px;
      padding: 19px 0; } }

h4 {
  font-size: 26px;
  line-height: 26px;
  padding: 22px 0; }
  @media (max-width: 768px) {
    h4 {
      font-size: 20px;
      line-height: 20px;
      padding: 18px 0; } }

h5 {
  font-size: 22px;
  line-height: 22px;
  padding: 20px 0; }
  @media (max-width: 768px) {
    h5 {
      font-size: 19px;
      line-height: 19px;
      padding: 17px 0; } }

h6 {
  font-size: 18px;
  line-height: 18px;
  padding: 18px 0; }
  @media (max-width: 768px) {
    h6 {
      font-size: 18px;
      line-height: 18px;
      padding: 16px 0; } }

p {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 15px 15px;
  padding: 0; }

.pds-panels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 992px) {
    .pds-panels {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      .pds-panels .pds-panel-column {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto; } }
  .pds-panels.pds-constrained-content .pds-panel, .pds-panels.pds-constrained-content .pds-panel-floating-trigger > .pds-panel-floating {
    max-width: 720px; }
  @media (max-width: 992px) {
    .pds-panels.pds-constrained-content {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  .pds-panels.pds-constrained-content .pds-panel-column {
    max-width: 400px; }
    @media (max-width: 992px) {
      .pds-panels.pds-constrained-content .pds-panel-column {
        max-width: 750px;
        width: 100%; } }

.pds-panels-fill .pds-panel, .pds-panels-fill .pds-panel-floating-trigger > .pds-panel-floating {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  width: 100%; }

.pds-panel-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 400px;
          flex: 1 1 400px; }
  .pds-panel-column .pds-panel, .pds-panel-column .pds-panel-floating-trigger > .pds-panel-floating {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    width: calc(~"100% - 15px");
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    @media (max-width: 992px) {
      .pds-panel-column .pds-panel, .pds-panel-column .pds-panel-floating-trigger > .pds-panel-floating {
        -webkit-margin-end: 15px;
                margin-inline-end: 15px;
        width: calc(~"100% - 30px"); } }
  .pds-panel-column:last-child {
    -webkit-margin-end: 15px;
            margin-inline-end: 15px; }
    @media (max-width: 992px) {
      .pds-panel-column:last-child {
        -webkit-margin-end: 0;
                margin-inline-end: 0; } }

.pds-panel h3, .pds-panel-floating-trigger > .pds-panel-floating h3, .pds-panel-header {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 40px; }

.pds-panel, .pds-panel-floating-trigger > .pds-panel-floating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 0 15px 15px;
  width: calc(~"100% - 30px"); }
  .pds-panel h3, .pds-panel-floating-trigger > .pds-panel-floating h3 {
    padding: 0 15px; }
  .pds-panel p, .pds-panel-floating-trigger > .pds-panel-floating p, .pds-panel > pds-rich-text-display, .pds-panel-floating-trigger > .pds-panel-floating > pds-rich-text-display {
    margin: 0 15px 15px; }
  .pds-panel.pds-panel-full, .pds-panel-floating-trigger > .pds-panel-full.pds-panel-floating {
    height: 100%;
    margin-bottom: 0; }
    .pds-panel.pds-panel-full .pds-panel-body, .pds-panel-floating-trigger > .pds-panel-full.pds-panel-floating .pds-panel-body {
      overflow-y: auto; }
    .pds-panel.pds-panel-full .pds-panel-header, .pds-panel-floating-trigger > .pds-panel-full.pds-panel-floating .pds-panel-header {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
  .pds-panel.pds-panel-first, .pds-panel-floating-trigger > .pds-panel-first.pds-panel-floating {
    margin-top: 15px; }
  @media (max-width: 992px) {
    .pds-panel, .pds-panel-floating-trigger > .pds-panel-floating {
      -ms-flex-preferred-size: auto;
          flex-basis: auto; } }
  .pds-panel pds-card .pds-card-content, .pds-panel-floating-trigger > .pds-panel-floating pds-card .pds-card-content {
    border: 1px solid #C4C9CA; }

.pds-panel-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #C4C9CA; }
  .pds-panel-header button.pds-button-square {
    border: none;
    -webkit-border-start: 1px solid #C4C9CA;
            border-inline-start: 1px solid #C4C9CA;
    border-radius: 0;
    height: 34px;
    width: 34px;
    padding: 4px;
    -webkit-margin-end: 3px;
            margin-inline-end: 3px; }
  .pds-panel-header .pds-loader, .pds-panel-header .pds-loader-sm, .pds-panel-header .pds-loader-lg {
    -webkit-margin-end: 15px;
            margin-inline-end: 15px;
    height: 17px; }

.pds-panel-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 0 15px 15px;
  text-align: end; }
  .pds-panel-footer a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-panel-footer a pds-icon {
      -webkit-margin-start: 5px;
              margin-inline-start: 5px;
      width: 16px;
      height: 16px;
      fill: #0075DB; }

.pds-panel-floating-trigger {
  position: relative;
  display: inline-block;
  -ms-flex-item-align: baseline;
      align-self: baseline; }
  .pds-panel-floating-trigger > .pds-panel-floating {
    margin: 0;
    position: absolute;
    z-index: 8001;
    top: calc(~"100% + 10px");
    white-space: nowrap;
    -webkit-box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.2);
            box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #838E91; }
    .pds-panel-floating-trigger > .pds-panel-floating.pds-align-above {
      top: auto;
      bottom: calc(~"100% + 10px"); }
    .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left {
      inset-inline-start: 0; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left::before {
        content: "";
        position: absolute;
        top: -11px;
        bottom: auto;
        inset-inline-start: 8px;
        inset-inline-end: auto;
        width: 0;
        height: 0;
        -webkit-border-start: 11px solid transparent;
                border-inline-start: 11px solid transparent;
        -webkit-border-end: 11px solid transparent;
                border-inline-end: 11px solid transparent;
        border-bottom: 11px solid #C4C9CA; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left::after {
        content: "";
        position: absolute;
        top: -10px;
        inset-inline-start: 9px;
        inset-inline-end: auto;
        width: 0;
        height: 0;
        -webkit-border-start: 10px solid transparent;
                border-inline-start: 10px solid transparent;
        -webkit-border-end: 10px solid transparent;
                border-inline-end: 10px solid transparent;
        border-bottom: 10px solid #FFFFFF; }
    .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right {
      inset-inline-end: 0; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right::before {
        content: "";
        position: absolute;
        top: -11px;
        bottom: auto;
        inset-inline-start: auto;
        inset-inline-end: 8px;
        width: 0;
        height: 0;
        -webkit-border-start: 11px solid transparent;
                border-inline-start: 11px solid transparent;
        -webkit-border-end: 11px solid transparent;
                border-inline-end: 11px solid transparent;
        border-bottom: 11px solid #C4C9CA; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right::after {
        content: "";
        position: absolute;
        top: -10px;
        inset-inline-start: auto;
        inset-inline-end: 9px;
        width: 0;
        height: 0;
        -webkit-border-start: 10px solid transparent;
                border-inline-start: 10px solid transparent;
        -webkit-border-end: 10px solid transparent;
                border-inline-end: 10px solid transparent;
        border-bottom: 10px solid #FFFFFF; }
    .pds-panel-floating-trigger > .pds-panel-floating:not(.pds-align-left):not(.pds-align-right)::before {
      content: "";
      position: absolute;
      top: -11px;
      bottom: auto;
      inset-inline-start: calc(~"0% + 10px");
      inset-inline-end: auto;
      width: 0;
      height: 0;
      -webkit-border-start: 11px solid transparent;
              border-inline-start: 11px solid transparent;
      -webkit-border-end: 11px solid transparent;
              border-inline-end: 11px solid transparent;
      border-bottom: 11px solid #C4C9CA; }
    .pds-panel-floating-trigger > .pds-panel-floating:not(.pds-align-left):not(.pds-align-right)::after {
      content: "";
      position: absolute;
      top: -10px;
      inset-inline-start: calc(~"0% + 10px");
      inset-inline-end: auto;
      width: 0;
      height: 0;
      -webkit-border-start: 10px solid transparent;
              border-inline-start: 10px solid transparent;
      -webkit-border-end: 10px solid transparent;
              border-inline-end: 10px solid transparent;
      border-bottom: 10px solid #FFFFFF; }
    .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left.pds-align-above {
      inset-inline-start: 0; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left.pds-align-above::after {
        content: "";
        position: absolute;
        top: auto;
        bottom: -12px;
        inset-inline-start: 9px;
        inset-inline-end: auto;
        width: 0;
        height: 0;
        -webkit-border-start: 6px solid #FFFFFF;
                border-inline-start: 6px solid #FFFFFF;
        -webkit-border-end: 6px solid transparent;
                border-inline-end: 6px solid transparent;
        border-top: 6px solid transparent;
        border-bottom: 6px solid #FFFFFF;
        -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
                transform-origin: 0 0;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5);
                box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5); }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-left.pds-align-above::before {
        display: none; }
    .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right.pds-align-above {
      inset-inline-end: 0; }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right.pds-align-above::after {
        content: "";
        position: absolute;
        top: auto;
        bottom: -12px;
        inset-inline-start: auto;
        inset-inline-end: 9px;
        width: 0;
        height: 0;
        -webkit-border-start: 6px solid #FFFFFF;
                border-inline-start: 6px solid #FFFFFF;
        -webkit-border-end: 6px solid transparent;
                border-inline-end: 6px solid transparent;
        border-top: 6px solid transparent;
        border-bottom: 6px solid #FFFFFF;
        -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
                transform-origin: 0 0;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5);
                box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5); }
      .pds-panel-floating-trigger > .pds-panel-floating.pds-align-right.pds-align-above::before {
        display: none; }
    .pds-panel-floating-trigger > .pds-panel-floating:not(.pds-align-left):not(.pds-align-right).pds-align-above::after {
      content: "";
      position: absolute;
      top: auto;
      bottom: -12px;
      inset-inline-start: 0;
      inset-inline-end: auto;
      width: 0;
      height: 0;
      -webkit-border-start: 6px solid #FFFFFF;
              border-inline-start: 6px solid #FFFFFF;
      -webkit-border-end: 6px solid transparent;
              border-inline-end: 6px solid transparent;
      border-top: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      -webkit-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
              transform-origin: 0 0;
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      -webkit-box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5);
              box-shadow: -1px 1px 0px 0px #C4C9CA, -2px 3px 2px 0px rgba(0, 0, 0, 0.5); }
    .pds-panel-floating-trigger > .pds-panel-floating:not(.pds-align-left):not(.pds-align-right).pds-align-above::before {
      display: none; }
    .pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row {
      -webkit-padding-start: 10px;
              padding-inline-start: 10px;
      -webkit-padding-end: 10px;
              padding-inline-end: 10px; }
    .pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row:hover {
      background-color: #F1F4F4; }
    .pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row:last-child {
      border-bottom: none; }
    .pds-panel-floating-trigger > .pds-panel-floating .pds-row, .pds-panel-floating-trigger > .pds-panel-floating .pds-row:last-child {
      margin-bottom: 0; }

.pds-popover button, pds-popover-menu .pds-panel-floating button {
  border: none;
  width: 100%;
  margin: 5px;
  padding: 10px;
  border-radius: 2px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .pds-popover button pds-icon, pds-popover-menu .pds-panel-floating button pds-icon {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0; }

.pds-panels.pds-panels-single-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .pds-panels.pds-panels-single-column > .pds-panel, .pds-panel-floating-trigger.pds-panels.pds-panels-single-column > .pds-panel-floating {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }

@media (min-width: 769px) {
  pds-app-nav:not(.pds-widget),
  .pds-app-nav-container:empty {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    width: 110px;
    grid-area: pds-layout-nav;
    -webkit-border-end: 1px solid #C4C9CA;
            border-inline-end: 1px solid #C4C9CA;
    position: relative;
    opacity: 1;
    -webkit-transform: opacity 1s;
        -ms-transform: opacity 1s;
            transform: opacity 1s; }
    pds-app-nav:not(.pds-widget)::before,
    .pds-app-nav-container:empty::before {
      width: 110px;
      content: '';
      background: url('data:image/svg+xml;utf8,\a       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 296">\a       <rect fill="%23A8B1B2" x="42.5" y="14.5" width="25" height="25" rx="4"/><rect fill="%23A8B1B2" x="17" y="49" width="76" height="13" rx="2"/>\a       <rect fill="%23C4C9CA" x="42.5" y="88.5" width="25" height="25" rx="4"/><rect fill="%23C4C9CA" x="17" y="123" width="76" height="13" rx="2"/>\a       <rect fill="%23DFE2E2" x="42.5" y="162.5" width="25" height="25" rx="4"/><rect fill="%23DFE2E2" x="17" y="197" width="76" height="13" rx="2"/>\a       <rect fill="%23F1F4F4" x="42.5" y="236.5" width="25" height="25" rx="4"/><rect fill="%23F1F4F4" x="17" y="271" width="76" height="13" rx="2"/>\a       </svg>');
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      inset-inline-start: 0;
      inset-inline-end: 0; } }

@media (max-width: 768px) {
    .pds-app-header-bar,
.pds-wizard-header-bar {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3; }
    pds-app-nav,
.pds-app-nav-container {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
    pds-error-page {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5; }
    main {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5; }
    pds-app-nav:not(.pds-widget),
  .pds-app-nav-container:empty {
    -ms-grid-row: 1;
    -ms-grid-column: 1; } }

pds-app-nav.pds-app-nav {
  position: relative; }
  @media (min-width: 769px) {
    pds-app-nav.pds-app-nav {
      overflow-y: auto; } }
  pds-app-nav.pds-app-nav:focus {
    outline: none;
    border: none; }

.pds-nav-item-image {
  width: 185px;
  height: 90px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.pds-app-nav-content {
  background: #F1F4F4;
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: static;
  height: 100%; }
  @media (max-width: 768px) {
    .pds-app-nav-content {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      height: 60px; } }
  .pds-app-nav-content.pds-is-secondary-open header span {
    display: none; }
  .pds-app-nav-content .pds-nav-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    @media (max-width: 768px) {
      .pds-app-nav-content .pds-nav-content {
        display: none;
        position: absolute;
        z-index: 8000;
        top: 0;
        inset-inline-start: 0;
        height: 100vh;
        max-height: none;
        width: 240px; } }
    .pds-app-nav-content .pds-nav-content .pds-nav-lists {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 110px;
              flex: 1 1 110px;
      overflow-y: auto; }
      .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user {
        background: #00427C;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        display: none; }
        @media (max-width: 768px) {
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; } }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .pds-nav-user-header {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-ordinal-group: 3;
              -ms-flex-order: 2;
                  order: 2; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .pds-nav-user-header a:after {
            content: '';
            position: absolute;
            inset-inline-start: auto;
            inset-inline-end: 20px;
            top: 24px;
            opacity: .5;
            -webkit-border-start: 5px solid transparent;
                    border-inline-start: 5px solid transparent;
            border-bottom: 6px solid #A8B1B2;
            -webkit-border-end: 5px solid transparent;
                    border-inline-end: 5px solid transparent; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user.pds-is-open .pds-nav-user-header a:after {
          border-top: 6px solid #A8B1B2;
          border-bottom: none; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a:hover, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a:focus {
          background-color: #00427c; }
        @media (max-width: 768px) {
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-icon {
            height: 42px;
            padding: 0;
            width: 42px; }
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-icon svg {
              height: 24px;
              width: 24px;
              border-radius: 50%;
              border: 2px solid #8FCBFF; } }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar {
          margin: auto;
          height: 42px;
          padding: 0; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar .pds-user-avatar-contents {
            background: none;
            height: 42px; }
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar .pds-user-avatar-contents .pds-image-contents > img {
              height: 42px;
              width: 42px;
              border-radius: 50%;
              border: 2px solid #8FCBFF; }
          @media (max-width: 768px) {
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar {
              margin: 0;
              height: 28px;
              -webkit-padding-start: 20px;
                      padding-inline-start: 20px; }
              .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar .pds-user-avatar-contents {
                width: 28px;
                height: 28px; }
                .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar .pds-user-avatar-contents .pds-image-contents > img {
                  height: 28px;
                  width: 28px; }
              .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a pds-user-avatar pds-icon {
                padding: 0; } }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a .nav-user-initials {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin: auto;
          padding: 0;
          font-size: 20px;
          font-weight: bold;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          height: 42px;
          width: 42px;
          border-radius: 50%;
          border: 2px solid #8FCBFF; }
          @media (max-width: 768px) {
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a .nav-user-initials {
              margin: 0;
              -webkit-margin-start: 20px;
                      margin-inline-start: 20px;
              height: 28px;
              width: 28px;
              font-size: 12px; } }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .pds-username-multiline {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a,
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name {
          color: white;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a .nav-user-name-text,
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name .nav-user-name-text {
            display: none; }
          @media (max-width: 768px) {
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a,
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name {
              -webkit-box-flex: 1;
                  -ms-flex: 1 0 50px;
                      flex: 1 0 50px;
              height: 50px; }
              .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user a .nav-user-name-text,
              .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user div.nav-user-name .nav-user-name-text {
                display: inline-block;
                -webkit-padding-start: 16px;
                        padding-inline-start: 16px;
                color: white; } }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user ul {
          overflow-y: auto; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .user-options {
          -webkit-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .user-options a {
            color: white; }
            .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .user-options a pds-icon svg {
              height: 24px;
              width: 24px;
              border: none;
              border-radius: 0;
              fill: #FFFFFF; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .user-options a:hover pds-icon svg, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-nav-user .user-options :focus pds-icon svg {
            fill: #677274; }
      .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 60px;
        width: 70px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-contents button {
          height: 60px;
          width: 70px;
          background: none;
          border: none;
          padding: 0;
          border-radius: 0; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-contents button:hover:not(disabled) {
            background: none; }
      .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-user {
        font-size: 16px;
        color: #FFFFFF;
        border: 2px solid #C2E2FF;
        background: #0075DB;
        border-radius: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: 40px;
        height: 40px;
        font-weight: 700;
        font-family: "Open Sans";
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        padding: 1px 0; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-user .pds-user-avatar-contents {
          width: 40px;
          height: 40px;
          border: 2px solid #C2E2FF; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-user .pds-user-avatar-contents .pds-image-contents img {
            width: 40px;
            height: 40px; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-user:hover, .pds-app-nav-content .pds-nav-content .pds-nav-lists .pds-user-menu-user:hover:not(disabled) {
          background: #0075DB; }
      .pds-app-nav-content .pds-nav-content .pds-nav-lists button.pds-user-menu-trigger:hover:not(disabled) {
        background: #0075DB; }
      .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme li {
        text-align: start;
        margin-bottom: 5px; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme li:last-child {
          margin-bottom: 0; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme li pds-icon {
          -webkit-margin-end: 15px;
                  margin-inline-end: 15px; }
          .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme li pds-icon svg {
            height: 20px; }
        .pds-app-nav-content .pds-nav-content .pds-nav-lists .user-menu-theme li a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          color: #333333;
          font-size: 14px;
          height: 32px; }
  .pds-app-nav-content pds-icon {
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    --pds-icon-svg-height: 20px; }
    @media (min-width: 769px) {
      .pds-app-nav-content pds-icon {
        margin: auto; } }
    @media (max-width: 768px) {
      .pds-app-nav-content pds-icon {
        padding-top: 0;
        padding-bottom: 0;
        -webkit-margin-start: 11px;
                margin-inline-start: 11px; } }
    .pds-app-nav-content pds-icon svg {
      height: 24px;
      width: 24px;
      fill: #677274; }
  .pds-app-nav-content a {
    position: relative; }
    .pds-app-nav-content a.pds-has-secondary-item::after {
      content: '';
      position: absolute;
      inset-inline-start: auto;
      inset-inline-end: 20px;
      top: 20px;
      opacity: 0.5;
      -webkit-border-start: 6px solid #A8B1B2;
              border-inline-start: 6px solid #A8B1B2;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; }
      @media (min-width: 769px) {
        .pds-app-nav-content a.pds-has-secondary-item::after {
          top: 22px;
          inset-inline-end: 8px; } }
    .pds-app-nav-content a.pds-is-active::after, .pds-app-nav-content a.pds-is-selected::after {
      -webkit-border-start: 6px solid #677274;
              border-inline-start: 6px solid #677274;
      opacity: 1; }
    .pds-app-nav-content a:hover::after {
      -webkit-border-start: 6px solid #677274;
              border-inline-start: 6px solid #677274; }
    .pds-app-nav-content a.pds-has-secondary-item-centered::after {
      top: 10px; }
  .pds-app-nav-content.pds-four-tier-support {
    max-width: 110px; }
    .pds-app-nav-content.pds-four-tier-support .pds-nav-primary {
      /* * * * * * * * * * * * * * * * * * *  End Four Tier Menu  * * * * * * * * * * * * * * * */ }
      .pds-app-nav-content.pds-four-tier-support .pds-nav-primary a {
        height: auto; }
      .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-primary-nav-link > a {
        min-height: 74px; }
        .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-primary-nav-link > a span {
          white-space: pre-wrap;
          margin-bottom: 10px; }
          @media (max-width: 768px) {
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-primary-nav-link > a span {
              margin-bottom: 0;
              -webkit-border-start: none;
                      border-inline-start: none; } }
      .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu {
        position: fixed;
        height: 0;
        background-color: white;
        top: 60px;
        inset-inline-start: 110px;
        z-index: 8000;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; }
        .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu li {
          padding: 0; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu li a {
            font-size: 14px;
            line-height: 21px;
            padding: 5px 10px 6px;
            border-radius: 4px;
            text-align: start;
            margin: 0 10px 4px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu li a:hover, .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu li a:focus {
              text-decoration: underline; }
        .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-secondary-tier {
          background-color: white;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 250px;
                  flex: 0 0 250px;
          max-width: 250px;
          width: 100%;
          -webkit-border-end: 1px solid #C4C9CA;
                  border-inline-end: 1px solid #C4C9CA;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-secondary-tier .pds-app-nav-secondary-tier-header {
            display: block;
            padding: 15px 15px 8px;
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
            border-bottom: 1px solid #C4C9CA;
            max-width: 250px;
            -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                    flex: 0 0 auto;
            color: #0075DB; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-secondary-tier .pds-app-nav-secondary-tier-list-container {
            height: 0;
            -webkit-box-flex: 1;
                -ms-flex: 1 0 auto;
                    flex: 1 0 auto;
            overflow-y: auto;
            padding: 15px 5px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-secondary-tier .pds-app-nav-secondary-tier-list-container a.pds-is-selected {
              background-color: #F1F4F4;
              color: #00427C; }
              .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-secondary-tier .pds-app-nav-secondary-tier-list-container a.pds-is-selected.pds-is-active {
                background-color: #C2E2FF;
                color: #333333; }
        .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers {
          background-color: white;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 705px;
                  flex: 0 0 705px;
          width: 705px;
          display: block;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2); }
          @media (max-width: 1200px) {
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers {
              width: 475px;
              -ms-flex-preferred-size: 475px;
                  flex-basis: 475px; } }
          @media (max-width: 970px) {
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers {
              width: 245px;
              -ms-flex-preferred-size: 245px;
                  flex-basis: 245px; } }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content {
            -webkit-column-width: 230px;
               -moz-column-width: 230px;
                    column-width: 230px;
            -webkit-column-count: 3;
               -moz-column-count: 3;
                    column-count: 3;
            -webkit-column-gap: 0;
               -moz-column-gap: 0;
                    column-gap: 0;
            padding-top: 15px;
            -webkit-padding-end: 10px;
                    padding-inline-end: 10px;
            padding-bottom: 0;
            -webkit-padding-start: 5px;
                    padding-inline-start: 5px;
            width: 705px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content template {
              display: block !important;
              max-height: 0;
              overflow: hidden; }
            @media (max-width: 1200px) {
              .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content {
                -webkit-column-count: 2;
                   -moz-column-count: 2;
                        column-count: 2;
                -ms-flex-preferred-size: 475px;
                    flex-basis: 475px;
                width: 475px; } }
            @media (max-width: 970px) {
              .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content {
                -webkit-column-count: 1;
                   -moz-column-count: 1;
                        column-count: 1;
                -ms-flex-preferred-size: 245px;
                    flex-basis: 245px;
                width: 245px; } }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content .pds-app-nav-tertiary-group {
              width: 230px;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              margin-bottom: 26px; }
              .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content .pds-app-nav-tertiary-group .pds-app-nav-tertiary-item-header {
                display: block;
                font-size: 18px;
                line-height: 27px;
                font-weight: 600;
                color: #0075DB;
                margin-top: 0;
                -webkit-margin-end: 20px;
                        margin-inline-end: 20px;
                margin-bottom: 4px;
                -webkit-margin-start: 20px;
                        margin-inline-start: 20px; }
                .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content .pds-app-nav-tertiary-group .pds-app-nav-tertiary-item-header:nth-child(2) {
                  height: 600px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers .pds-app-nav-scrolling-third-and-fourth-tier-content .pds-app-nav-tertiary-item-last {
              margin-bottom: 26px; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-single-column {
            -ms-flex-preferred-size: 240px;
                flex-basis: 240px;
            width: 240px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-single-column .pds-app-nav-scrolling-third-and-fourth-tier-content {
              -webkit-column-count: auto;
                 -moz-column-count: auto;
                      column-count: auto;
              -webkit-column-width: auto;
                 -moz-column-width: auto;
                      column-width: auto;
              width: auto; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-mega-menu-max-columns-1 {
            -ms-flex-preferred-size: 240px;
                flex-basis: 240px;
            width: 240px; }
            .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-mega-menu-max-columns-1 .pds-app-nav-scrolling-third-and-fourth-tier-content {
              -webkit-column-count: auto;
                 -moz-column-count: auto;
                      column-count: auto;
              -webkit-column-width: auto;
                 -moz-column-width: auto;
                      column-width: auto;
              width: auto; }
          .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-mega-menu-max-columns-2 {
            -ms-flex-preferred-size: 475px;
                flex-basis: 475px;
            width: 475px; }
            @media (max-width: 970px) {
              .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu .pds-app-nav-third-and-fourth-tiers.pds-app-nav-mega-menu-max-columns-2 {
                width: 245px;
                -ms-flex-preferred-size: 245px;
                    flex-basis: 245px; } }
        .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu a.pds-is-active {
          -webkit-border-start: none;
                  border-inline-start: none;
          -webkit-padding-start: 10px;
                  padding-inline-start: 10px;
          font-weight: 600; }
    .pds-app-nav-content.pds-four-tier-support.pds-is-nav-open .pds-nav-primary .pds-primary-nav-link > a {
      min-height: 0px;
      height: 50px; }
  .pds-app-nav-content.pds-four-tier-support .pds-nav-primary .pds-app-nav-mega-menu a.pds-is-active, .pds-app-nav-content .pds-app-nav-mega-menu a.pds-is-active, .pds-app-nav-content .pds-app-nav-mobile-menu-secondary-plus a.pds-is-active {
    background-color: #C2E2FF;
    -webkit-border-start: 4px solid #0075DB;
            border-inline-start: 4px solid #0075DB;
    -webkit-padding-start: 6px;
            padding-inline-start: 6px; }

.pds-nav-primary {
  min-width: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 769px) {
    .pds-nav-primary {
      border-top: none;
      -webkit-border-start: none;
              border-inline-start: none;
      border-bottom: none;
      -webkit-border-end: 1px solid #C4C9CA;
              border-inline-end: 1px solid #C4C9CA; } }
  .pds-nav-primary .pds-nav-secondary {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-nav-primary .pds-nav-secondary .pds-nav-category a span {
      -webkit-margin-start: 50px;
              margin-inline-start: 50px; }
    .pds-nav-primary .pds-nav-secondary .pds-nav-category a::after {
      content: '';
      position: absolute;
      inset-inline-start: 20px;
      inset-inline-end: auto;
      top: 25px;
      -webkit-border-end: 6px solid #C2E2FF;
              border-inline-end: 6px solid #C2E2FF;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; }
    .pds-nav-primary .pds-nav-secondary .pds-nav-category .pds-nav-category-header {
      font-weight: 600;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 215px;
      min-height: 30px;
      background-color: #FFFFFF;
      color: #0075DB;
      -webkit-padding-start: 15px;
              padding-inline-start: 15px;
      line-height: 24px;
      text-align: start; }
    .pds-nav-primary .pds-nav-secondary ul a {
      border-radius: 0; }
    @media (min-width: 769px) {
      .pds-nav-primary .pds-nav-secondary {
        z-index: 8000;
        width: 240px; }
        .pds-nav-primary .pds-nav-secondary .pds-nav-category {
          position: static;
          display: none; } }
    @media (max-width: 768px) {
      .pds-nav-primary .pds-nav-secondary {
        margin-top: 0;
        position: absolute;
        z-index: 1;
        height: 100%;
        min-width: 240px;
        max-width: 300px;
        -ms-flex-preferred-size: 240px;
            flex-basis: 240px; }
        .pds-nav-primary .pds-nav-secondary .pds-nav-category {
          min-height: 90px;
          -ms-flex-negative: 0;
              flex-shrink: 0;
          position: static;
          width: 100%; } }
  @media (max-width: 768px) {
    .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 240px;
      grid-template-columns: 240px;
      -ms-grid-rows: auto auto 1fr;
      grid-template-rows: auto auto 1fr;
          grid-template-areas: "pds-app-nav-selected-parent"
 "pds-app-nav-selected-header"
 "pds-app-nav-selected-children";
      inset-inline-start: 0;
      width: 240px;
      height: 100%; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus a {
        height: auto; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus pds-icon {
        height: 20px;
        width: 20px;
        margin: 0; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus a:hover pds-icon, .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus a:focus pds-icon {
        border-radius: 0;
        background-color: transparent; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent {
        grid-area: pds-app-nav-selected-parent;
        background-color: #F1F4F4;
        border-bottom: 1px solid #C4C9CA;
        color: #333333;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        padding: 11px 15px 12px; }
        .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent pds-icon {
          margin-top: 1px; }
          .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent pds-icon svg {
            fill: #0075DB;
            opacity: 0.5; }
        .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent span {
          max-height: none;
          display: inline;
          padding: 0;
          -webkit-margin-start: 15px;
                  margin-inline-start: 15px;
          line-height: 21px;
          font-size: 14px; }
        .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent:focus {
          text-decoration: underline; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-header {
        grid-area: pds-app-nav-selected-header;
        line-height: 27px;
        font-size: 18px;
        font-weight: 600;
        padding: 11px 15px 12px;
        color: #0075DB; }
      .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children {
        grid-area: pds-app-nav-selected-children;
        overflow-y: auto; }
        .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          margin: 0;
          padding-top: 14px;
          -webkit-padding-end: 12px;
                  padding-inline-end: 12px;
          padding-bottom: 15px;
          -webkit-padding-start: 15px;
                  padding-inline-start: 15px;
          border-radius: 0; }
          .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a span {
            -webkit-box-flex: 1;
                -ms-flex: 1 0 auto;
                    flex: 1 0 auto;
            padding: 0;
            font-size: 14px;
            line-height: 21px; }
          .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a pds-icon {
            -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                    flex: 0 0 auto; }
            .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a pds-icon svg {
              fill: #A8B1B2;
              opacity: 0.5; }
          .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a.pds-is-active {
            -webkit-padding-start: 11px;
                    padding-inline-start: 11px; }
            .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children a.pds-is-active pds-icon {
              background-color: transparent; } }
  @media (max-width: 768px) {
    .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-parent {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
    .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-header {
    -ms-grid-row: 2;
    -ms-grid-column: 1; }
    .pds-nav-primary .pds-app-nav-mobile-menu-secondary-plus .pds-app-nav-selected-children {
    -ms-grid-row: 3;
    -ms-grid-column: 1; } }

.pds-app-nav-trigger {
  display: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #FFFFFF;
  font-size: 26px;
  background-color: #0075DB; }
  .pds-app-nav-trigger:focus, .pds-app-nav-trigger:hover {
    background-color: #00427C;
    color: #333333;
    text-decoration: none; }
  .pds-app-nav-trigger pds-icon {
    margin: 0; }
    .pds-app-nav-trigger pds-icon svg {
      fill: white; }
  @media (max-width: 768px) {
    .pds-app-nav-trigger {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.pds-nav-close {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #00427C; }
  .pds-nav-close a {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 60px; }
    .pds-nav-close a span {
      color: white; }
  .pds-nav-close pds-icon svg {
    fill: white; }
  @media (max-width: 768px) {
    .pds-nav-close {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.pds-is-secondary-open .pds-nav-primary a.pds-is-selected.pds-has-secondary-item::after {
  content: '';
  -webkit-border-start: 6px solid #677274;
          border-inline-start: 6px solid #677274;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; }

.pds-is-secondary-open .pds-nav-primary .pds-nav-secondary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed; }
  @media (min-width: 769px) {
    .pds-is-secondary-open .pds-nav-primary .pds-nav-secondary {
      -webkit-box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
              box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2); }
      .pds-is-secondary-open .pds-nav-primary .pds-nav-secondary.pds-secondary-bottom {
        bottom: 10px; } }

@media (max-width: 768px) {
  .pds-is-nav-open .pds-nav-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .pds-is-nav-open.pds-is-secondary-open .pds-nav-secondary {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .pds-is-nav-open .pds-nav-primary ul a, .pds-is-nav-open .pds-nav-primary .pds-nav-category a, .pds-is-nav-open .pds-nav-secondary ul a, .pds-is-nav-open .pds-nav-secondary .pds-nav-category a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

@media (max-width: 768px) {
  .pds-is-nav-open .pds-nav-primary {
    min-width: 240px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: #F1F4F4; } }

.pds-nav-primary ul, .pds-nav-secondary ul {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  outline: none;
  border: none; }

.pds-nav-primary ul a, .pds-nav-secondary ul a, .pds-nav-primary .pds-nav-close a, .pds-nav-primary .pds-nav-category a, .pds-nav-secondary .pds-nav-close a, .pds-nav-secondary .pds-nav-category a {
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 768px) {
    .pds-nav-primary ul a, .pds-nav-secondary ul a, .pds-nav-primary .pds-nav-close a, .pds-nav-primary .pds-nav-category a, .pds-nav-secondary .pds-nav-close a, .pds-nav-secondary .pds-nav-category a {
      height: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

@media (max-width: 768px) {
  .pds-nav-primary ul .pds-wide-screen-secondary-nav-items, .pds-nav-secondary ul .pds-wide-screen-secondary-nav-items {
    display: none; } }

.pds-nav-primary ul li:focus, .pds-nav-secondary ul li:focus {
  outline: none; }

.pds-nav-primary .pds-nav-close a:first-of-type, .pds-nav-primary .pds-nav-category a:first-of-type, .pds-nav-secondary .pds-nav-close a:first-of-type, .pds-nav-secondary .pds-nav-category a:first-of-type {
  height: 60px; }

.pds-nav-primary .pds-nav-close a:hover, .pds-nav-primary .pds-nav-close a:focus, .pds-nav-primary .pds-nav-category a:hover, .pds-nav-primary .pds-nav-category a:focus, .pds-nav-secondary .pds-nav-close a:hover, .pds-nav-secondary .pds-nav-close a:focus, .pds-nav-secondary .pds-nav-category a:hover, .pds-nav-secondary .pds-nav-category a:focus {
  background-color: #00427C;
  text-decoration: underline; }
  .pds-nav-primary .pds-nav-close a:hover pds-icon, .pds-nav-primary .pds-nav-close a:focus pds-icon, .pds-nav-primary .pds-nav-category a:hover pds-icon, .pds-nav-primary .pds-nav-category a:focus pds-icon, .pds-nav-secondary .pds-nav-close a:hover pds-icon, .pds-nav-secondary .pds-nav-close a:focus pds-icon, .pds-nav-secondary .pds-nav-category a:hover pds-icon, .pds-nav-secondary .pds-nav-category a:focus pds-icon {
    background-color: transparent; }

.pds-nav-primary .pds-nav-category, .pds-nav-secondary .pds-nav-category {
  background-color: white; }
  .pds-nav-primary .pds-nav-category a, .pds-nav-secondary .pds-nav-category a {
    background-color: #00427C;
    color: white;
    height: 60px; }

.pds-nav-primary a {
  height: 74px; }
  .pds-nav-primary a:hover, .pds-nav-primary a:focus {
    border: none;
    outline: none;
    background-color: #F1F4F4; }
    .pds-nav-primary a:hover pds-icon, .pds-nav-primary a:focus pds-icon {
      background-color: transparent;
      border-radius: 50%; }
    .pds-nav-primary a:hover span:not(.nav-user-initials), .pds-nav-primary a:focus span:not(.nav-user-initials) {
      text-decoration: underline; }
  .pds-nav-primary a.pds-is-selected {
    background: #F1F4F4; }
  .pds-nav-primary a.pds-is-active {
    background-color: #C2E2FF;
    font-weight: 600; }
    @media (max-width: 768px) {
      .pds-nav-primary a.pds-is-active {
        -webkit-border-start: 4px solid #0075DB;
                border-inline-start: 4px solid #0075DB; } }
    .pds-nav-primary a.pds-is-active:hover, .pds-nav-primary a.pds-is-active:focus {
      background: #C2E2FF;
      font-weight: 600; }
    .pds-nav-primary a.pds-is-active pds-icon {
      background-color: transparent;
      border-radius: 50%; }
      .pds-nav-primary a.pds-is-active pds-icon svg {
        fill: #0075DB; }
  .pds-nav-primary a > pds-icon {
    margin-top: 0;
    margin-bottom: 0; }

@media (min-width: 769px) {
  .pds-nav-primary .pds-primary-nav-list {
    background-color: #FFFFFF; }
  .pds-nav-primary a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    text-align: center;
    padding-top: 6px; }
    .pds-nav-primary a span {
      padding: 0; }
      .pds-nav-primary a span.pds-nav-name {
        white-space: pre-wrap; }
      .pds-nav-primary a span.pds-nav-item-description {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400; }
    .pds-nav-primary a.pds-nav-item-leaf-node {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .pds-nav-primary a.pds-nav-item-leaf-node span.pds-nav-item-name-alt {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px; }
  .pds-nav-primary .pds-primary-nav-link a {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-nav-primary .pds-primary-nav-link a:hover, .pds-nav-primary .pds-primary-nav-link a:focus {
      background-color: #F1F4F4; }
      .pds-nav-primary .pds-primary-nav-link a:hover pds-icon svg, .pds-nav-primary .pds-primary-nav-link a:focus pds-icon svg {
        fill: #333333; }
    .pds-nav-primary .pds-primary-nav-link a.pds-is-selected:hover pds-icon svg, .pds-nav-primary .pds-primary-nav-link a.pds-is-selected:focus pds-icon svg {
      fill: #00427C; }
    .pds-nav-primary .pds-primary-nav-link a.pds-is-active:hover, .pds-nav-primary .pds-primary-nav-link a.pds-is-active:focus {
      background-color: #C2E2FF;
      font-weight: 600; }
      .pds-nav-primary .pds-primary-nav-link a.pds-is-active:hover pds-icon.pds-app-nav svg.pds-app-nav, .pds-nav-primary .pds-primary-nav-link a.pds-is-active:focus pds-icon.pds-app-nav svg.pds-app-nav {
        fill: #0075DB; }
    .pds-nav-primary .pds-primary-nav-link a > span {
      white-space: nowrap;
      padding: 0px 10px;
      font-size: 12px;
      line-height: 18px; } }

@media (max-width: 768px) {
  .pds-nav-primary .pds-primary-nav-list {
    height: calc(~"100% - 60px");
    background-color: #FFFFFF; }
  .pds-nav-primary a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-nav-primary a span {
      -webkit-padding-start: 11px;
              padding-inline-start: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 24px;
      width: 155px;
      max-height: 48px; }
  .pds-nav-primary .pds-primary-nav-link a:hover pds-icon svg, .pds-nav-primary .pds-primary-nav-link a:focus pds-icon svg {
    fill: #333333; } }

.pds-nav-primary .pds-nav-secondary ul {
  background: #FFFFFF;
  padding: 15px 5px 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  .pds-nav-primary .pds-nav-secondary ul li {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-nav-primary .pds-nav-secondary ul li.pds-nav-secondary-group {
      padding-bottom: 26px; }
      .pds-nav-primary .pds-nav-secondary ul li.pds-nav-secondary-group span.secondary-nav-link-header {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        margin: 0 20px 4px;
        padding: 0;
        color: #0075DB;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px; }
      .pds-nav-primary .pds-nav-secondary ul li.pds-nav-secondary-group ul.pds-wide-screen-secondary-nav-items {
        padding: 0; }
  .pds-nav-primary .pds-nav-secondary ul a {
    padding: 5px 10px 6px;
    margin: 0 10px 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    outline: none;
    height: auto;
    min-height: 32px; }
    .pds-nav-primary .pds-nav-secondary ul a.pds-is-active {
      background: #C2E2FF; }
    .pds-nav-primary .pds-nav-secondary ul a.pds-is-active::before {
      content: " ";
      position: absolute;
      width: 4px;
      height: 32px;
      top: 0;
      inset-inline-start: 0;
      border-radius: 0;
      background: transparent; }
    .pds-nav-primary .pds-nav-secondary ul a.pds-app-nav-secondary-item-last {
      margin-bottom: 30px; }
    .pds-nav-primary .pds-nav-secondary ul a:hover, .pds-nav-primary .pds-nav-secondary ul a:focus {
      border: none;
      text-decoration: underline; }
    .pds-nav-primary .pds-nav-secondary ul a span {
      padding: 0;
      white-space: normal;
      text-align: start;
      font-size: 14px;
      line-height: 21px;
      -ms-flex-item-align: normal;
          -ms-grid-row-align: normal;
          align-self: normal; }
      .pds-nav-primary .pds-nav-secondary ul a span.pds-nav-item-name-alt {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px; }
      .pds-nav-primary .pds-nav-secondary ul a span.pds-nav-item-description {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400; }

.pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height {
  background-color: #FFFFFF;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  width: 925px; }
  .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
    width: 935px;
    display: block;
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0;
    -webkit-column-width: 230px;
       -moz-column-width: 230px;
            column-width: 230px;
    overflow: hidden; }
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list li.pds-nav-secondary-list-item, .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list li.pds-nav-secondary-group {
      -webkit-column-break-inside: avoid;
         -moz-column-break-inside: avoid;
              break-inside: avoid;
      width: 230px;
      overflow: hidden; }
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list template {
      display: block !important;
      max-height: 0;
      overflow: hidden; }
  .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height.pds-nav-secondary-2-columns {
    width: 480px; }
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height.pds-nav-secondary-2-columns ul.pds-nav-secondary-list {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;
      width: 480px; }
  .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height.pds-nav-secondary-3-columns {
    width: 705px; }
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height.pds-nav-secondary-3-columns ul.pds-nav-secondary-list {
      width: 705px;
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3; }
  @media (max-width: 1200px) {
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height {
      width: 705px; }
      .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list {
        width: 705px;
        -webkit-column-count: 3;
           -moz-column-count: 3;
                column-count: 3; } }
  @media (max-width: 970px) {
    .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height {
      width: 480px; }
      .pds-nav-primary .pds-nav-secondary.pds-nav-secondary-full-height ul.pds-nav-secondary-list {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        width: 480px; } }

.pds-wizard-header-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#0075DB), to(#00427C));
  background: linear-gradient(to right, #0075DB, #00427C);
  -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  z-index: 6000;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  max-width: 100%;
  padding: 0 10px;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Open Sans, sans-serif; }
  @media (max-width: 768px) {
    .pds-wizard-header-bar {
      height: 60px;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }
  .pds-wizard-header-bar .pds-wizard-header-left {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .pds-wizard-header-bar .pds-wizard-header-bar-center {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 300px;
            flex: 0 1 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-wizard-header-bar .pds-wizard-header-bar-center pds-class-picker button.pds-class-picker .class-selector-text {
      position: relative;
      top: -1px; }
    @media (max-width: 768px) {
      .pds-wizard-header-bar .pds-wizard-header-bar-center {
        -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
                order: 0;
        width: 300px; } }
    @media (max-width: 576px) {
      .pds-wizard-header-bar .pds-wizard-header-bar-center {
        min-width: 200px;
        width: 200px; } }
    .pds-wizard-header-bar .pds-wizard-header-bar-center .pds-wizard-header-bar-text {
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      @media (min-width: 577px) {
        .pds-wizard-header-bar .pds-wizard-header-bar-center .pds-wizard-header-bar-text {
          max-width: 300px; } }
      @media (max-width: 576px) {
        .pds-wizard-header-bar .pds-wizard-header-bar-center .pds-wizard-header-bar-text {
          max-width: 200px; } }
      .pds-wizard-header-bar .pds-wizard-header-bar-center .pds-wizard-header-bar-text .pds-page-text-primary {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        overflow: hidden;
        text-align: center;
        padding: 0;
        margin: 0 15px; }
      .pds-wizard-header-bar .pds-wizard-header-bar-center .pds-wizard-header-bar-text .pds-page-text-secondary {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        overflow: hidden;
        text-align: center;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
  .pds-wizard-header-bar .pds-wizard-actions {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 50px;
              flex: 0 1 50px;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer; }
      @media (max-width: 768px) {
        .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action {
          width: 40px; } }
    .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button pds-icon {
      fill: #FFFFFF;
      height: 32px;
      width: 32px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      margin: 0; }
      .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button pds-icon svg {
        width: 20px; }
    .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button:hover, .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button:focus,
    .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button button:focus,
    .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button a:focus {
      outline: none;
      border: none;
      cursor: pointer; }
      .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button:hover pds-icon svg, .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button:focus pds-icon svg,
      .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button button:focus pds-icon svg,
      .pds-wizard-header-bar .pds-wizard-actions pds-app-switcher .pds-popover-button-contents button a:focus pds-icon svg {
        z-index: 1;
        fill: #00427C; }
    .pds-wizard-header-bar .pds-wizard-actions pds-popover-menu pds-popover {
      height: 32px;
      width: 32px; }
      .pds-wizard-header-bar .pds-wizard-actions pds-popover-menu pds-popover button {
        background: none;
        border: none;
        padding: 0; }
        .pds-wizard-header-bar .pds-wizard-actions pds-popover-menu pds-popover button pds-icon {
          fill: #FFFFFF;
          margin: 0; }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-wizard-action pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-search pds-icon {
      height: 32px;
      width: 32px;
      fill: #FFFFFF; }
      .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action pds-icon svg,
      .pds-wizard-header-bar .pds-wizard-actions a.pds-wizard-action pds-icon svg,
      .pds-wizard-header-bar .pds-wizard-actions a.pds-search pds-icon svg {
        width: 20px;
        height: 20px; }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action:hover pds-icon, .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action:focus pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-wizard-action:hover pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-wizard-action:focus pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-search:hover pds-icon,
    .pds-wizard-header-bar .pds-wizard-actions a.pds-search:focus pds-icon {
      cursor: pointer;
      border-radius: 50%;
      background-color: #FFFFFF;
      fill: #00427C;
      max-width: 32px; }
    @media (max-width: 768px) {
      .pds-wizard-header-bar .pds-wizard-actions a.pds-search {
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; } }
    .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content pds-icon {
      fill: #FFFFFF;
      margin: 0; }
      .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content pds-icon svg {
        width: 20px;
        height: 20px; }
    .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content button {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 32px;
              flex: 0 1 32px;
      height: 100%;
      width: 32px;
      padding: 0; }
      .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content button:hover, .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content button:focus {
        border: none; }
        .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content button:hover pds-icon, .pds-wizard-header-bar .pds-wizard-actions pds-help-menu .pds-help-menu-content button:focus pds-icon {
          background-color: #FFFFFF;
          fill: #00427C;
          border-radius: 50%; }
    .pds-wizard-header-bar .pds-wizard-actions .nav-user-initials {
      font-family: Open Sans; }
    .pds-wizard-header-bar .pds-wizard-actions button.pds-global-create-menu {
      border: 1px solid #00427C; }
      .pds-wizard-header-bar .pds-wizard-actions button.pds-global-create-menu:first-child {
        -webkit-border-end: none;
                border-inline-end: none; }
    .pds-wizard-header-bar .pds-wizard-actions .pds-global-create-menu-content.pds-global-create-menu .pds-button-bar.pds-global-create-menu > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu, .pds-wizard-header-bar .pds-wizard-actions .pds-global-create-menu-content.pds-global-create-menu .pds-page-controls .pds-global-create-menu.pds-view-select > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu, .pds-page-controls .pds-wizard-header-bar .pds-wizard-actions .pds-global-create-menu-content.pds-global-create-menu .pds-global-create-menu.pds-view-select > button.pds-global-create-menu > pds-icon.pds-global-create-menu svg.pds-global-create-menu {
      height: 20px;
      width: 20px; }
    .pds-wizard-header-bar .pds-wizard-actions button.pds-wizard-action {
      background: none;
      border: none; }
    .pds-wizard-header-bar .pds-wizard-actions button.pds-button-square:hover:not([disabled]), .pds-wizard-header-bar .pds-wizard-actions button.pds-button-square:focus:not([disabled]) {
      background-color: transparent; }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 480px) {
        .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-2 {
          display: none; } }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-3,
    .pds-wizard-header-bar .pds-wizard-actions pds-user-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 768px) {
        .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-3,
        .pds-wizard-header-bar .pds-wizard-actions pds-user-menu {
          display: none; } }
    .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-4 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media (max-width: 992px) {
        .pds-wizard-header-bar .pds-wizard-actions .pds-wizard-action.pds-wizard-action-priority-4 {
          display: none; } }
  @media (max-width: 1200px) {
    .pds-wizard-header-bar pds-detail-select select {
      width: calc(~"100% - 110px"); } }

button.pds-section-select-trigger > div.color-1:first-of-type, .class-block > div.color-1 {
  color: #3E8D21; }

button.pds-section-select-trigger > div.color-2:first-of-type, .class-block > div.color-2 {
  background-color: #C84C09; }

button.pds-section-select-trigger > div.color-3:first-of-type, .class-block > div.color-3 {
  background-color: #0F9585; }

button.pds-section-select-trigger > div.color-4:first-of-type, .class-block > div.color-4 {
  background-color: #CB1010; }

button.color-1.pds-section-select-trigger {
  color: #3E8D21; }

button.color-2.pds-section-select-trigger {
  color: #C84C09; }

button.color-3.pds-section-select-trigger {
  color: #0F9585; }

button.color-4.pds-section-select-trigger {
  color: #CB1010; }

button.pds-section-select-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  text-align: start;
  padding: 0;
  min-width: 236px;
  max-width: 350px;
  margin: auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border: 1px solid #838E91; }
  button.pds-section-select-trigger:focus:not([disabled]) {
    border: 1px solid #8FCBFF; }
  @media (max-width: 768px) {
    button.pds-section-select-trigger {
      max-width: calc(~"100vw - 130px");
      margin-top: 5px;
      height: 44px; } }
  @media (max-width: 576px) {
    button.pds-section-select-trigger {
      max-width: 100%; } }
  button.pds-section-select-trigger > div:first-of-type {
    position: relative;
    top: -1px;
    height: 32px;
    width: 32px;
    margin: -1px;
    border-start-start-radius: 4px;
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
    border-end-start-radius: 4px;
    padding: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 32px;
            flex: 0 0 32px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (max-width: 768px) {
      button.pds-section-select-trigger > div:first-of-type {
        top: 0;
        height: 44px;
        width: 44px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 44px;
                flex: 0 0 44px; } }
    button.pds-section-select-trigger > div:first-of-type pds-img {
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center; }
      button.pds-section-select-trigger > div:first-of-type pds-img .pds-image-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    button.pds-section-select-trigger > div:first-of-type img, button.pds-section-select-trigger > div:first-of-type pds-icon {
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
      border-radius: 50%;
      margin: 0;
      fill: white;
      height: 30px;
      width: 30px;
      -webkit-box-flex: 1;
          -ms-flex: auto;
              flex: auto; }
      @media (max-width: 768px) {
        button.pds-section-select-trigger > div:first-of-type img, button.pds-section-select-trigger > div:first-of-type pds-icon {
          fill: #FFFFFF; } }
      button.pds-section-select-trigger > div:first-of-type img svg, button.pds-section-select-trigger > div:first-of-type pds-icon svg {
        height: 20px;
        width: 20px; }
    button.pds-section-select-trigger > div:first-of-type pds-icon.pds-photo-icon,
    button.pds-section-select-trigger > div:first-of-type pds-img.pds-photo-icon {
      height: 30px;
      width: 30px; }
      button.pds-section-select-trigger > div:first-of-type pds-icon.pds-photo-icon svg, button.pds-section-select-trigger > div:first-of-type pds-icon.pds-photo-icon img,
      button.pds-section-select-trigger > div:first-of-type pds-img.pds-photo-icon svg,
      button.pds-section-select-trigger > div:first-of-type pds-img.pds-photo-icon img {
        height: 30px;
        width: 30px; }
  button.pds-section-select-trigger > div:nth-of-type(2) {
    margin: 0 10px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    button.pds-section-select-trigger > div:nth-of-type(2) > div {
      max-width: calc(~"350px - 20px - 44px - 30px");
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 18px;
      line-height: normal; }
      @media (max-width: 768px) {
        button.pds-section-select-trigger > div:nth-of-type(2) > div {
          max-width: calc(~"100vw - 130px - 20px - 44px - 30px"); } }
    button.pds-section-select-trigger > div:nth-of-type(2) > .class-selector-student-name {
      font-size: 18px;
      font-weight: 600;
      color: #333333; }
    button.pds-section-select-trigger > div:nth-of-type(2) .class-selector-course-period-student {
      font-size: 14px; }
  button.pds-section-select-trigger > div:last-of-type {
    width: 30px;
    color: #0075DB;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px; }
    button.pds-section-select-trigger > div:last-of-type pds-icon {
      margin: 0;
      height: 30px;
      width: 30px; }
      button.pds-section-select-trigger > div:last-of-type pds-icon svg {
        fill: #677274;
        width: 20px; }

.pds-class-selector {
  position: fixed;
  width: 200vw;
  height: 100vh;
  inset-inline-end: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 9000;
  display: flex;
  flex-direction: column;
  -webkit-transition: top 0.25s ease, opacity 0s ease 0.25s;
  transition: top 0.25s ease, opacity 0s ease 0.25s;
  top: -100vh; }
  @media (max-width: 768px) {
    .pds-class-selector {
      color: #000000; }
      .pds-class-selector button svg {
        fill: #000000; } }
  .pds-class-selector:not(.pds-is-open) {
    opacity: 0; }
    .pds-class-selector:not(.pds-is-open) * {
      display: none; }
  .pds-class-selector.pds-is-open {
    -webkit-transition: top 0.5s ease;
    transition: top 0.5s ease;
    top: 0; }
  .pds-class-selector .pds-class-selector-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 86px;
            flex: 0 0 86px;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 18px;
    position: relative; }
    .pds-class-selector .pds-class-selector-header button {
      display: inline-block;
      width: auto;
      margin: 0; }
    @media (max-width: 768px) {
      .pds-class-selector .pds-class-selector-header {
        -ms-flex-preferred-size: 120px;
            flex-basis: 120px;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end; }
        .pds-class-selector .pds-class-selector-header > div {
          margin-bottom: 10px; } }
    .pds-class-selector .pds-class-selector-header > div {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
      .pds-class-selector .pds-class-selector-header > div.pds-year-term-selector-group {
        position: absolute;
        inset-inline-start: 15px;
        top: 21px;
        width: calc(~"50% - 100px");
        text-align: start;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .pds-class-selector .pds-class-selector-header > div.pds-year-term-selector-group > * {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          -webkit-margin-end: 15px;
                  margin-inline-end: 15px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          position: relative; }
        .pds-class-selector .pds-class-selector-header > div.pds-year-term-selector-group select {
          margin: 0;
          border: none;
          width: auto;
          -webkit-padding-end: 50px;
                  padding-inline-end: 50px;
          background-image: url("../../../../scripts/pds/img/pointer-down.svg"); }
          [dir='rtl'] .pds-class-selector .pds-class-selector-header > div.pds-year-term-selector-group select {
            background-position: 0% 100%; }

@media (min-width: 769px) and (max-width: 992px) {
  .pds-class-selector .pds-class-selector-header > div.pds-class-group-selector-group {
    position: absolute;
    inset-inline-end: 100px;
    top: 21px; } }
      .pds-class-selector .pds-class-selector-header > div.pds-selector-close-group {
        position: absolute;
        inset-inline-end: 15px;
        top: 21px; }
        .pds-class-selector .pds-class-selector-header > div.pds-selector-close-group button {
          background: transparent; }
          .pds-class-selector .pds-class-selector-header > div.pds-selector-close-group button svg {
            fill: white; }
  .pds-class-selector .pds-class-selector-body {
    width: 100vw;
    height: 100%;
    overflow-y: auto; }
    .pds-class-selector .pds-class-selector-body .pds-message-info {
      max-width: 450px;
      margin: 0 auto; }
    .pds-class-selector .pds-class-selector-body .class-block-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .pds-class-selector .pds-class-selector-body .class-block-heading {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #A8B1B2;
      font-size: 18px;
      font-weight: 600; }
      .pds-class-selector .pds-class-selector-body .class-block-heading::before, .pds-class-selector .pds-class-selector-body .class-block-heading::after {
        content: '';
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        height: 1px;
        margin: 0 10px;
        background-color: #A8B1B2; }
      .pds-class-selector .pds-class-selector-body .class-block-heading::before {
        -webkit-margin-end: 20px;
                margin-inline-end: 20px; }
      .pds-class-selector .pds-class-selector-body .class-block-heading::after {
        -webkit-margin-start: 20px;
                margin-inline-start: 20px; }
    .pds-class-selector .pds-class-selector-body pds-class-block {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 310px;
              flex: 1 0 310px; }
    .pds-class-selector .pds-class-selector-body .class-block-spacer {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 310px;
              flex: 1 0 310px;
      height: 0px;
      max-height: 0px; }

.class-block > div {
  position: relative;
  width: 290px;
  height: 120px;
  margin: 10px auto;
  border-radius: 4px;
  background-color: #3E8D21; }
  .class-block > div .class-block-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: white;
    height: 100%; }

.class-block .class-block-group {
  margin-bottom: 20px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
  .class-block .class-block-group::before, .class-block .class-block-group::after {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    background-color: white;
    border-radius: 0;
    border-end-end-radius: 4px;
    border-end-start-radius: 4px; }
  .class-block .class-block-group::before {
    width: 280px;
    bottom: -5px;
    inset-inline-start: 5px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    z-index: -1; }
  .class-block .class-block-group::after {
    width: 270px;
    bottom: -10px;
    inset-inline-start: 10px;
    z-index: -2; }

.class-block .class-block-secondary,
.class-block .class-block-main-sub {
  font-size: 14px;
  line-height: 1.5em;
  font-style: italic; }

.class-block .class-block-main {
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 600; }

.class-block .class-block-scheduling-term {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  padding: 10px; }

.class-block .pds-class-block-school-abbreviation {
  position: absolute;
  bottom: 0;
  inset-inline-start: 0;
  padding: 2px; }

.class-block .class-block-options {
  color: white;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  padding: 10px; }
  .class-block .class-block-options pds-icon {
    fill: #FFFFFF;
    height: 20px;
    width: 20px;
    --pds-icon-svg-height: 20px;
    --pds-icon-svg-width: 20px;
    --pds-icon-svg-fill: #FFFFFF; }
    .class-block .class-block-options pds-icon svg {
      height: 20px;
      width: 20px;
      fill: #FFFFFF; }
  .class-block .class-block-options > .dropdown-arrow {
    position: absolute;
    top: calc(~"100% - 20px");
    inset-inline-end: 27px; }
    .class-block .class-block-options > .dropdown-arrow::before, .class-block .class-block-options > .dropdown-arrow::after {
      content: '';
      position: absolute;
      border: solid transparent; }
    .class-block .class-block-options > .dropdown-arrow::before {
      -webkit-border-start: 9px solid transparent;
              border-inline-start: 9px solid transparent;
      -webkit-border-end: 9px solid transparent;
              border-inline-end: 9px solid transparent;
      border-bottom: 9px solid white;
      inset-inline-start: -2px; }
    .class-block .class-block-options > .dropdown-arrow::after {
      -webkit-border-start: 7px solid transparent;
              border-inline-start: 7px solid transparent;
      -webkit-border-end: 7px solid transparent;
              border-inline-end: 7px solid transparent;
      border-bottom: 7px solid white;
      top: 1px; }

.class-block .class-block-attendance-status {
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .class-block .class-block-attendance-status span {
    font-size: 12px;
    -webkit-padding-end: 5px;
            padding-inline-end: 5px; }
  .class-block .class-block-attendance-status pds-icon {
    fill: #FFFFFF;
    height: 20px;
    --pds-icon-svg-height: 20px;
    --pds-icon-svg-width: 20px;
    --pds-icon-svg-fill: #FFFFFF; }
    .class-block .class-block-attendance-status pds-icon svg {
      height: 20px;
      fill: #FFFFFF; }
  .class-block .class-block-attendance-status.attendance-na pds-icon {
    fill: #000000;
    --pds-icon-svg-fill: #FFFFFF;
    opacity: 0.3; }
    .class-block .class-block-attendance-status.attendance-na pds-icon svg {
      fill: #000000; }

.class-block .pds-menu-dropdown {
  top: 32px;
  inset-inline-end: -5px;
  inset-inline-start: auto;
  min-width: 150px;
  color: black;
  text-align: start;
  z-index: 1; }
  .class-block .pds-menu-dropdown li:last-of-type {
    border-bottom: none; }
  .class-block .pds-menu-dropdown > li > a {
    padding: 10px; }

.pds-section-select-trigger > div:first-of-type {
  fill: #677274; }

.pds-app-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .pds-app-actions > li {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-size: 22px;
    min-width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative; }
    .pds-app-actions > li > a {
      color: #0075DB;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .pds-app-actions > li > a > * {
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center; }
      .pds-app-actions > li > a pds-icon svg {
        fill: #0075DB; }
    .pds-app-actions > li > pds-notification-indicator > div > a {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .pds-app-actions > li > pds-notification-indicator > div > a > * {
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center; }
      .pds-app-actions > li > pds-notification-indicator > div > a pds-icon svg {
        fill: #677274; }
  .pds-app-actions button.pds-button-square:hover:not([disabled]), .pds-app-actions button.pds-button-square:focus:not([disabled]) {
    background-color: transparent; }

.pds-badge, .pds-field-badge {
  display: inline-block;
  min-width: 30px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #C4C9CA;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  padding: 1px 4px;
  color: #677274;
  margin-top: -4px; }
  .pds-badge.pds-is-elevated, .pds-field-badge.pds-is-elevated {
    background: #EB1313;
    border: 1px solid #EB1313;
    color: #FFFFFF; }

.pds-field-badge {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px; }

/* Base for label styling */
.pds-input-radio [type="radio"]:checked,
.pds-input-radio [type="radio"]:not(:checked) {
  position: absolute;
  inset-inline-start: -9999px; }

.pds-input-radio-single {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  line-height: 24px; }
  .pds-input-radio-single [type="radio"]:checked,
  .pds-input-radio-single [type="radio"]:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px; }

[type="radio"]:checked ~ .pds-label-text:first-of-type,
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  min-height: 44px;
  -webkit-margin-start: 35px;
          margin-inline-start: 35px; }

[type="radio"]:checked ~ .pds-label-text:first-of-type {
  font-weight: 700; }

/* radio circle aspect */
[type="radio"]:checked ~ .pds-label-text:first-of-type:before,
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type:before, [type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:before,
[type="radio"]:not(:checked) ~ .pds-radio-hitbox:first-of-type:before, .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-setup-indicator:before {
  content: ' ';
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid #838E91;
  background: #FFFFFF;
  border-radius: 100%;
  -webkit-box-shadow: none;
          box-shadow: none; }

[type="radio"]:checked ~ .pds-label-text:first-of-type:before, [type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:before, .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-is-default .pds-global-create-menu-setup-indicator:before {
  background: #0075DB;
  border-color: #0075DB; }

[type="radio"]:checked ~ .pds-label-text:first-of-type:before,
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type:before {
  inset-inline-start: -35px;
  top: 10px; }

/* radio filled aspect */
[type="radio"]:checked ~ .pds-label-text:first-of-type:after,
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type:after, [type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:after,
[type="radio"]:not(:checked) ~ .pds-radio-hitbox:first-of-type:after, .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-is-default .pds-global-create-menu-setup-indicator:after {
  content: ' ';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  font-weight: 600;
  background: #FFFFFF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

[type="radio"]:checked ~ .pds-label-text:first-of-type:after,
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type:after {
  top: 17px;
  inset-inline-start: -28px; }

/* radio aspect changes */
[type="radio"]:not(:checked) ~ .pds-label-text:first-of-type:after {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0); }

[type="radio"]:checked ~ .pds-label-text:first-of-type:after {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

/* disabled checkbox */
[type="radio"]:disabled:checked ~ .pds-label-text:first-of-type:before,
[type="radio"]:disabled:not(:checked) ~ .pds-label-text:first-of-type:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border-color: #A8B1B2;
  opacity: 0.45; }

[type="radio"]:disabled:checked ~ .pds-label-text:first-of-type:after {
  color: #A8B1B2;
  background: #A8B1B2;
  opacity: 0.45; }

[type="radio"]:disabled ~ .pds-label-text:first-of-type {
  color: #A8B1B2; }

/* accessibility */
[type="radio"]:checked:focus ~ .pds-label-text:first-of-type:before,
[type="radio"]:not(:checked):focus ~ .pds-label-text:first-of-type:before {
  border: 1px solid #8FE5FF;
  -webkit-box-shadow: 0 0 5px #C4C9CA;
          box-shadow: 0 0 5px #C4C9CA;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s; }

/* hover style just for information */
.pds-radio-only {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0; }
  .pds-radio-only .pds-radio-hitbox {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    cursor: pointer; }
  .pds-radio-only [type="radio"]:checked,
  .pds-radio-only [type="radio"]:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px; }

[type="radio"]:checked ~ .pds-radio-hitbox:first-of-type {
  font-weight: 700; }

[type="radio"]:checked ~ .pds-label-text:first-of-type:before, [type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:before, .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-is-default .pds-global-create-menu-setup-indicator:before {
  background: #0075DB;
  border-color: #0075DB; }

[type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:before,
[type="radio"]:not(:checked) ~ .pds-radio-hitbox:first-of-type:before {
  inset-inline-start: 0;
  top: 0px; }

[type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:after,
[type="radio"]:not(:checked) ~ .pds-radio-hitbox:first-of-type:after {
  top: 7px;
  inset-inline-start: 7px; }

/* radio aspect changes */
[type="radio"]:not(:checked) ~ .pds-radio-hitbox:first-of-type:after {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0); }

[type="radio"]:checked ~ .pds-radio-hitbox:first-of-type:after {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

/* disabled checkbox */
[type="radio"]:disabled:checked ~ .pds-radio-hitbox:first-of-type:before,
[type="radio"]:disabled:not(:checked) ~ .pds-radio-hitbox:first-of-type:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border-color: #A8B1B2;
  opacity: 0.45; }

[type="radio"]:disabled:checked ~ .pds-radio-hitbox:first-of-type:after {
  color: #A8B1B2;
  background: #A8B1B2;
  opacity: 0.45; }

[type="radio"]:disabled ~ .pds-radio-hitbox:first-of-type {
  color: #A8B1B2; }

/* accessibility */
[type="radio"]:checked:focus ~ .pds-radio-hitbox:first-of-type:before,
[type="radio"]:not(:checked):focus ~ .pds-radio-hitbox:first-of-type:before {
  border: 1px solid #8FE5FF;
  -webkit-box-shadow: 0 0 5px #C4C9CA;
          box-shadow: 0 0 5px #C4C9CA;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s; }

.pds-input-checkbox [type="checkbox"]:checked,
.pds-input-checkbox [type="checkbox"]:not(:checked) {
  position: absolute;
  inset-inline-start: -9999px; }

.pds-input-checkbox-single {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  line-height: 24px; }
  .pds-input-checkbox-single [type="checkbox"]:checked,
  .pds-input-checkbox-single [type="checkbox"]:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type,
[type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  min-height: 44px;
  -webkit-margin-start: 35px;
          margin-inline-start: 35px; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type {
  font-weight: 700; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type.pds-all-label {
  color: black; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type:before,
[type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:before {
  content: ' ';
  position: absolute;
  inset-inline-start: -35px;
  top: 10px;
  width: 24px;
  height: 24px;
  border: 1px solid #838E91;
  background: #FFFFFF;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type:before {
  background: #0075DB;
  border-color: #0075DB; }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type:after,
[type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:after {
  content: '';
  background: no-repeat center center;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23ffffff'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E");
  background-size: 14px 10px;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 14px;
  inset-inline-start: -31px;
  font-size: 14px;
  line-height: 0.8;
  font-weight: 600;
  color: #FFFFFF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

[type="checkbox"]:not(:checked) ~ .pds-is-indeterminate.pds-label-text:first-of-type:after {
  content: '';
  background-image: none;
  background-size: 11px 7px;
  height: 12px;
  width: 12px;
  top: 16px;
  inset-inline-start: -29px;
  font-size: 16px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #0075DB;
  border-radius: 4px;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

[type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:after {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0); }

[type="checkbox"]:checked ~ .pds-label-text:first-of-type:after {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

[type="checkbox"]:disabled:checked ~ .pds-label-text:first-of-type:before,
[type="checkbox"]:disabled:not(:checked) ~ .pds-label-text:first-of-type:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  opacity: 0.45;
  border-color: #A8B1B2; }

[type="checkbox"]:disabled:checked ~ .pds-label-text:first-of-type:after {
  color: #A8B1B2;
  opacity: 0.45;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23999999'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E"); }

[type="checkbox"]:disabled ~ .pds-label-text:first-of-type {
  color: #A8B1B2; }

[type="checkbox"]:checked:focus ~ .pds-label-text:first-of-type:before,
[type="checkbox"]:not(:checked):focus ~ .pds-label-text:first-of-type:before {
  border: 1px solid #8FE5FF;
  -webkit-box-shadow: 0 0 5px #C4C9CA;
          box-shadow: 0 0 5px #C4C9CA;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s; }

.pds-input-checkbox.pds-is-readonly li label, .pds-input-checkbox.pds-is-readonly li .pds-help-text, .pds-input-checkbox.pds-is-readonly li .pds-label-text {
  display: none; }

.pds-input-checkbox.pds-is-readonly li .pds-readonly-data, .pds-input-checkbox.pds-is-readonly li .pds-readonly-data-long {
  margin-bottom: 0; }

.pds-all-label {
  font-weight: 600; }

.pds-is-readonly [type="checkbox"]:checked ~ .pds-label-text:first-of-type:before,
.pds-is-readonly [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:before,
.pds-is-readonly [type="checkbox"]:disabled:checked ~ .pds-label-text:first-of-type:before,
.pds-is-readonly [type="checkbox"]:disabled:not(:checked) ~ .pds-label-text:first-of-type:before {
  background: #F1F4F4;
  border: none; }

.pds-is-readonly [type="checkbox"]:disabled:checked ~ .pds-label-text:first-of-type:after {
  color: #333333;
  opacity: 1.0;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23333333'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E"); }

.pds-is-readonly [type="checkbox"]:disabled ~ .pds-label-text:first-of-type {
  color: #333333; }

.pds-checkbox-only {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0; }
  .pds-checkbox-only .pds-checkbox-hitbox {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    cursor: pointer; }
  .pds-checkbox-only [type="checkbox"]:checked,
  .pds-checkbox-only [type="checkbox"]:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px; }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type {
  font-weight: 700; }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type.pds-all-label {
  color: black; }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type:before,
[type="checkbox"]:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:before {
  content: ' ';
  position: absolute;
  inset-inline-start: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #838E91;
  background: #FFFFFF;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none; }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type:before {
  background: #0075DB;
  border-color: #0075DB; }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type:after,
[type="checkbox"]:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:after {
  content: '';
  background: no-repeat center center;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23ffffff'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E");
  background-size: 14px 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  font-size: 14px;
  line-height: 0.8;
  font-weight: 600;
  color: #FFFFFF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

[type="checkbox"]:not(:checked) ~ .pds-is-indeterminate.pds-checkbox-hitbox:first-of-type:after {
  content: '';
  background-image: none;
  background-size: 11px 7px;
  height: 12px;
  width: 12px;
  top: 0;
  inset-inline-start: 0;
  font-size: 16px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #0075DB;
  border-radius: 4px;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

[type="checkbox"]:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:after {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0); }

[type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type:after {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

[type="checkbox"]:disabled:checked ~ .pds-checkbox-hitbox:first-of-type:before,
[type="checkbox"]:disabled:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  opacity: 0.45;
  border-color: #A8B1B2; }

[type="checkbox"]:disabled:checked ~ .pds-checkbox-hitbox:first-of-type:after {
  color: #A8B1B2;
  opacity: 0.45;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23999999'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E"); }

[type="checkbox"]:disabled ~ .pds-checkbox-hitbox:first-of-type {
  color: #A8B1B2; }

[type="checkbox"]:checked:focus ~ .pds-checkbox-hitbox:first-of-type:before,
[type="checkbox"]:not(:checked):focus ~ .pds-checkbox-hitbox:first-of-type:before {
  border: 1px solid #8FE5FF;
  -webkit-box-shadow: 0 0 5px #C4C9CA;
          box-shadow: 0 0 5px #C4C9CA;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s; }

.pds-all-label {
  font-weight: 600; }

.pds-is-readonly [type="checkbox"]:checked ~ .pds-checkbox-hitbox:first-of-type:before,
.pds-is-readonly [type="checkbox"]:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:before,
.pds-is-readonly [type="checkbox"]:disabled:checked ~ .pds-checkbox-hitbox:first-of-type:before,
.pds-is-readonly [type="checkbox"]:disabled:not(:checked) ~ .pds-checkbox-hitbox:first-of-type:before {
  background: #F1F4F4;
  border: none; }

.pds-is-readonly [type="checkbox"]:disabled:checked ~ .pds-checkbox-hitbox:first-of-type:after {
  color: #333333;
  opacity: 1.0;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'699.97%20640.77%2014.06%2010.46'%3E%0A%20%20%20%20%3Cpath%20fill%3D'%23333333'%20d%3D'M704.64%20651.23l-4.67-4.67%201.06-1.06%203.61%203.6%208.33-8.33%201.06%201.06z'%2F%3E%0A%20%20%3C%2Fsvg%3E"); }

.pds-is-readonly [type="checkbox"]:disabled ~ .pds-checkbox-hitbox:first-of-type {
  color: #333333; }

.pds-input-rating-star {
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  width: 170px;
  height: 34px;
  overflow: hidden;
  position: relative;
  background: url("data:image/svg+xml,%3Csvg%20width%3D'50'%20height%3D'50'%20viewBox%3D'0%200%2050%2050'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Ctitle%3EShape%3C%2Ftitle%3E%3Cpath%20d%3D'M25%205.015l4.5%2014.62.324%201.062h15.764l-11.912%208.97-.852.65.323%201.03%204.515%2014.608-11.765-9.09-.882-.683-.883.666-11.764%209.09%204.485-14.65.323-1.03-.852-.652-11.912-8.924h15.764l.324-1.06L25%205.014zM25%200l-5.882%2019.167H0l15.456%2011.727L9.574%2049.94%2025%2038.105%2040.456%2050l-5.882-19.106L50%2019.167H30.882L25%200z'%20fill%3D'%2375db'%20fill-rule%3D'evenodd'%2F%3E%3C%2Fsvg%3E");
  background-size: contain; }
  .pds-input-rating-star label {
    width: 34px;
    height: 34px;
    display: inline-block;
    padding: 0px; }
    .pds-input-rating-star label div {
      display: none; }
    .pds-input-rating-star label i {
      opacity: 0;
      position: absolute;
      inset-inline-start: 0;
      top: 0;
      height: 100%;
      width: 20%;
      z-index: 1;
      background: url("data:image/svg+xml,%3Csvg%20width%3D'50'%20height%3D'50'%20viewBox%3D'0%200%2050%2050'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Ctitle%3Efill%3C%2Ftitle%3E%3Cpath%20d%3D'M25%200l-5.882%2019.167H0l15.456%2011.727L9.574%2049.94%2025%2038.105%2040.456%2050l-5.882-19.106L50%2019.167H30.882'%20fill%3D'%2375db'%20fill-rule%3D'evenodd'%2F%3E%3C%2Fsvg%3E");
      background-size: contain; }
    .pds-input-rating-star label input[type="radio"] {
      opacity: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      z-index: 2;
      position: relative;
      inset-inline-start: auto; }
      .pds-input-rating-star label input[type="radio"]:hover {
        cursor: pointer; }
      .pds-input-rating-star label input[type="radio"]:hover + i,
      .pds-input-rating-star label input[type="radio"]:checked + i {
        opacity: 1; }
  .pds-input-rating-star label ~ label i {
    width: 40%; }
  .pds-input-rating-star label ~ label ~ label i {
    width: 60%; }
  .pds-input-rating-star label ~ label ~ label ~ label i {
    width: 80%; }
  .pds-input-rating-star label ~ label ~ label ~ label ~ label i {
    width: 100%; }

.pds-menu-dropdown {
  display: none;
  top: 100%;
  inset-inline-start: calc(~"50% - 100px");
  inset-inline-end: auto;
  position: absolute;
  min-width: 200px;
  max-width: 250px;
  padding: 5px;
  background-color: #FFFFFF;
  -webkit-border-end: 1px solid #C4C9CA;
          border-inline-end: 1px solid #C4C9CA;
  border-bottom: 1px solid #C4C9CA;
  -webkit-border-start: 1px solid #C4C9CA;
          border-inline-start: 1px solid #C4C9CA;
  -webkit-box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.2); }
  .pds-menu-dropdown.pds-is-open {
    display: block; }
  .pds-menu-dropdown > li > a {
    display: block;
    font-size: 16px;
    color: #000000;
    padding: 15px spacing-medium;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .pds-menu-dropdown > li > a:hover, .pds-menu-dropdown > li > a:focus {
      background-color: #F1F4F4; }
  .pds-menu-dropdown > li > div {
    font-size: 16px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 15px 0; }

.pds-switch {
  position: relative;
  margin-bottom: 15px;
  outline: 0;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 15px 15px; }

.pds-input-switch {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
  width: auto; }

.pds-switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 40px;
  background: #FFFFFF;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .pds-switch-paddle {
    margin: 0; }
  .pds-switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    inset-inline-end: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    border-radius: 100%;
    background: #FFFFFF;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .pds-switch-paddle {
    background: #52BA2B; }
    input:checked ~ .pds-switch-paddle::after {
      inset-inline-end: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .pds-switch-paddle {
    outline: 0; }

.pds-switch-paddle::after {
  -webkit-box-shadow: 0 0 0 1px #F1F4F4, 0 3px 4px 1px rgba(0, 0, 0, 0.35);
          box-shadow: 0 0 0 1px #F1F4F4, 0 3px 4px 1px rgba(0, 0, 0, 0.35);
  height: 1.7rem;
  width: 1.7rem;
  inset-inline-start: .14rem;
  top: .14rem; }

input ~ .pds-switch-paddle {
  -webkit-box-shadow: 0 0 0 1px #C4C9CA;
          box-shadow: 0 0 0 1px #C4C9CA; }

input:checked ~ .pds-switch-paddle {
  -webkit-box-shadow: 0 0 0 1px #52BA2B;
          box-shadow: 0 0 0 1px #52BA2B;
  background: #52BA2B; }

input:checked ~ .pds-switch-paddle::after {
  inset-inline-start: 2.25rem; }

.pds-banner-content,
.pds-banner:not(.style-scope) {
  background: #0075DB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-border-start: 1px solid #FFFFFF;
          border-inline-start: 1px solid #FFFFFF;
  height: 50px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.pds-banner-message {
  padding: 0 15px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600; }
  @media (max-width: 768px) {
    .pds-banner-message {
      font-size: 14px; } }

.pds-banner-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .pds-banner-controls fieldset.pds-input-checkbox {
    margin-bottom: 0; }
  .pds-banner-controls .pds-label-text {
    color: #FFFFFF; }
  .pds-banner-controls [type='checkbox']:checked ~ .pds-label-text:first-of-type:before,
  .pds-banner-controls [type='checkbox']:not(:checked) ~ .pds-label-text:first-of-type:before {
    border-color: #FFFFFF; }

.pds-banner-actions {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .pds-banner-actions button {
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    height: 32px;
    min-width: 100px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    background-color: transparent;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    padding: 0 15px;
    -webkit-transition: all 0.1875s ease-in-out;
    transition: all 0.1875s ease-in-out; }
    .pds-banner-actions button:hover, .pds-banner-actions button:focus {
      text-decoration: underline;
      background-color: rgba(0, 0, 0, 0.2);
      border: 1px solid #FFFFFF; }
    .pds-banner-actions button[disabled] {
      color: #FFFFFF;
      border: 1px solid #FFFFFF; }
      .pds-banner-actions button[disabled]:hover, .pds-banner-actions button[disabled]:focus {
        text-decoration: none;
        background-color: transparent; }
    @media (max-width: 768px) {
      .pds-banner-actions button {
        font-size: 18px;
        line-height: 27px;
        height: 44px;
        min-width: 100px;
        width: auto;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto; } }
  .pds-banner-actions button + button,
  .pds-banner-actions .pds-banner-button + .pds-banner-button,
  .pds-banner-actions .pds-banner-button + template + .pds-banner-button,
  .pds-banner-actions .pds-banner-button + template + template + .pds-banner-button {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }

.pds-date-widget-content input {
  border-radius: 0;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  -webkit-border-end: none;
          border-inline-end: none; }

.pds-overlay-panel pds-date-picker-dialog {
  display: block;
  width: 360px;
  position: fixed;
  top: calc(~"50% - 180px");
  inset-inline-start: calc(~"50% - 180px");
  border-radius: 4px;
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  background: #FFFFFF;
  border: 1px solid #838E91;
  opacity: 1;
  z-index: 9000; }
  .pds-overlay-panel pds-date-picker-dialog:focus {
    border-color: #8FE5FF; }
  @media (max-width: 576px) {
    .pds-overlay-panel pds-date-picker-dialog {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: unset;
      height: 100%;
      top: 0;
      inset-inline-start: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
              box-shadow: none; } }

.pds-date-picker-dialog:focus {
  border-color: #8FE5FF; }

.pds-date-picker-dialog-content .pds-datepicker-dialog-form-label {
  -webkit-margin-start: 25px;
          margin-inline-start: 25px;
  line-height: 24px;
  margin-bottom: 7px;
  display: block;
  color: #333333;
  font-size: 16px;
  font-weight: 400; }

.pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element {
  width: 360px; }
  .pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element .pds-date-picker-content {
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #838E91; }
    @media (max-width: 576px) {
      .pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element .pds-date-picker-content {
        border: none;
        border-radius: 0; } }
  .pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element .pds-calendar-selectors {
    padding: 8px 10px 0; }
    @media (max-width: 768px) {
      .pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element .pds-calendar-selectors {
        -webkit-padding-start: 0;
                padding-inline-start: 0;
        -webkit-padding-end: 0;
                padding-inline-end: 0;
        margin-top: 3px; } }
  @media (max-width: 576px) {
    .pds-date-picker-dialog-content.pds-date-widget-dialog-as-form-element {
      width: 100%; } }

@media (max-width: 576px) {
  .pds-date-picker-dialog-content {
    height: 100%;
    width: 100%; } }

.pds-date-picker-dialog-content .pds-date-picker-content {
  background-color: #FFFFFF;
  margin: 0 15px 15px; }
  @media (max-width: 576px) {
    .pds-date-picker-dialog-content .pds-date-picker-content {
      margin: 0;
      overflow-y: auto;
      height: calc(~"100% - 45px"); } }

.pds-date-picker-dialog-content .pds-datepicker-header header {
  position: relative;
  background: #F1F4F4;
  padding: 6px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 44px;
          flex: 0 0 44px;
  height: 44px;
  border-bottom: 1px solid #C4C9CA;
  border-radius: 0;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
  margin-bottom: 15px; }
  @media (max-width: 576px) {
    .pds-date-picker-dialog-content .pds-datepicker-header header {
      margin-bottom: 0; } }
  .pds-date-picker-dialog-content .pds-datepicker-header header button.pds-date-picker-close {
    position: absolute;
    inset-inline-end: 0;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    padding: 0; }
    @media (max-width: 768px) {
      .pds-date-picker-dialog-content .pds-datepicker-header header button.pds-date-picker-close {
        height: 32px;
        width: 32px;
        min-width: 32px; }
        .pds-date-picker-dialog-content .pds-datepicker-header header button.pds-date-picker-close pds-icon {
          margin-top: 3px; } }

.pds-date-picker-dialog-content button {
  min-width: 0; }

.pds-date-picker-dialog-content .pds-calendar-selectors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 768px) {
    .pds-date-picker-dialog-content .pds-calendar-selectors {
      margin: 15px 10px 10px; } }
  .pds-date-picker-dialog-content .pds-calendar-selectors button {
    position: relative;
    top: 1px;
    inset-inline-start: 0;
    padding: 0;
    margin: 5px 2px; }
    .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 32px;
              flex: 0 0 32px; }
      @media (max-width: 768px) {
        .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 44px;
                  flex: 0 0 44px; } }
      .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog {
        border: 1px solid #0075DB;
        height: 32px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 32px;
                flex: 0 0 32px;
        padding: 0; }
        [dir='rtl'] .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog pds-icon[name="pointer-right"],
        [dir='rtl'] .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog pds-icon[name="pointer-left"] {
          -webkit-transform: scaleX(-1);
              -ms-transform: scaleX(-1);
                  transform: scaleX(-1); }
        .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog:first-of-type {
          margin: 0;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px; }
        .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog:last-of-type {
          margin: 0;
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px; }
        @media (max-width: 768px) {
          .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog:first-of-type, .pds-date-picker-dialog-content .pds-calendar-selectors button.pds-button-square.pds-date-picker-dialog:last-of-type {
            height: 44px;
            -webkit-box-flex: 0;
                -ms-flex: 0 0 44px;
                    flex: 0 0 44px; } }
    .pds-date-picker-dialog-content .pds-calendar-selectors button pds-icon svg {
      width: 22px;
      height: 22px; }
  .pds-date-picker-dialog-content .pds-calendar-selectors select {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 1px 0 0; }
    .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog {
      height: 32px;
      background-size: 32px 32px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      color: #333333; }
      .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog:first-of-type {
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; }
      .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog option {
        color: #333333; }
        @media (max-width: 576px) {
          .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog option {
            font-size: 14px; } }
      @media (max-width: 768px) {
        .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog {
          height: 44px;
          background-size: 44px 44px;
          font-size: 16px;
          line-height: 27px; } }
      @media (max-width: 576px) {
        .pds-date-picker-dialog-content .pds-calendar-selectors select.pds-date-picker-dialog {
          font-size: 14px; } }

.pds-date-picker-dialog-content .pds-calendar-days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 44px;
          flex: 1 0 44px;
  height: 44px;
  width: 44px;
  text-align: center;
  overflow: hidden; }

.pds-date-picker-dialog-content .pds-date-picker-day-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%; }
  .pds-date-picker-dialog-content .pds-date-picker-day-wrapper .pds-date-picker-day-label {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 44px;
            flex: 1 0 44px;
    font-weight: 400;
    color: #677274;
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    text-align: center;
    overflow: hidden;
    margin-top: 10px;
    -webkit-margin-end: 2px;
            margin-inline-end: 2px;
    margin-bottom: 5px;
    -webkit-margin-start: 0;
            margin-inline-start: 0; }
    .pds-date-picker-dialog-content .pds-date-picker-day-wrapper .pds-date-picker-day-label:last-of-type {
      -webkit-margin-end: 0;
              margin-inline-end: 0; }
    @media (max-width: 768px) {
      .pds-date-picker-dialog-content .pds-date-picker-day-wrapper .pds-date-picker-day-label {
        font-size: 16px;
        line-height: 24px; } }
    @media (max-width: 576px) {
      .pds-date-picker-dialog-content .pds-date-picker-day-wrapper .pds-date-picker-day-label {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 14.2857%;
                flex: 1 0 14.2857%;
        width: 14.2857%;
        margin: 0;
        height: 36px;
        line-height: 36px;
        -webkit-box-sizing: content-box;
                box-sizing: content-box; } }
  @media (max-width: 576px) {
    .pds-date-picker-dialog-content .pds-date-picker-day-wrapper .pds-date-picker-day-label-end {
      display: none; } }

.pds-date-picker-dialog-content .pds-date-picker-date-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around; }
  .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 44px;
            flex: 1 0 44px;
    height: 44px;
    border: none;
    margin: 1px;
    padding: 2px;
    color: #333333;
    font-weight: 400; }
    @media (max-width: 576px) {
      .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 14.2857%;
                flex: 1 0 14.2857%;
        height: 50px;
        border-radius: 0;
        margin: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; } }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day:focus:not([disabled]) {
      background-color: transparent;
      outline: none; }
      .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day:focus:not([disabled]) .pds-datepicker-date:after {
        content: '';
        position: absolute;
        top: -1px;
        inset-inline-start: -1px;
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        border: 1px solid #8FE5FF; }
        @media (max-width: 768px) {
          .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day:focus:not([disabled]) .pds-datepicker-date:after {
            width: 44px;
            height: 44px;
            top: 0;
            inset-inline-start: 0; } }
        @media (max-width: 576px) {
          .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day:focus:not([disabled]) .pds-datepicker-date:after {
            width: 38px;
            height: 38px; } }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-date-picker-today {
      color: #0075DB;
      font-weight: 600; }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day .pds-datepicker-date, .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day .pds-datepicker-date,
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day .pds-selectable-day:hover .pds-datepicker-date {
      position: relative;
      border-radius: 100%;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 32px;
              flex: 1 0 32px;
      height: 32px;
      max-width: 32px;
      -webkit-margin-start: 0;
              margin-inline-start: 0;
      line-height: 32px;
      font-size: 12px; }
      @media (max-width: 768px) {
        .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day .pds-datepicker-date, .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day .pds-datepicker-date,
        .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day .pds-selectable-day:hover .pds-datepicker-date {
          -webkit-box-flex: 1;
              -ms-flex: 1 0 44px;
                  flex: 1 0 44px;
          height: 44px;
          max-width: 44px;
          line-height: 44px;
          font-size: 14px; } }
      @media (max-width: 576px) {
        .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day .pds-datepicker-date, .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day .pds-datepicker-date,
        .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day .pds-selectable-day:hover .pds-datepicker-date {
          -webkit-box-flex: 1;
              -ms-flex: 1 0 38px;
                  flex: 1 0 38px;
          height: 38px;
          max-width: 38px;
          line-height: 38px; } }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day .pds-datepicker-date {
      background-color: transparent; }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day:hover .pds-datepicker-date, .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selectable-day:focus .pds-datepicker-date {
      background-color: #F1F4F4; }
    .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day {
      padding: 0;
      color: #FFFFFF;
      font-weight: 600; }
      .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day .pds-datepicker-date {
        background-color: #0075DB; }
      .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day:hover .pds-datepicker-date, .pds-date-picker-dialog-content .pds-date-picker-date-wrapper .pds-datepicker-day.pds-selected-day:focus .pds-datepicker-date {
        background-color: #0075DB; }

.pds-date-picker-dialog-content .pds-selectable-day {
  padding: 0;
  cursor: pointer; }
  .pds-date-picker-dialog-content .pds-selectable-day:hover {
    background-color: transparent;
    padding: 0; }
    .pds-date-picker-dialog-content .pds-selectable-day:hover .pds-datepicker-date {
      background-color: #F1F4F4; }
  .pds-date-picker-dialog-content .pds-selectable-day.pds-disabled-day {
    cursor: default; }
    .pds-date-picker-dialog-content .pds-selectable-day.pds-disabled-day .pds-datepicker-date {
      background-image: repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5) 5px, rgba(0, 0, 0, 0.1) 5px, rgba(0, 0, 0, 0.1) 10px); }
  .pds-date-picker-dialog-content .pds-selectable-day.pds-selected-day:hover .pds-datepicker-date {
    background-color: #0075DB; }

.pds-footer {
  background: #FFFFFF; }
  .pds-footer .pds-copyright {
    font-size: 9px;
    padding: 7.5px;
    text-align: end; }

.pds-modal {
  display: none;
  top: 15vh;
  inset-inline-start: 0;
  margin: 0 1rem;
  position: fixed;
  max-height: 75vh;
  width: calc(~"100vw - 2rem");
  max-width: 560px;
  z-index: 9000;
  background: #FFFFFF;
  -webkit-box-shadow: 0 0 20px 200vh rgba(0, 0, 0, 0.25), 0 5px 8px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 20px 200vh rgba(0, 0, 0, 0.25), 0 5px 8px rgba(0, 0, 0, 0.5);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 4px; }
  @media (max-width: 768px) {
    .pds-modal {
      max-height: unset; } }
  .pds-modal.pds-drawer-mode {
    margin: auto;
    inset-inline-end: 0;
    top: 0;
    bottom: 0;
    inset-inline-start: auto;
    max-height: none;
    max-width: none; }
    .pds-modal.pds-drawer-mode .pds-modal-body {
      max-height: none;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
    .pds-modal.pds-drawer-mode .pds-overflow-container {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      overflow-y: auto; }
      .pds-modal.pds-drawer-mode .pds-overflow-container section {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 100%;
                flex: 1 0 100%;
        overflow-y: auto;
        height: 100%; }
    .pds-modal.pds-drawer-mode .pds-is-open {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
    .pds-modal.pds-drawer-mode footer, .pds-modal.pds-drawer-mode .pds-modal-footer {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
  .pds-modal.pds-is-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  @media (min-width: 577px) {
    .pds-modal {
      width: 560px;
      /* rtl-lint:ignore */
      margin: 0 calc(~"50vw - 280px"); } }
  .pds-modal > header,
  .pds-modal .pds-modal-header {
    position: relative;
    background: #F1F4F4;
    padding: 6px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    min-height: 44px;
    border-bottom: 1px solid #838E91;
    border-radius: 0;
    border-start-start-radius: 4px;
    border-start-end-radius: 4px; }
  .pds-modal > footer,
  .pds-modal .pds-modal-footer {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    min-height: 32px;
    border-top: 1px solid #838E91;
    padding: 7.5px;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
    @media (max-width: 768px) {
      .pds-modal > footer,
      .pds-modal .pds-modal-footer {
        min-height: 44px; } }
  .pds-modal.pds-modal-large {
    max-width: 60vw;
    width: 60vw;
    margin: 0 20vw; }
    @media (max-width: 1200px) {
      .pds-modal.pds-modal-large {
        max-width: 90vw;
        width: 90vw;
        margin: 0 5vw; } }
    .pds-modal.pds-modal-large .pds-modal-footer {
      -webkit-box-sizing: content-box;
              box-sizing: content-box;
      min-height: 32px;
      border-top: 1px solid #838E91;
      padding: 7.5px;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; }
      @media (max-width: 768px) {
        .pds-modal.pds-modal-large .pds-modal-footer {
          min-height: 44px; } }

.pds-modal-title {
  color: #333333;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 600; }

.pds-modal-header .pds-modal-close {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  inset-inline-end: 0;
  top: 50%;
  background: none;
  border: none;
  padding: 0 7.5px;
  margin-top: calc(~"0px - 15px");
  -webkit-margin-end: 10px;
          margin-inline-end: 10px; }
  @media (max-width: 768px) {
    .pds-modal-header .pds-modal-close.pds-button-round {
      min-width: 32px; } }
  .pds-modal-header .pds-modal-close pds-icon {
    margin: 0; }
    .pds-modal-header .pds-modal-close pds-icon svg {
      height: 20px;
      width: 20px; }
  .pds-modal-header .pds-modal-close:hover {
    background: none; }
    .pds-modal-header .pds-modal-close:hover:not([disabled]) {
      background: none; }
  .pds-modal-header .pds-modal-close.date-picker-close {
    margin-top: 0;
    -webkit-margin-end: 5px;
            margin-inline-end: 5px; }

.pds-modal-close {
  position: absolute;
  cursor: pointer;
  inset-inline-end: 0;
  top: 0;
  width: auto;
  background: none;
  border: none;
  padding: 0 7.5px; }
  @media (max-width: 768px) {
    .pds-modal-close.pds-button-round {
      min-width: 32px; } }
  .pds-modal-close pds-icon {
    margin: 0; }
    .pds-modal-close pds-icon svg {
      height: 20px;
      width: 20px; }
  .pds-modal-close:hover {
    background: none; }
    .pds-modal-close:hover:not([disabled]) {
      background: none; }

.pds-modal-body {
  padding: 20px 0;
  max-height: calc(~"75vh - 88px");
  overflow-y: auto; }
  .pds-modal-body p:last-child {
    margin-bottom: 0; }
  .pds-modal-body > .pds-modal-text {
    padding: 20px; }

.pds-modal-actions, .pds-modal-actions-binary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  .pds-modal-actions button, .pds-modal-actions-binary button {
    margin: 0 3.75px; }
    .pds-modal-actions button:first-child, .pds-modal-actions-binary button:first-child {
      margin-bottom: 0; }
    .pds-modal-actions button:first-child, .pds-modal-actions-binary button:first-child {
      -webkit-margin-start: 0;
              margin-inline-start: 0; }
    .pds-modal-actions button:last-child, .pds-modal-actions-binary button:last-child {
      -webkit-margin-end: 0;
              margin-inline-end: 0; }

.pds-modal pds-rich-text-editor .pds-rich-text-editor.pds-rich-text-editor-content {
  margin: 0; }

.pds-modal .pds-row {
  margin-bottom: 0; }

.pds-modal .pds-selection-status {
  margin: 0  1.0714rem 1.0714rem; }

pds-overlay,
.pds-overlay,
.pds-calendar-page .pds-calendar-page-detail-panel-overlay,
.pds-calendar-page .pds-calendar-page-legend-overlay {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 8000; }
  pds-overlay.pds-overlay-blocking,
  .pds-overlay.pds-overlay-blocking,
  .pds-calendar-page .pds-overlay-blocking.pds-calendar-page-detail-panel-overlay,
  .pds-calendar-page .pds-overlay-blocking.pds-calendar-page-legend-overlay {
    z-index: 9100; }

.pds-overlay-dropdown {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  background: transparent;
  z-index: 8000;
  pointer-events: none; }

@-webkit-keyframes pds-loader-animation {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0); }
  20% {
    -webkit-transform: scale(1.8, 1.8) translate(0, -3px);
            transform: scale(1.8, 1.8) translate(0, -3px); }
  40% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0); } }

@keyframes pds-loader-animation {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0); }
  20% {
    -webkit-transform: scale(1.8, 1.8) translate(0, -3px);
            transform: scale(1.8, 1.8) translate(0, -3px); }
  40% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0); } }

.pds-loader, .pds-loader-sm, .pds-loader-lg {
  height: 40px;
  text-align: center; }
  .pds-loader .pds-loader-bar, .pds-loader-sm .pds-loader-bar, .pds-loader-lg .pds-loader-bar {
    display: inline-block;
    position: relative;
    width: 9px;
    height: 9px;
    margin: 16px 2px;
    border-radius: 50%;
    -webkit-animation: pds-loader-animation 1s ease-in-out infinite;
            animation: pds-loader-animation 1s ease-in-out infinite; }
  .pds-loader .pds-loader-bar:nth-child(1), .pds-loader-sm .pds-loader-bar:nth-child(1), .pds-loader-lg .pds-loader-bar:nth-child(1) {
    background-color: #EB1313;
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .pds-loader .pds-loader-bar:nth-child(2), .pds-loader-sm .pds-loader-bar:nth-child(2), .pds-loader-lg .pds-loader-bar:nth-child(2) {
    background-color: #F5691D;
    -webkit-animation-delay: 0.09s;
            animation-delay: 0.09s; }
  .pds-loader .pds-loader-bar:nth-child(3), .pds-loader-sm .pds-loader-bar:nth-child(3), .pds-loader-lg .pds-loader-bar:nth-child(3) {
    background-color: #52BA2B;
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s; }
  .pds-loader .pds-loader-bar:nth-child(4), .pds-loader-sm .pds-loader-bar:nth-child(4), .pds-loader-lg .pds-loader-bar:nth-child(4) {
    background-color: #12B19F;
    -webkit-animation-delay: 0.27s;
            animation-delay: 0.27s; }

.pds-loader-sm {
  height: 20px; }
  .pds-loader-sm .pds-loader-bar {
    width: 6px;
    height: 6px;
    margin: 7px 0px; }

.pds-loader-lg {
  height: 70px; }
  .pds-loader-lg .pds-loader-bar {
    width: 18px;
    height: 18px;
    margin: 25px 4px; }

.pds-main-loader {
  position: fixed;
  display: block;
  top: calc(~"50vh - 35px");
  z-index: 10000;
  inset-inline-start: calc(~"50vw - 100px"); }
  .pds-main-loader:after {
    position: fixed;
    content: "";
    display: block;
    top: 0;
    inset-inline-start: 0;
    width: 100vw;
    height: 100vh; }
  .pds-main-loader pds-loader {
    z-index: 9001;
    position: absolute;
    width: 200px;
    height: 70px; }
  .pds-main-loader pds-overlay {
    z-index: 8999; }

pds-circular-progress {
  height: 80px;
  width: 80px;
  display: inline-block; }
  pds-circular-progress[data-size="pds-small"], pds-circular-progress[data-size="small"] {
    height: 24px;
    width: 24px; }
  pds-circular-progress[data-size="pds-medium"], pds-circular-progress[data-size="medium"] {
    height: 50px;
    width: 50px; }

.pds-circular-progress-content.pds-green .pds-checkmark-container pds-icon {
  fill: #52BA2B; }

.pds-circular-progress-content.pds-green .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
  background-color: #52BA2B; }

.pds-circular-progress-content.pds-blue .pds-checkmark-container pds-icon {
  fill: #12B19F; }

.pds-circular-progress-content.pds-blue .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
  background-color: #12B19F; }

.pds-circular-progress-content.pds-orange .pds-checkmark-container pds-icon {
  fill: #F9CE33; }

.pds-circular-progress-content.pds-orange .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
  background-color: #F9CE33; }

.pds-circular-progress-content.pds-red .pds-checkmark-container pds-icon {
  fill: #CB1010; }

.pds-circular-progress-content.pds-red .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
  background-color: #CB1010; }

.pds-circular-progress-content.pds-small .pds-checkmark-container {
  margin: 0px;
  width: 24px;
  height: 24px; }

.pds-circular-progress-content.pds-small .pds-circular-progress-graph {
  margin: 3px 0;
  width: 24px;
  height: 24px; }
  .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-circle .pds-mask, .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-circle .pds-fill, .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-circle .pds-shadow {
    width: 24px;
    height: 24px; }
  .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-circle .pds-mask {
    clip: rect(0px, 24px, 24px, 12px); }
    .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
      clip: rect(0px, 12px, 24px, 0px); }
  .pds-circular-progress-content.pds-small .pds-circular-progress-graph .pds-inset {
    width: 18px;
    height: 18px;
    -webkit-margin-start: 3px;
            margin-inline-start: 3px;
    margin-top: 3px; }

.pds-circular-progress-content.pds-medium .pds-checkmark-container {
  margin: 5px;
  width: 40px;
  height: 40px; }

.pds-circular-progress-content.pds-medium .pds-circular-progress-graph {
  margin: 5px;
  width: 40px;
  height: 40px; }
  .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-circle .pds-mask, .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-circle .pds-fill, .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-circle .pds-shadow {
    width: 40px;
    height: 40px; }
  .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-circle .pds-mask {
    clip: rect(0px, 40px, 40px, 20px); }
    .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
      clip: rect(0px, 20px, 40px, 0px); }
  .pds-circular-progress-content.pds-medium .pds-circular-progress-graph .pds-inset {
    width: 32px;
    height: 32px;
    -webkit-margin-start: 4px;
            margin-inline-start: 4px;
    margin-top: 4px; }

.pds-circular-progress-content .pds-checkmark-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin: 10px;
  width: 60px;
  height: 60px; }
  .pds-circular-progress-content .pds-checkmark-container pds-icon {
    fill: #0075DB;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }

.pds-circular-progress-content .pds-circular-progress-graph {
  margin: 10px;
  width: 60px;
  height: 60px;
  position: relative;
  background-color: #F1F4F4;
  border-radius: 50%; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
    background-color: #0075DB; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-mask, .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-fill, .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-shadow {
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius: 50%; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-shadow {
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) inset; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-mask, .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-fill {
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform 500ms;
    transition: -ms-transform 500ms;
    -webkit-transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    border-radius: 50%; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-mask {
    clip: rect(0px, 60px, 60px, 30px); }
    .pds-circular-progress-content .pds-circular-progress-graph .pds-circle .pds-mask .pds-fill {
      clip: rect(0px, 30px, 60px, 0px);
      background-color: #0075DB; }
  .pds-circular-progress-content .pds-circular-progress-graph .pds-inset {
    width: 50px;
    height: 50px;
    position: absolute;
    -webkit-margin-start: 5px;
            margin-inline-start: 5px;
    margin-top: 5px;
    background-color: #FFFFFF;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="0"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="0"] .pds-circle .pds-fill {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="0"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="1"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="1"] .pds-circle .pds-fill {
    -webkit-transform: rotate(1.8deg);
    -ms-transform: rotate(1.8deg);
    transform: rotate(1.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="1"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="2"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="2"] .pds-circle .pds-fill {
    -webkit-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="2"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="3"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="3"] .pds-circle .pds-fill {
    -webkit-transform: rotate(5.4deg);
    -ms-transform: rotate(5.4deg);
    transform: rotate(5.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="3"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="4"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="4"] .pds-circle .pds-fill {
    -webkit-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="4"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="5"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="5"] .pds-circle .pds-fill {
    -webkit-transform: rotate(9deg);
    -ms-transform: rotate(9deg);
    transform: rotate(9deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="5"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="6"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="6"] .pds-circle .pds-fill {
    -webkit-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="6"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="7"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="7"] .pds-circle .pds-fill {
    -webkit-transform: rotate(12.6deg);
    -ms-transform: rotate(12.6deg);
    transform: rotate(12.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="7"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="8"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="8"] .pds-circle .pds-fill {
    -webkit-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="8"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="9"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="9"] .pds-circle .pds-fill {
    -webkit-transform: rotate(16.2deg);
    -ms-transform: rotate(16.2deg);
    transform: rotate(16.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="9"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="10"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="10"] .pds-circle .pds-fill {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="10"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    transform: rotate(36deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="11"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="11"] .pds-circle .pds-fill {
    -webkit-transform: rotate(19.8deg);
    -ms-transform: rotate(19.8deg);
    transform: rotate(19.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="11"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="12"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="12"] .pds-circle .pds-fill {
    -webkit-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="12"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="13"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="13"] .pds-circle .pds-fill {
    -webkit-transform: rotate(23.4deg);
    -ms-transform: rotate(23.4deg);
    transform: rotate(23.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="13"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="14"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="14"] .pds-circle .pds-fill {
    -webkit-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="14"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="15"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="15"] .pds-circle .pds-fill {
    -webkit-transform: rotate(27deg);
    -ms-transform: rotate(27deg);
    transform: rotate(27deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="15"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    transform: rotate(54deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="16"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="16"] .pds-circle .pds-fill {
    -webkit-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="16"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="17"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="17"] .pds-circle .pds-fill {
    -webkit-transform: rotate(30.6deg);
    -ms-transform: rotate(30.6deg);
    transform: rotate(30.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="17"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="18"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="18"] .pds-circle .pds-fill {
    -webkit-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="18"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="19"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="19"] .pds-circle .pds-fill {
    -webkit-transform: rotate(34.2deg);
    -ms-transform: rotate(34.2deg);
    transform: rotate(34.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="19"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="20"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="20"] .pds-circle .pds-fill {
    -webkit-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    transform: rotate(36deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="20"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="21"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="21"] .pds-circle .pds-fill {
    -webkit-transform: rotate(37.8deg);
    -ms-transform: rotate(37.8deg);
    transform: rotate(37.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="21"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="22"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="22"] .pds-circle .pds-fill {
    -webkit-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="22"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="23"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="23"] .pds-circle .pds-fill {
    -webkit-transform: rotate(41.4deg);
    -ms-transform: rotate(41.4deg);
    transform: rotate(41.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="23"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="24"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="24"] .pds-circle .pds-fill {
    -webkit-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="24"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="25"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="25"] .pds-circle .pds-fill {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="25"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="26"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="26"] .pds-circle .pds-fill {
    -webkit-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="26"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="27"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="27"] .pds-circle .pds-fill {
    -webkit-transform: rotate(48.6deg);
    -ms-transform: rotate(48.6deg);
    transform: rotate(48.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="27"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="28"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="28"] .pds-circle .pds-fill {
    -webkit-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="28"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="29"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="29"] .pds-circle .pds-fill {
    -webkit-transform: rotate(52.2deg);
    -ms-transform: rotate(52.2deg);
    transform: rotate(52.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="29"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="30"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="30"] .pds-circle .pds-fill {
    -webkit-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    transform: rotate(54deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="30"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    transform: rotate(108deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="31"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="31"] .pds-circle .pds-fill {
    -webkit-transform: rotate(55.8deg);
    -ms-transform: rotate(55.8deg);
    transform: rotate(55.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="31"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="32"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="32"] .pds-circle .pds-fill {
    -webkit-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="32"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="33"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="33"] .pds-circle .pds-fill {
    -webkit-transform: rotate(59.4deg);
    -ms-transform: rotate(59.4deg);
    transform: rotate(59.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="33"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="34"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="34"] .pds-circle .pds-fill {
    -webkit-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="34"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="35"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="35"] .pds-circle .pds-fill {
    -webkit-transform: rotate(63deg);
    -ms-transform: rotate(63deg);
    transform: rotate(63deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="35"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    transform: rotate(126deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="36"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="36"] .pds-circle .pds-fill {
    -webkit-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="36"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="37"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="37"] .pds-circle .pds-fill {
    -webkit-transform: rotate(66.6deg);
    -ms-transform: rotate(66.6deg);
    transform: rotate(66.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="37"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="38"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="38"] .pds-circle .pds-fill {
    -webkit-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="38"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="39"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="39"] .pds-circle .pds-fill {
    -webkit-transform: rotate(70.2deg);
    -ms-transform: rotate(70.2deg);
    transform: rotate(70.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="39"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="40"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="40"] .pds-circle .pds-fill {
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="40"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    transform: rotate(144deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="41"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="41"] .pds-circle .pds-fill {
    -webkit-transform: rotate(73.8deg);
    -ms-transform: rotate(73.8deg);
    transform: rotate(73.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="41"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="42"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="42"] .pds-circle .pds-fill {
    -webkit-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="42"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="43"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="43"] .pds-circle .pds-fill {
    -webkit-transform: rotate(77.4deg);
    -ms-transform: rotate(77.4deg);
    transform: rotate(77.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="43"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="44"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="44"] .pds-circle .pds-fill {
    -webkit-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="44"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="45"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="45"] .pds-circle .pds-fill {
    -webkit-transform: rotate(81deg);
    -ms-transform: rotate(81deg);
    transform: rotate(81deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="45"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    transform: rotate(162deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="46"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="46"] .pds-circle .pds-fill {
    -webkit-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="46"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="47"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="47"] .pds-circle .pds-fill {
    -webkit-transform: rotate(84.6deg);
    -ms-transform: rotate(84.6deg);
    transform: rotate(84.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="47"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="48"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="48"] .pds-circle .pds-fill {
    -webkit-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="48"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="49"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="49"] .pds-circle .pds-fill {
    -webkit-transform: rotate(88.2deg);
    -ms-transform: rotate(88.2deg);
    transform: rotate(88.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="49"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="50"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="50"] .pds-circle .pds-fill {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="50"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="51"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="51"] .pds-circle .pds-fill {
    -webkit-transform: rotate(91.8deg);
    -ms-transform: rotate(91.8deg);
    transform: rotate(91.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="51"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(183.6deg);
    -ms-transform: rotate(183.6deg);
    transform: rotate(183.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="52"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="52"] .pds-circle .pds-fill {
    -webkit-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="52"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(187.2deg);
    -ms-transform: rotate(187.2deg);
    transform: rotate(187.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="53"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="53"] .pds-circle .pds-fill {
    -webkit-transform: rotate(95.4deg);
    -ms-transform: rotate(95.4deg);
    transform: rotate(95.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="53"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(190.8deg);
    -ms-transform: rotate(190.8deg);
    transform: rotate(190.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="54"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="54"] .pds-circle .pds-fill {
    -webkit-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="54"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(194.4deg);
    -ms-transform: rotate(194.4deg);
    transform: rotate(194.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="55"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="55"] .pds-circle .pds-fill {
    -webkit-transform: rotate(99deg);
    -ms-transform: rotate(99deg);
    transform: rotate(99deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="55"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(198deg);
    -ms-transform: rotate(198deg);
    transform: rotate(198deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="56"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="56"] .pds-circle .pds-fill {
    -webkit-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="56"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(201.6deg);
    -ms-transform: rotate(201.6deg);
    transform: rotate(201.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="57"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="57"] .pds-circle .pds-fill {
    -webkit-transform: rotate(102.6deg);
    -ms-transform: rotate(102.6deg);
    transform: rotate(102.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="57"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(205.2deg);
    -ms-transform: rotate(205.2deg);
    transform: rotate(205.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="58"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="58"] .pds-circle .pds-fill {
    -webkit-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="58"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(208.8deg);
    -ms-transform: rotate(208.8deg);
    transform: rotate(208.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="59"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="59"] .pds-circle .pds-fill {
    -webkit-transform: rotate(106.2deg);
    -ms-transform: rotate(106.2deg);
    transform: rotate(106.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="59"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(212.4deg);
    -ms-transform: rotate(212.4deg);
    transform: rotate(212.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="60"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="60"] .pds-circle .pds-fill {
    -webkit-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    transform: rotate(108deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="60"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    transform: rotate(216deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="61"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="61"] .pds-circle .pds-fill {
    -webkit-transform: rotate(109.8deg);
    -ms-transform: rotate(109.8deg);
    transform: rotate(109.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="61"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(219.6deg);
    -ms-transform: rotate(219.6deg);
    transform: rotate(219.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="62"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="62"] .pds-circle .pds-fill {
    -webkit-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="62"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(223.2deg);
    -ms-transform: rotate(223.2deg);
    transform: rotate(223.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="63"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="63"] .pds-circle .pds-fill {
    -webkit-transform: rotate(113.4deg);
    -ms-transform: rotate(113.4deg);
    transform: rotate(113.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="63"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(226.8deg);
    -ms-transform: rotate(226.8deg);
    transform: rotate(226.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="64"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="64"] .pds-circle .pds-fill {
    -webkit-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="64"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(230.4deg);
    -ms-transform: rotate(230.4deg);
    transform: rotate(230.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="65"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="65"] .pds-circle .pds-fill {
    -webkit-transform: rotate(117deg);
    -ms-transform: rotate(117deg);
    transform: rotate(117deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="65"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(234deg);
    -ms-transform: rotate(234deg);
    transform: rotate(234deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="66"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="66"] .pds-circle .pds-fill {
    -webkit-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="66"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(237.6deg);
    -ms-transform: rotate(237.6deg);
    transform: rotate(237.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="67"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="67"] .pds-circle .pds-fill {
    -webkit-transform: rotate(120.6deg);
    -ms-transform: rotate(120.6deg);
    transform: rotate(120.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="67"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(241.2deg);
    -ms-transform: rotate(241.2deg);
    transform: rotate(241.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="68"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="68"] .pds-circle .pds-fill {
    -webkit-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="68"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(244.8deg);
    -ms-transform: rotate(244.8deg);
    transform: rotate(244.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="69"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="69"] .pds-circle .pds-fill {
    -webkit-transform: rotate(124.2deg);
    -ms-transform: rotate(124.2deg);
    transform: rotate(124.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="69"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(248.4deg);
    -ms-transform: rotate(248.4deg);
    transform: rotate(248.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="70"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="70"] .pds-circle .pds-fill {
    -webkit-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    transform: rotate(126deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="70"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    transform: rotate(252deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="71"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="71"] .pds-circle .pds-fill {
    -webkit-transform: rotate(127.8deg);
    -ms-transform: rotate(127.8deg);
    transform: rotate(127.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="71"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(255.6deg);
    -ms-transform: rotate(255.6deg);
    transform: rotate(255.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="72"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="72"] .pds-circle .pds-fill {
    -webkit-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="72"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(259.2deg);
    -ms-transform: rotate(259.2deg);
    transform: rotate(259.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="73"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="73"] .pds-circle .pds-fill {
    -webkit-transform: rotate(131.4deg);
    -ms-transform: rotate(131.4deg);
    transform: rotate(131.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="73"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(262.8deg);
    -ms-transform: rotate(262.8deg);
    transform: rotate(262.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="74"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="74"] .pds-circle .pds-fill {
    -webkit-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="74"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(266.4deg);
    -ms-transform: rotate(266.4deg);
    transform: rotate(266.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="75"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="75"] .pds-circle .pds-fill {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="75"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="76"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="76"] .pds-circle .pds-fill {
    -webkit-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="76"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(273.6deg);
    -ms-transform: rotate(273.6deg);
    transform: rotate(273.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="77"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="77"] .pds-circle .pds-fill {
    -webkit-transform: rotate(138.6deg);
    -ms-transform: rotate(138.6deg);
    transform: rotate(138.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="77"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(277.2deg);
    -ms-transform: rotate(277.2deg);
    transform: rotate(277.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="78"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="78"] .pds-circle .pds-fill {
    -webkit-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="78"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(280.8deg);
    -ms-transform: rotate(280.8deg);
    transform: rotate(280.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="79"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="79"] .pds-circle .pds-fill {
    -webkit-transform: rotate(142.2deg);
    -ms-transform: rotate(142.2deg);
    transform: rotate(142.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="79"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(284.4deg);
    -ms-transform: rotate(284.4deg);
    transform: rotate(284.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="80"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="80"] .pds-circle .pds-fill {
    -webkit-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    transform: rotate(144deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="80"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    transform: rotate(288deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="81"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="81"] .pds-circle .pds-fill {
    -webkit-transform: rotate(145.8deg);
    -ms-transform: rotate(145.8deg);
    transform: rotate(145.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="81"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(291.6deg);
    -ms-transform: rotate(291.6deg);
    transform: rotate(291.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="82"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="82"] .pds-circle .pds-fill {
    -webkit-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="82"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(295.2deg);
    -ms-transform: rotate(295.2deg);
    transform: rotate(295.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="83"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="83"] .pds-circle .pds-fill {
    -webkit-transform: rotate(149.4deg);
    -ms-transform: rotate(149.4deg);
    transform: rotate(149.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="83"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(298.8deg);
    -ms-transform: rotate(298.8deg);
    transform: rotate(298.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="84"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="84"] .pds-circle .pds-fill {
    -webkit-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="84"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(302.4deg);
    -ms-transform: rotate(302.4deg);
    transform: rotate(302.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="85"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="85"] .pds-circle .pds-fill {
    -webkit-transform: rotate(153deg);
    -ms-transform: rotate(153deg);
    transform: rotate(153deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="85"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(306deg);
    -ms-transform: rotate(306deg);
    transform: rotate(306deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="86"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="86"] .pds-circle .pds-fill {
    -webkit-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="86"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(309.6deg);
    -ms-transform: rotate(309.6deg);
    transform: rotate(309.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="87"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="87"] .pds-circle .pds-fill {
    -webkit-transform: rotate(156.6deg);
    -ms-transform: rotate(156.6deg);
    transform: rotate(156.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="87"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(313.2deg);
    -ms-transform: rotate(313.2deg);
    transform: rotate(313.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="88"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="88"] .pds-circle .pds-fill {
    -webkit-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="88"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(316.8deg);
    -ms-transform: rotate(316.8deg);
    transform: rotate(316.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="89"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="89"] .pds-circle .pds-fill {
    -webkit-transform: rotate(160.2deg);
    -ms-transform: rotate(160.2deg);
    transform: rotate(160.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="89"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(320.4deg);
    -ms-transform: rotate(320.4deg);
    transform: rotate(320.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="90"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="90"] .pds-circle .pds-fill {
    -webkit-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    transform: rotate(162deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="90"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    transform: rotate(324deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="91"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="91"] .pds-circle .pds-fill {
    -webkit-transform: rotate(163.8deg);
    -ms-transform: rotate(163.8deg);
    transform: rotate(163.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="91"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(327.6deg);
    -ms-transform: rotate(327.6deg);
    transform: rotate(327.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="92"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="92"] .pds-circle .pds-fill {
    -webkit-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="92"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(331.2deg);
    -ms-transform: rotate(331.2deg);
    transform: rotate(331.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="93"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="93"] .pds-circle .pds-fill {
    -webkit-transform: rotate(167.4deg);
    -ms-transform: rotate(167.4deg);
    transform: rotate(167.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="93"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(334.8deg);
    -ms-transform: rotate(334.8deg);
    transform: rotate(334.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="94"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="94"] .pds-circle .pds-fill {
    -webkit-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="94"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(338.4deg);
    -ms-transform: rotate(338.4deg);
    transform: rotate(338.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="95"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="95"] .pds-circle .pds-fill {
    -webkit-transform: rotate(171deg);
    -ms-transform: rotate(171deg);
    transform: rotate(171deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="95"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(342deg);
    -ms-transform: rotate(342deg);
    transform: rotate(342deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="96"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="96"] .pds-circle .pds-fill {
    -webkit-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="96"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(345.6deg);
    -ms-transform: rotate(345.6deg);
    transform: rotate(345.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="97"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="97"] .pds-circle .pds-fill {
    -webkit-transform: rotate(174.6deg);
    -ms-transform: rotate(174.6deg);
    transform: rotate(174.6deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="97"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(349.2deg);
    -ms-transform: rotate(349.2deg);
    transform: rotate(349.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="98"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="98"] .pds-circle .pds-fill {
    -webkit-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="98"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(352.8deg);
    -ms-transform: rotate(352.8deg);
    transform: rotate(352.8deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="99"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="99"] .pds-circle .pds-fill {
    -webkit-transform: rotate(178.2deg);
    -ms-transform: rotate(178.2deg);
    transform: rotate(178.2deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="99"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(356.4deg);
    -ms-transform: rotate(356.4deg);
    transform: rotate(356.4deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="100"] .pds-circle .pds-mask.pds-full, .pds-circular-progress-content .pds-circular-progress-graph[data-progress="100"] .pds-circle .pds-fill {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .pds-circular-progress-content .pds-circular-progress-graph[data-progress="100"] .pds-circle .pds-fill.pds-fix {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); }

pds-grid-widget {
  /* This is the container that holds the entirety of the filters that show up.*/
  /* This is for the pagination controls container that shows at the bottom of
     the grid widget. */
  /* This is for the tags that get the sortable up/down arrows */
  /* Landscape phone to portrait tablet */
  /* @media screen and (max-width:767px) { */
  /* Landscape phones and down */
  /* @media screen and (max-width:480px) { */
  /* End of Media Query */
  /**
  * END of GridWidget styles
  */ }
  pds-grid-widget .pds-filter-controls {
    margin-bottom: 15px;
    background-color: #DFE2E2;
    /* The box that holds all of the filter rows*/ }
    pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button,
    pds-grid-widget .pds-filter-controls button.pds-filter-control-button,
    pds-grid-widget .pds-filter-controls select.pds-filter-select-options,
    pds-grid-widget .pds-filter-controls input {
      height: 32px;
      padding-top: 3px;
      padding-bottom: 3px; }
      @media (max-width: 768px) {
        pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button,
        pds-grid-widget .pds-filter-controls button.pds-filter-control-button,
        pds-grid-widget .pds-filter-controls select.pds-filter-select-options,
        pds-grid-widget .pds-filter-controls input {
          height: 44px; } }
    pds-grid-widget .pds-filter-controls select.pds-filter-select-options {
      font-size: 14px;
      font-weight: 600;
      height: 32px;
      background-size: 32px, 32px; }
      @media (max-width: 768px) {
        pds-grid-widget .pds-filter-controls select.pds-filter-select-options {
          height: 44px;
          background-size: 44px, 44px; } }
    pds-grid-widget .pds-filter-controls input {
      font-size: 14px;
      font-weight: 600; }
    pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button {
      padding: 6px; }
      pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-button-square {
        height: 30px;
        width: 30px; }
        pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-button-square pds-icon {
          fill: #0075DB; }
        @media (max-width: 768px) {
          pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-button-square {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            height: 42px;
            width: 42px; } }
      pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-button-action-attached, pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-filter-control-button {
        top: 1px;
        padding: 2px; }
        pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-button-action-attached pds-icon, pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-filter-control-button pds-icon {
          fill: #677274;
          margin: 0.11rem 0; }
      pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-filter-control-button {
        position: relative;
        top: 1px;
        height: 30px;
        min-width: 30px; }
        @media (max-width: 768px) {
          pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-filter-control-button {
            width: 42px;
            height: 42px;
            min-width: 42px; } }
      pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-selection-status-item {
        padding: 3px 10px;
        height: 32px; }
        @media (max-width: 768px) {
          pds-grid-widget .pds-filter-controls button.pds-date-widget-dialog-trigger-button.pds-selection-status-item {
            height: 44px; } }
    pds-grid-widget .pds-filter-controls .pds-filter-box {
      border: none;
      padding: 10px 15px 0;
      /* Each Div Represents 1 filter */ }
      pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -ms-flex-line-pack: stretch;
            align-content: stretch;
        /* The label or select that shows up first in each row*/
        /* Individual styles for each of the filter types*/ }
        pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container > button {
          margin: 0;
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px;
          border: none; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container > button:hover {
            border: none; }
        pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-filter-description {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 25%;
                  flex: 0 0 25%;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px;
          margin-bottom: 0;
          margin-top: 0; }
        pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container label.pds-filter-description {
          line-height: 34px; }
        pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 auto;
                  flex: 1 1 auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component > * {
            -webkit-box-flex: 1;
                -ms-flex: 1 1 100%;
                    flex: 1 1 100%; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component .pds-input-checkbox, pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component input, pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component select {
            margin-bottom: 0; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component input[type="text"] {
            height: 32px; }
            @media (max-width: 768px) {
              pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component input[type="text"] {
                height: 42px; } }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-text-filter {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-text-filter select {
              -webkit-box-flex: 0;
                  -ms-flex: 0 0 125px;
                      flex: 0 0 125px; }
              pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-text-filter select + pds-text-widget {
                -webkit-margin-start: 10px;
                        margin-inline-start: 10px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-text-filter pds-text-widget {
              -webkit-box-flex: 1;
                  -ms-flex: 1 1 auto;
                      flex: 1 1 auto; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-text-filter span {
              line-height: 34px;
              -webkit-margin-start: 10px;
                      margin-inline-start: 10px; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter span {
              line-height: 34px;
              -webkit-margin-start: 10px;
                      margin-inline-start: 10px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter select {
              -webkit-box-flex: 0;
                  -ms-flex: 0 0 125px;
                      flex: 0 0 125px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter pds-number-widget {
              -webkit-box-flex: 1;
                  -ms-flex: 1 1 auto;
                      flex: 1 1 auto; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter pds-number-widget, pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter select:not(:first-of-type), pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-number-filter label {
              -webkit-margin-start: 10px;
                      margin-inline-start: 10px; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter span {
              line-height: 34px;
              -webkit-margin-start: 10px;
                      margin-inline-start: 10px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter > select {
              -webkit-box-flex: 0;
                  -ms-flex: 0 0 125px;
                      flex: 0 0 125px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget {
              -webkit-box-flex: 1;
                  -ms-flex: 1 1 auto;
                      flex: 1 1 auto; }
              pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget .pds-date-widget-content .pds-input-area button.pds-button-action-attached {
                background-color: #FFFFFF;
                height: 32px;
                width: 32px; }
                pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget .pds-date-widget-content .pds-input-area button.pds-button-action-attached:hover:not([disabled]), pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget .pds-date-widget-content .pds-input-area button.pds-button-action-attached:focus:not([disabled]) {
                  background-color: #F1F4F4; }
                @media (max-width: 768px) {
                  pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget .pds-date-widget-content .pds-input-area button.pds-button-action-attached {
                    height: 42px;
                    width: 44px; } }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter pds-date-widget,
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-date-filter label {
              -webkit-margin-start: 10px;
                      margin-inline-start: 10px; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-popover-trigger-content {
              -webkit-padding-start: 0;
                      padding-inline-start: 0; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter button {
              color: #677274; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-input-checkbox {
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                      flex-direction: row;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
              -webkit-margin-start: 0;
                      margin-inline-start: 0;
              -webkit-margin-end: 0;
                      margin-inline-end: 0; }
              pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-input-checkbox label {
                display: inline-block;
                width: auto; }
                pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-input-checkbox label .pds-label-text {
                  padding: 5px;
                  -webkit-padding-end: 35px;
                          padding-inline-end: 35px;
                  -webkit-padding-start: 0;
                          padding-inline-start: 0;
                  min-height: 34px; }
                  pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-input-checkbox label .pds-label-text::before {
                    top: 5px; }
                  pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-input-checkbox label .pds-label-text::after {
                    top: 9px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-vertical-multiselect {
              border: 1px solid lightgrey;
              position: absolute;
              top: 34px;
              inset-inline-start: 0;
              background-color: white;
              max-height: 220px;
              z-index: 1000;
              overflow-y: auto;
              overflow-x: hidden; }
              pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter .pds-vertical-multiselect label {
                -webkit-margin-start: 10px;
                        margin-inline-start: 10px; }
            pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-multi-select-filter button.pds-remove-option {
              -webkit-padding-end: 5px;
                      padding-inline-end: 5px;
              -webkit-padding-start: 10px;
                      padding-inline-start: 10px; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-select-filter select {
            display: block;
            width: 100%; }
          pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container pds-autonomous-component pds-pill-select-filter button {
            margin: 0 5px; }
        pds-grid-widget .pds-filter-controls .pds-filter-box > .pds-filter-container input[type="checkbox"] {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          -ms-flex-positive: 0;
              flex-grow: 0;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px; }
    pds-grid-widget .pds-filter-controls .pds-button-group-primary {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      margin-bottom: 0;
      padding: 5px; }
      pds-grid-widget .pds-filter-controls .pds-button-group-primary button {
        -webkit-margin-start: 10px;
                margin-inline-start: 10px; }
    pds-grid-widget .pds-filter-controls .pds-selection-status-bar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      pds-grid-widget .pds-filter-controls .pds-selection-status-bar .grid-widget-clear-all {
        line-height: 12px;
        height: 32px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        white-space: nowrap; }
        @media (max-width: 768px) {
          pds-grid-widget .pds-filter-controls .pds-selection-status-bar .grid-widget-clear-all {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            width: auto;
            height: 44px; } }
      pds-grid-widget .pds-filter-controls .pds-selection-status-bar .pds-selection-status {
        background: transparent;
        padding-top: 0;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
                flex: 1 1 100%;
        border: none; }
      pds-grid-widget .pds-filter-controls .pds-selection-status-bar > span:last-child {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        padding-top: 5px;
        margin-bottom: 10px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; }
    pds-grid-widget .pds-filter-controls .pds-selected-items {
      background-color: #FFFFFF;
      border-radius: 4px;
      padding: 5px 10px;
      -webkit-margin-start: 10px;
              margin-inline-start: 10px; }
    pds-grid-widget .pds-filter-controls .pds-validation {
      color: #970C0C; }
  pds-grid-widget .pds-pagination-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 15px 15px;
    /* Shows the X out of Y message after the filter box, and before the injected content.*/ }
    pds-grid-widget .pds-pagination-controls .pds-grid-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      pds-grid-widget .pds-pagination-controls .pds-grid-header label {
        padding: 5px;
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1; }
    pds-grid-widget .pds-pagination-controls .pds-page-slider {
      text-align: center;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      [dir='rtl'] pds-grid-widget .pds-pagination-controls .pds-page-slider pds-icon[name="pointer-right"],
      [dir='rtl'] pds-grid-widget .pds-pagination-controls .pds-page-slider pds-icon[name="pointer-left"] {
        -webkit-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
                transform: scaleX(-1); }
      pds-grid-widget .pds-pagination-controls .pds-page-slider button {
        margin: 0;
        padding: 0;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 32px;
                flex: 0 0 32px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    pds-grid-widget .pds-pagination-controls pds-numbered-slider {
      max-width: 300px;
      margin: 0 10px;
      display: inline-block;
      width: 100%; }
  pds-grid-widget .pds-sortable:focus {
    outline: none; }
    pds-grid-widget .pds-sortable:focus > span > span {
      border-bottom: 1px solid #0075DB;
      color: #0075DB; }
    pds-grid-widget .pds-sortable:focus .sr-only {
      display: none; }
  pds-grid-widget .pds-sortable span {
    display: inline-block;
    position: relative; }
  pds-grid-widget .pds-sortable.left span {
    padding: 0;
    -webkit-padding-end: 18px;
            padding-inline-end: 18px; }
  pds-grid-widget .pds-sortable:hover {
    cursor: pointer;
    color: #0075DB; }
  pds-grid-widget .pds-sortable ::-moz-selection {
    background: transparent; }
  pds-grid-widget .pds-sortable ::selection {
    background: transparent; }
  pds-grid-widget .pds-sortable.pds-sort-ascending i {
    padding: 0px;
    -webkit-padding-end: 1px;
            padding-inline-end: 1px;
    -webkit-padding-start: 2px;
            padding-inline-start: 2px; }
  pds-grid-widget .pds-sortable.pds-sort-descending i {
    padding: 0px;
    -webkit-padding-end: 1px;
            padding-inline-end: 1px;
    -webkit-padding-start: 2px;
            padding-inline-start: 2px; }
  pds-grid-widget .pds-sortable i {
    position: absolute;
    top: calc(~"50% - 9px");
    inset-inline-end: -19px; }
  pds-grid-widget .pds-input-text.pds-number-widget {
    margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    pds-grid-widget header:after {
      margin: 8px; } }
  @media screen and (max-width: 767px) {
    pds-grid-widget header:after {
      margin: 8px; }
    pds-grid-widget fieldset.filterBox div:first-of-type label {
      display: inline-block; }
    pds-grid-widget fieldset.filterBox > div > label {
      margin: 0;
      padding: 0;
      width: 22%;
      vertical-align: top; }
    pds-grid-widget fieldset.filterBox > div > div {
      border-top: 1px solid #C4C9CA; }
      pds-grid-widget fieldset.filterBox > div > div:first-of-type {
        border-top: none; }
        pds-grid-widget fieldset.filterBox > div > div:first-of-type input {
          width: calc(~"78% - 14px"); }
      pds-grid-widget fieldset.filterBox > div > div:nth-of-type(4) div {
        width: 78%;
        display: inline-block;
        padding: 0; }
    pds-grid-widget fieldset.filterBox label.toLabel {
      margin: 0;
      padding: 0 5px;
      width: calc(~"6% - 10px"); } }

.sr-only {
  position: absolute;
  inset-inline-start: -999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

:focus {
  border: none;
  outline: 2px solid #6AF0E0;
  outline-offset: -2px; }

[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
  border-color: #6AF0E0; }

/**
 * Color Map - We removed the color map from the css files in PDS-2125
 * --> Leaving it here in comment form for future reference <--
 * Category 1: #CC4E00 //Solution: Unified Talent
 * Category 2: #DA2F6B //Solution: Unified Administration
 * Category 3: #007bc7 //Solution: Unified Classroom
 * Category 4: #3c57ba //Solution: Unified Insights
 * Category 5: #00427c //Solution: Student Information System
 * Category 6: #35823C //Solution: Unified Home
 * Category 7: #9942a8 //Solution: Unified Communities
 * Category 8: #002A3A //Higher Ed Platform product
 */
.pds-app-switcher-contents {
  height: 100%; }
  .pds-app-switcher-contents button.pds-app-switcher-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 40px;
    border: none;
    background-color: #002B52; }
    .pds-app-switcher-contents button.pds-app-switcher-trigger:hover:not([disabled]), .pds-app-switcher-contents button.pds-app-switcher-trigger:focus:not([disabled]) {
      background-color: #002B52;
      outline: none; }
      .pds-app-switcher-contents button.pds-app-switcher-trigger:hover:not([disabled]) pds-icon, .pds-app-switcher-contents button.pds-app-switcher-trigger:focus:not([disabled]) pds-icon {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 32px;
                flex: 0 1 32px;
        border-radius: 50%;
        background-color: #FFFFFF;
        fill: #00427C; }
    .pds-app-switcher-contents button.pds-app-switcher-trigger.pds-app-switcher:hover:not([disabled]), .pds-app-switcher-contents button.pds-app-switcher-trigger.pds-app-switcher:focus:not([disabled]) {
      background-color: #002B52; }

button.pds-is-active.pds-app-switcher-open {
  background-color: #002B52; }
  button.pds-is-active.pds-app-switcher-open:hover, button.pds-is-active.pds-app-switcher-open:focus {
    background-color: #00427C; }
    button.pds-is-active.pds-app-switcher-open:hover pds-icon, button.pds-is-active.pds-app-switcher-open:focus pds-icon {
      border-radius: 50%;
      background-color: #FFFFFF;
      fill: #00427C;
      outline: none; }

.pds-app-switcher-trigger {
  border-radius: 0; }

.pds-app-switcher-swatch, .pds-app-switcher-swatch-custom {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 2.5px; }

.pds-app-switcher-swatch {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 15px;
  font-weight: 600; }

[data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme {
  width: 300px;
  min-height: 132px;
  z-index: 7000;
  color: #333333; }
  [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .pds-popover-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 10px 15px; }
  [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list {
    background-color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: start;
    padding: 5px 0 0;
    max-height: calc(~"100vh - 128px");
    overflow-y: auto;
    overflow-x: hidden; }
    [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      width: 288px;
      max-width: 290px;
      margin: 0 5px 5px;
      padding: 8px 10px 9px;
      color: #333333;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      border-radius: 4px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a .pds-app-switcher-swatch, [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a .pds-app-switcher-swatch-custom {
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        margin-top: 1px; }
      [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:hover, [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:visited, [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:focus {
        background-color: #F1F4F4;
        text-decoration: none; }
        [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:hover .pds-app-switcher-app-name, [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:visited .pds-app-switcher-app-name, [data-tippy-root] .tippy-box.popover-theme.pds-app-switcher-theme .app-switcher-product-list a:focus .pds-app-switcher-app-name {
          text-decoration: underline; }

.pds-message-success, .pds-message-info, .pds-message-error, .pds-message-warning, .pds-message-bland {
  min-height: 50px;
  font-size: 18px;
  line-height: 20px;
  padding: 15px;
  -webkit-padding-start: 50px;
          padding-inline-start: 50px;
  margin: 15px;
  background: no-repeat 10px 10px/30px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  font-family: Open Sans, sans-serif; }
  [dir='rtl'] .pds-message-success, [dir='rtl'] .pds-message-info, [dir='rtl'] .pds-message-error, [dir='rtl'] .pds-message-warning, [dir='rtl'] .pds-message-bland {
    background-position-x: calc(~"100% - 10px"); }
  .pds-message-success a, .pds-message-info a, .pds-message-error a, .pds-message-warning a, .pds-message-bland a {
    text-decoration: underline; }
  .pds-message-success span, .pds-message-info span, .pds-message-error span, .pds-message-warning span, .pds-message-bland span {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .pds-message-success .pds-message-embedded-link, .pds-message-info .pds-message-embedded-link, .pds-message-error .pds-message-embedded-link, .pds-message-warning .pds-message-embedded-link, .pds-message-bland .pds-message-embedded-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
  .pds-message-success a.pds-message-dismiss, .pds-message-info a.pds-message-dismiss, .pds-message-error a.pds-message-dismiss, .pds-message-warning a.pds-message-dismiss, .pds-message-bland a.pds-message-dismiss {
    text-decoration: none;
    -ms-flex-preferred-size: 44px;
        flex-basis: 44px;
    text-align: center; }
  .pds-message-success .pds-emphasized-item-list, .pds-message-info .pds-emphasized-item-list, .pds-message-error .pds-emphasized-item-list, .pds-message-warning .pds-emphasized-item-list, .pds-message-bland .pds-emphasized-item-list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .pds-message-success .pds-emphasized-item, .pds-message-info .pds-emphasized-item, .pds-message-error .pds-emphasized-item, .pds-message-warning .pds-emphasized-item, .pds-message-bland .pds-emphasized-item {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: #C4C9CA solid 1px;
    font-size: 10px;
    padding: 1px 7px 1px;
    margin: 2px 5px;
    min-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    display: block;
    line-height: 1.5; }

.pds-message-success {
  background-image: url("../../../../scripts/pds/img/icon-success.svg");
  background-color: #E5F7DE;
  color: #333333;
  border: 1px solid #52BA2B; }
  .pds-message-success .pds-emphasized-item {
    border-color: #52BA2B; }

.pds-message-info {
  background-image: url("../../../../scripts/pds/img/icon-information.svg");
  background-color: #E5F3FF;
  color: #333333;
  border: 1px solid #0075DB; }
  .pds-message-info .pds-emphasized-item {
    border-color: #0075DB; }

.pds-message-error {
  background-image: url("../../../../scripts/pds/img/icon-error.svg");
  background-color: #FDE7E7;
  color: #333333;
  border: 1px solid #EB1313; }
  .pds-message-error .pds-emphasized-item {
    border-color: #EB1313; }

.pds-message-warning {
  background-image: url("../../../../scripts/pds/img/icon-warning.svg");
  background-color: #FEEFE7;
  color: #333333;
  border: 1px solid #F5691D; }
  .pds-message-warning .pds-emphasized-item {
    border-color: #F5691D; }

.pds-message-bland {
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  background-color: #F1F4F4;
  color: #000000; }

.pds-messages-docked .pds-message-success, .pds-messages-docked .pds-message-info, .pds-messages-docked .pds-message-error, .pds-messages-docked .pds-message-warning, .pds-messages-docked .pds-message-bland {
  border-top: none;
  -webkit-border-end: none;
          border-inline-end: none;
  -webkit-border-start: none;
          border-inline-start: none;
  margin: 0;
  border-radius: 0; }

.pds-messages-floating {
  position: fixed;
  top: 96px;
  inset-inline-end: 0;
  inset-inline-start: 15px;
  padding-top: 15px; }
  @media (min-width: 769px) {
    .pds-messages-floating {
      inset-inline-start: 109px; } }
  @media (min-width: 993px) {
    .pds-messages-floating {
      inset-inline-start: 109px; } }
  .pds-messages-floating .pds-message-success, .pds-messages-floating .pds-message-info, .pds-messages-floating .pds-message-error, .pds-messages-floating .pds-message-warning, .pds-messages-floating .pds-message-bland {
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25); }

.pds-messages {
  z-index: 9500; }

.pds-global-tabs {
  margin-top: -15px;
  margin-bottom: 15px; }
  .pds-global-tabs .pds-tabs-panel {
    display: none;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-global-tabs .pds-tabs-panel.pds-is-active {
      display: block; }

.pds-tabs {
  margin: 0;
  list-style-type: none;
  border-bottom: 1px solid #C4C9CA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #FFFFFF;
  font-family: Open Sans, sans-serif; }
  .pds-tabs li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
    .pds-tabs li > a {
      color: #677274;
      display: block;
      padding: 0 20px;
      line-height: 48px;
      font-size: 14px;
      font-weight: 400; }
    .pds-tabs li:focus, .pds-tabs li:hover {
      background: #F1F4F4;
      color: #8FCBFF;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .pds-tabs li.pds-is-active > a {
      background: linear-gradient(to top, #0075DB 3px, transparent -3px);
      background-clip: content-box;
      color: #0075DB;
      font-weight: 600; }
    .pds-tabs li.pds-disable > a {
      background-clip: content-box;
      color: #C4C9CA;
      font-weight: 600;
      cursor: default; }
    .pds-tabs li.pds-disable > a:focus {
      outline: none;
      border: none; }
    .pds-tabs li.pds-tabs-buttons {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      .pds-tabs li.pds-tabs-buttons button {
        border-radius: 0;
        border-top: none;
        border-bottom: none; }
      .pds-tabs li.pds-tabs-buttons pds-dialog button {
        border-radius: 4px;
        border-top: 1px solid #C4C9CA;
        border-bottom: 1px solid #C4C9CA; }
  .pds-tabs .pds-input-checkbox li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.pds-global-tabs-detail {
  background: #F1F4F4; }

.pds-scoped-tabs {
  background: #FFFFFF;
  border: 1px solid #C4C9CA; }
  .pds-scoped-tabs .pds-tabs {
    margin: 0;
    list-style-type: none;
    background: linear-gradient(to top, #C4C9CA 1px, #F1F4F4 -1px);
    border-top: none;
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 0; }
    .pds-scoped-tabs .pds-tabs li {
      -webkit-border-end: 1px solid #C4C9CA;
              border-inline-end: 1px solid #C4C9CA;
      display: inline-block; }
      .pds-scoped-tabs .pds-tabs li > a {
        color: #677274;
        display: block;
        padding: 0 20px;
        line-height: 42px;
        font-size: 14px;
        font-weight: 400; }
      .pds-scoped-tabs .pds-tabs li:focus, .pds-scoped-tabs .pds-tabs li:hover {
        background: #C4C9CA;
        color: #677274;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      .pds-scoped-tabs .pds-tabs li.pds-is-active {
        background: #FFFFFF; }
        .pds-scoped-tabs .pds-tabs li.pds-is-active > a {
          color: #333333;
          font-weight: 400; }
  .pds-scoped-tabs .pds-tabs-panel {
    display: none; }
    .pds-scoped-tabs .pds-tabs-panel.pds-content-tabs {
      padding-top: 15px; }
    .pds-scoped-tabs .pds-tabs-panel.pds-is-active {
      display: block; }

.pds-global-breadcrumbs {
  margin-top: -15px;
  margin-bottom: 15px; }
  .pds-global-breadcrumbs .pds-breadcrumbs {
    margin: 0;
    list-style-type: none;
    border-bottom: 1px solid #C4C9CA;
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #FFFFFF; }
    .pds-global-breadcrumbs .pds-breadcrumbs li {
      display: inline-block;
      padding: 0;
      -webkit-padding-end: 10px;
              padding-inline-end: 10px;
      -webkit-padding-start: 30px;
              padding-inline-start: 30px; }
      .pds-global-breadcrumbs .pds-breadcrumbs li:first-child {
        -webkit-padding-start: 20px;
                padding-inline-start: 20px; }
      .pds-global-breadcrumbs .pds-breadcrumbs li > a {
        color: #677274;
        display: block;
        line-height: 50px;
        font-size: 14px;
        font-weight: 400; }
      .pds-global-breadcrumbs .pds-breadcrumbs li:focus, .pds-global-breadcrumbs .pds-breadcrumbs li:hover {
        text-decoration: underline;
        color: #8FCBFF;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      .pds-global-breadcrumbs .pds-breadcrumbs li.pds-is-active > a {
        color: #0075DB;
        font-weight: 600; }
      .pds-global-breadcrumbs .pds-breadcrumbs li.pds-is-disabled {
        cursor: not-allowed;
        color: #DFE2E2; }
        .pds-global-breadcrumbs .pds-breadcrumbs li.pds-is-disabled:focus, .pds-global-breadcrumbs .pds-breadcrumbs li.pds-is-disabled:hover {
          text-decoration: none; }
        .pds-global-breadcrumbs .pds-breadcrumbs li.pds-is-disabled > a {
          pointer-events: none;
          color: #DFE2E2; }
      .pds-global-breadcrumbs .pds-breadcrumbs li:not(:first-child) {
        background: url("../../../../scripts/pds/img/icons/Arrow/chevron-right.svg") no-repeat 0 50%; }
        [dir='rtl'] .pds-global-breadcrumbs .pds-breadcrumbs li:not(:first-child) {
          background: none;
          position: relative; }
          [dir='rtl'] .pds-global-breadcrumbs .pds-breadcrumbs li:not(:first-child)::before {
            content: '';
            background: url(../img/icons/Arrow/chevron-right.svg) no-repeat 100% 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%) scaleX(-1);
                -ms-transform: translateY(-50%) scaleX(-1);
                    transform: translateY(-50%) scaleX(-1);
            width: 20px;
            height: 20px;
            inset-inline-start: 0; }
  .pds-global-breadcrumbs .pds-breadcrumbs-panel {
    display: none; }
    .pds-global-breadcrumbs .pds-breadcrumbs-panel.pds-is-active {
      display: block; }

.pds-global-breadcrumbs-detail {
  background: #F1F4F4; }

.pds-scoped-breadcrumbs {
  background: #FFFFFF; }
  .pds-scoped-breadcrumbs .pds-breadcrumbs {
    margin: 0;
    list-style-type: none;
    border-top: none;
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 0; }
    .pds-scoped-breadcrumbs .pds-breadcrumbs li {
      display: inline-block;
      padding: 0;
      -webkit-padding-end: 10px;
              padding-inline-end: 10px;
      -webkit-padding-start: 30px;
              padding-inline-start: 30px; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li:first-child {
        -webkit-padding-start: 20px;
                padding-inline-start: 20px; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li > a {
        color: #677274;
        display: block;
        line-height: 44px;
        font-size: 14px;
        font-weight: 400; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li:focus, .pds-scoped-breadcrumbs .pds-breadcrumbs li:hover {
        text-decoration: underline;
        color: #677274;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li.pds-is-active > a {
        color: #333333;
        font-weight: 600; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li.pds-is-disabled {
        cursor: not-allowed;
        color: #DFE2E2; }
        .pds-scoped-breadcrumbs .pds-breadcrumbs li.pds-is-disabled:focus, .pds-scoped-breadcrumbs .pds-breadcrumbs li.pds-is-disabled:hover {
          text-decoration: none; }
        .pds-scoped-breadcrumbs .pds-breadcrumbs li.pds-is-disabled > a {
          pointer-events: none;
          color: #DFE2E2; }
      .pds-scoped-breadcrumbs .pds-breadcrumbs li:not(:first-child) {
        background: url("../../../../scripts/pds/img/icons/Arrow/chevron-right.svg") no-repeat 0 50%; }
        [dir='rtl'] .pds-scoped-breadcrumbs .pds-breadcrumbs li:not(:first-child) {
          background: none;
          position: relative; }
          [dir='rtl'] .pds-scoped-breadcrumbs .pds-breadcrumbs li:not(:first-child)::before {
            content: '';
            background: url(../img/icons/Arrow/chevron-right.svg) no-repeat 100% 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%) scaleX(-1);
                -ms-transform: translateY(-50%) scaleX(-1);
                    transform: translateY(-50%) scaleX(-1);
            width: 20px;
            height: 20px;
            inset-inline-start: 0; }
  .pds-scoped-breadcrumbs .pds-breadcrumbs-panel {
    display: none; }
    .pds-scoped-breadcrumbs .pds-breadcrumbs-panel.pds-is-active {
      display: block; }

.pds-thumbnail {
  border: 1px solid #C4C9CA;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 200px;
  height: 160px;
  overflow: hidden;
  background: #FFFFFF; }

.pds-thumb-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #C4C9CA;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px; }
  .pds-thumb-title .pds-thumb-content {
    padding: 0 7.5px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .pds-thumb-title .pds-thumb-date {
    font-size: 12px;
    font-weight: 400;
    padding: 0 7.5px;
    color: #677274;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    display: block; }

.pds-thumbnails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .pds-thumbnails .pds-thumbnail {
    width: none;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 200px;
            flex: 0 0 200px;
    margin-bottom: 15px; }
  .pds-thumbnails .pds-thumbnail {
    -webkit-margin-start: 15px;
            margin-inline-start: 15px; }

.pds-thumbnail > pds-img {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100px;
          flex: 1 0 100px;
  width: 200px;
  height: 100px; }

.pds-thumbnail img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
  background-size: auto;
  background-position: center center; }

.pds-thumbnail > pds-icon {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  height: 100px; }
  .pds-thumbnail > pds-icon svg {
    height: 85px;
    width: 200px; }

.pds-stamp-count {
  color: #000000;
  background: #F1F4F4;
  font-size: 18px;
  font-weight: 700; }

.pds-stamp-title {
  color: #677274;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }

.pds-stamp {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  min-width: 56px;
  background: white;
  border-radius: 4px;
  border: 1px solid #C4C9CA; }
  .pds-stamp .pds-stamp-title {
    border-end-start-radius: 4px;
    border-end-end-radius: 4px;
    border-top: 1px solid #C4C9CA; }
  .pds-stamp > * {
    padding: 2px; }

.pds-stamp-row {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid #C4C9CA; }
  .pds-stamp-row > * {
    padding: 0 8px; }

.pds-numbered-slider-content {
  display: block;
  width: 100%;
  position: relative;
  height: 32px;
  line-height: 32px; }
  .pds-numbered-slider-content span {
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 32px;
    text-align: center;
    color: white;
    height: 32px;
    line-height: 32px;
    pointer-events: none; }
    .pds-numbered-slider-content span.pds-dot-background {
      background-color: #C4C9CA;
      border-radius: 50%; }
  .pds-numbered-slider-content input {
    position: absolute;
    top: 0;
    inset-inline-start: 0; }
    .pds-numbered-slider-content input[type=range] {
      -webkit-appearance: none;
      /* Hides the slider so that custom slider can be made */
      width: 100%;
      /* Specific width is required for Firefox. */
      background: transparent;
      /* Otherwise white in Chrome */
      height: 32px;
      border: none;
      margin: 0;
      padding: 0;
      /* Styles the slider */
      /* All the same stuff for Firefox */
      /* All the same stuff for IE */ }
      .pds-numbered-slider-content input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none; }
      .pds-numbered-slider-content input[type=range]:focus {
        outline: none;
        /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }
      .pds-numbered-slider-content input[type=range]::-ms-track {
        width: 100%;
        cursor: pointer;
        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent; }
      .pds-numbered-slider-content input[type=range]::-webkit-slider-thumb {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0075DB;
        cursor: pointer;
        border: none;
        -webkit-appearance: none;
        margin-top: -13px;
        /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */ }
      .pds-numbered-slider-content input[type=range]::-moz-range-thumb {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0075DB;
        cursor: pointer;
        border: none; }
      .pds-numbered-slider-content input[type=range]::-ms-thumb {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0075DB;
        cursor: pointer;
        border: none;
        margin-top: 0px; }
      .pds-numbered-slider-content input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: #C4C9CA;
        border-radius: 3px; }
      .pds-numbered-slider-content input[type=range]:focus::-webkit-slider-runnable-track {
        background: #C4C9CA;
        border-radius: 3px; }
      .pds-numbered-slider-content input[type=range]::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: #C4C9CA;
        border-radius: 3px;
        padding: 0;
        margin: 0; }
      .pds-numbered-slider-content input[type=range]::-ms-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
        border-radius: 3px; }
      .pds-numbered-slider-content input[type=range]::-ms-fill-lower {
        background: #C4C9CA; }
      .pds-numbered-slider-content input[type=range]:focus::-ms-fill-lower {
        background: #C4C9CA; }
      .pds-numbered-slider-content input[type=range]::-ms-fill-upper {
        background: #C4C9CA; }
      .pds-numbered-slider-content input[type=range]:focus::-ms-fill-upper {
        background: #C4C9CA; }

pds-card-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  margin: 0 15px 15px; }
  pds-card-stack .pds-card-spacer, pds-card-stack pds-card {
    margin: 0 3px 15px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 156px;
            flex: 1 1 156px; }
    pds-card-stack .pds-is-active.pds-card-spacer::after, pds-card-stack pds-card.pds-is-active::after {
      content: ' ';
      position: absolute;
      bottom: -11px;
      inset-inline-start: calc(~"50% - 12px");
      inset-inline-end: calc(~"50% - 12px");
      -webkit-border-start: 12px solid transparent;
              border-inline-start: 12px solid transparent;
      -webkit-border-end: 12px solid transparent;
              border-inline-end: 12px solid transparent;
      border-bottom: 8px solid #333333; }
  pds-card-stack .pds-card-spacer {
    height: 0px;
    max-height: 0px;
    border-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent; }

pds-card-stack .pds-card-spacer, pds-card {
  display: inline-block;
  width: 156px;
  height: 135px;
  position: relative; }

pds-card {
  cursor: pointer; }
  pds-card.pds-item-controls-dismissed:not(.pds-is-active):hover .pds-card-content pds-item-controls {
    display: none; }
  pds-card:not(.pds-is-active):hover .pds-card-content, pds-card.pds-item-controls-visible .pds-card-content {
    top: 0;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
    pds-card:not(.pds-is-active):hover .pds-card-content pds-item-controls, pds-card.pds-item-controls-visible .pds-card-content pds-item-controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
  pds-card.pds-is-active .pds-card-content {
    border: 1px solid #C4C9CA;
    border-bottom-width: 4px; }
  pds-card pds-item-controls {
    height: 32px;
    line-height: 32px; }
    @media (max-width: 768px) {
      pds-card pds-item-controls {
        height: 44px;
        line-height: 44px; } }
  pds-card .pds-card-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 5px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 130px;
    max-width: 320px;
    min-width: 156px;
    background-color: #FFFFFF;
    border-radius: 4px; }
  pds-card.pds-is-selected .pds-card-content {
    background-color: oldlace; }
  pds-card .pds-card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  pds-card .pds-card-metrics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    pds-card .pds-card-metrics:hover {
      cursor: pointer; }
  pds-card .pds-card-header {
    text-align: start;
    height: 26px;
    vertical-align: middle;
    border-bottom: 2px solid #C4C9CA;
    font-size: 10px;
    margin: 5px 10px; }
  pds-card .pds-card-title {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 34px;
            flex: 0 0 34px;
    font-size: 12px;
    font-weight: 600;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    padding-top: 10px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 10px;
            padding-inline-end: 10px; }
  pds-card .pds-card-header + .pds-card-title {
    padding-top: 0; }
  pds-card .pds-card-visualization {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 60px;
            flex: 0 0 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  pds-card .pds-card-metrics {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 36px;
            flex: 0 0 36px;
    font-size: 12px;
    color: #677274; }
    pds-card .pds-card-metrics pds-icon {
      width: 18px;
      height: 18px; }

pds-card.pds-card-assignment .pds-card-title {
  -ms-flex-preferred-size: 24px;
      flex-basis: 24px; }

pds-card.pds-card-assignment .pds-card-visualization {
  -ms-flex-preferred-size: 50px;
      flex-basis: 50px; }

pds-card.pds-card-assignment .pds-card-metrics {
  -ms-flex-preferred-size: 30px;
      flex-basis: 30px; }

pds-date-visualization {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  background-color: #0075DB; }
  pds-date-visualization > div:first-child {
    line-height: 1.5em;
    color: #FFFFFF;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    font-weight: 700;
    font-size: 11px; }
  pds-date-visualization > div:last-child {
    line-height: 1.5em;
    border-radius: 1px;
    background-color: #FFFFFF;
    margin: 0 6px 8px;
    font-weight: 700;
    font-size: 18px; }
  pds-date-visualization.small-size {
    height: 42px;
    width: 42px; }
    pds-date-visualization.small-size > div:last-child {
      line-height: 1.2em; }

pds-item-quick-view {
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  min-height: 375px;
  display: none;
  background-color: #333333; }
  pds-item-quick-view button.pds-button-round pds-icon {
    fill: #FFFFFF; }
  pds-item-quick-view button.pds-button-round:hover pds-icon, pds-item-quick-view button.pds-button-round:focus pds-icon {
    fill: #677274; }
  pds-item-quick-view.pds-show-quick-view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  pds-item-quick-view hr {
    margin-bottom: 1em; }
  pds-item-quick-view .pds-quick-view-links {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 15px;
    -webkit-padding-end: 5px;
            padding-inline-end: 5px;
    padding-bottom: 15px;
    -webkit-padding-start: 25px;
            padding-inline-start: 25px; }
    pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
    pds-item-quick-view .pds-quick-view-links pds-popover-menu {
      overflow: hidden; }
    pds-item-quick-view .pds-quick-view-links pds-popover {
      width: 100%; }
      pds-item-quick-view .pds-quick-view-links pds-popover .pds-popover-trigger-content {
        -webkit-padding-start: 0;
                padding-inline-start: 0; }
    pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button {
      border: none;
      background: transparent; }
      pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button span.pds-popover-button-contents {
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        max-width: 210px; }
        pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button span.pds-popover-button-contents pds-icon {
          -webkit-padding-start: 0px;
                  padding-inline-start: 0px; }
        pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button span.pds-popover-button-contents span {
          -webkit-padding-end: 0px;
                  padding-inline-end: 0px;
          -webkit-padding-start: 0px;
                  padding-inline-start: 0px;
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
          font-weight: 600;
          white-space: normal; }
      pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button:hover, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button.pds-panel-floating-trigger.pds-is-active:hover {
        text-decoration: none;
        color: #000000;
        background-color: #F1F4F4;
        border-radius: 2px; }
    pds-item-quick-view .pds-quick-view-links a, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 600;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      padding: 5px;
      margin-top: 15px;
      margin-bottom: 15px;
      height: auto; }
      pds-item-quick-view .pds-quick-view-links a span, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 160px;
        display: block; }
      pds-item-quick-view .pds-quick-view-links a span, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button span {
        white-space: normal; }
      pds-item-quick-view .pds-quick-view-links a pds-icon svg, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button pds-icon svg {
        font-size: 20px;
        -webkit-margin-end: 20px;
                margin-inline-end: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
      pds-item-quick-view .pds-quick-view-links a pds-icon, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button pds-icon {
        fill: #FFFFFF; }
      pds-item-quick-view .pds-quick-view-links a:hover, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button:hover {
        text-decoration: none;
        color: #000000;
        background-color: #F1F4F4;
        border-radius: 2px; }
        pds-item-quick-view .pds-quick-view-links a:hover pds-icon, pds-item-quick-view .pds-quick-view-links pds-popover-menu.pds-quick-view-link pds-popover button:hover pds-icon {
          fill: #677274; }
    pds-item-quick-view .pds-quick-view-links .pds-more {
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      pds-item-quick-view .pds-quick-view-links .pds-more span {
        -webkit-border-start: 1px solid #F1F4F4;
                border-inline-start: 1px solid #F1F4F4;
        height: 30px;
        -webkit-margin-end: 15px;
                margin-inline-end: 15px; }
    pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more {
      display: none; }
    pds-item-quick-view .pds-quick-view-links .pds-submenu-panel-container [data-tippy-root] {
      display: none; }
    @media (max-width: 768px) {
      pds-item-quick-view .pds-quick-view-links {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-flex: 1;
              -ms-flex: 1 1 auto;
                  flex: 1 1 auto;
          max-width: calc(~"100vw - 56px"); }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link {
            display: none;
            max-width: calc(~"33% - 40px"); }
            pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link a {
              overflow: hidden; }
            pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link pds-popover-menu.pds-quick-view-link pds-popover button {
              overflow: hidden; }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link:nth-child(1), pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link:nth-child(2), pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link:nth-child(3) {
            display: inherit;
            -webkit-margin-end: 40px;
                    margin-inline-end: 40px; }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container.pds-quick-view-over-3 {
            max-width: calc(~"100vw - 100px"); }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container[data-total-links='1'] > .pds-quick-view-link {
            max-width: calc(~"100% - 40px"); }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container[data-total-links='2'] > .pds-quick-view-link {
            max-width: calc(~"50% - 40px"); }
        pds-item-quick-view .pds-quick-view-links .pds-quick-view-over-3 ~ .pds-more {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more {
          position: absolute;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          inset-inline-end: 15px;
          bottom: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          padding: 15px; }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link {
            display: block; }
          pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link:nth-child(1), pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link:nth-child(2), pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link:nth-child(3) {
            display: none; }
        pds-item-quick-view .pds-quick-view-links .pds-submenu-panel-container [data-tippy-root] {
          display: none; } }
    @media (max-width: 576px) {
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link:nth-child(3) {
        display: none; }
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > [data-tippy-root] {
        display: none; }
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container > .pds-quick-view-link {
        display: none;
        max-width: calc(~"50% - 40px"); }
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-link-container.pds-quick-view-over-2 {
        max-width: calc(~"100vw - 100px"); }
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link:nth-child(3) {
        display: inherit; }
        pds-item-quick-view .pds-quick-view-links .pds-quick-view-links-more .pds-quick-view-link:nth-child(3) pds-popover-menu {
          display: inherit; }
      pds-item-quick-view .pds-quick-view-links .pds-submenu-panel-container [data-tippy-root] {
        display: inherit; }
      pds-item-quick-view .pds-quick-view-links .pds-quick-view-over-2 ~ .pds-more {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
  pds-item-quick-view .pds-quick-view-main {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    pds-item-quick-view .pds-quick-view-main h2, pds-item-quick-view .pds-quick-view-main h3 {
      font-size: 12px;
      font-weight: 600;
      color: #333333;
      padding: 0; }
    pds-item-quick-view .pds-quick-view-main header {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 40px;
              flex: 0 0 40px;
      background-color: #F1F4F4;
      border-bottom: 1px solid #C4C9CA;
      border-start-start-radius: 4px;
      border-start-end-radius: 4px;
      padding: 0 10px; }
      pds-item-quick-view .pds-quick-view-main header + section {
        border-start-start-radius: 0;
        border-start-end-radius: 0; }
    pds-item-quick-view .pds-quick-view-main.pds-content-long {
      height: 375px; }
      pds-item-quick-view .pds-quick-view-main.pds-content-long > section {
        overflow-y: auto;
        -ms-flex-preferred-size: 0px;
            flex-basis: 0px; }
    pds-item-quick-view .pds-quick-view-main section {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      background-color: white;
      border-radius: 4px; }
      pds-item-quick-view .pds-quick-view-main section .pds-glance-indicators {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 150px;
                flex: 0 0 150px;
        -webkit-border-end: 1px solid #C4C9CA;
                border-inline-end: 1px solid #C4C9CA;
        padding: 10px; }
        pds-item-quick-view .pds-quick-view-main section .pds-glance-indicators h3 {
          margin-bottom: 10px; }
      pds-item-quick-view .pds-quick-view-main section .pds-quick-view-content {
        padding: 10px;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1; }
        pds-item-quick-view .pds-quick-view-main section .pds-quick-view-content h3 {
          -webkit-margin-start: 0;
                  margin-inline-start: 0; }
      @media (max-width: 768px) {
        pds-item-quick-view .pds-quick-view-main section {
          overflow-y: auto;
          -ms-flex-preferred-size: 0px;
              flex-basis: 0px; } }
      @media (max-width: 1200px) {
        pds-item-quick-view .pds-quick-view-main section .pds-quick-view-content {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 auto;
                  flex: 1 1 auto; }
          pds-item-quick-view .pds-quick-view-main section .pds-quick-view-content .pds-icon-list {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column; }
            pds-item-quick-view .pds-quick-view-main section .pds-quick-view-content .pds-icon-list .pds-icon-labeled {
              width: auto; } }
    @media (max-width: 768px) {
      pds-item-quick-view .pds-quick-view-main {
        padding: 0 15px; } }
  pds-item-quick-view .pds-quick-view-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 54px;
            flex: 0 0 54px; }
    @media (max-width: 768px) {
      pds-item-quick-view .pds-quick-view-close {
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end; } }
  pds-item-quick-view .pds-quick-view-close button,
  pds-item-quick-view .pds-more button {
    border: none;
    background-color: #333333;
    margin: 15px;
    width: 32px;
    height: 32px;
    line-height: 25px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 10px;
            padding-inline-end: 10px; }
    @media (max-width: 768px) {
      pds-item-quick-view .pds-quick-view-close button,
      pds-item-quick-view .pds-more button {
        width: 44px;
        height: 44px;
        margin: 5px;
        -webkit-margin-end: 15px;
                margin-inline-end: 15px; } }
  pds-item-quick-view.pds-with-arrow::before {
    content: ' ';
    position: absolute;
    top: -8px;
    inset-inline-start: calc(~"50% - 12px");
    inset-inline-end: calc(~"50% - 12px");
    -webkit-border-start: 12px solid transparent;
            border-inline-start: 12px solid transparent;
    -webkit-border-end: 12px solid transparent;
            border-inline-end: 12px solid transparent;
    border-bottom: 8px solid #333333; }
  @media (max-width: 768px) {
    pds-item-quick-view {
      position: fixed;
      top: 0 !important;
      height: 100vh;
      z-index: 8000;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }

th.pds-active-row td {
  padding-bottom: 400px; }

.pds-glance-indicator {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 600; }
  .pds-glance-indicator > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-glance-indicator > div pds-icon {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }
      .pds-glance-indicator > div pds-icon svg:first-of-type {
        display: none; }
    .pds-glance-indicator > div span {
      font-size: 24px;
      width: 100%;
      height: 100%; }
  .pds-glance-indicator footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 10px;
    color: #677274;
    margin: 5px 15px 0; }

pds-selection-toolbar {
  display: block;
  background-color: #FFFFFF; }

.pds-page-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* Styles apply to direct descendant buttons only to
  avoid applying styles to buttons that open up
  in dialogs/modals. To apply styles to buttons within
  a container, add the pds-page-control-button class. */
  margin-bottom: 15px; }
  .pds-page-controls .pds-action-buttons {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .pds-page-controls .pds-action-buttons button {
      margin: 0;
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }
  .pds-page-controls .pds-view-select {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .pds-page-controls .pds-page-options {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .pds-page-controls .pds-page-options > button,
  .pds-page-controls .pds-page-options pds-grid-widget-filter-button > button,
  .pds-page-controls .pds-page-options .pds-popover-trigger > button:not(.pds-is-active),
  .pds-page-controls .pds-page-options > pds-immersive-reader > button, .pds-page-controls .pds-page-options-left > button,
  .pds-page-controls .pds-page-options-left pds-grid-widget-filter-button > button,
  .pds-page-controls .pds-page-options-left .pds-popover-trigger > button:not(.pds-is-active),
  .pds-page-controls .pds-page-options-left > pds-immersive-reader > button, .pds-page-controls .pds-page-options-right > button,
  .pds-page-controls .pds-page-options-right pds-grid-widget-filter-button > button,
  .pds-page-controls .pds-page-options-right .pds-popover-trigger > button:not(.pds-is-active),
  .pds-page-controls .pds-page-options-right > pds-immersive-reader > button, .pds-page-controls .pds-page-options button.pds-page-control-button {
    background-color: transparent;
    border: none;
    margin-top: 0;
    margin-bottom: 0; }
    .pds-page-controls .pds-page-options > button:hover,
    .pds-page-controls .pds-page-options pds-grid-widget-filter-button > button:hover,
    .pds-page-controls .pds-page-options .pds-popover-trigger > button:hover:not(.pds-is-active),
    .pds-page-controls .pds-page-options > pds-immersive-reader > button:hover, .pds-page-controls .pds-page-options-left > button:hover,
    .pds-page-controls .pds-page-options-left pds-grid-widget-filter-button > button:hover,
    .pds-page-controls .pds-page-options-left .pds-popover-trigger > button:hover:not(.pds-is-active),
    .pds-page-controls .pds-page-options-left > pds-immersive-reader > button:hover, .pds-page-controls .pds-page-options-right > button:hover,
    .pds-page-controls .pds-page-options-right pds-grid-widget-filter-button > button:hover,
    .pds-page-controls .pds-page-options-right .pds-popover-trigger > button:hover:not(.pds-is-active),
    .pds-page-controls .pds-page-options-right > pds-immersive-reader > button:hover, .pds-page-controls .pds-page-options button.pds-page-control-button:hover {
      background-color: #FFFFFF; }
    .pds-page-controls .pds-page-options > button.pds-is-active,
    .pds-page-controls .pds-page-options pds-grid-widget-filter-button > button.pds-is-active,
    .pds-page-controls .pds-page-options .pds-popover-trigger > button.pds-is-active:not(.pds-is-active),
    .pds-page-controls .pds-page-options > pds-immersive-reader > button.pds-is-active, .pds-page-controls .pds-page-options-left > button.pds-is-active,
    .pds-page-controls .pds-page-options-left pds-grid-widget-filter-button > button.pds-is-active,
    .pds-page-controls .pds-page-options-left .pds-popover-trigger > button.pds-is-active:not(.pds-is-active),
    .pds-page-controls .pds-page-options-left > pds-immersive-reader > button.pds-is-active, .pds-page-controls .pds-page-options-right > button.pds-is-active,
    .pds-page-controls .pds-page-options-right pds-grid-widget-filter-button > button.pds-is-active,
    .pds-page-controls .pds-page-options-right .pds-popover-trigger > button.pds-is-active:not(.pds-is-active),
    .pds-page-controls .pds-page-options-right > pds-immersive-reader > button.pds-is-active, .pds-page-controls .pds-page-options button.pds-is-active.pds-page-control-button {
      color: #0075DB; }
      .pds-page-controls .pds-page-options > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options pds-grid-widget-filter-button > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options .pds-popover-trigger > button.pds-is-active:not(.pds-is-active) .pds-icon, .pds-page-controls .pds-page-options > pds-immersive-reader > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-left > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-left pds-grid-widget-filter-button > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button.pds-is-active:not(.pds-is-active) .pds-icon, .pds-page-controls .pds-page-options-left > pds-immersive-reader > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-right > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-right pds-grid-widget-filter-button > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button.pds-is-active:not(.pds-is-active) .pds-icon, .pds-page-controls .pds-page-options-right > pds-immersive-reader > button.pds-is-active .pds-icon, .pds-page-controls .pds-page-options button.pds-is-active.pds-page-control-button .pds-icon {
        fill: #0075DB; }
    .pds-page-controls .pds-page-options > button pds-icon, .pds-page-controls .pds-page-options pds-grid-widget-filter-button > button pds-icon, .pds-page-controls .pds-page-options .pds-popover-trigger > button:not(.pds-is-active) pds-icon, .pds-page-controls .pds-page-options > pds-immersive-reader > button pds-icon, .pds-page-controls .pds-page-options-left > button pds-icon, .pds-page-controls .pds-page-options-left pds-grid-widget-filter-button > button pds-icon, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button:not(.pds-is-active) pds-icon, .pds-page-controls .pds-page-options-left > pds-immersive-reader > button pds-icon, .pds-page-controls .pds-page-options-right > button pds-icon, .pds-page-controls .pds-page-options-right pds-grid-widget-filter-button > button pds-icon, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button:not(.pds-is-active) pds-icon, .pds-page-controls .pds-page-options-right > pds-immersive-reader > button pds-icon, .pds-page-controls .pds-page-options button.pds-page-control-button pds-icon {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px;
      -webkit-margin-start: 0;
              margin-inline-start: 0; }
  .pds-page-controls .pds-page-options .pds-popover-trigger > button, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button {
    margin-top: 0;
    margin-bottom: 0; }
  .pds-page-controls .pds-page-options .pds-popover-trigger > button.pds-fields:not(.pds-button-square) span, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button.pds-fields:not(.pds-button-square) span, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button.pds-fields:not(.pds-button-square) span {
    -webkit-padding-start: 0px;
            padding-inline-start: 0px; }
  .pds-page-controls .pds-page-options .pds-popover-trigger > button.pds-fields:not(.pds-button-square) pds-icon, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button.pds-fields:not(.pds-button-square) pds-icon, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button.pds-fields:not(.pds-button-square) pds-icon {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px; }
  .pds-page-controls .pds-page-options .pds-popover-trigger > button.pds-button-square:not(.pds-is-active) pds-icon, .pds-page-controls .pds-page-options-left .pds-popover-trigger > button.pds-button-square:not(.pds-is-active) pds-icon, .pds-page-controls .pds-page-options-right .pds-popover-trigger > button.pds-button-square:not(.pds-is-active) pds-icon {
    -webkit-margin-end: 0px;
            margin-inline-end: 0px; }
  .pds-page-controls .pds-page-settings {
    padding: 5px;
    z-index: 7000;
    background-color: #FFFFFF;
    position: absolute;
    top: 100%;
    inset-inline-end: 0;
    -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5); }
    .pds-page-controls .pds-page-settings:hover {
      cursor: pointer; }

.pds-progress-bar-widget-content .pds-progress-bar-max-progress {
  background-color: #C4C9CA;
  border-radius: 4px;
  height: 10px;
  width: 100%; }

.pds-progress-bar-widget-content .pds-progress-bar-current-progress {
  background-color: #0075DB;
  border-radius: 4px;
  height: 10px;
  width: 0%;
  max-width: 100%; }

.pds-progress-bar-widget-content .pds-progress-bar-text {
  color: #4D5557;
  font-size: 14pt;
  margin: 16px auto 0;
  text-align: center; }

.pds-quick-link-card, .pds-report-link-card, .pds-setting-link-card {
  width: 250px;
  height: 60px;
  border-radius: 4px;
  margin-top: 0;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
  margin-bottom: 20px;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  background: white;
  color: #333333;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px; }
  .pds-quick-link-card:hover, .pds-report-link-card:hover, .pds-quick-link-card:active, .pds-setting-link-card:active, .pds-setting-link-card:hover {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none;
    -webkit-box-shadow: 0 1px 4px #677274;
            box-shadow: 0 1px 4px #677274; }

.pds-report-link-card:active {
  border: 1px solid #0075DB; }

.pds-quick-link-card:active {
  border: 1px solid #8FE5FF; }

.pds-setting-link-card:active {
  border: 1px solid #BB8CCF; }

.pds-link-card-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px; }

.pds-link-card-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 190px;
          flex: 0 0 190px;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-padding-end: 2px;
          padding-inline-end: 2px; }

.pds-link-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 15px; }

.pds-link-list-header {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #677274; }
  .pds-link-list-header:after {
    top: 50%;
    content: '';
    display: block;
    position: absolute;
    border-bottom: 1px solid #C4C9CA;
    width: 100%;
    height: auto;
    z-index: 1; }
  .pds-link-list-header span {
    display: inline-block;
    position: relative;
    background: #F1F4F4;
    padding: 0;
    -webkit-padding-end: 2rem;
            padding-inline-end: 2rem;
    z-index: 2; }

pds-selection-toolbar .pds-banner {
  background: #0075DB;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF; }
  pds-selection-toolbar .pds-banner .pds-toolbar-close {
    font-size: 22px; }
  pds-selection-toolbar .pds-banner > span {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: 600; }
  pds-selection-toolbar .pds-banner label {
    color: #FFFFFF; }
  pds-selection-toolbar .pds-banner [type='checkbox']:checked ~ .pds-label-text:first-of-type:before,
  pds-selection-toolbar .pds-banner [type='checkbox']:not(:checked) ~ .pds-label-text:first-of-type:before {
    width: 20px;
    height: 20px;
    top: 12px;
    border-color: #FFFFFF; }
  pds-selection-toolbar .pds-banner [type='checkbox']:checked ~ .pds-label-text:first-of-type:after,
  pds-selection-toolbar .pds-banner [type='checkbox']:not(:checked) ~ .pds-label-text:first-of-type:after {
    top: 14px;
    inset-inline-start: -33px; }
  pds-selection-toolbar .pds-banner .pds-label-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  pds-selection-toolbar .pds-banner .pds-selection-toolbar-stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0 20px; }
    pds-selection-toolbar .pds-banner .pds-selection-toolbar-stats span {
      margin: 0;
      -webkit-margin-end: 10px;
              margin-inline-end: 10px;
      -webkit-margin-start: 30px;
              margin-inline-start: 30px;
      color: #0075DB;
      font-weight: 600; }
  pds-selection-toolbar .pds-banner .pds-toolbar-close {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    pds-selection-toolbar .pds-banner .pds-toolbar-close button {
      border: none;
      margin: 0;
      color: #A8B1B2; }

table.pds-data-list pds-item-controls, ul.pds-data-list pds-item-controls {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0; }
  table.pds-data-list pds-item-controls > div:first-of-type > button, ul.pds-data-list pds-item-controls > div:first-of-type > button, table.pds-data-list pds-item-controls > div:first-of-type button[type=button], ul.pds-data-list pds-item-controls > div:first-of-type button[type=button], table.pds-data-list pds-item-controls > div:first-of-type pds-popover-menu, ul.pds-data-list pds-item-controls > div:first-of-type pds-popover-menu, table.pds-data-list pds-item-controls > div:first-of-type pds-popover-menu div.pds-tooltip-trigger button, ul.pds-data-list pds-item-controls > div:first-of-type pds-popover-menu div.pds-tooltip-trigger button {
    border-start-start-radius: 4px; }
  table.pds-data-list pds-item-controls > div:last-of-type > button, ul.pds-data-list pds-item-controls > div:last-of-type > button, table.pds-data-list pds-item-controls > div:last-of-type button[type=button], ul.pds-data-list pds-item-controls > div:last-of-type button[type=button], table.pds-data-list pds-item-controls > div:last-of-type pds-popover-menu, ul.pds-data-list pds-item-controls > div:last-of-type pds-popover-menu, table.pds-data-list pds-item-controls > div:last-of-type pds-popover-menu div.pds-tooltip-trigger button, ul.pds-data-list pds-item-controls > div:last-of-type pds-popover-menu div.pds-tooltip-trigger button {
    border-start-end-radius: 4px; }

table.pds-data-list-hoverable-rows tbody tr {
  cursor: pointer; }
  table.pds-data-list-hoverable-rows tbody tr:hover, table.pds-data-list-hoverable-rows tbody tr.pds-item-controls-visible {
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
  table.pds-data-list-hoverable-rows tbody tr.pds-item-controls-dismissed:hover pds-item-controls {
    display: none; }

table.pds-data-list {
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: -4px; }
  table.pds-data-list thead tr {
    background: transparent; }
  table.pds-data-list thead th.pds-is-active-column {
    color: #0075DB;
    background-color: #C2E2FF; }
  table.pds-data-list tbody tr {
    border-radius: 4px; }
    table.pds-data-list tbody tr:hover pds-item-controls, table.pds-data-list tbody tr.pds-item-controls-visible pds-item-controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      table.pds-data-list tbody tr:hover pds-item-controls > div > button, table.pds-data-list tbody tr.pds-item-controls-visible pds-item-controls > div > button {
        max-width: 60px; }
    table.pds-data-list tbody tr th, table.pds-data-list tbody tr td {
      padding: 5px 4px 3px;
      height: 36px;
      background-color: #FFFFFF;
      -webkit-border-start: none;
              border-inline-start: none;
      -webkit-border-end: none;
              border-inline-end: none;
      border-top: 1px solid transparent;
      border-bottom: 2px solid transparent; }
      @media (max-width: 768px) {
        table.pds-data-list tbody tr th, table.pds-data-list tbody tr td {
          padding: 6px 4px 2px; } }
      table.pds-data-list tbody tr th:first-child, table.pds-data-list tbody tr td:first-child {
        border-radius: 0;
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
        -webkit-border-end: none;
                border-inline-end: none;
        -webkit-border-start: 1px solid transparent;
                border-inline-start: 1px solid transparent;
        -webkit-padding-start: 15px;
                padding-inline-start: 15px; }
      table.pds-data-list tbody tr th:last-child, table.pds-data-list tbody tr td:last-child {
        border-radius: 0;
        border-start-end-radius: 4px;
        border-end-end-radius: 4px;
        -webkit-border-start: none;
                border-inline-start: none;
        -webkit-border-end: 1px solid transparent;
                border-inline-end: 1px solid transparent; }
      table.pds-data-list tbody tr th.pds-content-and-controls > div, table.pds-data-list tbody tr td.pds-content-and-controls > div {
        position: relative; }
      table.pds-data-list tbody tr th.pds-is-active-column, table.pds-data-list tbody tr td.pds-is-active-column {
        background-color: #C2E2FF; }
    table.pds-data-list tbody tr.pds-is-selected td, table.pds-data-list tbody tr.pds-is-selected th {
      background-color: oldlace; }
    table.pds-data-list tbody tr.pds-is-active:hover {
      -webkit-box-shadow: none;
              box-shadow: none; }
      table.pds-data-list tbody tr.pds-is-active:hover pds-item-controls {
        display: none; }
    table.pds-data-list tbody tr.pds-is-active td, table.pds-data-list tbody tr.pds-is-active th {
      border-top: 1px solid #C4C9CA;
      border-bottom: 1px solid #C4C9CA; }
      table.pds-data-list tbody tr.pds-is-active td:first-child, table.pds-data-list tbody tr.pds-is-active th:first-child {
        -webkit-border-start: 1px solid #C4C9CA;
                border-inline-start: 1px solid #C4C9CA; }
      table.pds-data-list tbody tr.pds-is-active td:last-child, table.pds-data-list tbody tr.pds-is-active th:last-child {
        -webkit-border-end: 1px solid #C4C9CA;
                border-inline-end: 1px solid #C4C9CA; }
    table.pds-data-list tbody tr.pds-quick-view-spacer td {
      height: 385px;
      visibility: hidden; }
    table.pds-data-list tbody tr.pds-is-active-row {
      -webkit-box-shadow: 0 0 0 2px #0075DB;
              box-shadow: 0 0 0 2px #0075DB; }
      table.pds-data-list tbody tr.pds-is-active-row td {
        background-color: #C2E2FF; }
      table.pds-data-list tbody tr.pds-is-active-row td.pds-is-active-column {
        background-color: white;
        -webkit-box-shadow: inset 0 0 4px #0075DB;
                box-shadow: inset 0 0 4px #0075DB;
        border: solid 1px #0075DB; }

.pds-panel table.pds-data-list tbody tr td, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr td, .pds-panel table.pds-data-list tbody tr th, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr th {
  border-top: 1px solid #838E91;
  border-bottom: 1px solid #838E91; }
  .pds-panel table.pds-data-list tbody tr td:first-child, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr td:first-child, .pds-panel table.pds-data-list tbody tr th:first-child, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr th:first-child {
    -webkit-border-start: 1px solid #838E91;
            border-inline-start: 1px solid #838E91; }
  .pds-panel table.pds-data-list tbody tr td:last-child, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr td:last-child, .pds-panel table.pds-data-list tbody tr th:last-child, .pds-panel-floating-trigger > .pds-panel-floating table.pds-data-list tbody tr th:last-child {
    -webkit-border-end: 1px solid #838E91;
            border-inline-end: 1px solid #838E91; }

pds-item-controls {
  display: none;
  position: absolute;
  bottom: 0;
  inset-inline-start: auto;
  inset-inline-end: 3px;
  padding: 0;
  width: auto; }
  pds-item-controls.pds-is-panel-open, pds-item-controls:hover {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  pds-item-controls .pds-item-control-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 60px; }
    pds-item-controls .pds-item-control-contents .pds-popover-trigger-content {
      -webkit-padding-start: 0;
              padding-inline-start: 0; }
    pds-item-controls .pds-item-control-contents pds-popover-menu, pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger, pds-item-controls .pds-item-control-contents button.pds-panel-floating-trigger {
      width: 100%; }
      pds-item-controls .pds-item-control-contents pds-popover-menu span.pds-popover-button-contents pds-icon, pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger span.pds-popover-button-contents pds-icon, pds-item-controls .pds-item-control-contents button.pds-panel-floating-trigger span.pds-popover-button-contents pds-icon {
        -webkit-padding-start: 0;
                padding-inline-start: 0; }
    pds-item-controls .pds-item-control-contents button, pds-item-controls .pds-item-control-contents button[type=button] {
      height: 32px;
      line-height: 32px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      border-radius: 0;
      border-bottom: 0;
      border-top: 0;
      padding: 0;
      margin: 0;
      width: auto;
      min-width: 0; }
      @media (max-width: 768px) {
        pds-item-controls .pds-item-control-contents button, pds-item-controls .pds-item-control-contents button[type=button] {
          height: 44px;
          line-height: 44px; } }
      pds-item-controls .pds-item-control-contents button i, pds-item-controls .pds-item-control-contents button pds-icon svg, pds-item-controls .pds-item-control-contents button[type=button] i, pds-item-controls .pds-item-control-contents button[type=button] pds-icon svg {
        width: 20px;
        height: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
    pds-item-controls .pds-item-control-contents:first-of-type pds-popover-menu, pds-item-controls .pds-item-control-contents:first-of-type pds-popover-menu div.pds-tooltip-trigger, pds-item-controls .pds-item-control-contents:first-of-type button, pds-item-controls .pds-item-control-contents:first-of-type button[type=button] {
      border-end-start-radius: 4px;
      -webkit-border-start: 0;
              border-inline-start: 0; }
    pds-item-controls .pds-item-control-contents:last-of-type pds-popover-menu, pds-item-controls .pds-item-control-contents:last-of-type pds-popover-menu div.pds-tooltip-trigger, pds-item-controls .pds-item-control-contents:last-of-type button, pds-item-controls .pds-item-control-contents:last-of-type button[type=button], pds-item-controls .pds-item-control-contents pds-popover-menu:last-of-type pds-popover-menu, pds-item-controls .pds-item-control-contents pds-popover-menu:last-of-type pds-popover-menu div.pds-tooltip-trigger, pds-item-controls .pds-item-control-contents pds-popover-menu:last-of-type button, pds-item-controls .pds-item-control-contents pds-popover-menu:last-of-type button[type=button], pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger:last-of-type pds-popover-menu, pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger:last-of-type pds-popover-menu div.pds-tooltip-trigger, pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger:last-of-type button, pds-item-controls .pds-item-control-contents pds-popover-menu div.pds-tooltip-trigger:last-of-type button[type=button] {
      border-end-end-radius: 4px;
      -webkit-border-end: 0;
              border-inline-end: 0; }

pds-card-stack pds-item-controls {
  inset-inline-start: 0;
  inset-inline-end: 0; }

.pds-reporting-term-select-container select {
  height: 32px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 91px;
          flex: 0 1 91px;
  background-size: 32px 32px;
  padding: 5px;
  -webkit-padding-end: 40px;
          padding-inline-end: 40px;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px; }
  .pds-reporting-term-select-container select:focus:not([disabled]) {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #C2E2FF; }

.pds-search-container {
  position: relative;
  width: 40px;
  height: 60px; }
  .pds-search-container .pds-search-container-inner {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    z-index: 5500; }
    .pds-search-container .pds-search-container-inner pds-icon {
      fill: #FFFFFF; }
    .pds-search-container .pds-search-container-inner .search-message-container {
      padding: 5px; }
    .pds-search-container .pds-search-container-inner > .pds-search-input {
      position: relative;
      height: 100%; }
      .pds-search-container .pds-search-container-inner > .pds-search-input > input {
        -webkit-transition: none;
        transition: none;
        border-radius: 4px;
        margin: 0;
        cursor: pointer;
        border: none;
        opacity: 0;
        width: 0;
        height: 0; }
        .pds-search-container .pds-search-container-inner > .pds-search-input > input:focus, .pds-search-container .pds-search-container-inner > .pds-search-input > input.expanded {
          width: 250px;
          height: 44px;
          opacity: 1;
          border: 1px solid #F1F4F4;
          -webkit-padding-end: 44px;
                  padding-inline-end: 44px;
          cursor: text;
          -webkit-transition: width .25s ease-in-out;
          transition: width .25s ease-in-out;
          position: absolute;
          inset-inline-end: 0;
          top: 50%;
          margin-top: -22px; }
      .pds-search-container .pds-search-container-inner > .pds-search-input > a {
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        cursor: pointer;
        color: #0075DB;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .pds-search-container .pds-search-container-inner > .dropdown-arrow {
      position: absolute;
      top: calc(~"100% - 10px");
      inset-inline-end: 125px; }
      .pds-search-container .pds-search-container-inner > .dropdown-arrow::before, .pds-search-container .pds-search-container-inner > .dropdown-arrow::after {
        content: '';
        position: absolute;
        border: solid transparent; }
      .pds-search-container .pds-search-container-inner > .dropdown-arrow::before {
        -webkit-border-start: 9px solid transparent;
                border-inline-start: 9px solid transparent;
        -webkit-border-end: 9px solid transparent;
                border-inline-end: 9px solid transparent;
        border-bottom: 9px solid #C4C9CA;
        inset-inline-start: -2px; }
      .pds-search-container .pds-search-container-inner > .dropdown-arrow::after {
        -webkit-border-start: 7px solid transparent;
                border-inline-start: 7px solid transparent;
        -webkit-border-end: 7px solid transparent;
                border-inline-end: 7px solid transparent;
        border-bottom: 7px solid #F1F4F4;
        top: 1px; }
    .pds-search-container .pds-search-container-inner > .pds-navigation-search {
      max-width: none;
      width: 350px;
      max-height: 70vh;
      overflow-y: auto;
      padding: 0;
      inset-inline-end: 0;
      inset-inline-start: auto;
      top: calc(~"100% + 1px"); }
      @media (max-width: 576px) {
        .pds-search-container .pds-search-container-inner > .pds-navigation-search {
          width: calc(~"100vw - 20px"); } }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search ul {
        font-size: 16px; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search ul li:first-of-type,
        .pds-search-container .pds-search-container-inner > .pds-navigation-search ul li:last-of-type {
          border-bottom: none; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search li > ul {
        -webkit-margin-end: 0;
                margin-inline-end: 0; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search li > ul > li pds-icon {
          fill: #677274; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search ul > li:not(.section-header) {
        border-bottom: 0; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search ul > li.section-header {
        border-bottom: 0.5px solid #C4C9CA;
        font-size: 12px;
        font-weight: 700;
        padding: .5rem .5rem;
        background: none; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 10px 10px;
        color: black; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-user-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-user-icon {
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          height: 25px;
          width: 25px;
          display: inline-block;
          margin: 0;
          background-image: url("../../../../scripts/pds/img/user-s.svg"); }
          button .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-user-icon, button .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-user-icon {
            -webkit-margin-start: 10px;
                    margin-inline-start: 10px; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-result-name, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-result-name {
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px;
          -webkit-box-flex: 1;
              -ms-flex: 1 1 auto;
                  flex: 1 1 auto; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-icon-dropped-student-f, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-icon-dropped-student-f {
          margin: 0;
          -webkit-margin-end: 5px;
                  margin-inline-end: 5px;
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px;
          height: 20px;
          width: 20px; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile:hover, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link:hover {
          background-color: #F1F4F4; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile img, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link img {
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          height: 25px;
          width: 25px;
          display: inline-block;
          margin: 0;
          border-radius: 50%; }
          button .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile img, button .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link img {
            -webkit-margin-start: 10px;
                    margin-inline-start: 10px; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-btn, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-quick-message-btn, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-favorite-btn, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-btn, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-quick-message-btn, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-favorite-btn {
          padding: 0;
          height: auto;
          width: auto;
          border: 0;
          background: transparent; }
          .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-quick-message-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-favorite-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-quick-message-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-favorite-btn pds-icon {
            height: 20px;
            width: 20px; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-quick-message-btn pds-icon, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-quick-message-btn pds-icon {
          fill: #A8B1B2; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-favorite-btn svg, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-favorite-btn svg {
          stroke: #677274;
          fill: transparent; }
        .pds-search-container .pds-search-container-inner > .pds-navigation-search .user-profile .pds-search-favorite-btn .pds-search-favorite svg, .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-favorite-btn .pds-search-favorite svg {
          fill: #F9CE33; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link .pds-search-link-icon svg {
        fill: #677274; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link[data-nav-group="QUICK_LINKS"] .pds-search-link-icon svg {
        fill: #52BA2B; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link[data-nav-group="SETTINGS"] .pds-search-link-icon svg {
        fill: #BB8CCF; }
      .pds-search-container .pds-search-container-inner > .pds-navigation-search .nav-link[data-nav-group="REPORTS"] .pds-search-link-icon svg {
        fill: #12B19F; }

pds-html-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  pds-html-content p,
  pds-html-content .pds-html-string {
    font-size: initial; }
  pds-html-content .pds-html-string {
    margin: 0;
    font-family: "Open Sans";
    line-height: 20px;
    color: #333333;
    overflow: hidden; }
  pds-html-content .pds-button-padding {
    -webkit-padding-end: 15px;
            padding-inline-end: 15px; }
  pds-html-content .pds-is-collapsed {
    max-height: 40px; }
  pds-html-content .pds-is-overflow {
    width: calc(~"100% - 50px"); }
  pds-html-content .pds-icon-pointer-up-2 {
    fill: #0075DB; }
  pds-html-content .pds-temp-div {
    visibility: hidden;
    overflow: visible;
    position: fixed; }
  pds-html-content button.pds-button-square {
    padding: 0;
    width: 25px;
    height: 25px;
    border: none; }

select {
  font-family: Open Sans, sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  text-transform: none;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none; }
  select optgroup {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    background: #C4C9CA;
    margin: 0; }
    select optgroup option {
      background: white; }
  select option {
    font-family: Open Sans, sans-serif;
    color: #333333;
    background-color: #FFFFFF;
    font-weight: normal; }

select::-ms-expand {
  display: none; }

select:focus {
  border-color: #8FE5FF; }

.pds-selection-status-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 350px;
  color: #FFFFFF;
  border-radius: 4px;
  height: 32px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #0075DB;
  padding: 3px 10px;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  margin-bottom: 10px; }
  @media (max-width: 768px) {
    .pds-selection-status-item {
      height: 44px;
      font-size: 18px;
      line-height: 27px; } }
  .pds-selection-status-item .pds-selection-status-primary {
    line-height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    display: block; }
  .pds-selection-status-item .pds-selection-status-secondary {
    border-radius: 4px;
    border: 1px solid #C4C9CA;
    font-size: 10px;
    padding: 1px 7px 1px;
    -webkit-margin-start: 5px;
            margin-inline-start: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    display: block;
    line-height: 1.5; }
  .pds-selection-status-item svg {
    height: 16px;
    width: 16px;
    fill: #FFFFFF; }
    @media (max-width: 768px) {
      .pds-selection-status-item svg {
        height: 20px;
        width: 20px; } }
  .pds-selection-status-item:hover:not([disabled]), .pds-selection-status-item:focus:not([disabled]) {
    background-color: #00427C;
    text-decoration: underline; }

.pds-filter-controls button.pds-selection-status-item {
  border: none;
  background-color: #0075DB;
  color: #FFFFFF;
  top: 0; }
  .pds-filter-controls button.pds-selection-status-item:hover {
    background-color: #00427C; }
  .pds-filter-controls button.pds-selection-status-item span.pds-selection-status-primary {
    position: unset;
    display: inline-block;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    text-align: start; }
  .pds-filter-controls button.pds-selection-status-item pds-icon {
    display: inline-block;
    text-align: end;
    height: 20px; }
    @media (max-width: 768px) {
      .pds-filter-controls button.pds-selection-status-item pds-icon {
        height: 22px; } }
    .pds-filter-controls button.pds-selection-status-item pds-icon svg {
      fill: #FFFFFF; }
  .pds-filter-controls button.pds-selection-status-item .pds-selection-status-secondary {
    border-color: #FFFFFF; }
  @media (max-width: 768px) {
    .pds-filter-controls button.pds-selection-status-item {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      width: auto; } }

.pds-selection-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 0;
  border: 1px solid #C4C9CA;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.pds-dialog-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px;
  background-color: #FFFFFF; }
  .pds-dialog-footer .pds-button-group-primary {
    margin-bottom: 0; }
  .pds-dialog-footer button {
    margin-bottom: 0; }
    .pds-dialog-footer button pds-icon {
      margin: auto; }

main > .pds-dialog-footer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

.pds-detail-screen-mode main .pds-content, .pds-detail-screen-pro-mode main .pds-content, .pds-page-has-tools-mode main .pds-content, .pds-detail-screen-mode main .pds-content-basic, .pds-detail-screen-pro-mode main .pds-content-basic, .pds-page-has-tools-mode main .pds-content-basic, .pds-detail-screen-mode main .pss-content, .pds-detail-screen-pro-mode main .pss-content, .pds-page-has-tools-mode main .pss-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0;
  overflow: hidden;
  height: 95vh; }

.pds-detail-screen, .pds-detail-screen-pro, .pds-page-has-tools-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%; }

.pds-detail-options, .pds-page-tools, .pds-page-tools-inverted {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
          flex: 0 0 250px;
  -webkit-border-end: 1px solid #838E91;
          border-inline-end: 1px solid #838E91;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.pds-detail-content, .pds-detail-pro-content, .pds-page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  height: 100%;
  max-height: 100%; }

.pds-detail-screen-mode main {
  background: #FFFFFF; }

.pds-detail-screen {
  width: 100vw; }

.pds-detail-options {
  background-color: #333333; }

.pds-detail-content .pds-global-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
  overflow: auto; }
  @media (max-width: 992px) {
    .pds-detail-content .pds-global-tabs {
      height: 91.5vh;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 10px; } }

@media (max-width: 992px) and (orientation: landscape) {
  .pds-detail-content .pds-global-tabs {
    height: 95%;
    margin-bottom: 22px; } }
  .pds-detail-content .pds-global-tabs .pds-tabs {
    border-top: none;
    -webkit-padding-start: 2rem;
            padding-inline-start: 2rem;
    text-align: start;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .pds-detail-content .pds-global-tabs .pds-tabs li a {
      font-weight: 600; }

.pds-detail-content .pds-tabs-panel.pds-is-active {
  background: #F1F4F4;
  overflow: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }

.pds-detail-visual {
  background-color: #FFFFFF;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 200px;
  position: relative;
  text-align: center;
  width: 250px; }

.pds-detail-visual:after {
  top: 100%;
  inset-inline-start: 50%;
  border: solid transparent;
  content: " ";
  width: 0;
  position: absolute;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #FFFFFF;
  border-width: 10px;
  -webkit-margin-start: -10px;
          margin-inline-start: -10px; }

.pds-detail-links {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  padding: 10px 5px 5px; }
  .pds-detail-links a {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    fill: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    -webkit-padding-end: 5px;
            padding-inline-end: 5px;
    -webkit-padding-start: 30px;
            padding-inline-start: 30px; }
    .pds-detail-links a span {
      display: block;
      width: 160px; }
    .pds-detail-links a pds-icon {
      -webkit-margin-end: 20px;
              margin-inline-end: 20px;
      fill: #FFFFFF; }
      .pds-detail-links a pds-icon svg {
        height: 20px;
        width: 20px; }
    .pds-detail-links a:hover {
      background-color: #F1F4F4;
      border-radius: 2px;
      color: #000000;
      fill: #000000;
      text-decoration: none; }

.pds-detail-screen-mode main .pds-content, .pds-detail-screen-pro-mode main .pds-content, .pds-page-has-tools-mode main .pds-content, .pds-detail-screen-mode main .pds-content-basic, .pds-detail-screen-pro-mode main .pds-content-basic, .pds-page-has-tools-mode main .pds-content-basic, .pds-detail-screen-mode main .pss-content, .pds-detail-screen-pro-mode main .pss-content, .pds-page-has-tools-mode main .pss-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0;
  overflow: hidden;
  height: 95vh; }

.pds-detail-screen, .pds-detail-screen-pro, .pds-page-has-tools-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%; }

.pds-detail-options, .pds-page-tools, .pds-page-tools-inverted {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
          flex: 0 0 250px;
  -webkit-border-end: 1px solid #838E91;
          border-inline-end: 1px solid #838E91;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.pds-detail-content, .pds-detail-pro-content, .pds-page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  height: 100%;
  max-height: 100%; }

.pds-app.pds-detail-screen-pro-mode {
  -ms-grid-columns: unset;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: 60px 0px 1fr;
  grid-template-rows: 60px 1fr;
      grid-template-areas: "pds-layout-header pds-layout-sidebar"
 "pds-layout-main pds-layout-sidebar"; }
  @media (max-width: 768px) {
    .pds-app.pds-detail-screen-pro-mode {
      -ms-grid-rows: 60px 1fr;
      grid-template-rows: 60px 1fr; } }

.pds-detail-screen-pro-mode {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .pds-detail-screen-pro-mode main {
    width: auto; }
    @media (max-width: 768px) {
      .pds-detail-screen-pro-mode main {
        width: auto; } }

.pds-detail-screen-pro {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.pds-detail-pro-content {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-detail-pro-content .pds-page-toolbar {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-detail-pro-content .pds-page-toolbar .pds-tabs {
      overflow: visible; }
    .pds-detail-pro-content .pds-page-toolbar button {
      -webkit-border-start: 1px solid #0075DB;
              border-inline-start: 1px solid #0075DB; }
      .pds-detail-pro-content .pds-page-toolbar button:first-child {
        -webkit-border-end: none;
                border-inline-end: none; }
    .pds-detail-pro-content .pds-page-toolbar li:hover {
      background-color: initial; }
  .pds-detail-pro-content .pds-page-content {
    overflow: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .pds-detail-pro-content .pds-tabs-panel {
    position: relative; }
  .pds-detail-pro-content .pds-page-controls {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    margin: 15px 0; }

.pds-detail-screen-mode main .pds-content, .pds-detail-screen-pro-mode main .pds-content, .pds-page-has-tools-mode main .pds-content, .pds-detail-screen-mode main .pds-content-basic, .pds-detail-screen-pro-mode main .pds-content-basic, .pds-page-has-tools-mode main .pds-content-basic, .pds-detail-screen-mode main .pss-content, .pds-detail-screen-pro-mode main .pss-content, .pds-page-has-tools-mode main .pss-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0;
  overflow: hidden;
  height: 95vh; }

.pds-detail-screen, .pds-detail-screen-pro, .pds-page-has-tools-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%; }

.pds-detail-options, .pds-page-tools, .pds-page-tools-inverted {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
          flex: 0 0 250px;
  -webkit-border-end: 1px solid #838E91;
          border-inline-end: 1px solid #838E91;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.pds-detail-content, .pds-detail-pro-content, .pds-page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  height: 100%;
  max-height: 100%; }

@media (max-width: 768px) {
  .pds-page-has-tools-layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; } }

.pds-page-tools, .pds-page-tools-inverted {
  overflow-y: auto;
  overflow-x: auto;
  background: white; }
  @media (max-width: 768px) {
    .pds-page-tools, .pds-page-tools-inverted {
      -webkit-box-flex: 0;
          -ms-flex: 0 2 auto;
              flex: 0 2 auto; } }

.pds-page-tools-inverted {
  color: #A8B1B2;
  background-color: #333333; }
  .pds-page-tools-inverted h3 {
    color: #FFFFFF; }
  .pds-page-tools-inverted .pds-panel, .pds-page-tools-inverted .pds-panel-floating-trigger > .pds-panel-floating {
    background: none; }
    .pds-page-tools-inverted .pds-panel .pds-panel-header, .pds-page-tools-inverted .pds-panel-floating-trigger > .pds-panel-floating .pds-panel-header {
      border-bottom-color: #A8B1B2; }
      .pds-page-tools-inverted .pds-panel .pds-panel-header button, .pds-page-tools-inverted .pds-panel-floating-trigger > .pds-panel-floating .pds-panel-header button {
        background: none; }
      .pds-page-tools-inverted .pds-panel .pds-panel-header button.pds-button-square, .pds-page-tools-inverted .pds-panel-floating-trigger > .pds-panel-floating .pds-panel-header button.pds-button-square {
        border-inline-start-color: #A8B1B2; }
        .pds-page-tools-inverted .pds-panel .pds-panel-header button.pds-button-square pds-icon, .pds-page-tools-inverted .pds-panel-floating-trigger > .pds-panel-floating .pds-panel-header button.pds-button-square pds-icon {
          fill: #A8B1B2; }

.pds-page-content {
  overflow-y: auto; }
  @media (max-width: 768px) {
    .pds-page-content {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; } }
  .pds-page-content > .pds-panels:first-child {
    margin-top: 15px; }

.pds-page-tools-nav .pds-page-tools-nav-item span.pds-page-tools-nav-item-count {
  background: #0075DB;
  border-radius: 45%;
  color: #FFFFFF;
  display: block;
  text-align: center;
  height: 18px;
  line-height: 18px;
  width: 22px;
  font-size: 12px; }

.pds-page-tools-nav {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: auto; }
  .pds-page-tools-nav .pds-page-tools-nav-item {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px;
    -webkit-padding-start: 7px;
            padding-inline-start: 7px;
    -webkit-padding-end: 7px;
            padding-inline-end: 7px;
    border-radius: 3px; }
    .pds-page-tools-nav .pds-page-tools-nav-item:hover {
      background: #333333; }
    .pds-page-tools-nav .pds-page-tools-nav-item a {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      color: #A8B1B2;
      font-weight: 700;
      font-size: 14px; }
    .pds-page-tools-nav .pds-page-tools-nav-item.selected {
      background: #0075DB; }
      .pds-page-tools-nav .pds-page-tools-nav-item.selected span.pds-page-tools-nav-item-count {
        background: #FFFFFF;
        color: #0075DB; }
    .pds-page-tools-nav .pds-page-tools-nav-item:hover a, .pds-page-tools-nav .pds-page-tools-nav-item.selected a {
      color: #FFFFFF;
      text-decoration: none; }

.tippy-box.tooltip-theme {
  padding-top: 2px;
  padding-bottom: 4px;
  border-radius: 4px;
  font-family: "Open Sans";
  font-weight: 400;
  text-align: start; }
  .tippy-box.tooltip-theme .pds-tooltip-last-element {
    margin-bottom: 0px; }
  .tippy-box.tooltip-theme .pds-flag-exempt, .tippy-box.tooltip-theme .pds-flag-collected {
    font-style: italic; }
  .tippy-box.tooltip-theme .pds-row {
    margin: 0;
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-late.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #EB1313; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-exempt.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #9C56B9; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-absent.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #52BA2B; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-missing.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #F5691D; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-collected.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #00427C; }
    .tippy-box.tooltip-theme .pds-row .pds-flag-incomplete.pds-is-selected {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #0075DB; }
  .tippy-box.tooltip-theme .tippy-content pds-tooltip span ~ .pds-password-rules-tooltip {
    margin-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }

td pds-popover button {
  border: none;
  height: 40px; }

pds-popover {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%; }
  pds-popover label {
    pointer-events: none; }
  pds-popover button.pds-popover-main-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    pds-popover button.pds-popover-main-button > span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
  pds-popover .pds-popover-trigger {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
  pds-popover button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    pds-popover button.pds-fields:not(.pds-button-multiselect) .pds-popover-button-contents pds-icon:last-child {
      display: none; }
    pds-popover button.pds-is-active:not(.pds-button-multiselect):hover:not([disabled]) .pds-popover-button-contents pds-icon, pds-popover button.pds-is-active:not(.pds-button-multiselect):focus:not([disabled]) .pds-popover-button-contents pds-icon {
      fill: #FFFFFF; }
      pds-popover button.pds-is-active:not(.pds-button-multiselect):hover:not([disabled]) .pds-popover-button-contents pds-icon svg, pds-popover button.pds-is-active:not(.pds-button-multiselect):focus:not([disabled]) .pds-popover-button-contents pds-icon svg {
        fill: #00427C; }
  pds-popover .pds-popover-trigger-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
    [dir='rtl'] pds-popover .pds-popover-trigger-content {
      -webkit-box-flex: unset;
          -ms-flex: unset;
              flex: unset; }
    pds-popover .pds-popover-trigger-content label, pds-popover .pds-popover-trigger-content .pds-help-text {
      -webkit-padding-start: 10px;
              padding-inline-start: 10px; }
    pds-popover .pds-popover-trigger-content label {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 600; }
    pds-popover .pds-popover-trigger-content .pds-help-text {
      margin: 0 0 6px;
      font-size: 14px;
      font-weight: 400;
      font-style: italic; }
  pds-popover.pds-flags-popover button.pds-fields:not(.pds-button-multiselect) .pds-popover-button-contents pds-icon {
    display: none; }
  pds-popover button.pds-fields:not(.pds-button-square) {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 19px; }
    pds-popover button.pds-fields:not(.pds-button-square):not(.pds-button-multiselect).pds-is-active {
      color: #FFFFFF; }
      pds-popover button.pds-fields:not(.pds-button-square):not(.pds-button-multiselect).pds-is-active pds-icon {
        fill: #FFFFFF; }
      pds-popover button.pds-fields:not(.pds-button-square):not(.pds-button-multiselect).pds-is-active:hover {
        background-color: #00427C;
        color: #FFFFFF; }
        pds-popover button.pds-fields:not(.pds-button-square):not(.pds-button-multiselect).pds-is-active:hover .pds-section-select-trigger pds-icon {
          fill: #0075DB; }
        pds-popover button.pds-fields:not(.pds-button-square):not(.pds-button-multiselect).pds-is-active:hover pds-icon {
          fill: #FFFFFF; }
    pds-popover button.pds-fields:not(.pds-button-square) span.pds-popover-button-contents {
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
      pds-popover button.pds-fields:not(.pds-button-square) span.pds-popover-button-contents span {
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 10px; }
      pds-popover button.pds-fields:not(.pds-button-square) span.pds-popover-button-contents pds-icon {
        margin: 0;
        padding: 0;
        -webkit-padding-start: 10px;
                padding-inline-start: 10px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex; }
  pds-popover.pds-editor-keys-button {
    width: 34px; }
    pds-popover.pds-editor-keys-button .pds-popover-trigger-content {
      -webkit-padding-start: 0;
              padding-inline-start: 0; }

.pds-popover-item-divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #C4C9CA; }

.pds-actions-separator {
  -webkit-border-start: 1px solid #C4C9CA;
          border-inline-start: 1px solid #C4C9CA;
  padding: 0 10px; }

[data-tippy-root] {
  -webkit-perspective: none;
          perspective: none; }
  [data-tippy-root]:focus {
    border: none;
    outline: none; }
  [data-tippy-root].pds-has-icon .pds-popover-menu-items {
    overflow: hidden;
    overflow-y: auto; }
    [data-tippy-root].pds-has-icon .pds-popover-menu-items button {
      width: 100%; }
    [data-tippy-root].pds-has-icon .pds-popover-menu-items span.pds-popover-button-contents span {
      white-space: normal;
      width: 100%; }
  [data-tippy-root][data-popper-placement^="bottom"] .tippy-box.popover-theme .tippy-arrow::before {
    border-bottom: 7px solid #FFFFFF !important;
    top: -5px; }
  [data-tippy-root][data-popper-placement^="top"] .tippy-box.popover-theme .tippy-arrow::before {
    border-top: 7px solid #FFFFFF !important;
    bottom: -6px; }
  [data-tippy-root][data-popper-placement^="right"] .tippy-box.popover-theme .tippy-arrow::before {
    -webkit-border-end: 7px solid #FFFFFF !important;
            border-inline-end: 7px solid #FFFFFF !important;
    inset-inline-start: 0; }
  [data-tippy-root][data-popper-placement^="left"] .tippy-box.popover-theme .tippy-arrow::before {
    -webkit-border-start: 7px solid #FFFFFF !important;
            border-inline-start: 7px solid #FFFFFF !important;
    inset-inline-end: 0; }
  [data-tippy-root] .tippy-box.popover-theme {
    width: 300px;
    background-color: #FFFFFF !important;
    padding: 0;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid #838E91;
    overflow: hidden; }
    [data-tippy-root] .tippy-box.popover-theme .tippy-content {
      padding: 0; }
      [data-tippy-root] .tippy-box.popover-theme .tippy-content fieldset.pds-input-checkbox {
        margin: 0;
        -webkit-padding-start: 10px;
                padding-inline-start: 10px; }
      [data-tippy-root] .tippy-box.popover-theme .tippy-content .pds-pop-over-menu-items button.pds-button-blend.pds-pop-over {
        color: #333333; }
    [data-tippy-root] .tippy-box.popover-theme.pds-user-menu-theme {
      width: 240px;
      padding: 5px;
      -webkit-padding-start: 15px;
              padding-inline-start: 15px; }
      @media (max-width: 768px) {
        [data-tippy-root] .tippy-box.popover-theme.pds-user-menu-theme {
          display: none; } }
    [data-tippy-root] .tippy-box.popover-theme .pds-row pds-popover {
      width: 100%; }
    [data-tippy-root] .tippy-box.popover-theme.pds-popover-in-dialog-theme .pds-is-multi-select {
      position: relative; }
      [data-tippy-root] .tippy-box.popover-theme.pds-popover-in-dialog-theme .pds-is-multi-select fieldset li {
        -webkit-padding-end: 20px;
                padding-inline-end: 20px; }

[data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme {
  max-height: 45vh;
  overflow-y: auto;
  width: auto; }
  [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content {
    background-color: white;
    color: #333333; }
    [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox {
      padding: 5px 0 0;
      margin: 0; }
      [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul {
        margin: 0 5px; }
        [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul li {
          min-height: 32px; }
          @media (max-width: 768px) {
            [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul li {
              margin-bottom: 5px;
              height: auto; } }
        [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row {
          margin-bottom: 5px; }
          [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label {
            padding: 0 10px;
            border-radius: 4px; }
            @media (max-width: 768px) {
              [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label {
                padding: 6px 10px 5px; } }
            [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label input {
              height: 32px; }
            [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text {
              font-size: 14px;
              line-height: 21px;
              font-weight: 400;
              min-height: 32px;
              padding-top: 5px;
              padding-bottom: 6px;
              -webkit-margin-start: 30px;
                      margin-inline-start: 30px; }
              @media (max-width: 768px) {
                [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text {
                  font-size: 16px;
                  line-height: 24px;
                  text-align: start; } }
              [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:before {
                top: 6px;
                inset-inline-start: -30px;
                height: 20px;
                width: 20px; }
              [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:after {
                top: 8px;
                inset-inline-start: -28px; }
            [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label.pds-is-selected {
              background-color: #C2E2FF; }
            [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
              font-weight: 400; }
          [data-tippy-root] .tippy-box.popover-theme.pds-multi-select-popover-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row:hover label {
            text-decoration: underline;
            background-color: #F1F4F4; }

pds-multi-select pds-popover button.pds-fields:not(.pds-button-square) .pds-popover-button-contents span {
  -webkit-padding-start: 0;
          padding-inline-start: 0; }

[data-tippy-root] .tippy-box.popover-theme {
  width: 300px; }

.pds-popover-header {
  height: 44px;
  display: block;
  padding-top: 8px;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-padding-end: 15px;
          padding-inline-end: 15px;
  background-color: #F1F4F4;
  border-bottom: 1px solid #C4C9CA;
  text-align: start; }
  .pds-popover-header .pds-popover-header-name {
    font-size: 18px;
    line-height: 27px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black; }

.pds-popover-menu-items {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 45vh; }
  .pds-popover-header + .pds-popover-menu-items {
    border-start-start-radius: 0;
    border-start-end-radius: 0; }
  .pds-popover-menu-items .pds-row {
    margin: 5px;
    padding: 0; }
    .pds-popover-menu-items .pds-row:first-of-type {
      margin-top: 5px; }
    .pds-popover-menu-items .pds-row:last-of-type {
      margin-bottom: 5px; }
    .pds-popover-menu-items .pds-row .pds-popover-menu-button {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
    .pds-popover-menu-items .pds-row button:not(:disabled) {
      color: #333333; }
    .pds-popover-menu-items .pds-row button {
      height: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      text-align: start;
      white-space: normal;
      overflow: visible;
      line-height: 27px;
      border: none;
      padding: 5px 0 6px; }
      @media (max-width: 768px) {
        .pds-popover-menu-items .pds-row button {
          padding: 10px 0; } }
      .pds-popover-menu-items .pds-row button.pds-is-active {
        color: #FFFFFF; }
        .pds-popover-menu-items .pds-row button.pds-is-active pds-icon {
          fill: #FFFFFF; }
      .pds-popover-menu-items .pds-row button:hover, .pds-popover-menu-items .pds-row button:focus {
        background-color: #F1F4F4; }
      .pds-popover-menu-items .pds-row button:focus {
        border: none;
        outline: none; }
      .pds-popover-menu-items .pds-row button span.pds-popover-button-contents {
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; }
        .pds-popover-menu-items .pds-row button span.pds-popover-button-contents span {
          -ms-flex-item-align: center;
              -ms-grid-row-align: center;
              align-self: center;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 10px;
          font-size: 14px;
          line-height: 21px; }
          @media (max-width: 768px) {
            .pds-popover-menu-items .pds-row button span.pds-popover-button-contents span {
              font-size: 16px;
              line-height: 24px; } }
        .pds-popover-menu-items .pds-row button span.pds-popover-button-contents pds-icon {
          position: relative;
          top: 1px;
          inset-inline-start: 0;
          bottom: auto;
          margin: 0;
          padding: 0;
          -webkit-padding-start: 10px;
                  padding-inline-start: 10px;
          fill: #677274;
          color: #677274; }
          @media (max-width: 768px) {
            .pds-popover-menu-items .pds-row button span.pds-popover-button-contents pds-icon {
              top: 2px; } }
    .pds-popover-menu-items .pds-row pds-popover-menu {
      width: 100%; }
      .pds-popover-menu-items .pds-row pds-popover-menu.pds-nested-popover .pds-popover-trigger-content {
        -webkit-padding-start: 0;
                padding-inline-start: 0;
        width: 100%; }

.pds-popover-header + .pds-popover-menu-items {
  border-start-start-radius: 0;
  border-start-end-radius: 0; }

pds-select-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-margin-start: 15px;
          margin-inline-start: 15px; }

.pds-checkbox-widget-content {
  position: relative;
  margin: 0 15px 20px; }
  .pds-checkbox-widget-content fieldset {
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    position: relative;
    margin: 0; }
    .pds-checkbox-widget-content fieldset legend {
      display: block;
      -webkit-margin-start: 0;
              margin-inline-start: 0;
      margin-bottom: 0.5rem; }
      .pds-checkbox-widget-content fieldset legend .pds-field-help {
        -webkit-margin-start: 8px;
                margin-inline-start: 8px; }
      .pds-checkbox-widget-content fieldset legend .pds-input-checkbox-header-text {
        display: inline-block; }
  .pds-checkbox-widget-content .pds-help-text {
    margin-top: -0.5rem; }
  .pds-checkbox-widget-content.pds-input-checkbox-area .pds-input-checkbox li .pds-label-text {
    display: inline-block; }
  .pds-checkbox-widget-content .pds-input-checkbox-header-text {
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    font-size: 16px;
    line-height: 24px; }

.pds-radio-button-widget-content {
  margin: 0 15px 20px; }
  .pds-radio-button-widget-content fieldset {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    width: 100%;
    margin: 0; }
    .pds-radio-button-widget-content fieldset legend {
      display: block;
      -webkit-margin-start: 0;
              margin-inline-start: 0;
      margin-bottom: 0.5rem; }
      .pds-radio-button-widget-content fieldset legend .pds-field-help {
        -webkit-margin-start: 8px;
                margin-inline-start: 8px; }
  .pds-radio-button-widget-content .pds-help-text {
    margin-top: -0.5rem; }
  .pds-radio-button-widget-content .pds-input-radio-header-text {
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block; }
  .pds-radio-button-widget-content .pds-radio-button-clear {
    height: 32px;
    width: 32px;
    margin: 0 3px;
    padding: 0;
    vertical-align: middle; }
    @media (max-width: 768px) {
      .pds-radio-button-widget-content .pds-radio-button-clear {
        height: 44px;
        width: 44px; } }

.pds-central .pds-radio-button-clear .pds-icon {
  fill: #FFFFFF; }

.pds-central .pds-radio-button-clear:hover {
  background-color: transparent; }

.pds-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 15px 15px;
  padding-top: 3px;
  padding-bottom: 3px; }
  .pds-row > :last-child:not(:first-child) {
    text-align: end;
    -webkit-margin-start: 20px;
            margin-inline-start: 20px; }

.pds-row-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 4px 2px;
  border-bottom: 1px solid #C4C9CA;
  font-size: 12px;
  font-weight: 600;
  margin: 0 15px 0; }

.pds-row-stack h3 {
  margin-bottom: 10px; }

.pds-row-stack .pds-row.pds-row-not-stack {
  border-bottom: none; }

.pds-panel .pds-row-stack .pds-row, .pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row {
  border-bottom: 1px solid #C4C9CA; }
  .pds-panel .pds-row-stack .pds-row:last-of-type, .pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row:last-of-type {
    border: none;
    margin-bottom: 15px; }

.pds-panel-floating .pds-row {
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0; }
  .pds-panel-floating .pds-row:last-child {
    margin-bottom: 15px;
    border-bottom: none; }

.pds-notification-drop-down .notification-row .pds-row:last-of-type {
  margin-bottom: 0; }

.pds-name-and-detail {
  padding: 0; }
  .pds-name-and-detail > :first-child {
    font-size: 16px;
    line-height: 0.7;
    font-weight: 600; }
  .pds-name-and-detail > :last-child {
    font-size: 10px;
    display: block;
    line-height: 0.7;
    font-weight: normal; }

.pds-name-and-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .pds-name-and-desc > .name-and-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-name-and-desc > .name-and-desc .pds-name {
      color: #333333;
      font-family: Open Sans;
      font-weight: 600; }
    .pds-name-and-desc > .name-and-desc .pds-desc {
      color: #677274;
      font-family: Open Sans; }

.pds-notification-indicator-content, .pds-help-menu-content {
  position: relative;
  width: 100%;
  height: 60px;
  line-height: initial;
  font-size: 14px; }
  .pds-notification-indicator-content.pds-panel-floating-trigger > .pds-panel-floating .pds-row-stack .pds-row:last-of-type, .pds-panel-floating-trigger.pds-help-menu-content > .pds-panel-floating .pds-row-stack .pds-row:last-of-type {
    margin: 0; }
  .pds-notification-indicator-content #notification-indicator-link, .pds-help-menu-content #notification-indicator-link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
  .pds-notification-indicator-content .pds-global-tabs.notification-indicator-tabs, .pds-help-menu-content .pds-global-tabs.notification-indicator-tabs {
    margin: 0; }
    .pds-notification-indicator-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge, .pds-help-menu-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .pds-notification-indicator-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge span, .pds-help-menu-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge span {
        -webkit-box-flex: initial;
            -ms-flex: initial;
                flex: initial; }
      .pds-notification-indicator-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge span.tab-badge, .pds-help-menu-content .pds-global-tabs.notification-indicator-tabs a.tab-with-badge span.tab-badge {
        background-color: #EB1313;
        border-radius: 50%;
        color: #FFFFFF;
        height: 15px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 15px;
                flex: 0 0 15px;
        display: inline-block;
        line-height: 15px;
        text-align: center;
        font-size: 10px;
        margin-top: 5px; }
  .pds-notification-indicator-content .pds-notification-drop-down, .pds-help-menu-content .pds-notification-drop-down {
    min-width: 500px;
    inset-inline-end: calc(~"-26px / 2");
    top: calc(~"26px + 9px"); }
    .pds-notification-indicator-content .pds-notification-drop-down .pds-row, .pds-help-menu-content .pds-notification-drop-down .pds-row {
      margin-bottom: 0;
      padding: 5px;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row:last-of-type, .pds-help-menu-content .pds-notification-drop-down .pds-row:last-of-type {
        border-bottom: 0; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row > div, .pds-help-menu-content .pds-notification-drop-down .pds-row > div {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row > pds-img, .pds-help-menu-content .pds-notification-drop-down .pds-row > pds-img {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 44px;
                flex: 0 0 44px; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row pds-icon, .pds-help-menu-content .pds-notification-drop-down .pds-row pds-icon {
        fill: #677274; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .pds-communication-notification-title, .pds-help-menu-content .pds-notification-drop-down .pds-row .pds-communication-notification-title, .pds-notification-indicator-content .pds-notification-drop-down .pds-row .pds-communication-notification-subtitle, .pds-help-menu-content .pds-notification-drop-down .pds-row .pds-communication-notification-subtitle, .pds-notification-indicator-content .pds-notification-drop-down .pds-row .pds-communication-notification-count, .pds-help-menu-content .pds-notification-drop-down .pds-row .pds-communication-notification-count, .pds-notification-indicator-content .pds-notification-drop-down .pds-row .pds-communication-notification-timestamp, .pds-help-menu-content .pds-notification-drop-down .pds-row .pds-communication-notification-timestamp {
        font-weight: 700; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .grey-text, .pds-help-menu-content .pds-notification-drop-down .pds-row .grey-text {
        color: #677274; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-ellipsis, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-ellipsis {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-title, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-title {
        font-size: 12px; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-subtitle, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-subtitle {
        font-size: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-subtitle pds-img, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-subtitle pds-img {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 26px;
                  flex: 0 0 26px;
          -webkit-padding-end: 10px;
                  padding-inline-end: 10px; }
        .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-subtitle .pds-img, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-subtitle .pds-img {
          width: 26px;
          vertical-align: middle; }
        .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-subtitle em, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-subtitle em {
          white-space: pre; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-timestamp, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-timestamp {
        font-size: 12px;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        -ms-flex-item-align: start;
            align-self: flex-start;
        padding-top: 2px; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-details, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-details {
        -webkit-margin-start: 0;
                margin-inline-start: 0;
        text-align: start; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-counts span, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-counts span {
        color: #0075DB; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-counts pds-icon, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-counts pds-icon {
        vertical-align: text-top; }
      .pds-notification-indicator-content .pds-notification-drop-down .pds-row .notification-counts pds-icon > svg, .pds-help-menu-content .pds-notification-drop-down .pds-row .notification-counts pds-icon > svg {
        width: 16px;
        height: 16px; }
    .pds-notification-indicator-content .pds-notification-drop-down .notification-row, .pds-help-menu-content .pds-notification-drop-down .notification-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-decoration: none;
      color: #000000;
      border-bottom: 1px solid #C4C9CA; }
      .pds-notification-indicator-content .pds-notification-drop-down .notification-row:last-of-type, .pds-help-menu-content .pds-notification-drop-down .notification-row:last-of-type {
        border-bottom: 0; }
  .pds-notification-indicator-content .fill-category-color-1, .pds-help-menu-content .fill-category-color-1 {
    fill: #00427C; }
  .pds-notification-indicator-content .fill-category-color-2, .pds-help-menu-content .fill-category-color-2 {
    fill: #12B19F; }
  .pds-notification-indicator-content .fill-category-color-3, .pds-help-menu-content .fill-category-color-3 {
    fill: #52BA2B; }
  .pds-notification-indicator-content .fill-category-color-4, .pds-help-menu-content .fill-category-color-4 {
    fill: #DA2F6B; }
  .pds-notification-indicator-content .fill-category-color-5, .pds-help-menu-content .fill-category-color-5 {
    fill: #EB1313; }
  .pds-notification-indicator-content .fill-category-color-6, .pds-help-menu-content .fill-category-color-6 {
    fill: #F5691D; }
  .pds-notification-indicator-content .fill-category-color-7, .pds-help-menu-content .fill-category-color-7 {
    fill: #9C56B9; }
  .pds-notification-indicator-content .fill-category-color-8, .pds-help-menu-content .fill-category-color-8 {
    fill: #677274; }
  .pds-notification-indicator-content pds-icon + .pds-notification-bubble, .pds-help-menu-content pds-icon + .pds-notification-bubble {
    position: absolute;
    inset-inline-end: -1px;
    top: -1px; }
  .pds-notification-indicator-content .pds-notification-drop-down, .pds-help-menu-content .pds-notification-drop-down {
    padding: 0; }
  .pds-notification-indicator-content .pds-notification-bell, .pds-help-menu-content .pds-notification-bell {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .pds-notification-indicator-content .pds-row-stack, .pds-help-menu-content .pds-row-stack {
    max-height: 55vh;
    overflow-y: auto; }
    .pds-notification-indicator-content .pds-row-stack img, .pds-help-menu-content .pds-row-stack img, .pds-notification-indicator-content .pds-row-stack svg, .pds-help-menu-content .pds-row-stack svg {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      -webkit-margin-end: 10px;
              margin-inline-end: 10px;
      max-width: inherit; }
    .pds-notification-indicator-content .pds-row-stack > a, .pds-help-menu-content .pds-row-stack > a, .pds-notification-indicator-content .pds-row-stack a.pds-row, .pds-help-menu-content .pds-row-stack a.pds-row {
      color: #000000; }
  .pds-notification-indicator-content .notification-header, .pds-help-menu-content .notification-header {
    padding: 5px;
    font-weight: bold;
    border-bottom: 1px solid #C4C9CA;
    color: #000000; }
  .pds-notification-indicator-content .pds-message-info, .pds-help-menu-content .pds-message-info {
    margin: 10px; }
  .pds-notification-indicator-content .notification-actions, .pds-help-menu-content .notification-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #C4C9CA; }
    .pds-notification-indicator-content .notification-actions button, .pds-help-menu-content .notification-actions button {
      margin: 0;
      border: 0;
      border-radius: 0;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
      .pds-notification-indicator-content .notification-actions button:first-of-type, .pds-help-menu-content .notification-actions button:first-of-type {
        -webkit-border-end: 1px solid #C4C9CA;
                border-inline-end: 1px solid #C4C9CA; }

.pds-notification-bubble {
  background-color: #EB1313;
  color: #FFFFFF;
  border-radius: 50%;
  font-size: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 15px;
  width: 15px; }
  .pds-notification-bubble.three-digits {
    font-size: 8px; }

pds-notification-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  pds-notification-indicator .pds-tabs-panel {
    padding: 0; }

.pds-row .pds-notification-bubble {
  height: 30px;
  width: 30px; }

div.input.cke_dialog_ui_checkbox_input, input.cke_dialog_ui_radio_input {
  position: static !important; }

.cke_dialog_ui_checkbox .cke_dialog_ui_checkbox_input {
  position: static !important; }

div.pds-rich-text-display-content {
  display: inline-block;
  font-family: sans-serif, Arial, Verdana, "Trebuchet MS";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6; }
  div.pds-rich-text-display-content div,
  div.pds-rich-text-display-content dl,
  div.pds-rich-text-display-content dt,
  div.pds-rich-text-display-content dd,
  div.pds-rich-text-display-content ul,
  div.pds-rich-text-display-content ol,
  div.pds-rich-text-display-content li,
  div.pds-rich-text-display-content h1,
  div.pds-rich-text-display-content h2,
  div.pds-rich-text-display-content h3,
  div.pds-rich-text-display-content h4,
  div.pds-rich-text-display-content h5,
  div.pds-rich-text-display-content h6,
  div.pds-rich-text-display-content pre,
  div.pds-rich-text-display-content form,
  div.pds-rich-text-display-content p,
  div.pds-rich-text-display-content blockquote,
  div.pds-rich-text-display-content th,
  div.pds-rich-text-display-content td {
    margin: 0;
    padding: 0;
    text-align: start;
    color: inherit;
    background-color: inherit; }
  div.pds-rich-text-display-content p {
    display: block;
    margin: 1em 0;
    font-size: 16px; }
  div.pds-rich-text-display-content span {
    display: inline; }
  div.pds-rich-text-display-content ul, div.pds-rich-text-display-content ol, div.pds-rich-text-display-content li {
    line-height: 21px; }
  div.pds-rich-text-display-content ul, div.pds-rich-text-display-content ol {
    margin: 0 15px 15px;
    list-style-position: outside; }
    div.pds-rich-text-display-content ul.pds-app-nav, div.pds-rich-text-display-content ol.pds-app-nav {
      margin: 0; }
  div.pds-rich-text-display-content ol {
    -webkit-margin-start: 2rem;
            margin-inline-start: 2rem; }
    div.pds-rich-text-display-content ol div.pds-rich-text-display-content ol {
      -webkit-margin-start: 1.3rem;
              margin-inline-start: 1.3rem; }
    div.pds-rich-text-display-content ol li {
      -webkit-padding-start: 3px;
              padding-inline-start: 3px; }
  div.pds-rich-text-display-content ul {
    list-style-type: disc;
    -webkit-margin-start: 2.2rem;
            margin-inline-start: 2.2rem; }
    div.pds-rich-text-display-content ul div.pds-rich-text-display-content ul {
      -webkit-margin-start: 1.3rem;
              margin-inline-start: 1.3rem; }
  div.pds-rich-text-display-content table {
    background: transparent;
    border-collapse: collapse;
    margin: 0;
    border-radius: 0; }
    div.pds-rich-text-display-content table tbody tr {
      border-radius: 0;
      background: transparent; }
      div.pds-rich-text-display-content table tbody tr td, div.pds-rich-text-display-content table tbody tr td:first-child, div.pds-rich-text-display-content table tbody tr td:last-child {
        background: transparent;
        border-radius: 0;
        padding: 5px;
        height: auto;
        text-align: start;
        border: 1px solid #838E91; }

.pds-app ~ .cke_reset_all td:first-child, .pds-app ~ .cke_reset_all td:last-child {
  padding: 0; }

.pds-app ~ .cke_reset_all td.cke_dialog_contents_body:first-child, .pds-app ~ .cke_reset_all td.cke_dialog_contents_body:last-child {
  padding: 9px 10px 5px; }

[dir="rtl"] .cke_resizer_ltr {
  border-color: transparent transparent transparent #bcbcbc;
  border-style: dashed dashed dashed solid;
  border-width: 10px 0 0 10px;
  cursor: sw-resize;
  float: left;
  margin-left: 5px;
  margin-right: 0;
  right: auto; }

pds-rich-text-editor .cke_chrome,
pds-rich-text-editor .cke_top,
pds-rich-text-editor .cke_bottom {
  border-color: #C4C9CA; }

pds-rich-text-editor[data-isreadonly="true"] .cke_top,
pds-rich-text-editor[data-isreadonly="true"] .cke_bottom,
pds-rich-text-editor .cke_editor_no-toolbar-example-rich-text-editor .cke_top,
pds-rich-text-editor .cke_editor_no-toolbar-example-rich-text-editor .cke_bottom {
  display: none; }

pds-rich-text-editor .pds-rich-text-editor.pds-rich-text-editor-content {
  margin: 0 15px 15px; }

[dir="rtl"] pds-rich-text-editor .cke_wordcount {
  float: left; }

.pds-help-menu-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  width: 50px; }
  .pds-help-menu-content button:hover:not([disabled]), .pds-help-menu-content button:focus:not([disabled]) {
    background-color: transparent;
    border: none;
    outline: none; }

[data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme {
  text-align: start;
  width: 300px; }
  [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-popover-header {
      color: #333333;
      background-color: #F1F4F4;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400; }
    [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 5px; }
      [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a {
        color: #333333;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin: 0 5px 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; }
        [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a span {
          -webkit-margin-start: 15px;
                  margin-inline-start: 15px;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px;
          margin-top: 5px;
          margin-bottom: 6px; }
        [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a pds-icon {
          margin-top: 5px;
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px;
          -webkit-margin-end: 0;
                  margin-inline-end: 0;
          margin-bottom: 5px; }
        [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a:hover, [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a:focus {
          background-color: #F1F4F4;
          border-radius: 4px;
          text-decoration: none; }
          [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a:hover span, [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items a:focus span {
            text-decoration: underline; }
      [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover .pds-help-items .pds-message-info {
        margin: 0 5px 10px; }
    [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme .pds-help-menu-popover footer a {
      display: block;
      text-align: end;
      margin: 0 10px 10px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400; }
  [data-tippy-root] .tippy-box.popover-theme.pds-help-menu-theme pds-icon {
    width: 20px;
    height: 20px; }

.pds-global-create-menu-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  width: auto;
  top: 0;
  inset-inline-end: 0;
  margin: 0 10px;
  padding: 0;
  position: relative; }
  @media (max-width: 768px) {
    .pds-global-create-menu-content {
      height: 44px; } }
  .pds-global-create-menu-content .pds-button-bar, .pds-global-create-menu-content .pds-page-controls .pds-view-select, .pds-page-controls .pds-global-create-menu-content .pds-view-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 10px; }
    .pds-global-create-menu-content .pds-button-bar > button, .pds-global-create-menu-content .pds-page-controls .pds-view-select > button, .pds-page-controls .pds-global-create-menu-content .pds-view-select > button {
      height: 32px;
      padding: 0;
      margin: 0; }
      @media (max-width: 768px) {
        .pds-global-create-menu-content .pds-button-bar > button, .pds-global-create-menu-content .pds-page-controls .pds-view-select > button, .pds-page-controls .pds-global-create-menu-content .pds-view-select > button {
          height: 44px; } }
      .pds-global-create-menu-content .pds-button-bar > button > pds-icon svg, .pds-global-create-menu-content .pds-page-controls .pds-view-select > button > pds-icon svg, .pds-page-controls .pds-global-create-menu-content .pds-view-select > button > pds-icon svg {
        height: 24px;
        width: 24px; }
  .pds-global-create-menu-content #pds-global-create-menu-drop-down {
    height: auto;
    width: 520px;
    position: absolute;
    color: #677274;
    top: calc(~"100% + 34px"); }
    @media (max-width: 768px) {
      .pds-global-create-menu-content #pds-global-create-menu-drop-down {
        position: fixed;
        top: 160px;
        inset-inline-start: 15px;
        inset-inline-end: 15px;
        bottom: 15px;
        width: auto; } }
    @media (max-width: 576px) {
      .pds-global-create-menu-content #pds-global-create-menu-drop-down {
        top: 200px; } }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down:before, .pds-global-create-menu-content #pds-global-create-menu-drop-down:after {
      display: none; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-bottom: 1px solid #C4C9CA;
      padding: 5px 10px;
      height: 45px;
      background-color: #F1F4F4; }
      .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-label .pds-global-create-menu-label-text {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        font-size: 18px;
        line-height: 34px;
        margin: 0 10px; }
      .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-label button {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        height: 32px;
        padding: 0;
        margin: 0; }
        @media (max-width: 768px) {
          .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-label button.pds-button-square {
            width: 32px;
            min-width: 32px; } }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-label button pds-icon {
          fill: #677274;
          margin: 0; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-container {
      display: block;
      max-height: 410px;
      width: auto;
      overflow-y: auto;
      padding: 10px; }
      @media (max-width: 768px) {
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-container {
          max-height: 100%; } }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-header {
      margin: 0 10px;
      border-bottom: 1px solid #C4C9CA;
      font-size: 18px; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
      .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item {
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        width: 150px;
        height: auto;
        position: relative;
        margin: 5px;
        border: 1px solid transparent;
        display: block;
        color: #677274;
        font-size: 12px;
        text-align: center; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item:hover {
          border-color: #C4C9CA;
          text-decoration: none; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item pds-icon {
          fill: #677274; }
          .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item pds-icon svg {
            height: 75px;
            width: 75px; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item .global-create-menu-item-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 150px;
          display: block; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down .pds-global-create-menu-item-container .pds-global-create-menu-item .pds-global-create-menu-default-indicator {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 78px;
          display: block;
          display: block;
          position: absolute;
          width: 78px;
          top: calc(~"50% - 20px");
          inset-inline-start: 50%;
          -webkit-margin-start: -38px;
                  margin-inline-start: -38px;
          background-color: #FFFFFF;
          border: 1px solid #00427C;
          border-radius: 10px;
          color: #00427C;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-label {
      border-bottom: 1px solid #C4C9CA;
      background-color: #0075DB;
      color: #FFFFFF; }
      .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-label button {
        width: auto;
        background-color: #FFFFFF;
        border: 1px solid #0075DB;
        color: #0075DB;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 21px; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-label button:hover:not([disabled]), .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-label button:focus:not([disabled]) {
          background-color: #F1F4F4; }
        .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-label button:focus:not([disabled]) {
          border-color: #C2E2FF; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-setup-indicator {
      display: block;
      position: absolute;
      padding: 0;
      top: 0;
      inset-inline-end: 24px;
      inset-inline-start: auto; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-is-default .pds-global-create-menu-setup-indicator:after {
      top: 7px;
      inset-inline-start: 7px; }
    .pds-global-create-menu-content #pds-global-create-menu-drop-down.pds-global-create-setup .pds-global-create-menu-item:hover .pds-global-create-menu-setup-indicator {
      top: 0px;
      inset-inline-end: 24px; }

pds-detail-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  pds-detail-select .pds-picture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: white;
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
    border: 1px solid #838E91;
    -webkit-border-end: 0;
            border-inline-end: 0;
    -webkit-margin-start: 3px;
            margin-inline-start: 3px; }
    pds-detail-select .pds-picture pds-icon svg {
      height: 20px;
      width: 20px; }
    pds-detail-select .pds-picture pds-img {
      height: 30px;
      width: 30px;
      padding: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      pds-detail-select .pds-picture pds-img img {
        width: 20px;
        height: 20px;
        border-radius: 50%; }
      pds-detail-select .pds-picture pds-img .pds-image-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
    @media (max-width: 768px) {
      pds-detail-select .pds-picture {
        width: 44px;
        min-width: 44px;
        height: 44px; }
        pds-detail-select .pds-picture pds-icon svg {
          height: 24px;
          width: 24px; }
        pds-detail-select .pds-picture pds-img {
          height: 42px;
          width: 42px;
          padding: 9px; }
          pds-detail-select .pds-picture pds-img img {
            width: 24px;
            height: 24px; } }
  pds-detail-select select {
    -webkit-border-start: none;
            border-inline-start: none;
    width: 350px; }
    @media (max-width: 992px) {
      pds-detail-select select {
        width: calc(~"100% - 210px"); } }
    @media (max-width: 768px) {
      pds-detail-select select {
        width: 278px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 278px;
                flex: 0 0 278px; } }
    @media (max-width: 576px) {
      pds-detail-select select {
        width: 247px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 247px;
                flex: 0 0 247px; } }
  pds-detail-select :not(.pds-picture) + select {
    -webkit-margin-start: 3px;
            margin-inline-start: 3px; }
  pds-detail-select .pds-picture + select {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0; }
  pds-detail-select button {
    -webkit-margin-start: 3px;
            margin-inline-start: 3px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    pds-detail-select button pds-icon {
      fill: #677274; }
      [dir='rtl'] pds-detail-select button pds-icon[name='caret-right'], [dir='rtl'] pds-detail-select button pds-icon[name='caret-left'] {
        -webkit-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
                transform: scaleX(-1); }
    pds-detail-select button:first-child {
      -webkit-margin-start: 0;
              margin-inline-start: 0; }

@media (max-width: 992px) {
  .pds-detail-screen-pro-mode pds-detail-select select {
    width: calc(~"100% - 110px"); } }

.pds-chat-message-content .pds-chat-tabs {
  margin: 0; }

.pds-chat-message-content.pds-modal-body {
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.pds-chat-message-content .pds-chat-message-header {
  color: #0075DB;
  background-color: #E5F3FF;
  min-height: 44px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 44px; }
  .pds-chat-message-content .pds-chat-message-header a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 10px 0; }
  .pds-chat-message-content .pds-chat-message-header svg {
    fill: #0075DB;
    height: 16px; }
  .pds-chat-message-content .pds-chat-message-header span {
    line-height: 24px; }

.pds-chat-message-content .pds-chat-message-container {
  padding-top: 10px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(~"75vh - 44px - 44px - 49px - 0.5357rem - 1px - 10px");
  font-size: 14px;
  line-height: 1.5em; }
  .pds-chat-message-content .pds-chat-message-container .pds-chat-message {
    background-color: #F1F4F4;
    color: #677274;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    overflow-wrap: break-word;
    word-break: break-word;
    margin: 0 0 5px;
    max-width: 500px;
    white-space: initial; }
  .pds-chat-message-content .pds-chat-message-container .pds-message-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%; }
  .pds-chat-message-content .pds-chat-message-container .pds-chat-message-group-right {
    -ms-flex-item-align: end;
        align-self: flex-end; }
    .pds-chat-message-content .pds-chat-message-container .pds-chat-message-group-right .pds-message-content {
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end; }
    .pds-chat-message-content .pds-chat-message-container .pds-chat-message-group-right .pds-chat-message {
      background-color: #E5F3FF;
      color: #0075DB; }
  .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 20px 5px; }
    .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group pds-img {
      -ms-flex-item-align: end;
          align-self: flex-end;
      -webkit-margin-end: 5px;
              margin-inline-end: 5px; }
    .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group img {
      border-radius: 50%; }
    .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group svg, .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group img {
      height: 20px;
      width: 20px; }
  .pds-chat-message-content .pds-chat-message-container .pds-text-widget-content.pds-input-text {
    margin-top: 20px;
    margin-bottom: 0; }
  .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group-detail {
    line-height: 20px;
    margin: 0 0 5px; }
    .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group-detail .pds-quick-message-sender-name {
      font-weight: 700; }
    .pds-chat-message-content .pds-chat-message-container .pds-quick-message-group-detail .pds-quick-message-sent-at {
      font-size: 12px; }

.pds-quick-message-footer .pds-modal-actions.pds-fields, .pds-quick-message-footer .pds-fields.pds-modal-actions-binary {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-quick-message-footer .pds-modal-actions.pds-fields .pds-quick-message-submit, .pds-quick-message-footer .pds-fields.pds-modal-actions-binary .pds-quick-message-submit {
    height: 44px; }
  .pds-quick-message-footer .pds-modal-actions.pds-fields .pds-quick-message-input, .pds-quick-message-footer .pds-fields.pds-modal-actions-binary .pds-quick-message-input {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 100%;
            flex: 0 1 100%;
    height: 44px;
    font-size: 14px; }
    .pds-quick-message-footer .pds-modal-actions.pds-fields .pds-quick-message-input .pds-text-widget-content, .pds-quick-message-footer .pds-fields.pds-modal-actions-binary .pds-quick-message-input .pds-text-widget-content {
      margin-bottom: 5px; }

pds-score-inspector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-border-start: 3px solid #00427C;
          border-inline-start: 3px solid #00427C;
  width: 400px;
  height: 100vh; }
  pds-score-inspector.pds-is-closed, pds-score-inspector.pds-score-inspector-tiny.pds-is-closed {
    height: 0;
    width: 0;
    border: none; }
  pds-score-inspector .pds-score-inspector-control-buttons button,
  pds-score-inspector .pds-score-inspector-footer-controls button {
    height: 32px;
    width: 32px; }
    pds-score-inspector .pds-score-inspector-control-buttons button pds-icon,
    pds-score-inspector .pds-score-inspector-footer-controls button pds-icon {
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      height: 20px;
      width: 20px; }
  pds-score-inspector header.pds-score-inspector-header {
    -ms-flex-item-align: normal;
        -ms-grid-row-align: normal;
        align-self: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    background-color: #00427C; }
    pds-score-inspector header.pds-score-inspector-header > div:first-child {
      border-bottom: 1px solid #00427C; }
    pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-title-bar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-title-bar span {
        height: 40px;
        -webkit-margin-start: 10px;
                margin-inline-start: 10px;
        color: white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-weight: bold; }
    pds-score-inspector header.pds-score-inspector-header pds-detail-select {
      margin: 10px 10px; }
      pds-score-inspector header.pds-score-inspector-header pds-detail-select button {
        border: none; }
      pds-score-inspector header.pds-score-inspector-header pds-detail-select select {
        max-width: 283px; }
    pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        margin: 5px;
        padding: 0;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px; }
        pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button pds-icon {
          fill: #FFFFFF; }
        pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button pds-icon[name="assignment-f"] {
          -webkit-margin-end: 5px;
                  margin-inline-end: 5px; }
        pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.disabled {
          cursor: pointer;
          color: #FFFFFF; }
          pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.disabled pds-icon {
            fill: #FFFFFF; }
          pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.disabled:hover {
            background-color: transparent; }
        pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.pds-button-blend.pds-button-square {
          margin: 8px;
          -webkit-margin-start: 6px;
                  margin-inline-start: 6px;
          -webkit-box-flex: 0;
              -ms-flex: 0 44px;
                  flex: 0 44px;
          width: 44px;
          height: 44px; }
          pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.pds-button-blend.pds-button-square:hover:not([disabled]), pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-control-buttons button.pds-button-blend.pds-button-square:focus:not([disabled]) {
            border: 1px solid #FFFFFF;
            background-color: rgba(0, 0, 0, 0.2); }
    pds-score-inspector header.pds-score-inspector-header .pds-score-inspector-title {
      -webkit-margin-start: 10px;
              margin-inline-start: 10px; }
  pds-score-inspector section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 90%;
            flex: 1 1 90%;
    overflow-y: auto; }
    pds-score-inspector section .pds-score-status {
      min-height: 54px;
      height: 54px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 10px;
      background-color: #F1F4F4; }
      pds-score-inspector section .pds-score-status .pds-score-inspector-control-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        background-color: white; }
        pds-score-inspector section .pds-score-status .pds-score-inspector-control-buttons button {
          -webkit-box-flex: 1;
              -ms-flex: 1 0 auto;
                  flex: 1 0 auto;
          color: #FFFFFF;
          height: 32px;
          width: 32px;
          padding: 0;
          border: solid 1px #0057A3; }
          pds-score-inspector section .pds-score-status .pds-score-inspector-control-buttons button pds-icon {
            fill: #677274; }
      pds-score-inspector section .pds-score-status .pds-score-attachment-details {
        font-size: 16px;
        padding-top: 10px;
        color: #333333;
        -webkit-margin-start: 10px;
                margin-inline-start: 10px; }
      pds-score-inspector section .pds-score-status .pds-score-status-submitted {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 12px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-margin-start: 10px;
                margin-inline-start: 10px; }
        pds-score-inspector section .pds-score-status .pds-score-status-submitted span {
          padding: 0;
          -webkit-padding-start: 5px;
                  padding-inline-start: 5px;
          color: #677274; }
        pds-score-inspector section .pds-score-status .pds-score-status-submitted pds-icon {
          fill: #677274;
          width: 10px;
          height: 20px; }
      pds-score-inspector section .pds-score-status .pds-score-status-returned {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 12px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-margin-start: 10px;
                margin-inline-start: 10px; }
        pds-score-inspector section .pds-score-status .pds-score-status-returned span {
          padding: 0;
          -webkit-padding-start: 5px;
                  padding-inline-start: 5px;
          color: #603073; }
        pds-score-inspector section .pds-score-status .pds-score-status-returned pds-icon {
          fill: #603073;
          width: 10px;
          height: 20px; }
      pds-score-inspector section .pds-score-status .return-assignment-button {
        height: 32px;
        width: 138px;
        border: 1px solid #0057A3;
        margin-top: 10px;
        -webkit-margin-end: 0;
                margin-inline-end: 0;
        margin-bottom: 10px;
        -webkit-margin-start: auto;
                margin-inline-start: auto;
        padding: 1px 10px;
        font-size: 13px;
        background-color: white; }
        pds-score-inspector section .pds-score-status .return-assignment-button pds-icon {
          fill: #0057A3;
          width: 12px; }
        pds-score-inspector section .pds-score-status .return-assignment-button.disabled {
          background-color: #F1F4F4;
          color: #677274; }
    pds-score-inspector section .pds-editor-row-keys button,
    pds-score-inspector section .pds-editor-row-popovers button {
      margin-bottom: 0; }
  pds-score-inspector footer.pds-score-inspector-footer-controls {
    background: #F1F4F4;
    border-top: 1px solid #838E91;
    -ms-flex-item-align: normal;
        -ms-grid-row-align: normal;
        align-self: normal; }
    pds-score-inspector footer.pds-score-inspector-footer-controls button {
      padding: 0;
      margin: 10px;
      -webkit-margin-end: 0;
              margin-inline-end: 0;
      border-color: #0075DB; }
      pds-score-inspector footer.pds-score-inspector-footer-controls button pds-icon.pds-score-inspector-mode-toggle svg {
        fill: #0075DB; }
  pds-score-inspector button {
    min-width: 0; }
    pds-score-inspector button.pds-is-active {
      background-color: #0075DB;
      color: #FFFFFF; }
      pds-score-inspector button.pds-is-active:hover {
        background-color: #C2E2FF;
        color: #000000; }
  pds-score-inspector .pds-editor-row > .pds-comments-button {
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-bottom: 4px; }
  pds-score-inspector.pds-score-inspector-tiny {
    width: 60px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-main-key {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    pds-score-inspector.pds-score-inspector-tiny pds-popover:not(.pds-flags-popover) {
      width: auto; }
      pds-score-inspector.pds-score-inspector-tiny pds-popover:not(.pds-flags-popover) .pds-popover-trigger-content, pds-score-inspector.pds-score-inspector-tiny pds-popover:not(.pds-flags-popover) .pds-popover-trigger {
        -webkit-padding-start: 0;
                padding-inline-start: 0; }
    pds-score-inspector.pds-score-inspector-tiny .pds-score-inspector-control-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-main .pds-editor-row-main-key button.pds-button-square {
        border-color: #838E91;
        border-bottom-width: 0;
        border-radius: 0;
        background-color: #FFFFFF; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-main .pds-editor-row-main-key:first-child button.pds-button-square {
        border-start-start-radius: 4px;
        border-start-end-radius: 4px; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-main .pds-editor-row-main-key:last-child button.pds-button-square {
        border-bottom-width: 1px;
        border-end-start-radius: 4px;
        border-end-end-radius: 4px; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-main button.pds-fields.pds-button-square {
        border-radius: 0;
        border-end-start-radius: 4px;
        border-end-end-radius: 4px; }
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-extras {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-popover-trigger-content,
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-popover-trigger {
        padding: 0; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-codes-button button {
        height: 32px;
        width: 32px;
        margin: 0 2px 1px;
        font-size: 9px; }
        @media (max-width: 768px) {
          pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-codes-button button {
            height: 44px;
            width: 44px; } }
        pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-codes-button button .pds-popover-button-contents {
          -webkit-padding-start: 10px;
                  padding-inline-start: 10px; }
          pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-codes-button button .pds-popover-button-contents span {
            -webkit-padding-start: 0;
                    padding-inline-start: 0; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers .pds-flags-popover button.pds-button-square {
        width: 83px;
        height: auto; }
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers button.pds-comments-button {
        padding: 7px;
        min-height: 32px; }
    pds-score-inspector.pds-score-inspector-tiny .pds-button-square,
    pds-score-inspector.pds-score-inspector-tiny pds-popover .pds-popover-button > button {
      width: 32px;
      height: 32px;
      line-height: 17px; }
      @media (max-width: 768px) {
        pds-score-inspector.pds-score-inspector-tiny .pds-button-square,
        pds-score-inspector.pds-score-inspector-tiny pds-popover .pds-popover-button > button {
          height: 44px;
          width: 44px; } }
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-main-key button.pds-button-square,
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-keys-extras button.pds-button-square {
      padding: 7px;
      border-color: #0057A3; }
    pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline > button.pds-button-square {
      padding: 0; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline > button.pds-button-square pds-icon {
        margin-top: 0; }
    pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating {
      position: absolute;
      bottom: 0;
      inset-inline-end: 100%;
      width: 400px; }
      pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-padding-end: 3px;
                padding-inline-end: 3px; }
    pds-score-inspector.pds-score-inspector-tiny header.pds-score-inspector-header {
      margin-bottom: 15px; }
      pds-score-inspector.pds-score-inspector-tiny header.pds-score-inspector-header > .pds-score-inspector-control-buttons {
        height: 60px;
        border: none;
        margin: 0; }
    pds-score-inspector.pds-score-inspector-tiny .pds-score-inspector-control-buttons pds-icon,
    pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers > button pds-icon,
    pds-score-inspector.pds-score-inspector-tiny pds-popover .pds-popover-button > button pds-icon,
    pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating pds-icon {
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -webkit-margin-start: 0;
              margin-inline-start: 0;
      height: 16px;
      width: 16px; }
      pds-score-inspector.pds-score-inspector-tiny .pds-score-inspector-control-buttons pds-icon[name="pointer-left-circle-s"],
      pds-score-inspector.pds-score-inspector-tiny .pds-editor-row-popovers > button pds-icon[name="pointer-left-circle-s"],
      pds-score-inspector.pds-score-inspector-tiny pds-popover .pds-popover-button > button pds-icon[name="pointer-left-circle-s"],
      pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating pds-icon[name="pointer-left-circle-s"] {
        height: 20px;
        width: 20px; }
    pds-score-inspector.pds-score-inspector-tiny .pds-rich-text-editor-content {
      margin: 3px;
      -webkit-margin-start: 5px;
              margin-inline-start: 5px; }
    pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating {
      background-color: #00427C;
      padding-top: 3px; }
      pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating pds-icon[name="close-s"] {
        fill: white; }
      pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating button:hover pds-icon[name="close-s"] {
        fill: #0075DB; }
    pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline button.pds-button-square {
        border: 1px solid #838E91;
        border-radius: 50%; }
        pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline button.pds-button-square.pds-is-selected {
          border: none; }
        pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline button.pds-button-square:not(.pds-flag-collected) {
          margin-top: 3px; }
        pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline button.pds-button-square pds-icon {
          padding: 0;
          background: none;
          border: none;
          margin-bottom: 0; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-collected {
        margin-top: 10px; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-late.pds-is-selected {
        background-color: #EB1313; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-exempt.pds-is-selected {
        background-color: #9C56B9; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-absent.pds-is-selected {
        background-color: #52BA2B; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-missing.pds-is-selected {
        background-color: #F5691D; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-collected.pds-is-selected {
        background-color: #00427C; }
      pds-score-inspector.pds-score-inspector-tiny pds-flag-buttons.pds-flag-buttons-inline .pds-flag-incomplete.pds-is-selected {
        background-color: #0075DB; }
    pds-score-inspector.pds-score-inspector-tiny footer.pds-score-inspector-footer-controls button {
      display: block;
      margin: 10px auto; }
      @media (max-width: 768px) {
        pds-score-inspector.pds-score-inspector-tiny footer.pds-score-inspector-footer-controls button {
          margin: 6.5px auto; } }
  pds-score-inspector .pds-has-comments pds-icon {
    fill: #0075DB; }
  pds-score-inspector .pds-flags-popover {
    width: 32px; }
    @media (max-width: 768px) {
      pds-score-inspector .pds-flags-popover {
        width: 44px; } }
    pds-score-inspector .pds-flags-popover button.pds-fields {
      height: 32px;
      width: 32px; }
      @media (max-width: 768px) {
        pds-score-inspector .pds-flags-popover button.pds-fields {
          height: 44px;
          width: 44px; } }
    pds-score-inspector .pds-flags-popover button.pds-fields span.pds-popover-button-contents {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-radius: 0; }
    pds-score-inspector .pds-flags-popover pds-flag-buttons {
      width: 251px; }
      pds-score-inspector .pds-flags-popover pds-flag-buttons button.pds-button-square {
        width: 83px; }

.pds-editor-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 5px 10px;
  border-top: 1px solid #C4C9CA; }
  .pds-editor-row:last-child {
    border-bottom: 1px solid #C4C9CA; }
  .pds-editor-row button {
    margin: 0;
    height: 32px;
    padding: 7px;
    border-color: #0057A3; }
    .pds-editor-row button.pds-button-square {
      width: 32px;
      line-height: 17px;
      margin: 0; }
      @media (max-width: 768px) {
        .pds-editor-row button.pds-button-square {
          margin-top: 0;
          -webkit-margin-end: 1px;
                  margin-inline-end: 1px;
          margin-bottom: 1px;
          -webkit-margin-start: 0;
                  margin-inline-start: 0; } }
      .pds-editor-row button.pds-button-square.pds-comments-button {
        -webkit-margin-end: 0;
                margin-inline-end: 0; }
      .pds-editor-row button.pds-button-square span {
        max-width: 56px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; }
    @media (max-width: 768px) {
      .pds-editor-row button {
        height: 44px; } }
  .pds-editor-row pds-flag-buttons button.pds-button-square {
    -webkit-margin-end: 0;
            margin-inline-end: 0; }
  .pds-editor-row.pds-is-active {
    color: #0057A3;
    background-color: #C2E2FF;
    border-color: #0075DB; }
    .pds-editor-row.pds-is-active .pds-editor-row-input input {
      border-bottom-color: #0057A3 !important; }
    .pds-editor-row.pds-is-active pds-flag-buttons.pds-flag-buttons-inline button {
      background-color: transparent;
      color: #0057A3;
      border: none; }
      .pds-editor-row.pds-is-active pds-flag-buttons.pds-flag-buttons-inline button:hover {
        background-color: #E5F3FF; }
  .pds-editor-row.pds-is-master + .pds-editor-row {
    border-top-width: 3px;
    border-top-color: #0075DB; }
  .pds-editor-row svg {
    height: 16px;
    width: 16px; }
  .pds-editor-row .pds-editor-row-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-bottom: 5px;
    font-weight: 600; }
  .pds-editor-row .pds-editor-row-scores {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px; }
    .pds-editor-row .pds-editor-row-scores .pds-editor-row-left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 220px;
              flex: 0 0 220px; }
      .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 173px;
                  flex: 0 0 173px;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-single-row button.pds-button-square, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square {
            border-color: #838E91;
            border-radius: 0;
            border-inline-end-width: 0; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-single-row button.pds-button-square:first-child {
            border-start-start-radius: 4px;
            border-end-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-single-row button.pds-button-square:last-child {
            border-start-end-radius: 4px;
            border-end-end-radius: 4px;
            border-inline-end-width: 1px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square:first-child {
            border-start-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square:nth-child(5) {
            border-start-end-radius: 4px;
            border-end-end-radius: 4px;
            border-inline-end-width: 1px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square:nth-child(6) {
            border-end-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square:nth-child(n+6) {
            border-top-width: 0; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-double-row button.pds-button-square:last-child {
            border-end-end-radius: 4px;
            border-inline-end-width: 1px;
            width: 33px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square:first-child {
            border-start-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square:nth-child(5) {
            border-start-end-radius: 4px;
            border-inline-end-width: 1px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square:nth-child(6) {
            border-end-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square:nth-child(n+6) {
            border-top-width: 0; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-button-square:last-child {
            border-end-end-radius: 4px;
            border-inline-end-width: 1px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main.pds-full button.pds-fields.pds-button-square {
            border-radius: 0;
            border-end-end-radius: 4px;
            border-top-width: 0; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main button .pds-tooltip {
            white-space: normal;
            width: -moz-max-content;
            width: -webkit-max-content;
            max-width: 200px;
            text-align: start; }
            .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main button .pds-tooltip div.pds-rich-text-display-content {
              word-break: break-word; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main button.pds-button-square:first-child {
            border-start-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main button.pds-button-square:last-child {
            border-end-end-radius: 4px;
            border-inline-end-width: 1px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key > button, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key > pds-popover .pds-popover-button > button {
            -webkit-border-start: none;
                    border-inline-start: none;
            border-radius: 0; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:first-child > button, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:first-child > pds-popover .pds-popover-button > button {
            border-end-start-radius: 4px;
            border-start-start-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:last-child > button, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:last-child > pds-popover .pds-popover-button > button {
            border-end-end-radius: 4px;
            border-start-end-radius: 4px; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:nth-child(5n + 1) > button, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:nth-child(5n + 1) > pds-popover .pds-popover-button > button {
            border-end-start-radius: 4px;
            border-start-start-radius: 4px;
            -webkit-border-start: 1px solid #838E91;
                    border-inline-start: 1px solid #838E91; }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:nth-child(5n + 0) > button, .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-main .pds-editor-row-main-key:nth-child(5n + 0) > pds-popover .pds-popover-button > button {
            border-end-end-radius: 4px;
            border-start-end-radius: 4px; }
        .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-extras {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          margin: 0 5px; }
          @media (max-width: 768px) {
            .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-extras {
              margin: 0; } }
          .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-extras button {
            border-top: none;
            border-radius: 0; }
            .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-extras button:first-child {
              border-top: 1px solid #0057A3;
              border-start-start-radius: 4px;
              border-start-end-radius: 4px; }
            .pds-editor-row .pds-editor-row-scores .pds-editor-row-left .pds-editor-row-keys .pds-editor-row-keys-extras button:last-child {
              border-end-start-radius: 4px;
              border-end-end-radius: 4px; }
  .pds-editor-row .pds-editor-row-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 132px;
            flex: 0 0 132px; }
    .pds-editor-row .pds-editor-row-right .pds-editor-row-points-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 35px;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-margin-end: 5px;
              margin-inline-end: 5px; }
    .pds-editor-row .pds-editor-row-right .pds-editor-row-input {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input pds-text-widget {
        width: 100%;
        margin-bottom: 0; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-input pds-text-widget input {
          width: 100%;
          height: 32px;
          border-end-start-radius: 0;
          border-end-end-radius: 0;
          border-color: #0057A3; }
          @media (max-width: 768px) {
            .pds-editor-row .pds-editor-row-right .pds-editor-row-input pds-text-widget input {
              height: 44px; } }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input .pds-editor-row-input-markup {
        position: absolute;
        top: 1px;
        inset-inline-end: 1px;
        height: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-size: 12px;
        background-color: #F1F4F4;
        color: #A8B1B2;
        border-start-end-radius: 3px; }
        @media (max-width: 768px) {
          .pds-editor-row .pds-editor-row-right .pds-editor-row-input .pds-editor-row-input-markup {
            height: 42px; } }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-1.pds-input-has-markup .pds-editor-row-input-markup {
        -webkit-border-start: 5px solid #F9CE33;
                border-inline-start: 5px solid #F9CE33;
        -webkit-border-end: none;
                border-inline-end: none; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-1 .pds-editor-row-input-markup {
        -webkit-border-end: 5px solid #F9CE33;
                border-inline-end: 5px solid #F9CE33; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-2.pds-input-has-markup .pds-editor-row-input-markup {
        -webkit-border-start: 5px solid #F5691D;
                border-inline-start: 5px solid #F5691D;
        -webkit-border-end: none;
                border-inline-end: none; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-2 .pds-editor-row-input-markup {
        -webkit-border-end: 5px solid #F5691D;
                border-inline-end: 5px solid #F5691D; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-3.pds-input-has-markup .pds-editor-row-input-markup {
        -webkit-border-start: 5px solid #EB1313;
                border-inline-start: 5px solid #EB1313;
        -webkit-border-end: none;
                border-inline-end: none; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-3 .pds-editor-row-input-markup {
        -webkit-border-end: 5px solid #EB1313;
                border-inline-end: 5px solid #EB1313; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-4.pds-input-has-markup .pds-editor-row-input-markup {
        -webkit-border-start: 5px solid #52BA2B;
                border-inline-start: 5px solid #52BA2B;
        -webkit-border-end: none;
                border-inline-end: none; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-4 .pds-editor-row-input-markup {
        -webkit-border-end: 5px solid #52BA2B;
                border-inline-end: 5px solid #52BA2B; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-5.pds-input-has-markup .pds-editor-row-input-markup {
        -webkit-border-start: 5px solid #2A5F16;
                border-inline-start: 5px solid #2A5F16;
        -webkit-border-end: none;
                border-inline-end: none; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-color-level-5 .pds-editor-row-input-markup {
        -webkit-border-end: 5px solid #2A5F16;
                border-inline-end: 5px solid #2A5F16; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-input-has-markup .pds-editor-row-input-markup {
        min-width: 34px; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-input.pds-input-has-markup pds-text-widget input {
        -webkit-padding-end: 42px;
                padding-inline-end: 42px; }
    .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      border-top: 0; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > * {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 33%;
                flex: 1 1 33%; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > * button {
          border-top-width: 0;
          border-inline-end-width: 0;
          border-radius: 0; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > *:first-child button {
          border-end-start-radius: 4px; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > *:last-child button {
          border-end-end-radius: 4px;
          border-inline-end-width: 1px; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > button {
        border-top-width: 0;
        border-inline-end-width: 0;
        border-radius: 0; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > button.pds-comments-button pds-icon {
          margin: 1px 0; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > button:first-child {
          border-end-start-radius: 4px; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers > button:last-child {
          border-end-end-radius: 4px;
          border-inline-end-width: 1px; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers pds-popover .pds-popover-trigger-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers pds-popover span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers pds-popover button.pds-popover-main-button {
        height: 32px;
        line-height: 19px;
        font-size: 9px;
        width: 100%; }
        .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers pds-popover button.pds-popover-main-button .pds-popover-button-text {
          -webkit-padding-start: 0;
                  padding-inline-start: 0;
          -webkit-padding-end: 0;
                  padding-inline-end: 0; }
        @media (max-width: 768px) {
          .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers pds-popover button.pds-popover-main-button {
            height: 44px; } }
      .pds-editor-row .pds-editor-row-right .pds-editor-row-popovers .pds-codes-button {
        border: none; }
  .pds-editor-row .pds-editor-row-comment button pds-icon {
    margin: 0; }
  .pds-editor-row .pds-editor-row-comment .pds-rich-text-editor-content {
    margin: 0; }
  .pds-editor-row .pds-editor-row-comment .pds-global-tabs .pds-tabs-panel.pds-is-active table tbody > tr > td button {
    line-height: 16px; }

.pds-editor-keys-popover-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .pds-editor-keys-popover-content button.pds-button-square {
    border: 1px solid #C4C9CA;
    border-radius: 4px;
    margin: 2px;
    max-width: 38px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }

pds-score-inspector-button.pds-score-inspector-button-close {
  background-color: #00427C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  pds-score-inspector-button.pds-score-inspector-button-close pds-icon {
    fill: #0075DB; }
  pds-score-inspector-button.pds-score-inspector-button-close:hover button, pds-score-inspector-button.pds-score-inspector-button-close:active button {
    border-radius: 0;
    background-color: #F1F4F4; }
  pds-score-inspector-button.pds-score-inspector-button-close:hover pds-icon, pds-score-inspector-button.pds-score-inspector-button-close:active pds-icon {
    fill: #0075DB; }

pds-score-inspector-button button:focus:not([disabled]) {
  background-color: #FFFFFF; }

pds-score-inspector-button button:hover:not([disabled]) {
  background-color: #F1F4F4; }

pds-flag-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .pds-editor-row pds-flag-buttons button.pds-button-square {
    width: 60px; }
  pds-flag-buttons button.pds-button-square {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: none;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    width: 60px; }
    pds-flag-buttons button.pds-button-square pds-icon {
      border-radius: 50%;
      padding: 6px;
      background: white;
      border: 1px solid #F1F4F4;
      margin-top: 2px;
      width: 32px;
      height: 32px; }
    pds-flag-buttons button.pds-button-square svg {
      height: 20px;
      width: 20px;
      opacity: 0.5; }
    pds-flag-buttons button.pds-button-square.pds-is-selected svg {
      opacity: 1; }
    pds-flag-buttons button.pds-button-square span:not(.pds-two-character-underline) {
      font-size: 12px;
      margin: 10px 4px 2px;
      padding: 0; }
  pds-flag-buttons .pds-flag-late svg {
    fill: #EB1313; }
  pds-flag-buttons .pds-flag-late.pds-is-selected pds-icon {
    background: #EB1313; }
  pds-flag-buttons .pds-flag-exempt svg {
    fill: #9C56B9; }
  pds-flag-buttons .pds-flag-exempt.pds-is-selected pds-icon {
    background: #9C56B9; }
  pds-flag-buttons .pds-flag-absent svg {
    fill: #52BA2B; }
  pds-flag-buttons .pds-flag-absent.pds-is-selected pds-icon {
    background: #52BA2B; }
  pds-flag-buttons .pds-flag-missing svg {
    fill: #F5691D; }
  pds-flag-buttons .pds-flag-missing.pds-is-selected pds-icon {
    background: #F5691D; }
  pds-flag-buttons .pds-flag-collected svg {
    fill: #00427C; }
  pds-flag-buttons .pds-flag-collected.pds-is-selected pds-icon {
    background: #00427C; }
  pds-flag-buttons .pds-flag-incomplete svg {
    fill: #0075DB; }
  pds-flag-buttons .pds-flag-incomplete.pds-is-selected pds-icon {
    background: #0075DB; }
  pds-flag-buttons .pds-is-selected {
    border: none; }
    pds-flag-buttons .pds-is-selected svg {
      fill: #FFFFFF; }
  pds-flag-buttons .pds-two-character-underline {
    text-decoration: underline; }

pds-dialog[name='names-and-pronouns-options-dialog'] section {
  display: block; }
  pds-dialog[name='names-and-pronouns-options-dialog'] section .pds-message-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    pds-dialog[name='names-and-pronouns-options-dialog'] section .pds-message-info strong {
      cursor: pointer; }

pds-score-cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  border: 1px solid #838E91;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  min-width: 95px; }
  pds-score-cell.pds-is-active {
    background-color: #F1F4F4;
    border-color: #0075DB;
    outline: none; }
  pds-score-cell.pds-is-dirty {
    background-color: #FEF9E6; }
  pds-score-cell.pds-is-auto-calculated-but-user-modified {
    background-clip: padding-box;
    background-image: linear-gradient(135deg, #677274 6px, #677274 6px, transparent 0);
    background-position: 0 0; }
  pds-score-cell.pds-is-invalid:not(.pds-is-active) {
    background: repeating-linear-gradient(-45deg, #FAC6C6, #FAC6C6 2px, #FDE7E7 2px, #FDE7E7 4px);
    border-color: #CB1010; }
  pds-score-cell.pds-color-level-1 {
    border-color: #F9CE33; }
    pds-score-cell.pds-color-level-1:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0px;
      inset-inline-end: 0;
      width: 5px;
      background-color: #F9CE33; }
  pds-score-cell.pds-color-level-2 {
    border-color: #F5691D; }
    pds-score-cell.pds-color-level-2:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0px;
      inset-inline-end: 0;
      width: 5px;
      background-color: #F5691D; }
  pds-score-cell.pds-color-level-3 {
    border-color: #EB1313; }
    pds-score-cell.pds-color-level-3:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0px;
      inset-inline-end: 0;
      width: 5px;
      background-color: #EB1313; }
  pds-score-cell.pds-color-level-4 {
    border-color: #52BA2B; }
    pds-score-cell.pds-color-level-4:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0px;
      inset-inline-end: 0;
      width: 5px;
      background-color: #52BA2B; }
  pds-score-cell.pds-color-level-5 {
    border-color: #2A5F16; }
    pds-score-cell.pds-color-level-5:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0px;
      inset-inline-end: 0;
      width: 5px;
      background-color: #2A5F16; }
  pds-score-cell span {
    margin: 0 auto; }
    pds-score-cell span.pds-flag-exempt {
      font-style: italic;
      color: #9C56B9; }
      pds-score-cell span.pds-flag-exempt svg {
        fill: #9C56B9; }
      pds-score-cell span.pds-flag-exempt .pds-tooltip {
        font-style: normal; }
    pds-score-cell span pds-icon.pds-flag-collected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      pds-score-cell span pds-icon.pds-flag-collected svg {
        fill: #00427C; }
    pds-score-cell span pds-icon.pds-has-comments {
      vertical-align: super;
      padding: 5px; }
      pds-score-cell span pds-icon.pds-has-comments svg {
        fill: #0075DB;
        height: 16px;
        width: 16px; }
    pds-score-cell span.pds-has-comments {
      margin-top: 0;
      -webkit-margin-end: 2px;
              margin-inline-end: 2px;
      padding: 0 3px;
      min-width: 35px; }
      pds-score-cell span.pds-has-comments.pds-tooltip-trigger:hover .pds-tooltip {
        max-width: 500px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        white-space: normal; }
    pds-score-cell span.pds-score-cell-score {
      padding: 0 4px; }
  pds-score-cell .pds-score-cell-flag-container {
    min-width: 35px; }

pds-color-levels-selector .pds-popover-trigger-content {
  padding: 0; }

.pds-popover-row.pds-color-level-1 {
  position: relative; }
  .pds-popover-row.pds-color-level-1:after {
    content: '';
    position: absolute;
    inset-inline-start: 35px;
    top: 14px;
    background-color: #F9CE33;
    height: 16px;
    width: 16px;
    border-radius: 2px; }
  .pds-popover-row.pds-color-level-1 [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-popover-row.pds-color-level-1 [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
    -webkit-padding-start: 24px;
            padding-inline-start: 24px; }

.pds-popover-row.pds-color-level-2 {
  position: relative; }
  .pds-popover-row.pds-color-level-2:after {
    content: '';
    position: absolute;
    inset-inline-start: 35px;
    top: 14px;
    background-color: #F5691D;
    height: 16px;
    width: 16px;
    border-radius: 2px; }
  .pds-popover-row.pds-color-level-2 [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-popover-row.pds-color-level-2 [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
    -webkit-padding-start: 24px;
            padding-inline-start: 24px; }

.pds-popover-row.pds-color-level-3 {
  position: relative; }
  .pds-popover-row.pds-color-level-3:after {
    content: '';
    position: absolute;
    inset-inline-start: 35px;
    top: 14px;
    background-color: #EB1313;
    height: 16px;
    width: 16px;
    border-radius: 2px; }
  .pds-popover-row.pds-color-level-3 [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-popover-row.pds-color-level-3 [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
    -webkit-padding-start: 24px;
            padding-inline-start: 24px; }

.pds-popover-row.pds-color-level-4 {
  position: relative; }
  .pds-popover-row.pds-color-level-4:after {
    content: '';
    position: absolute;
    inset-inline-start: 35px;
    top: 14px;
    background-color: #52BA2B;
    height: 16px;
    width: 16px;
    border-radius: 2px; }
  .pds-popover-row.pds-color-level-4 [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-popover-row.pds-color-level-4 [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
    -webkit-padding-start: 24px;
            padding-inline-start: 24px; }

.pds-popover-row.pds-color-level-5 {
  position: relative; }
  .pds-popover-row.pds-color-level-5:after {
    content: '';
    position: absolute;
    inset-inline-start: 35px;
    top: 14px;
    background-color: #2A5F16;
    height: 16px;
    width: 16px;
    border-radius: 2px; }
  .pds-popover-row.pds-color-level-5 [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type, .pds-popover-row.pds-color-level-5 [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
    -webkit-padding-start: 24px;
            padding-inline-start: 24px; }

.pds-flag-indicators-container {
  position: relative;
  inset-inline-start: 50%;
  -webkit-margin-start: -8px;
          margin-inline-start: -8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 16px; }

pds-score-cell .pds-flag-indicators-container {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px; }

.pds-flag-indicators-dot {
  height: 6px;
  width: 6px;
  border: 1px solid #C4C9CA;
  border-radius: 50%;
  margin: 1px; }
  .pds-flag-indicators-dot.pds-is-selected {
    border: none; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-late {
      background: #EB1313; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-missing {
      background: #F5691D; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-incomplete {
      background: #0075DB; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-absent {
      background: #52BA2B; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-collected {
      background: #00427C; }
    .pds-flag-indicators-dot.pds-is-selected.pds-flag-exempt {
      background: #9C56B9; }
    .pds-flag-indicators-dot.pds-is-selected.pds-has-comments {
      background: #0075DB; }
  .pds-flag-indicators-dot.pds-hidden-dots {
    border: none; }

pds-summary-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #FFFFFF;
  border: 1px solid #838E91; }
  pds-summary-area.pds-summary-area-expanded .pds-summary-area-one-liner {
    white-space: normal; }
  pds-summary-area.pds-summary-area-expanded header > button {
    border-end-end-radius: 0; }
  pds-summary-area header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    pds-summary-area header > div {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      padding: 5px;
      max-width: calc(~"100% - 34px"); }
      pds-summary-area header > div div.pds-summary-area-one-liner {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-margin-start: 20px;
                margin-inline-start: 20px; }
      pds-summary-area header > div div.pds-tooltip {
        max-width: 97vw;
        white-space: normal; }
    pds-summary-area header > button {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      height: 34px;
      width: 34px;
      max-width: 34px;
      border-top: none;
      border-bottom: none;
      -webkit-border-end: none;
              border-inline-end: none;
      border-start-start-radius: 0;
      border-end-start-radius: 0; }
      pds-summary-area header > button svg {
        height: 16px;
        width: 16px; }
  pds-summary-area section {
    border-top: 1px solid #838E91;
    padding: 5px 10px; }
    pds-summary-area section .pds-tabs-panel {
      overflow-y: auto;
      max-height: 250px; }
      pds-summary-area section .pds-tabs-panel .pds-summary-area-grade-scale-title {
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-padding-end: 0px;
                padding-inline-end: 0px;
        -webkit-padding-start: 18px;
                padding-inline-start: 18px; }
  pds-summary-area td > div.pds-color-level-1 {
    position: relative; }
    pds-summary-area td > div.pds-color-level-1:after {
      content: '';
      position: absolute;
      inset-inline-start: 8px;
      margin-top: -8px;
      background-color: #F9CE33;
      height: 16px;
      width: 16px;
      border-radius: 2px; }
  pds-summary-area td > div.pds-color-level-2 {
    position: relative; }
    pds-summary-area td > div.pds-color-level-2:after {
      content: '';
      position: absolute;
      inset-inline-start: 8px;
      margin-top: -8px;
      background-color: #F5691D;
      height: 16px;
      width: 16px;
      border-radius: 2px; }
  pds-summary-area td > div.pds-color-level-3 {
    position: relative; }
    pds-summary-area td > div.pds-color-level-3:after {
      content: '';
      position: absolute;
      inset-inline-start: 8px;
      margin-top: -8px;
      background-color: #EB1313;
      height: 16px;
      width: 16px;
      border-radius: 2px; }
  pds-summary-area td > div.pds-color-level-4 {
    position: relative; }
    pds-summary-area td > div.pds-color-level-4:after {
      content: '';
      position: absolute;
      inset-inline-start: 8px;
      margin-top: -8px;
      background-color: #52BA2B;
      height: 16px;
      width: 16px;
      border-radius: 2px; }
  pds-summary-area td > div.pds-color-level-5 {
    position: relative; }
    pds-summary-area td > div.pds-color-level-5:after {
      content: '';
      position: absolute;
      inset-inline-start: 8px;
      margin-top: -8px;
      background-color: #2A5F16;
      height: 16px;
      width: 16px;
      border-radius: 2px; }
  pds-summary-area td > pds-icon > svg {
    width: 20px;
    height: 20px;
    margin: 4px; }
  pds-summary-area .pds-flag-missing > svg {
    fill: #F5691D; }
  pds-summary-area .pds-flag-late > svg {
    fill: #EB1313; }
  pds-summary-area .pds-flag-absent > svg {
    fill: #52BA2B; }
  pds-summary-area .pds-flag-incomplete > svg {
    fill: #0075DB; }
  pds-summary-area .pds-flag-collected > svg {
    fill: #00427C; }
  pds-summary-area .pds-flag-exempt > svg {
    fill: #9C56B9; }

pds-summary-area-button button {
  border-top: none;
  border-bottom: none; }

pds-comment-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #FFFFFF; }
  pds-comment-area header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
  pds-comment-area header span, pds-comment-area h4 {
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    padding: 5px 10px; }
  pds-comment-area h4 {
    border-bottom: 1px solid #838E91; }
  pds-comment-area pds-icon[name="star-f"] {
    fill: #C4C9CA;
    -webkit-margin-start: 15px;
            margin-inline-start: 15px; }
    pds-comment-area pds-icon[name="star-f"].pds-is-selected {
      fill: #F89763; }
  pds-comment-area pds-grid-widget .pds-filter-controls {
    margin-bottom: 0; }
  pds-comment-area table {
    margin: 0;
    width: 100%; }
    pds-comment-area table thead th {
      background-color: #FFFFFF;
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 3px;
      height: 34px; }
      pds-comment-area table thead th:first-child {
        width: 50px;
        -webkit-padding-start: 5px;
                padding-inline-start: 5px; }
      pds-comment-area table thead th:nth-child(2) {
        width: 78px; }
      pds-comment-area table thead th:last-child {
        width: 40px; }
    pds-comment-area table tbody td {
      height: 50px; }
      pds-comment-area table tbody td:first-child {
        -webkit-padding-start: 0;
                padding-inline-start: 0; }
      pds-comment-area table tbody td:last-child {
        -webkit-padding-end: 5px;
                padding-inline-end: 5px; }
    pds-comment-area table td, pds-comment-area table th {
      background-image: none;
      border-bottom: 1px solid #C4C9CA; }
  pds-comment-area button {
    height: 32px;
    font-size: 14px; }
    @media (max-width: 768px) {
      pds-comment-area button {
        height: 44px;
        font-size: 18px; } }
    pds-comment-area button.pds-button-square {
      width: 32px; }
  pds-comment-area .pds-global-tabs {
    margin-top: 0;
    margin-bottom: 0; }
  pds-comment-area .pds-tabs a {
    line-height: 38px;
    font-size: 12px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 10px;
            padding-inline-end: 10px; }

pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating pds-comment-area pds-icon[name="star-f"] {
  fill: #C4C9CA;
  -webkit-margin-start: 15px;
          margin-inline-start: 15px; }
  pds-score-inspector.pds-score-inspector-tiny .pds-comment-floating pds-comment-area pds-icon[name="star-f"].pds-is-selected {
    fill: #F89763; }

.pds-comment-floating pds-comment-area {
  max-height: calc(~"100vh - 100px");
  overflow-y: auto; }

.pds-image-contents.pds-maintain-aspect-ratio img {
  -o-object-fit: cover;
     object-fit: cover; }

.pds-user-avatar-contents {
  border-radius: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 60px;
  height: 60px;
  margin: auto;
  -ms-flex-pack: distribute;
      justify-content: space-around; }
  .pds-user-avatar-contents .pds-image-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%; }
    .pds-user-avatar-contents .pds-image-contents .pds-icon-svg {
      fill: #FFFFFF; }
    .pds-user-avatar-contents .pds-image-contents img, .pds-user-avatar-contents .pds-image-contents .user-avatar-even {
      width: 60px;
      height: 60px; }
    .pds-user-avatar-contents .pds-image-contents .user-avatar-horizontal-orientation {
      max-width: initial;
      max-height: 100%; }
    .pds-user-avatar-contents .pds-image-contents .user-avatar-vertical-orientation {
      max-height: initial;
      max-width: 100%; }
  .pds-user-avatar-contents.avatar-gradient-one {
    background: -webkit-gradient(linear, left top, left bottom, from(#C86DD7), to(#3023AE));
    background: linear-gradient(#C86DD7, #3023AE); }
  .pds-user-avatar-contents.avatar-gradient-two {
    background: -webkit-gradient(linear, left top, left bottom, from(#80DFBF), to(#1C7564));
    background: linear-gradient(#80DFBF, #1C7564); }
  .pds-user-avatar-contents.avatar-gradient-three {
    background: -webkit-gradient(linear, left top, left bottom, from(#F5515F), to(#9F041B));
    background: linear-gradient(#F5515F, #9F041B); }
  .pds-user-avatar-contents.avatar-gradient-four {
    background: -webkit-gradient(linear, left top, left bottom, from(#51A9F5), to(#044A9F));
    background: linear-gradient(#51A9F5, #044A9F); }
  .pds-user-avatar-contents.avatar-gradient-five {
    background: -webkit-gradient(linear, left top, left bottom, from(#F84DE8), to(#A10069));
    background: linear-gradient(#F84DE8, #A10069); }
  .pds-user-avatar-contents.avatar-gradient-six {
    background: -webkit-gradient(linear, left top, left bottom, from(#88D8FF), to(#008890));
    background: linear-gradient(#88D8FF, #008890); }
  .pds-user-avatar-contents.avatar-gradient-seven {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFD331), to(#F76B1C));
    background: linear-gradient(#FFD331, #F76B1C); }
  .pds-user-avatar-contents.avatar-gradient-eight {
    background: -webkit-gradient(linear, left top, left bottom, from(#A1E445), to(#4E7700));
    background: linear-gradient(#A1E445, #4E7700); }

.pds-user-menu-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  width: 70px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .pds-user-menu-contents button {
    height: 60px;
    width: 70px;
    min-width: unset;
    background: none;
    border: none;
    padding: 0;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-user-menu-contents button:hover:not([disabled]), .pds-user-menu-contents button:focus:not([disabled]) {
      cursor: pointer;
      background: transparent;
      outline: none; }
      .pds-user-menu-contents button:hover:not([disabled]) .pds-user-menu-user, .pds-user-menu-contents button:focus:not([disabled]) .pds-user-menu-user {
        border-color: #FFFFFF; }

.pds-user-menu-user {
  font-size: 16px;
  color: #FFFFFF;
  border: 2px solid #C4C9CA;
  background: #0075DB;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  cursor: pointer;
  padding: 0px;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased; }
  .pds-user-menu-user .pds-user-avatar-contents {
    background: #0075DB;
    width: 36px;
    height: 36px; }
    .pds-user-menu-user .pds-user-avatar-contents .pds-image-contents img {
      width: 36px;
      height: 36px;
      -o-object-fit: cover;
         object-fit: cover; }
  .pds-user-menu-user:hover, .pds-user-menu-user:hover:not(disabled), .pds-user-menu-user:focus,
  .pds-user-menu-user button.pds-user-menu-trigger:hover:not(disabled), .pds-user-menu-user button.pds-user-menu-trigger:focus:not(disabled) {
    background: #0075DB;
    border-color: #FFFFFF; }

.pds-user-menu-theme li {
  text-align: start;
  margin-bottom: 5px; }
  .pds-user-menu-theme li:last-child {
    margin-bottom: 0; }
  .pds-user-menu-theme li pds-icon {
    -webkit-margin-end: 15px;
            margin-inline-end: 15px; }
    .pds-user-menu-theme li pds-icon svg {
      height: 20px;
      fill: #677274; }
  .pds-user-menu-theme li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #333333;
    font-size: 14px;
    height: 32px; }

[data-tippy-root] .tippy-box.popover-theme.pds-user-menu-theme {
  max-height: calc(~"100vh - 85px");
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white !important;
  width: 240px;
  padding: 5px;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px; }
  @media (max-width: 768px) {
    [data-tippy-root] .tippy-box.popover-theme.pds-user-menu-theme {
      display: none; } }

.pds-error-page-content .pds-error-page-image {
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
  display: block;
  margin-bottom: 20px;
  width: 467px;
  height: 204px; }
  @media (max-width: 768px) {
    .pds-error-page-content .pds-error-page-image {
      width: 357px;
      height: 156px; } }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-page-image {
      width: 280px;
      height: 122px;
      margin-top: 0px;
      margin-bottom: 5px; } }

.pds-error-page-content .pds-error-page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 488px; }
  @media (max-width: 768px) {
    .pds-error-page-content .pds-error-page-actions {
      width: 374px;
      height: 440px; } }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-page-actions {
      width: 280px;
      height: 440px; } }

.pds-error-page-content .pds-error-message-category {
  color: #0075DB;
  margin: auto;
  font-weight: 600;
  font-size: 24px;
  margin-top: 30px;
  text-align: center; }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-message-category {
      width: 123px;
      line-height: 36px;
      margin: auto;
      font-size: 21px;
      margin-top: 5px;
      text-align: center;
      margin-bottom: 10px; } }

.pds-error-page-content .pds-error-message-description {
  color: #677274;
  font-size: 20px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 46px;
  text-align: center; }
  @media (max-width: 768px) {
    .pds-error-page-content .pds-error-message-description {
      margin-top: 55px;
      margin-bottom: 55px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-message-description {
      font-size: 16px;
      width: 280px;
      height: 60px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 24px; } }
  .pds-error-page-content .pds-error-message-description .pds-error-message-descriptions:first-child {
    font-weight: 600;
    padding-bottom: 5px; }
    @media (max-width: 576px) {
      .pds-error-page-content .pds-error-message-description .pds-error-message-descriptions:first-child {
        padding-bottom: 0px; } }

.pds-error-page-content .pds-error-page-primary-actions {
  height: auto;
  width: 450px;
  border-radius: 4px;
  background-color: #0075DB;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-padding-end: 15px;
          padding-inline-end: 15px;
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
  margin-bottom: 15px; }
  @media (max-width: 768px) {
    .pds-error-page-content .pds-error-page-primary-actions {
      width: 340px;
      margin-bottom: 15px; } }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-page-primary-actions {
      width: 280px;
      margin-top: 10px;
      height: auto;
      margin-bottom: 15px; } }
  .pds-error-page-content .pds-error-page-primary-actions .pds-error-page-primary-actions-text {
    height: auto;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
    @media (max-width: 576px) {
      .pds-error-page-content .pds-error-page-primary-actions .pds-error-page-primary-actions-text {
        height: auto;
        margin: auto; } }

.pds-error-page-content .pds-error-page-secondary-actions {
  height: auto;
  width: 450px;
  border: 1px solid #0075DB;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-padding-end: 15px;
          padding-inline-end: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
  margin-top: 15px; }
  @media (max-width: 768px) {
    .pds-error-page-content .pds-error-page-secondary-actions {
      width: 340px;
      margin-top: 15px; } }
  @media (max-width: 576px) {
    .pds-error-page-content .pds-error-page-secondary-actions {
      width: 280px;
      height: auto;
      margin-top: 15px;
      margin-bottom: 15px; } }
  .pds-error-page-content .pds-error-page-secondary-actions .pds-error-page-secondary-actions-text {
    height: auto;
    color: #0075DB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
    @media (max-width: 576px) {
      .pds-error-page-content .pds-error-page-secondary-actions .pds-error-page-secondary-actions-text {
        height: auto;
        margin: auto; } }

.pds-error-page-content pds-copyright-clause {
  height: 30px;
  width: 488px;
  text-align: center;
  margin-top: 84px;
  -webkit-margin-start: auto;
          margin-inline-start: auto;
  -webkit-margin-end: auto;
          margin-inline-end: auto; }
  @media (max-width: 768px) {
    .pds-error-page-content pds-copyright-clause {
      width: 314px;
      margin-top: 55px; } }
  @media (max-width: 576px) {
    .pds-error-page-content pds-copyright-clause {
      width: 280px;
      height: auto;
      margin-top: 15px;
      margin-bottom: 26px; } }

@media (max-width: 576px) {
  .pds-error-page-content pds-error-page {
    padding-top: 50px; } }

.pds-copyright-clause-contents {
  color: #A8B1B2;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  line-height: 15px; }

.pds-file-picker-widget-content input[type="file"] {
  border: none;
  height: .1px;
  width: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.pds-file-picker-widget-content input[type="file"] + label {
  color: green; }

.pds-file-picker-widget-content .pds-file-picker-selected-files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-padding-start: 5px;
          padding-inline-start: 5px; }

.pds-file-picker-widget-content button {
  white-space: nowrap; }

.pds-textarea-widget-content .pds-readonly-data, .pds-textarea-widget-content .pds-readonly-data-long {
  white-space: normal; }

.pds-assignment-return-notification-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px; }
  .pds-assignment-return-notification-content .fill-category-color-1 {
    fill: #00427C; }
  .pds-assignment-return-notification-content .fill-category-color-2 {
    fill: #12B19F; }
  .pds-assignment-return-notification-content .fill-category-color-3 {
    fill: #52BA2B; }
  .pds-assignment-return-notification-content .fill-category-color-4 {
    fill: #DA2F6B; }
  .pds-assignment-return-notification-content .fill-category-color-5 {
    fill: #EB1313; }
  .pds-assignment-return-notification-content .fill-category-color-6 {
    fill: #F5691D; }
  .pds-assignment-return-notification-content .fill-category-color-7 {
    fill: #9C56B9; }
  .pds-assignment-return-notification-content .fill-category-color-8 {
    fill: #677274; }
  .pds-assignment-return-notification-content .pds-notification-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .pds-assignment-return-notification-content .pds-notification-title {
    font-size: 12px;
    font-weight: 700; }
  .pds-assignment-return-notification-content .pds-notification-timestamp {
    width: 100%;
    text-align: end;
    color: #677274; }

.pds-immersive-reader-content.pds-button-blend {
  padding: 5px; }
  .pds-immersive-reader-content.pds-button-blend:hover:not(:disabled) {
    background-color: #F1F4F4; }
  .pds-immersive-reader-content.pds-button-blend span {
    font-size: 14px; }

.pds-immersive-reader-content pds-icon ~ span {
  -webkit-margin-start: 5px;
          margin-inline-start: 5px; }

.pds-immersive-reader-content pds-icon {
  -webkit-margin-start: 0px;
          margin-inline-start: 0px; }

.pds-section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  border-bottom: 1px solid #C4C9CA;
  width: calc(~"100% - 30px");
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 15px 15px; }
  .pds-section-header .pds-section-header-text {
    width: 100%;
    margin: 10px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pds-section-header .pds-section-header-text pds-icon {
      width: 20px;
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }
  .pds-section-header .pds-section-header-primary {
    word-break: break-word;
    padding: 0;
    margin: 0;
    color: #333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600; }
  .pds-section-header .pds-section-header-secondary {
    word-break: break-word;
    color: #677274;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; }
  .pds-section-header .pds-section-header-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px; }
  .pds-section-header.pds-section-header-flush {
    margin-bottom: 0; }

.pds-logout-content .logout-modal-radio-button-widget,
.pds-logout-content .pds-logout-dialog {
  max-width: 500px; }
  .pds-logout-content .logout-modal-radio-button-widget .pds-radio-button-widget-content.pds-radio-button-widget fieldset.pds-input-radio.pds-radio-button-widget,
  .pds-logout-content .pds-logout-dialog .pds-radio-button-widget-content.pds-radio-button-widget fieldset.pds-input-radio.pds-radio-button-widget {
    -webkit-box-flex: unset;
        -ms-flex: unset;
            flex: unset; }

.pds-logout-content .pds-modal-actions, .pds-logout-content .pds-modal-actions-binary {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.pds-parsed-html-content-container {
  position: relative; }
  .pds-parsed-html-content-container .pds-section-overlay-container {
    position: absolute;
    inset-inline-start: 0;
    top: 0; }
    .pds-parsed-html-content-container .pds-section-overlay-container .pds-section-overlay {
      position: absolute;
      z-index: 5500;
      cursor: pointer; }
      .pds-parsed-html-content-container .pds-section-overlay-container .pds-section-overlay.pds-section-overlay-debug {
        background: rgba(128, 0, 128, 0.3); }
      .pds-parsed-html-content-container .pds-section-overlay-container .pds-section-overlay.pds-section-overlay-selected {
        background-color: rgba(92, 179, 255, 0.2);
        border: solid 1px #5CB3FF; }
      .pds-parsed-html-content-container .pds-section-overlay-container .pds-section-overlay.pds-section-overlay-focused {
        background-color: rgba(103, 114, 116, 0.1); }
    .pds-parsed-html-content-container .pds-section-overlay-container button.pds-button-round {
      position: absolute;
      -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
              box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
      z-index: 5600;
      /* These are the new button styles which are needed for this component before the PDS team has a chance to fully implement
       * the new global button styles.  These are expected to be removed in the global-buttons updates branch.*/
      /* PDS-BUTTON-OVERRIDE: Chris, start removing here */
      height: 32px;
      width: 32px;
      padding: 0;
      color: #0057A3;
      border: 1px solid #0075DB;
      /* PDS-BUTTON-OVERRIDE: Chris, stop removing here  */ }
      .pds-parsed-html-content-container .pds-section-overlay-container button.pds-button-round pds-icon {
        fill: #0057A3; }
        .pds-parsed-html-content-container .pds-section-overlay-container button.pds-button-round pds-icon svg {
          height: 20px;
          width: 20px; }
      .pds-parsed-html-content-container .pds-section-overlay-container button.pds-button-round.pds-primary pds-icon {
        fill: #FFFFFF; }

.pds-gw-date-filter-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }
  .pds-gw-date-filter-content button.pds-date-widget-dialog-trigger-button,
  .pds-gw-date-filter-content select.pds-gw-date-filter-operator-select,
  .pds-gw-date-filter-content input {
    font-weight: 600;
    padding-top: 3px;
    padding-bottom: 3px; }
  .pds-gw-date-filter-content select.pds-gw-date-filter-operator-select {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    font-family: Open Sans, sans-serif;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    width: 60px; }
    .pds-gw-date-filter-content select.pds-gw-date-filter-operator-select option {
      font-weight: 400; }
    @media (max-width: 768px) {
      .pds-gw-date-filter-content select.pds-gw-date-filter-operator-select {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px;
        width: 75px; } }
  .pds-gw-date-filter-content pds-date-widget {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px; }
    @media (max-width: 768px) {
      .pds-gw-date-filter-content pds-date-widget {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
    .pds-gw-date-filter-content pds-date-widget .pds-validation {
      display: block; }
    .pds-gw-date-filter-content pds-date-widget input {
      width: calc(~"100% - 32px"); }
      @media (max-width: 768px) {
        .pds-gw-date-filter-content pds-date-widget input {
          width: calc(~"100% - 44px"); } }
    .pds-gw-date-filter-content pds-date-widget .pds-validation {
      color: #970C0C; }
  .pds-gw-date-filter-content .pds-gw-date-filter-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    @media (max-width: 576px) {
      .pds-gw-date-filter-content .pds-gw-date-filter-inputs {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .pds-gw-date-filter-content .pds-gw-date-filter-input-1, .pds-gw-date-filter-content .pds-gw-date-filter-input-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    .pds-gw-date-filter-content .pds-gw-date-filter-input-1 > span, .pds-gw-date-filter-content .pds-gw-date-filter-input-2 > span {
      line-height: 32px;
      -webkit-margin-end: 2px;
              margin-inline-end: 2px; }
      @media (max-width: 768px) {
        .pds-gw-date-filter-content .pds-gw-date-filter-input-1 > span, .pds-gw-date-filter-content .pds-gw-date-filter-input-2 > span {
          line-height: 44px; } }
  .pds-gw-date-filter-content.pds-gw-date-filter-range-fields .pds-gw-date-filter-operator-select {
    width: 95px; }
    @media (max-width: 768px) {
      .pds-gw-date-filter-content.pds-gw-date-filter-range-fields .pds-gw-date-filter-operator-select {
        width: calc(~"100% - 5px");
        max-width: none;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; } }
  @media (max-width: 768px) {
    .pds-gw-date-filter-content.pds-gw-date-filter-range-fields input {
      -webkit-padding-start: 5px;
              padding-inline-start: 5px;
      -webkit-padding-end: 3px;
              padding-inline-end: 3px; } }
  @media (max-width: 992px) {
    .pds-gw-date-filter-content.pds-gw-date-filter-range-fields {
      min-width: 465px; } }
  @media (max-width: 768px) {
    .pds-gw-date-filter-content.pds-gw-date-filter-range-fields {
      min-width: 350px;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  @media (max-width: 576px) {
    .pds-gw-date-filter-content.pds-gw-date-filter-range-fields {
      min-width: 0; } }

.pds-grid-widget-filter-content .pds-filter-controls {
  margin-bottom: 15px;
  background-color: #DFE2E2;
  /* The box that holds all of the filter rows*/ }
  .pds-grid-widget-filter-content .pds-filter-controls button.pds-filter-control-button,
  .pds-grid-widget-filter-content .pds-filter-controls select.pds-filter-select-options {
    padding-top: 3px;
    padding-bottom: 3px; }
  .pds-grid-widget-filter-content .pds-filter-controls select.pds-filter-select-options {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 600; }
    .pds-grid-widget-filter-content .pds-filter-controls select.pds-filter-select-options option {
      font-weight: 400; }
  .pds-grid-widget-filter-content .pds-filter-controls button {
    padding: 6px; }
    .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square {
      padding: 6px; }
      .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square pds-icon {
        -webkit-margin-start: 0;
                margin-inline-start: 0; }
      @media (max-width: 768px) {
        .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex; } }
      @media (max-width: 576px) {
        .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square.pds-grid-widget-filter-add-button-2 {
          display: none; } }
      .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square.pds-grid-widget-filter-add-button-1 {
        display: none; }
        @media (max-width: 576px) {
          .pds-grid-widget-filter-content .pds-filter-controls button.pds-button-square.pds-grid-widget-filter-add-button-1 {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex; } }
    .pds-grid-widget-filter-content .pds-filter-controls button pds-icon svg {
      width: 16px;
      height: 16px; }
    .pds-grid-widget-filter-content .pds-filter-controls button.pds-selection-status-item {
      padding: 3px 10px; }
  .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box {
    border: none;
    padding-top: 10px;
    -webkit-padding-end: 5px;
            padding-inline-end: 5px;
    padding-bottom: 5px;
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
    /* Each Div Represents 1 filter */ }
    .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
      /* The label or select that shows up first in each row*/
      /* Individual styles for each of the filter types*/ }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container > button, .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container button {
        margin: 0;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        border: none; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container > button:hover, .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container button:hover {
          border: none; }
        @media (max-width: 768px) {
          .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container > button, .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container button {
            -webkit-margin-end: 5px;
                    margin-inline-end: 5px; } }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-filter-description {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        margin-bottom: 0;
        margin-top: 0; }
        @media (max-width: 576px) {
          .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-filter-description {
            -webkit-margin-end: 5px;
                    margin-inline-end: 5px; } }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container label.pds-filter-description {
        line-height: 34px; }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer > * {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
                  flex: 1 1 100%; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer pds-gw-text-filter {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer pds-gw-number-filter {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer pds-gw-date-filter {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container pds-element-renderer pds-gw-multi-select-filter {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: relative; }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container input[type="checkbox"] {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -ms-flex-positive: 0;
            flex-grow: 0;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        margin-bottom: 5px;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container > * {
          -webkit-box-flex: 0;
              -ms-flex: 0 1 auto;
                  flex: 0 1 auto; }
        @media (max-width: 768px) {
          .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container {
            -webkit-margin-end: 5px;
                    margin-inline-end: 5px; } }
        @media (max-width: 576px) {
          .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container .pds-gw-filter-selector-container {
            -webkit-margin-end: 0;
                    margin-inline-end: 0; } }
      @media (max-width: 576px) {
        .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box > .pds-filter-container {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; } }
    @media (max-width: 768px) {
      .pds-grid-widget-filter-content .pds-filter-controls .pds-filter-box {
        -webkit-padding-end: 10px;
                padding-inline-end: 10px; } }
  .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .grid-widget-clear-all {
      line-height: 12px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      white-space: nowrap;
      margin-bottom: 5px; }
      @media (max-width: 768px) {
        .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .grid-widget-clear-all {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          width: auto; } }
    .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .pds-selection-status {
      background: transparent;
      padding-top: 0;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      border: none; }
      .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .pds-selection-status .pds-selection-status-item {
        margin-bottom: 5px; }
        @media (max-width: 768px) {
          .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .pds-selection-status .pds-selection-status-item {
            -webkit-margin-end: 5px;
                    margin-inline-end: 5px; } }
      @media (max-width: 768px) {
        .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .pds-selection-status {
          -webkit-padding-start: 5px;
                  padding-inline-start: 5px; } }
      @media (max-width: 576px) {
        .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar .pds-selection-status {
          -webkit-box-ordinal-group: 4;
              -ms-flex-order: 3;
                  order: 3;
          -webkit-padding-start: 0;
                  padding-inline-start: 0; } }
    .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar > span.pds-filter-result {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      padding-top: 5px;
      margin-bottom: 5px;
      -webkit-margin-end: 15px;
              margin-inline-end: 15px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      @media (max-width: 576px) {
        .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar > span.pds-filter-result {
          -webkit-box-ordinal-group: 3;
              -ms-flex-order: 2;
                  order: 2;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;
          -webkit-box-pack: end;
              -ms-flex-pack: end;
                  justify-content: flex-end;
          -webkit-margin-end: 15px;
                  margin-inline-end: 15px; } }
    @media (max-width: 576px) {
      .pds-grid-widget-filter-content .pds-filter-controls .pds-selection-status-bar {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }

.pds-grid-widget-filter-toggle-content .pds-grid-widget-filter-button {
  border: none;
  background-color: transparent; }
  .pds-grid-widget-filter-toggle-content .pds-grid-widget-filter-button pds-icon {
    margin-top: 4px;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 3px;
    -webkit-margin-start: 0;
            margin-inline-start: 0; }
  .pds-grid-widget-filter-toggle-content .pds-grid-widget-filter-button:hover {
    background-color: #FFFFFF; }

.pds-grid-widget-pagination-control-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 20px 20px; }
  @media (max-width: 576px) {
    .pds-grid-widget-pagination-control-content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
  .pds-grid-widget-pagination-control-content .pds-pagination-control-begin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 66%;
            flex: 1 1 66%;
    /* Shows the {start} - {end} of {total} message after the filter box, and before the injected content.*/ }
    @media (max-width: 768px) {
      .pds-grid-widget-pagination-control-content .pds-pagination-control-begin {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    @media (max-width: 576px) {
      .pds-grid-widget-pagination-control-content .pds-pagination-control-begin {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      font-size: 14px;
      line-height: 21px;
      color: #4D5557; }
      .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span {
        padding: 5px 0 6px;
        white-space: nowrap; }
        @media (max-width: 768px) {
          .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span {
            padding: 11px 0 12px; } }
      .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker button {
        -webkit-margin-start: 10px;
                margin-inline-start: 10px; }
      @media (max-width: 768px) {
        .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          -webkit-margin-end: 30px;
                  margin-inline-end: 30px; } }
      @media (max-width: 576px) {
        .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-margin-end: 0;
                  margin-inline-end: 0; } }
    .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 50%;
              flex: 0 1 50%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 5px 20px 6px;
      font-size: 14px;
      line-height: 21px;
      color: #4D5557; }
      @media (max-width: 768px) {
        .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          padding-top: 11px;
          -webkit-padding-end: 20px;
                  padding-inline-end: 20px;
          padding-bottom: 12px;
          -webkit-padding-start: 0;
                  padding-inline-start: 0; } }
      @media (max-width: 576px) {
        .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count {
          padding: 5px 0 15px; } }
  .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33%;
            flex: 1 1 33%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    [dir='rtl'] .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls pds-icon.pds-grid-widget-pagination-control[name="pointer-left"], [dir='rtl']
    .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls pds-icon.pds-grid-widget-pagination-control[name="pointer-right"] {
      -webkit-transform: scaleX(-1);
          -ms-transform: scaleX(-1);
              transform: scaleX(-1); }
    .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls div select {
        -webkit-margin-start: 10px;
                margin-inline-start: 10px;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        min-width: 150px; }
    @media (max-width: 768px) {
      .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto; } }
    @media (max-width: 576px) {
      .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }

.pds-gw-multi-field-filter-content input {
  font-weight: 600;
  height: 32px;
  padding-top: 3px;
  padding-bottom: 3px; }
  @media (max-width: 768px) {
    .pds-gw-multi-field-filter-content input {
      height: 44px; } }
  @media (max-width: 576px) {
    .pds-gw-multi-field-filter-content input {
      height: 44px; } }

.pds-gw-multi-field-filter-content pds-text-widget {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  margin-bottom: 5px; }
  .pds-gw-multi-field-filter-content pds-text-widget .pds-validation {
    color: #970C0C; }
  @media (max-width: 768px) {
    .pds-gw-multi-field-filter-content pds-text-widget {
      -webkit-margin-end: 5px;
              margin-inline-end: 5px; } }

.pds-gw-multi-select-filter-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .pds-gw-multi-select-filter-content .pds-popover-trigger-content {
    -webkit-padding-start: 0;
            padding-inline-start: 0; }
    @media (max-width: 576px) {
      .pds-gw-multi-select-filter-content .pds-popover-trigger-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
  .pds-gw-multi-select-filter-content pds-multi-select-widget button.pds-button-multiselect {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    width: auto; }
    .pds-gw-multi-select-filter-content pds-multi-select-widget button.pds-button-multiselect pds-icon {
      top: 6px; }
      @media (max-width: 768px) {
        .pds-gw-multi-select-filter-content pds-multi-select-widget button.pds-button-multiselect pds-icon {
          top: 11px; } }
    @media (max-width: 768px) {
      .pds-gw-multi-select-filter-content pds-multi-select-widget button.pds-button-multiselect {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
    @media (max-width: 576px) {
      .pds-gw-multi-select-filter-content pds-multi-select-widget button.pds-button-multiselect {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto; } }
  .pds-gw-multi-select-filter-content pds-multi-select-widget .pds-validation {
    color: #970C0C; }
  .pds-gw-multi-select-filter-content .pds-input-checkbox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    margin-bottom: 5px; }
    .pds-gw-multi-select-filter-content .pds-input-checkbox label {
      display: inline-block;
      width: auto; }
      .pds-gw-multi-select-filter-content .pds-input-checkbox label .pds-label-text {
        -webkit-padding-end: 35px;
                padding-inline-end: 35px;
        padding: 5px 0;
        min-height: 34px; }
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:checked ~ .pds-label-text:first-of-type,
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type {
      font-size: 16px;
      line-height: 24px;
      min-height: 34px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:checked ~ .pds-label-text:first-of-type:before,
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:before {
      top: 5px; }
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:checked ~ .pds-label-text:first-of-type:after,
    .pds-gw-multi-select-filter-content .pds-input-checkbox [type="checkbox"]:not(:checked) ~ .pds-label-text:first-of-type:after {
      top: 9px; }
  .pds-gw-multi-select-filter-content .pds-vertical-multiselect {
    border: 1px solid lightgrey;
    position: absolute;
    top: 34px;
    inset-inline-start: 0;
    background-color: white;
    max-height: 220px;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden; }
    .pds-gw-multi-select-filter-content .pds-vertical-multiselect label {
      -webkit-margin-start: 10px;
              margin-inline-start: 10px; }
  .pds-gw-multi-select-filter-content button.pds-remove-option {
    -webkit-padding-end: 5px;
            padding-inline-end: 5px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px; }
  .pds-gw-multi-select-filter-content .pds-selected-items {
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 5px 10px;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px; }
    @media (max-width: 768px) {
      .pds-gw-multi-select-filter-content .pds-selected-items {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
  @media (max-width: 576px) {
    .pds-gw-multi-select-filter-content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; } }

.pds-gw-number-filter-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }
  .pds-gw-number-filter-content select,
  .pds-gw-number-filter-content input {
    font-weight: 600;
    height: 32px;
    padding-top: 3px;
    padding-bottom: 3px; }
    @media (max-width: 768px) {
      .pds-gw-number-filter-content select,
      .pds-gw-number-filter-content input {
        height: 44px; } }
  .pds-gw-number-filter-content select {
    font-family: Open Sans, sans-serif;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 125px;
            flex: 0 0 125px;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px; }
    .pds-gw-number-filter-content select option {
      font-weight: 400; }
    .pds-gw-number-filter-content select.pds-filter-units {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto;
      width: auto; }
    @media (max-width: 768px) {
      .pds-gw-number-filter-content select {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
  .pds-gw-number-filter-content pds-number-widget {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    width: 70px; }
    .pds-gw-number-filter-content pds-number-widget input {
      width: 100%; }
    .pds-gw-number-filter-content pds-number-widget .pds-validation {
      display: block; }
    @media (max-width: 768px) {
      .pds-gw-number-filter-content pds-number-widget {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
    .pds-gw-number-filter-content pds-number-widget .pds-validation {
      color: #970C0C; }
  .pds-gw-number-filter-content select:not(:first-of-type), .pds-gw-number-filter-content label {
    -webkit-margin-start: 10px;
            margin-inline-start: 10px; }
  .pds-gw-number-filter-content .pds-gw-number-filter-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .pds-gw-number-filter-content .pds-gw-number-filter-input-1, .pds-gw-number-filter-content .pds-gw-number-filter-input-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    .pds-gw-number-filter-content .pds-gw-number-filter-input-1 > span, .pds-gw-number-filter-content .pds-gw-number-filter-input-2 > span {
      line-height: 32px;
      -webkit-margin-end: 5px;
              margin-inline-end: 5px; }
      @media (max-width: 768px) {
        .pds-gw-number-filter-content .pds-gw-number-filter-input-1 > span, .pds-gw-number-filter-content .pds-gw-number-filter-input-2 > span {
          line-height: 44px; } }
  .pds-gw-number-filter-content .pds-gw-number-filter-operator-select {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 60px; }
    @media (max-width: 768px) {
      .pds-gw-number-filter-content .pds-gw-number-filter-operator-select {
        width: 75px; } }
  .pds-gw-number-filter-content.pds-gw-number-filter-range-fields .pds-gw-number-filter-operator-select {
    width: 95px; }
    @media (max-width: 576px) {
      .pds-gw-number-filter-content.pds-gw-number-filter-range-fields .pds-gw-number-filter-operator-select {
        width: calc(~"100% - 5px"); } }
  @media (max-width: 992px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields {
      min-width: 460px; } }
  @media (max-width: 768px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields {
      min-width: 360px; } }
  @media (max-width: 576px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      min-width: 0; } }
  @media (max-width: 992px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units .pds-gw-number-filter-operator-select {
      width: calc(~"100% - 10px"); } }
  @media (max-width: 768px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units .pds-gw-number-filter-operator-select {
      width: calc(~"100% - 5px"); } }
  @media (max-width: 768px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units .pds-gw-number-filter-inputs {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  @media (max-width: 992px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units pds-number-widget {
      -webkit-margin-end: 5px;
              margin-inline-end: 5px;
      width: 65px; } }
  @media (max-width: 768px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units pds-number-widget {
      width: 70px; } }
  @media (max-width: 992px) {
    .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
  .pds-gw-number-filter-content.pds-gw-number-filter-range-fields.pds-gw-number-filter-uses-units .pds-filter-units {
    max-width: 100px; }

.pds-gw-pill-select-filter-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .pds-gw-pill-select-filter-content button {
    font-size: 18px;
    border-radius: 25px;
    min-width: 100px;
    margin-top: 0;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    padding: 3px 6px; }
    .pds-gw-pill-select-filter-content button.pds-primary {
      color: white; }
    @media (max-width: 768px) {
      .pds-gw-pill-select-filter-content button {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
  @media (max-width: 576px) {
    .pds-gw-pill-select-filter-content {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

.pds-gw-select-filter-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .pds-gw-select-filter-content select {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    display: block;
    font-weight: 600;
    padding-top: 3px;
    padding-bottom: 3px;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px; }
    .pds-gw-select-filter-content select option {
      font-weight: 400; }
    @media (max-width: 768px) {
      .pds-gw-select-filter-content select {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }

.pds-grid-widget-sort-fields-content:focus, .pds-sortable:focus {
  outline: none;
  text-decoration: underline;
  border: none; }
  .pds-grid-widget-sort-fields-content:focus .sr-only, .pds-sortable:focus .sr-only {
    display: none; }

.left.pds-grid-widget-sort-fields-content span, .left.pds-sortable span {
  padding: 0;
  -webkit-padding-end: 18px;
          padding-inline-end: 18px; }

.pds-grid-widget-sort-fields-content:hover, .pds-sortable:hover {
  cursor: pointer;
  color: #0075DB; }

.pds-grid-widget-sort-fields-content ::-moz-selection, .pds-sortable ::-moz-selection {
  background: transparent; }

.pds-grid-widget-sort-fields-content ::selection, .pds-sortable ::selection {
  background: transparent; }

.pds-sort-ascending.pds-grid-widget-sort-fields-content i, .pds-sort-ascending.pds-sortable i {
  padding: 0px;
  -webkit-padding-end: 1px;
          padding-inline-end: 1px;
  -webkit-padding-start: 2px;
          padding-inline-start: 2px; }

.pds-sort-descending.pds-grid-widget-sort-fields-content i, .pds-sort-descending.pds-sortable i {
  padding: 0px;
  -webkit-padding-end: 1px;
          padding-inline-end: 1px;
  -webkit-padding-start: 2px;
          padding-inline-start: 2px; }

.pds-grid-widget-sort-fields-content pds-icon, .pds-sortable pds-icon {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px; }
  .pds-grid-widget-sort-fields-content pds-icon svg, .pds-sortable pds-icon svg {
    height: 16px;
    width: 16px; }

.pds-grid-widget-sort-fields-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-padding-end: 20px;
          padding-inline-end: 20px; }
  .pds-grid-widget-sort-fields-content .pds-grid-widget-sort-fields-content-target {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600; }

.pds-sortable span {
  display: inline-block;
  position: relative; }

.pds-gw-text-filter-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .pds-gw-text-filter-content input {
    font-weight: 600;
    height: 32px;
    padding-top: 3px;
    padding-bottom: 3px; }
    @media (max-width: 768px) {
      .pds-gw-text-filter-content input {
        height: 44px; } }
    @media (max-width: 576px) {
      .pds-gw-text-filter-content input {
        height: 44px; } }
  .pds-gw-text-filter-content pds-text-widget {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    .pds-gw-text-filter-content pds-text-widget .pds-validation {
      color: #970C0C; }
    .pds-gw-text-filter-content pds-text-widget .pds-validation {
      display: block; }
    @media (max-width: 768px) {
      .pds-gw-text-filter-content pds-text-widget {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }
  .pds-gw-text-filter-content select {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 125px;
            flex: 0 0 125px;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
    margin-bottom: 5px;
    padding-top: 3px;
    padding-bottom: 3px; }
    .pds-gw-text-filter-content select option {
      font-weight: 400; }
    @media (max-width: 768px) {
      .pds-gw-text-filter-content select {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; } }

/** Polymer Multiselect Widget Styles **/
.pds-multi-select-widget-content label {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }

.pds-multi-select-widget-content .pds-validation {
  color: #CB1010;
  font-size: 14px; }

.pds-multi-select-widget-content button.pds-button-multiselect {
  display: inline-block;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;
  background: none;
  background-color: #FFFFFF;
  border: 1px solid #838E91;
  padding: 3px;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  -webkit-padding-end: 30px;
          padding-inline-end: 30px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  height: 32px; }
  @media (max-width: 768px) {
    .pds-multi-select-widget-content button.pds-button-multiselect {
      height: 44px;
      font-size: 16px;
      line-height: 27px; } }
  @media (max-width: 576px) {
    .pds-multi-select-widget-content button.pds-button-multiselect {
      text-align: start; } }
  .pds-multi-select-widget-content button.pds-button-multiselect:hover, .pds-multi-select-widget-content button.pds-button-multiselect:focus {
    background-color: #F1F4F4;
    text-decoration: underline; }
  .pds-multi-select-widget-content button.pds-button-multiselect:focus {
    border: 1px solid #0075DB; }
  .pds-multi-select-widget-content button.pds-button-multiselect pds-icon {
    position: absolute;
    inset-inline-end: 5px;
    top: 5px;
    fill: #677274;
    margin: 0; }
    @media (max-width: 768px) {
      .pds-multi-select-widget-content button.pds-button-multiselect pds-icon {
        top: 10px; } }
    .pds-multi-select-widget-content button.pds-button-multiselect pds-icon svg {
      width: 20px;
      height: 20px; }
  .pds-multi-select-widget-content button.pds-button-multiselect.pds-is-active {
    border: 1px solid #0075DB;
    background-color: #C2E2FF; }
  .pds-multi-select-widget-content button.pds-button-multiselect span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

[data-tippy-root]:focus {
  outline: none;
  border: none; }

[data-tippy-root] .tippy-box.pds-multi-select-widget-theme {
  width: auto;
  max-height: 45vh;
  overflow-y: auto;
  background-color: white;
  color: #333333;
  border: 1px solid #838E91;
  border-radius: 4px;
  -webkit-box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.2);
          box-shadow: -1px 2px 6px 0 rgba(0, 0, 0, 0.2); }
  [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox,
  [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox {
    padding: 5px 0 0;
    margin: 0;
    border: none; }
    [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul,
    [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul {
      margin: 0 5px; }
      [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul li,
      [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul li {
        min-height: 32px; }
        @media (max-width: 768px) {
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul li,
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul li {
            margin-bottom: 5px;
            height: auto; } }
      [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row,
      [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row {
        margin-bottom: 5px; }
        [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label,
        [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label {
          padding: 0 10px;
          border-radius: 4px; }
          @media (max-width: 768px) {
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label,
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label {
              padding: 6px 10px 5px; } }
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label input,
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label input {
            height: 32px; }
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text,
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            min-height: 32px;
            padding-top: 5px;
            padding-bottom: 6px;
            -webkit-margin-start: 30px;
                    margin-inline-start: 30px; }
            @media (max-width: 768px) {
              [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text,
              [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text {
                font-size: 16px;
                line-height: 24px;
                text-align: start; } }
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:before,
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:before {
              top: 6px;
              inset-inline-start: -30px;
              height: 20px;
              width: 20px; }
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:after,
            [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label .pds-label-text:after {
              top: 8px;
              inset-inline-start: -28px; }
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label.pds-is-selected,
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label.pds-is-selected {
            background-color: #C2E2FF; }
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row label [type="checkbox"]:checked ~ .pds-label-text:first-of-type,
          [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row label [type="checkbox"]:checked ~ .pds-label-text:first-of-type {
            font-weight: 400; }
        [data-tippy-root] .tippy-box.pds-multi-select-widget-theme .tippy-content fieldset.pds-input-checkbox ul .pds-popover-row:hover,
        [data-tippy-root] .tippy-box.pds-multi-select-widget-theme fieldset.pds-input-checkbox ul .pds-popover-row:hover {
          text-decoration: underline;
          background-color: #F1F4F4; }

.pds-pop-over-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .pds-pop-over-content .pds-popover-label-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #333333; }
  .pds-pop-over-content .pds-help-text {
    font-size: 12px;
    line-height: 18px;
    font-style: italic;
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
    margin-bottom: 0;
    font-weight: 400;
    color: #677274; }
  .pds-pop-over-content .pds-pop-over-trigger-button pds-icon {
    margin: 0; }
  .pds-pop-over-content .pds-pop-over-trigger-button.pds-pop-over-has-icon-and-text {
    -webkit-padding-start: 10px;
            padding-inline-start: 10px; }
    .pds-pop-over-content .pds-pop-over-trigger-button.pds-pop-over-has-icon-and-text pds-icon {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }
  .pds-pop-over-content label + template + template + .pds-pop-over-trigger-button {
    margin-top: 7px; }

.pds-pop-over-panel .pds-pop-over-header {
  background-color: #F1F4F4;
  padding: 10px 15px;
  text-align: start; }
  .pds-pop-over-panel .pds-pop-over-header .pds-pop-over-header-name {
    color: #333333;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400; }

.pds-pop-over-panel .pds-popover-item-divider {
  margin: 0 5px 5px;
  width: calc(~"100% - 10px"); }

.pds-pop-over-panel .pds-pop-over-menu-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 45vh;
  padding: 5px 0 0; }
  .pds-pop-over-panel .pds-pop-over-menu-items > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .pds-pop-over-panel .pds-pop-over-menu-items > div button,
  .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 5px 10px 6px;
    margin: 0 5px 5px;
    height: auto;
    line-height: 21px;
    font-size: 14px;
    text-align: start; }
    .pds-pop-over-panel .pds-pop-over-menu-items > div button:hover, .pds-pop-over-panel .pds-pop-over-menu-items > div button:focus,
    .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button:hover,
    .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button:focus {
      outline: none;
      text-decoration: underline;
      background-color: #F1F4F4; }
    .pds-pop-over-panel .pds-pop-over-menu-items > div button[aria-expanded="true"],
    .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button[aria-expanded="true"] {
      background-color: #C2F1FF; }
    .pds-pop-over-panel .pds-pop-over-menu-items > div button pds-icon,
    .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button pds-icon {
      margin: 0;
      margin-top: 1px;
      -webkit-margin-end: 15px;
              margin-inline-end: 15px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      .pds-pop-over-panel .pds-pop-over-menu-items > div button pds-icon svg,
      .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button pds-icon svg {
        height: 20px;
        width: 20px; }
    @media (max-width: 768px) {
      .pds-pop-over-panel .pds-pop-over-menu-items > div button,
      .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-trigger-button {
        height: auto;
        width: auto;
        line-height: 24px;
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px; } }
  .pds-pop-over-panel .pds-pop-over-menu-items .pds-pop-over-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }

.pds-detail-select-widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: Open Sans, sans-serif; }
  .pds-detail-select-widget-content button.pds-button-square {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px; }
    .pds-detail-select-widget-content button.pds-button-square pds-icon {
      height: 20px;
      width: 20px; }
      [dir='rtl'] .pds-detail-select-widget-content button.pds-button-square pds-icon[name='caret-right'], [dir='rtl'] .pds-detail-select-widget-content button.pds-button-square pds-icon[name='caret-left'] {
        -webkit-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
                transform: scaleX(-1); }
  .pds-detail-select-widget-content .pds-picture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 33px;
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
    -webkit-border-end: 0;
            border-inline-end: 0;
    -webkit-margin-start: 3px;
            margin-inline-start: 3px;
    background-color: white; }
    .pds-detail-select-widget-content .pds-picture:hover, .pds-detail-select-widget-content .pds-picture:focus {
      background-color: #F1F4F4; }
    .pds-detail-select-widget-content .pds-picture pds-icon svg {
      width: 20px;
      height: 20px; }
    .pds-detail-select-widget-content .pds-picture pds-img {
      padding: 4px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .pds-detail-select-widget-content .pds-picture pds-img img {
        width: 40px;
        border-radius: 50%; }
  .pds-detail-select-widget-content select {
    background-image: url("../../../../scripts/pds/img/caret-down.svg");
    background-size: 20px;
    background-position: right 5px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    text-overflow: ellipsis;
    background-color: #FFFFFF;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #333333;
    height: 32px;
    fill: #677274;
    padding-top: 0px;
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
    -webkit-padding-end: 27px;
            padding-inline-end: 27px;
    max-width: 400px; }
    [dir='rtl'] .pds-detail-select-widget-content select {
      background-position: left 5px center; }
    .pds-detail-select-widget-content select:hover, .pds-detail-select-widget-content select:focus {
      background-color: #F1F4F4;
      text-decoration: underline;
      border: none;
      outline: none; }
  .pds-detail-select-widget-content :not(.pds-picture) + select {
    -webkit-margin-start: 3px;
            margin-inline-start: 3px; }
  .pds-detail-select-widget-content .pds-picture + template + select {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0; }
  @media (max-width: 992px) {
    .pds-detail-select-widget-content .pds-detail-screen-pro-mode select {
      width: calc(~"100% - 110px"); } }
  @media (max-width: 768px) {
    .pds-detail-select-widget-content select {
      height: 44px;
      line-height: 40px;
      min-width: 44px;
      font-size: 14px;
      border: 1px solid #838E91; }
      .pds-detail-select-widget-content select:hover {
        border: 1px solid #838E91; }
    .pds-detail-select-widget-content button.pds-button-square {
      display: none; } }

.pds-messages-widget-content button.pds-button-dismiss {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  min-width: 0;
  padding: 0;
  position: absolute;
  inset-inline-end: 15px;
  top: 10px;
  border: none; }
  .pds-messages-widget-content button.pds-button-dismiss pds-icon {
    margin: 0; }

.pds-messages-widget-content .pds-message-success .pds-button-dismiss {
  background-color: #E5F7DE; }
  .pds-messages-widget-content .pds-message-success .pds-button-dismiss svg {
    fill: #52BA2B; }
  .pds-messages-widget-content .pds-message-success .pds-button-dismiss:hover, .pds-messages-widget-content .pds-message-success .pds-button-dismiss:focus {
    background: #52BA2B; }
    .pds-messages-widget-content .pds-message-success .pds-button-dismiss:hover svg, .pds-messages-widget-content .pds-message-success .pds-button-dismiss:focus svg {
      fill: #E5F7DE; }
  .pds-messages-widget-content .pds-message-success .pds-button-dismiss:active {
    background: #2d6718; }
    .pds-messages-widget-content .pds-message-success .pds-button-dismiss:active svg {
      fill: #E5F7DE; }

.pds-messages-widget-content .pds-message-info .pds-button-dismiss {
  background-color: #E5F3FF; }
  .pds-messages-widget-content .pds-message-info .pds-button-dismiss svg {
    fill: #0075DB; }
  .pds-messages-widget-content .pds-message-info .pds-button-dismiss:hover, .pds-messages-widget-content .pds-message-info .pds-button-dismiss:focus {
    background: #0075DB; }
    .pds-messages-widget-content .pds-message-info .pds-button-dismiss:hover svg, .pds-messages-widget-content .pds-message-info .pds-button-dismiss:focus svg {
      fill: #E5F3FF; }
  .pds-messages-widget-content .pds-message-info .pds-button-dismiss:active {
    background: #003f75; }
    .pds-messages-widget-content .pds-message-info .pds-button-dismiss:active svg {
      fill: #E5F3FF; }

.pds-messages-widget-content .pds-message-error .pds-button-dismiss {
  background-color: #FDE7E7; }
  .pds-messages-widget-content .pds-message-error .pds-button-dismiss svg {
    fill: #EB1313; }
  .pds-messages-widget-content .pds-message-error .pds-button-dismiss:hover, .pds-messages-widget-content .pds-message-error .pds-button-dismiss:focus {
    background: #EB1313; }
    .pds-messages-widget-content .pds-message-error .pds-button-dismiss:hover svg, .pds-messages-widget-content .pds-message-error .pds-button-dismiss:focus svg {
      fill: #FDE7E7; }
  .pds-messages-widget-content .pds-message-error .pds-button-dismiss:active {
    background: #8d0b0b; }
    .pds-messages-widget-content .pds-message-error .pds-button-dismiss:active svg {
      fill: #FDE7E7; }

.pds-messages-widget-content .pds-message-warning .pds-button-dismiss {
  background-color: #FEEFE7; }
  .pds-messages-widget-content .pds-message-warning .pds-button-dismiss svg {
    fill: #F5691D; }
  .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:hover, .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:focus {
    background: #F5691D; }
    .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:hover svg, .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:focus svg {
      fill: #FEEFE7; }
  .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:active {
    background: #a53f07; }
    .pds-messages-widget-content .pds-message-warning .pds-button-dismiss:active svg {
      fill: #FEEFE7; }

.pds-messages-widget-content .pds-message-success, .pds-messages-widget-content .pds-message-info, .pds-messages-widget-content .pds-message-error, .pds-messages-widget-content .pds-message-warning {
  position: relative;
  -webkit-padding-end: 45px;
          padding-inline-end: 45px; }

.pds-wizard-layout-mode {
  overflow: hidden; }
  .pds-wizard-layout-mode .pds-content, .pds-wizard-layout-mode main .pds-content-basic, main .pds-wizard-layout-mode .pds-content-basic, .pds-wizard-layout-mode main .pss-content, main .pds-wizard-layout-mode .pss-content {
    padding-top: 0;
    height: 95vh;
    overflow: hidden; }
  .pds-wizard-layout-mode .pds-wizard-layout-page {
    height: 100%;
    max-height: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 0;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content {
      height: 100%;
      max-height: 100%;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-steps-container {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 300px;
                flex: 0 0 300px;
        height: 100%;
        max-height: 100%;
        overflow: auto;
        -webkit-border-end: 1px solid #838E91;
                border-inline-end: 1px solid #838E91;
        background-color: #F1F4F4;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        @media (max-width: 576px) {
          .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-steps-container {
            display: none; } }
      .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step {
        height: 100%;
        min-height: 0;
        max-height: 100%;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step .pds-wizard-active-step-header {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 60px;
                  flex: 0 0 60px;
          border-bottom: 1px solid #838E91;
          height: 66px;
          background-color: #FFFFFF;
          margin: 0;
          padding: 0;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          font-family: Open Sans, sans-serif; }
          .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step .pds-wizard-active-step-header h2 {
            color: #333333;
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            margin: 15px;
            padding: 0; }
        .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step .pds-wizard-active-step-toolbar {
          border-bottom: 1px solid #838E91;
          height: 60px;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto; }
        .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step .pds-wizard-active-step-content {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
                  flex: 1 1 100%;
          overflow: auto;
          margin: 0;
          padding: 0;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box; }
          .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-content .pds-wizard-active-step .pds-wizard-active-step-content .pds-wizard-active-step-content-first-item {
            margin-top: 10px; }
    .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 60px;
              flex: 0 0 60px;
      height: 60px;
      -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
              box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
      background-color: #FFFFFF;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-top: 1px solid #838E91;
      margin: 0;
      padding: 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-secondary-buttons {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-padding-end: 0;
                padding-inline-end: 0;
        -webkit-padding-start: 20px;
                padding-inline-start: 20px;
        padding-top: 14px;
        padding-bottom: 14px;
        margin: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-secondary-buttons button {
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px; }
        @media (max-width: 768px) {
          .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-secondary-buttons {
            -webkit-padding-start: 20px;
                    padding-inline-start: 20px;
            -webkit-padding-end: 0;
                    padding-inline-end: 0;
            padding-top: 8px;
            padding-bottom: 8px; } }
      .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-primary-buttons {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-padding-end: 20px;
                padding-inline-end: 20px;
        -webkit-padding-start: 0;
                padding-inline-start: 0;
        padding-top: 14px;
        padding-bottom: 14px;
        margin: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-primary-buttons button {
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px; }
        @media (max-width: 768px) {
          .pds-wizard-layout-mode .pds-wizard-layout-page .pds-wizard-footer .pds-wizard-footer-primary-buttons {
            padding-top: 8px;
            -webkit-padding-end: 20px;
                    padding-inline-end: 20px;
            padding-bottom: 8px;
            -webkit-padding-start: 0;
                    padding-inline-start: 0; } }

.pds-wizard-steps-content ul {
  list-style-type: none;
  padding: 20px;
  margin: 0; }
  .pds-wizard-steps-content ul li {
    margin: 0 0 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pds-wizard-steps-content ul li.pds-wizard-step-is-active button, .pds-wizard-steps-content ul li.pds-wizard-step-is-active.pds-wizard-step-is-complete button {
      color: #0075DB; }
    .pds-wizard-steps-content ul li button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background-color: transparent;
      border: none;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      text-align: start;
      height: auto;
      max-height: none;
      min-height: 0;
      padding: 5px 6px;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      color: #677274;
      border-radius: 4px;
      position: relative; }
      .pds-wizard-steps-content ul li button::before {
        position: absolute;
        content: "";
        top: -2px;
        bottom: calc(~"100% - 7px");
        inset-inline-start: 16px;
        width: 2px;
        background-color: #C4C9CA; }
      .pds-wizard-steps-content ul li button::after {
        width: 2px;
        position: absolute;
        content: "";
        top: 25px;
        bottom: -2px;
        inset-inline-start: 16px;
        inset-inline-end: 1px;
        background-color: #C4C9CA; }
      .pds-wizard-steps-content ul li button:hover:not(:disabled), .pds-wizard-steps-content ul li button:focus {
        background-color: #F1F4F4;
        cursor: pointer;
        text-decoration: underline; }
        .pds-wizard-steps-content ul li button:hover:not(:disabled)::before, .pds-wizard-steps-content ul li button:hover:not(:disabled)::after, .pds-wizard-steps-content ul li button:focus::before, .pds-wizard-steps-content ul li button:focus::after {
          content: none; }
        .pds-wizard-steps-content ul li button:hover:not(:disabled) .pds-wizard-step-icon-box .pds-wizard-step-icon-unseen, .pds-wizard-steps-content ul li button:focus .pds-wizard-step-icon-box .pds-wizard-step-icon-unseen {
          border-color: #0075DB; }
      .pds-wizard-steps-content ul li button:focus {
        outline: none;
        border: none; }
      .pds-wizard-steps-content ul li button .pds-wizard-step-icon-box {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        height: 22px;
        width: 22px;
        -webkit-margin-end: 6px;
                margin-inline-end: 6px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .pds-wizard-steps-content ul li button .pds-wizard-step-icon-box pds-icon {
          background-color: #3E8D21;
          border-radius: 50%;
          height: 12px;
          width: 12px;
          margin: 5px; }
          .pds-wizard-steps-content ul li button .pds-wizard-step-icon-box pds-icon svg {
            margin: 2px;
            height: 8px;
            width: 8px;
            fill: #FFFFFF; }
        .pds-wizard-steps-content ul li button .pds-wizard-step-icon-box .pds-wizard-step-icon-active {
          background-color: #0075DB;
          border-radius: 50%;
          height: 12px;
          width: 12px;
          margin: 5px; }
        .pds-wizard-steps-content ul li button .pds-wizard-step-icon-box .pds-wizard-step-icon-unseen {
          border: 2px solid #A8B1B2;
          background-color: #FFFFFF;
          border-radius: 50%;
          height: 12px;
          width: 12px;
          margin: 5px; }
      .pds-wizard-steps-content ul li button .pds-wizard-step-text {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
                flex: 1 1 100%;
        max-height: 42px;
        overflow: hidden;
        padding-bottom: 1px; }
      .pds-wizard-steps-content ul li button:disabled {
        opacity: .7;
        cursor: default; }
    .pds-wizard-steps-content ul li:first-of-type button::before {
      content: none; }
    .pds-wizard-steps-content ul li:last-of-type button::after {
      content: none; }

.pds-calendar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%; }
  .pds-calendar-content .pds-calendar-table-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 31px;
            flex: 0 0 31px;
    -webkit-padding-end: 8px;
            padding-inline-end: 8px;
    height: 31px;
    border-bottom: 1px solid #838E91;
    background-color: #FFFFFF; }
  .pds-calendar-content .pds-calendar-table-body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%; }
  .pds-calendar-content table {
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    table-layout: fixed; }
    .pds-calendar-content table tbody tr:first-child td:first-child {
      border-start-start-radius: 0; }
    .pds-calendar-content table tbody tr:last-child td:last-child {
      border-end-start-radius: 0; }
    .pds-calendar-content table tbody td {
      background-image: none; }
    .pds-calendar-content table th {
      width: 14%;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      padding: 3px;
      -webkit-padding-start: 0;
              padding-inline-start: 0;
      -webkit-padding-end: 6px;
              padding-inline-end: 6px;
      height: 30px; }
      .pds-calendar-content table th .pds-calendar-day-initial {
        display: none; }
      @media (max-width: 768px) {
        .pds-calendar-content table th {
          font-size: 14px;
          line-height: 21px;
          padding-top: 4px;
          padding-bottom: 5px; } }
      @media (max-width: 576px) {
        .pds-calendar-content table th .pds-calendar-day-label {
          display: none; }
        .pds-calendar-content table th .pds-calendar-day-initial {
          display: inline; } }
    .pds-calendar-content table td {
      width: 14%;
      border: 1px solid #F1F4F4;
      padding: 0;
      vertical-align: top; }
      .pds-calendar-content table td.pds-calendar-weekend, .pds-calendar-content table td.pds-calendar-disabled-day {
        background-color: #F1F4F4; }
      .pds-calendar-content table td.pds-calendar-fill-day .pds-calendar-day-number {
        color: #677274; }
      .pds-calendar-content table td .pds-calendar-day {
        min-width: 44px;
        min-height: 125px;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .pds-calendar-content table td .pds-calendar-day .pds-calendar-disabled-day-text {
          font-size: 12px;
          line-height: 18px;
          font-style: italic;
          color: #677274;
          font-weight: 400;
          overflow: hidden;
          text-align: center;
          margin: 0 5px 5px;
          word-break: break-all; }
        .pds-calendar-content table td .pds-calendar-day .pds-calendar-day-number {
          display: inline-block;
          height: 26px;
          width: 26px;
          text-align: center;
          padding-top: 1px;
          padding-bottom: 1px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin: 5px; }
          .pds-calendar-content table td .pds-calendar-day .pds-calendar-day-number.pds-calendar-is-today {
            color: #FFFFFF;
            background-color: #0075DB;
            border-radius: 50%;
            font-weight: 700; }
          @media (max-width: 576px) {
            .pds-calendar-content table td .pds-calendar-day .pds-calendar-day-number {
              font-size: 14px;
              line-height: 21px;
              padding-top: 2px;
              padding-bottom: 3px; } }
        .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event {
          font-size: 12px;
          line-height: 18px;
          font-weight: 700;
          padding: 5px;
          -webkit-padding-start: 8px;
                  padding-inline-start: 8px;
          height: 28px;
          color: #333333;
          border-color: transparent;
          border-radius: 4px;
          background-color: rgba(0, 87, 163, 0.1);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin: 0 2px 2px;
          min-width: 0;
          position: relative; }
          .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event::after {
            position: absolute;
            content: '';
            inset-inline-start: -1px;
            top: -1px;
            bottom: -1px;
            width: 4px;
            display: block;
            background-color: #0057A3;
            border-start-start-radius: 4px;
            border-end-start-radius: 4px; }
          .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event.pds-selected-calendar-event {
            background-color: #0075DB;
            color: #FFFFFF; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event.pds-selected-calendar-event .pds-calendar-event-container pds-icon svg {
              fill: #FFFFFF; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event.pds-selected-calendar-event:focus, .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event.pds-selected-calendar-event:hover {
              background-color: #00427C; }
          .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event:focus, .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event:hover {
            border: 1px solid #0075DB; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event:focus::after, .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event:hover::after {
              border: 1px solid #0075DB;
              border-inline-end-width: 0; }
          .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container {
            white-space: nowrap;
            -webkit-margin-end: 2px;
                    margin-inline-end: 2px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            overflow: hidden;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container pds-format-time {
              margin: 0;
              -webkit-margin-end: 5px;
                      margin-inline-end: 5px;
              font-weight: 400; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container > * {
              -webkit-box-flex: 0;
                  -ms-flex: 0 0 auto;
                      flex: 0 0 auto; }
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container pds-icon {
              height: 16px;
              width: 16px;
              margin: 1px;
              -webkit-margin-start: 0;
                      margin-inline-start: 0;
              -webkit-margin-end: 5px;
                      margin-inline-end: 5px; }
              .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container pds-icon svg {
                fill: #0075DB;
                height: 16px;
                width: 16px; }
              @media (max-width: 576px) {
                .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event .pds-calendar-event-container pds-icon {
                  display: none; } }
          @media (max-width: 576px) {
            .pds-calendar-content table td .pds-calendar-day button.pds-calendar-event {
              height: 44px; } }

.pds-calendar-legend-content header {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #333333;
  margin: 15px 10px;
  padding: 0; }

.pds-calendar-legend-content ul {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 10px;
  -webkit-margin-start: 25px;
          margin-inline-start: 25px;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px; }
  .pds-calendar-legend-content ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #333333;
    margin: 0 0 15px; }
    .pds-calendar-legend-content ul li div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .pds-calendar-legend-content ul li div pds-icon {
        height: 20px;
        width: 20px; }
        .pds-calendar-legend-content ul li div pds-icon svg {
          height: 20px;
          width: 20px;
          fill: #0075DB; }
        .pds-calendar-legend-content ul li div pds-icon[name="calendar-unavailable"] svg {
          fill: #677274; }
      .pds-calendar-legend-content ul li div span {
        margin: 0;
        -webkit-margin-start: 10px;
                margin-inline-start: 10px;
        padding: 1px 0; }

.pds-calendar-page {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .pds-calendar-page .pds-calendar-page-detail-panel-overlay,
  .pds-calendar-page .pds-calendar-page-legend-overlay {
    display: none; }
    @media (max-width: 576px) {
      .pds-calendar-page .pds-calendar-page-detail-panel-overlay,
      .pds-calendar-page .pds-calendar-page-legend-overlay {
        display: block;
        position: absolute;
        z-index: auto; } }
  .pds-calendar-page .pds-calendar-page-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    background-color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #838E91;
    padding: 5px 10px; }
    .pds-calendar-page .pds-calendar-page-header > button {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; }
    @media (max-width: 768px) {
      .pds-calendar-page .pds-calendar-page-header .pds-button-calendar-today {
        display: none; } }
  .pds-calendar-page .pds-calendar-page-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    background-color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 100%;
    overflow: hidden;
    position: relative; }
    .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-legend {
      background-color: #FFFFFF;
      -webkit-border-end: 1px solid #838E91;
              border-inline-end: 1px solid #838E91;
      height: 100%;
      overflow-y: auto;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: 200px; }
      @media (max-width: 992px) {
        .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-legend {
          -webkit-box-shadow: 2px 0 15px rgba(0, 0, 0, 0.3);
                  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          bottom: 0;
          z-index: 5500; } }
    .pds-calendar-page .pds-calendar-page-content pds-calendar {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
      height: 100%; }
    .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel {
      background-color: #FFFFFF;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: 300px;
      -webkit-border-start: 1px solid #838E91;
              border-inline-start: 1px solid #838E91;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      height: 100%;
      color: #333333; }
      .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-header {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        background-color: #F1F4F4;
        border-bottom: 1px solid #838E91;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        padding: 10px 20px; }
      .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
                flex: 1 1 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto; }
        .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          margin-top: 10px; }
          .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info .pds-calendar-page-event-name {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin: 0 15px 10px; }
          .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info div.pds-calendar-page-event-time {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin: 0 15px 10px; }
            .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info div.pds-calendar-page-event-time > * {
              -webkit-margin-end: 5px;
                      margin-inline-end: 5px; }
            .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info div.pds-calendar-page-event-time pds-icon {
              height: 20px;
              width: 20px;
              -webkit-margin-end: 10px;
                      margin-inline-end: 10px; }
              .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info div.pds-calendar-page-event-time pds-icon svg {
                fill: #0075DB;
                height: 20px;
                width: 20px; }
          .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info .pds-calendar-page-event-location {
            margin: 0 15px 15px; }
          .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-content .pds-calendar-page-event-info hr {
            margin: 5px 15px 15px;
            width: calc(~"100% - 40px"); }
      .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-footer {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        border-top: 1px solid #838E91;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end; }
        .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel .pds-calendar-page-detail-panel-footer button {
          margin: 10px 20px; }
      @media (max-width: 992px) {
        .pds-calendar-page .pds-calendar-page-content .pds-calendar-page-detail-panel {
          -webkit-box-shadow: -2px 0 15px rgba(0, 0, 0, 0.3);
                  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          inset-inline-end: 0;
          bottom: 0;
          z-index: 5500; } }

/* grid system container styles */
.pds-grid-container {
  display: -ms-grid;
  display: grid;
  /* left/right padding should match gap between columns specified below */
  padding: 10px 20px;
  /* 20px gap between rows and columns */
  gap: 20px;
  /* Create a 12 column grid system of equal widths. */
  -ms-grid-columns: ~"(1fr [col-start])[12]";
  grid-template-columns: ~"repeat(12, 1fr [col-start])"; }

/* for full width grid items */
.pds-grid-1-1 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 12;
  grid-column: span 12; }

/* for grid items taking 3/4 of the row width */
.pds-grid-3-4 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 9;
  grid-column: span 9; }

/* for grid items taking 2/3 of the row width */
.pds-grid-2-3 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 8;
  grid-column: span 8; }

/* for grid items taking 1/2 of the row width */
.pds-grid-1-2 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 6;
  grid-column: span 6; }

/* for grid items taking 1/3 of the row width */
.pds-grid-1-3 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 4;
  grid-column: span 4; }

/* for grid items taking 1/4 of the row width */
.pds-grid-1-4 {
  /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
     It should work the same with any small value. 
   */
  min-width: 5px;
  -ms-grid-column-span: 3;
  grid-column: span 3; }

/* grid system is two equal columns between 993-1200px. Full width grid items (pds-grid-1-1) continue to be full width. */
@media (max-width: 1200px) {
  /* Change styles so each grid item covers 1/2 of the row width */
  .pds-grid-3-4, .pds-grid-2-3, .pds-grid-1-2, .pds-grid-1-3, .pds-grid-1-4 {
    /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
       It should work the same with any small value. 
     */
    min-width: 5px;
    -ms-grid-column-span: 6;
    grid-column: span 6; } }

/* grid system is one single column under 993px */
@media (max-width: 992px) {
  /* Change styles so each grid item covers entire row width */
  .pds-grid-3-4, .pds-grid-2-3, .pds-grid-1-2, .pds-grid-1-3, .pds-grid-1-4 {
    /* This min-width value is only here because it is required for horizontal overflow to work as expected in all browsers. 
       It should work the same with any small value. 
     */
    min-width: 5px;
    -ms-grid-column-span: 12;
    grid-column: span 12; } }

@media (max-width: 576px) {
  /* shrink grid padding and gap to 10px for smaller screens */
  .pds-grid-container {
    padding: 0;
    gap: 20px; } }

/* base dashboard panel styles */
.pds-dashboard-panel {
  background: #FFFFFF;
  /* 10px radius seems to match mockups, but design token specifies 14px? */
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 10px 0 2px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px 0 2px 40px rgba(0, 0, 0, 0.2);
  padding-top: 10px; }
  .pds-dashboard-panel.pds-content-flush {
    padding-top: 0; }

@media (min-width: 993px) {
  .pds-grid-1-2 .pds-grid-widget-pagination-control-content, .pds-grid-1-3 .pds-grid-widget-pagination-control-content, .pds-grid-1-4 .pds-grid-widget-pagination-control-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-grid-1-2 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin, .pds-grid-1-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin, .pds-grid-1-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .pds-grid-1-2 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker, .pds-grid-1-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker, .pds-grid-1-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-margin-end: 0;
                margin-inline-end: 0; }
        .pds-grid-1-2 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span, .pds-grid-1-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span, .pds-grid-1-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span {
          padding: 11px 0 12px; }
      .pds-grid-1-2 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count, .pds-grid-1-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count, .pds-grid-1-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        padding: 5px 0 15px; }
    .pds-grid-1-2 .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls, .pds-grid-1-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls, .pds-grid-1-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }

@media (min-width: 993px) and (max-width: 1200px) {
  .pds-grid-2-3 .pds-grid-widget-pagination-control-content, .pds-grid-3-4 .pds-grid-widget-pagination-control-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pds-grid-2-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin, .pds-grid-3-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .pds-grid-2-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker, .pds-grid-3-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-margin-end: 0;
                margin-inline-end: 0; }
        .pds-grid-2-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span, .pds-grid-3-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-quantity-picker span {
          padding: 11px 0 12px; }
      .pds-grid-2-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count, .pds-grid-3-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-begin .pds-pagination-control-item-count {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        padding: 5px 0 15px; }
    .pds-grid-2-3 .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls, .pds-grid-3-4 .pds-grid-widget-pagination-control-content .pds-pagination-control-page-controls {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }

.pds-calendar-event-form-content {
  max-width: 720px; }
  .pds-calendar-event-form-content .pds-cef-start-date-and-times {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 576px) {
      .pds-calendar-event-form-content .pds-cef-start-date-and-times {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-input-area {
      width: auto; }
      @media (max-width: 576px) {
        .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-input-area {
          width: 100%;
          max-width: none; } }
    .pds-calendar-event-form-content .pds-cef-start-date-and-times pds-date-widget .pds-input-area {
      width: 100%; }
      .pds-calendar-event-form-content .pds-cef-start-date-and-times pds-date-widget .pds-input-area input {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        width: calc(~"100% - 32px"); }
    .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-start-date, .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-start-time, .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-end-time {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 33%;
              flex: 1 1 33%;
      max-width: 250px; }
      @media (max-width: 576px) {
        .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-start-date, .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-start-time, .pds-calendar-event-form-content .pds-cef-start-date-and-times .pds-cef-end-time {
          max-width: none; } }
  .pds-calendar-event-form-content .pds-cef-repeat-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .pds-calendar-event-form-content .pds-cef-repeat-options pds-select-widget .pds-input-area {
      width: 100%;
      max-width: 248px;
      min-width: 248px; }
      @media (max-width: 576px) {
        .pds-calendar-event-form-content .pds-cef-repeat-options pds-select-widget .pds-input-area {
          max-width: none; } }
  .pds-calendar-event-form-content .pds-cef-end-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pds-calendar-event-form-content .pds-cef-end-date pds-date-widget .pds-input-area {
      width: 100%; }
      .pds-calendar-event-form-content .pds-cef-end-date pds-date-widget .pds-input-area input {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        width: calc(~"100% - 32px"); }
    .pds-calendar-event-form-content .pds-cef-end-date > * {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 33%;
              flex: 1 1 33%;
      max-width: 250px; }
      @media (max-width: 576px) {
        .pds-calendar-event-form-content .pds-cef-end-date > * {
          max-width: none; } }
    @media (max-width: 576px) {
      .pds-calendar-event-form-content .pds-cef-end-date {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .pds-calendar-event-form-content .pds-input-radio, .pds-calendar-event-form-content .pds-input-checkbox {
    width: calc(~"100% - 30px"); }
  @media (min-width: 577px) {
    .pds-calendar-event-form-content pds-checkbox-widget ul, .pds-calendar-event-form-content pds-radio-button-widget ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .pds-calendar-event-form-content pds-checkbox-widget ul li, .pds-calendar-event-form-content pds-radio-button-widget ul li {
        -webkit-margin-end: 35px;
                margin-inline-end: 35px; } }
  .pds-calendar-event-form-content .pds-cef-duration {
    margin-top: 0;
    -webkit-margin-end: 15px;
            margin-inline-end: 15px;
    margin-bottom: 20px;
    -webkit-margin-start: 25px;
            margin-inline-start: 25px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600; }
    .pds-calendar-event-form-content .pds-cef-duration .pds-cef-duration-label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 10px; }
  .pds-calendar-event-form-content .pds-cef-calendar-day-multi-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .pds-calendar-event-form-content .pds-cef-calendar-day-multi-select .pds-cef-selected-days-span {
      border: 1px solid #838E91;
      color: #333333;
      background: #FFFFFF;
      margin: 0 15px 20px;
      font-size: 14px;
      line-height: 21px;
      padding: 5px 10px 6px;
      border-radius: 4px; }
  .pds-calendar-event-form-content .pds-cef-warning-message {
    color: #CB1010;
    font-size: 14px;
    line-height: 21px; }

.pds-vertical-tabs {
  background: #FFFFFF;
  list-style: none;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 240px;
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden; }
  .pds-vertical-tabs li {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0; }
    .pds-vertical-tabs li.pds-is-active {
      background-color: #C2E2FF;
      -webkit-border-start: 4px solid #0075DB;
              border-inline-start: 4px solid #0075DB; }
      .pds-vertical-tabs li.pds-is-active a {
        -webkit-padding-start: 16px;
                padding-inline-start: 16px; }
      .pds-vertical-tabs li.pds-is-active pds-icon {
        fill: #0075DB; }
    .pds-vertical-tabs li a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #333333;
      position: relative;
      text-decoration: none;
      padding: 15px 0 14px;
      -webkit-padding-start: 20px;
              padding-inline-start: 20px;
      cursor: pointer; }
      .pds-vertical-tabs li a:hover, .pds-vertical-tabs li a:focus {
        background-color: #F1F4F4; }
        .pds-vertical-tabs li a:hover pds-icon, .pds-vertical-tabs li a:focus pds-icon {
          fill: #0075DB; }
      .pds-vertical-tabs li a .pds-icon-container {
        overflow: hidden;
        width: 24px;
        height: 24px;
        margin: 0;
        -webkit-margin-end: 15px;
                margin-inline-end: 15px;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
        .pds-vertical-tabs li a .pds-icon-container img {
          width: 24px; }
      .pds-vertical-tabs li a pds-icon {
        padding-top: 0;
        padding-bottom: 0;
        -webkit-box-align: unset;
            -ms-flex-align: unset;
                align-items: unset;
        -webkit-box-pack: unset;
            -ms-flex-pack: unset;
                justify-content: unset; }
        .pds-vertical-tabs li a pds-icon svg {
          width: 24px;
          height: 24px; }
      .pds-vertical-tabs li a .pds-vertical-tab-text {
        max-width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
      .pds-vertical-tabs li a .pds-icon-container + .pds-vertical-tab-text {
        max-width: 167px; }

.pds-notification-badge-content {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .pds-notification-badge-content .pds-notification-badge-circle {
    font-family: Open Sans, sans-serif;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #0075DB;
    text-align: center;
    min-width: 24px;
    border-radius: 9px;
    padding: 3px 5px;
    line-height: 12px; }
    .pds-notification-badge-content .pds-notification-badge-circle.pds-has-count {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 12px; }
    .pds-notification-badge-content .pds-notification-badge-circle.pds-missing-notification-count {
      min-width: inherit;
      border-radius: 50%;
      height: 10px;
      width: 10px; }
    .pds-notification-badge-content .pds-notification-badge-circle.pds-additional-styles {
      background-color: #EB1313; }
  .pds-notification-badge-content.pds-is-elevated .pds-notification-badge-circle {
    background-color: #EB1313; }

.pds-global-search-content {
  position: relative;
  max-width: 480px;
  width: 480px;
  height: 60px;
  -webkit-margin-end: 5px;
          margin-inline-end: 5px; }
  .pds-global-search-content * {
    -webkit-box-sizing: inherit;
            box-sizing: inherit; }
  .pds-global-search-content button {
    display: none;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: transparent;
    border-radius: 50%;
    border: none; }
    .pds-global-search-content button:hover {
      background-color: transparent; }
      @media (max-width: 768px) {
        .pds-global-search-content button:hover {
          background-color: #FFFFFF; } }
    .pds-global-search-content button pds-icon {
      margin: 0; }
    @media (max-width: 768px) {
      .pds-global-search-content button {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex; } }
  .pds-global-search-content .pds-global-search-input-icon {
    position: absolute;
    width: 20px;
    min-width: 20px;
    height: 20px;
    top: calc(~"50% - 9px");
    inset-inline-end: 7px;
    pointer-events: none; }
    .pds-global-search-content .pds-global-search-input-icon:hover {
      cursor: pointer; }
    @media (max-width: 768px) {
      .pds-global-search-content .pds-global-search-input-icon {
        display: none; } }
  .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls button pds-icon {
    fill: #677274; }
  @media (max-width: 768px) {
    .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls button {
      display: none; }
      .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls button:hover, .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls button:active {
        background-color: transparent; } }
  .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls input {
    width: 480px;
    max-width: 480px;
    height: 32px;
    opacity: 1;
    border: 1px solid #F1F4F4;
    -webkit-padding-end: 44px;
            padding-inline-end: 44px;
    cursor: text; }
    @media (max-width: 768px) {
      .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls input {
        width: 100%;
        max-width: 100%; } }
    .pds-global-search-content.pds-global-search-is-active .pds-global-search-controls input.pds-global-search-active-input {
      border-radius: 0;
      border-start-start-radius: 4px;
      border-start-end-radius: 4px; }
  .pds-global-search-content .pds-global-search-controls {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 60px;
    z-index: 8000; }
    .pds-global-search-content .pds-global-search-controls button {
      position: absolute;
      top: 16px;
      inset-inline-end: 1px;
      z-index: 1; }
      .pds-global-search-content .pds-global-search-controls button pds-icon {
        fill: #677274; }
      .pds-global-search-content .pds-global-search-controls button:hover:not([disabled]), .pds-global-search-content .pds-global-search-controls button:focus:not([disabled]), .pds-global-search-content .pds-global-search-controls button:active {
        border: none;
        background-color: transparent; }
        .pds-global-search-content .pds-global-search-controls button:hover:not([disabled]) pds-icon, .pds-global-search-content .pds-global-search-controls button:focus:not([disabled]) pds-icon, .pds-global-search-content .pds-global-search-controls button:active pds-icon {
          fill: #00427C; }
        @media (max-width: 768px) {
          .pds-global-search-content .pds-global-search-controls button:hover:not([disabled]), .pds-global-search-content .pds-global-search-controls button:focus:not([disabled]), .pds-global-search-content .pds-global-search-controls button:active {
            background-color: #FFFFFF; }
            .pds-global-search-content .pds-global-search-controls button:hover:not([disabled]).pds-button-active, .pds-global-search-content .pds-global-search-controls button:focus:not([disabled]).pds-button-active, .pds-global-search-content .pds-global-search-controls button:active.pds-button-active {
              background-color: transparent; }
            .pds-global-search-content .pds-global-search-controls button:hover:not([disabled]) pds-icon, .pds-global-search-content .pds-global-search-controls button:focus:not([disabled]) pds-icon, .pds-global-search-content .pds-global-search-controls button:active pds-icon {
              border-radius: 50%;
              background-color: #FFFFFF;
              fill: #00427C; } }
      @media (max-width: 768px) {
        .pds-global-search-content .pds-global-search-controls button pds-icon {
          fill: #FFFFFF; } }
    @media (max-width: 768px) {
      .pds-global-search-content .pds-global-search-controls.pds-global-search-show-input input {
        display: block; }
      .pds-global-search-content .pds-global-search-controls.pds-global-search-show-input .pds-global-search-input-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex; } }
    .pds-global-search-content .pds-global-search-controls input {
      -webkit-transition: none;
      transition: none;
      border-radius: 4px;
      margin: 0;
      cursor: pointer;
      border: none;
      position: absolute;
      inset-inline-end: 0;
      -webkit-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      top: calc(~"50% - 15px");
      width: 180px;
      max-width: 180px;
      opacity: 1;
      height: 32px; }
      .pds-global-search-content .pds-global-search-controls input::-webkit-input-placeholder {
        color: #677274;
        text-decoration: none;
        font-weight: 400; }
      .pds-global-search-content .pds-global-search-controls input:hover::-webkit-input-placeholder, .pds-global-search-content .pds-global-search-controls input:focus::-webkit-input-placeholder {
        color: #333333;
        text-decoration: underline;
        font-weight: 600; }
      @media (max-width: 768px) {
        .pds-global-search-content .pds-global-search-controls input {
          display: none;
          opacity: 0;
          width: 0; } }
  .pds-global-search-content .pds-global-search-result-container {
    background: #FFFFFF;
    max-width: 480px;
    width: 480px;
    position: absolute;
    top: 47px;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-end-end-radius: 4px;
    border-end-start-radius: 4px;
    overflow: auto;
    max-height: 80vh;
    z-index: 7000; }
    @media (max-width: 768px) {
      .pds-global-search-content .pds-global-search-result-container {
        width: 100%;
        max-width: 100%; } }
  .pds-global-search-content .pds-global-search-result-category ul {
    margin: 0; }
  .pds-global-search-content .pds-global-search-result-category.pds-global-search-is-sticky .pds-global-search-category-title {
    position: sticky;
    top: 0; }
  .pds-global-search-content .pds-global-search-category-title {
    background-color: #F1F4F4;
    padding: 10px 10px;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 21px; }
  .pds-global-search-content .pds-global-search-results-list-item {
    list-style: none;
    padding: 5px; }
    .pds-global-search-content .pds-global-search-results-list-item:focus-within {
      -webkit-box-shadow: inset 0px 0px 0 1px #6AF0E0;
              box-shadow: inset 0px 0px 0 1px #6AF0E0; }
  .pds-global-search-content .pds-global-search-result-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .pds-global-search-content .pds-global-search-result-item:hover, .pds-global-search-content .pds-global-search-result-item:focus {
      background-color: #F1F4F4;
      border-radius: 4px;
      text-decoration: none;
      outline: none; }
      .pds-global-search-content .pds-global-search-result-item:hover .pds-global-search-item-name, .pds-global-search-content .pds-global-search-result-item:focus .pds-global-search-item-name {
        text-decoration: underline;
        color: #333333;
        font-weight: bold;
        letter-spacing: -0.2px; }
      .pds-global-search-content .pds-global-search-result-item:hover .pds-global-search-item-user, .pds-global-search-content .pds-global-search-result-item:focus .pds-global-search-item-user {
        text-decoration: none;
        border: 1px solid #333333; }
      .pds-global-search-content .pds-global-search-result-item:hover .pds-global-search-item-image, .pds-global-search-content .pds-global-search-result-item:focus .pds-global-search-item-image {
        border: 1px solid #333333; }
      .pds-global-search-content .pds-global-search-result-item:hover pds-icon, .pds-global-search-content .pds-global-search-result-item:focus pds-icon {
        fill: #333333; }
    .pds-global-search-content .pds-global-search-result-item:focus {
      outline: none; }
  .pds-global-search-content .pds-global-search-item-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .pds-global-search-content .pds-global-search-media-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 38px;
    padding-top: 5px;
    -webkit-padding-start: 5px;
            padding-inline-start: 5px; }
    .pds-global-search-content .pds-global-search-media-container pds-icon {
      -webkit-margin-start: 3px;
              margin-inline-start: 3px; }
  .pds-global-search-content .pds-global-search-item-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #838E91; }
  .pds-global-search-content .pds-global-search-item-user {
    width: 24px;
    height: 24px;
    border: 1px solid #4D5557;
    border-radius: 100%;
    background: #0075DB;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .pds-global-search-content .pds-global-search-result-item-description-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow: hidden; }
  .pds-global-search-content .pds-global-search-item-name {
    text-align: start;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 21px;
    font-weight: unset;
    font-family: unset;
    overflow-wrap: anywhere; }
  .pds-global-search-content .pds-global-search-item-description {
    font-size: 12px;
    color: #677274;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 18px;
    max-width: 400px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 57px; }
  .pds-global-search-content .pds-global-search-item-description-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    font-size: 12px;
    color: #677274;
    font-weight: 400; }
  .pds-global-search-content .pds-global-search-item-description-list-item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    max-width: 400px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-margin-end: 5px;
            margin-inline-end: 5px; }
    .pds-global-search-content .pds-global-search-item-description-list-item:before {
      content: '\2022';
      display: inline-block;
      -webkit-padding-end: 8px;
              padding-inline-end: 8px; }
    .pds-global-search-content .pds-global-search-item-description-list-item:first-of-type:before {
      display: none; }
    .pds-global-search-content .pds-global-search-item-description-list-item:last-of-type {
      -webkit-margin-end: 0;
              margin-inline-end: 0; }
    .pds-global-search-content .pds-global-search-item-description-list-item dt, .pds-global-search-content .pds-global-search-item-description-list-item dd {
      margin: 0;
      white-space: nowrap; }
    .pds-global-search-content .pds-global-search-item-description-list-item dt {
      max-width: 200px; }
    .pds-global-search-content .pds-global-search-item-description-list-item dd {
      -webkit-margin-start: 3px;
              margin-inline-start: 3px;
      overflow: hidden;
      text-overflow: ellipsis; }
  .pds-global-search-content .pds-global-search-no-results {
    padding: 20px 0; }
    .pds-global-search-content .pds-global-search-no-results h2 {
      margin: 0;
      padding: 0;
      text-align: center;
      color: #333333;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600; }
    .pds-global-search-content .pds-global-search-no-results p {
      color: #677274;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      padding-top: 5px; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  overflow: visible;
  border: 0;
  height: 1px;
  background: #C4C9CA;
  margin-bottom: 21px;
  width: 100%; }

template {
  display: none !important; }

ul, ol, li, dl, td, dd {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

ul, ol {
  margin: 0 15px 15px;
  list-style-position: outside; }
  ul.pds-app-nav, ol.pds-app-nav {
    margin: 0; }

ol {
  -webkit-margin-start: 28px;
          margin-inline-start: 28px; }
  ol ol {
    -webkit-margin-start: 18px;
            margin-inline-start: 18px; }
  ol li {
    -webkit-padding-start: 3px;
            padding-inline-start: 3px; }

ul {
  list-style-type: disc;
  -webkit-margin-start: 31px;
          margin-inline-start: 31px; }
  ul ul {
    -webkit-margin-start: 18px;
            margin-inline-start: 18px; }

blockquote {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

pre {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto; }

.pds-is-hidden {
  display: none !important; }

.pds-is-invisible {
  visibility: hidden; }

@media (max-width: 576px) {
  .pds-hide-below-bp-sm {
    display: none; } }

@media (min-width: 577px) {
  .pds-hide-above-bp-sm {
    display: none; } }

@media (max-width: 768px) {
  .pds-hide-below-bp-md {
    display: none; } }

@media (min-width: 769px) {
  .pds-hide-above-bp-md {
    display: none; } }

@media (max-width: 992px) {
  .pds-hide-below-bp-lg {
    display: none; } }

@media (min-width: 993px) {
  .pds-hide-above-bp-lg {
    display: none; } }

@media (max-width: 1200px) {
  .pds-hide-below-bp-xl {
    display: none; } }

@media (min-width: 1201px) {
  .pds-hide-above-bp-xl {
    display: none; } }

div {
  font-family: Open Sans, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

fieldset {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  fieldset legend {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  border-style: none; }
}