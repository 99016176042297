.regional-toggle-on {
    width:  20em;
}

.regional-show-toggle .regional-month-display {
    font-size: 0.8em;
    font-weight: bold;
    color: grey;
    width: 55%;
    margin-right: 10px;
    unicode-bidi: bidi-override;
}

.regional-show-toggle .regional-toggle-text {
    font-size: 0.8em;
    font-weight: bold;
    color: grey;
}

.regional-show-toggle {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 5px;
    position: relative;
}

.regional-show-toggle .regional {
    display: flex;
    column-gap: 0.5rem;
    position: absolute;
    bottom: 5px;
}
.regional-show-toggle .regional[dir="rtl"] {
    left: 5px;
}
.regional-show-toggle .regional[dir="ltr"] {
    right: 5px;
}

.regional-date {
    font-size: 0.8em;
    font-weight: bold;
}
.regional-switch {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    height: 1.15rem;
    width: 40px;
    background: rgba(0, 0, 0, .2);
    border-radius: 6.25rem;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, .4);
    padding-inline-start: 0.2em;
    padding-inline-end: 0.2em;
}
.regional-switch input {
    position: absolute;
    opacity: 0;
}
.regional-switch div {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 6.25rem;
    background: #fff;;
    box-shadow: 0 0.1em 0.3em #0000004d;
    transition: all .3s;
}
.regional-toggle-active {
    background: #0066A5;
}

.hijriCompleteDate {
    float: none;
    [dir="rtl"] & {
        float: none;
    }
}

.regional-cal-toggle {
    transform: translate3d(100%,0,0);
}

.regional-toggle {
    display: flex;
    font-weight: bold;
    position: relative;
    justify-content: flex-end;
    margin: 0 20px;
    bottom: 10px;
}

.regional-toggle .regional-toggle-text {
    font-size: 0.85em;
    font-weight: bold;
}

.regional-toggle .regional {
    display: flex;
    column-gap: 0.5rem;
    position: absolute;
    bottom: 5px;
}

.regional-date-text {
    font-size: 0.85em;
    font-weight: 200;
}


.regional-greg-date {
    font-weight: bold;
}